import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatToCurrency } from '../../../utils/Currency';
import './WalletCard.scss';

const WalletCard = ({
  image, title, balance, id, cardholderId, petitionId
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  
  return (
    <div className="WalletCard" onClick={() => {
      history.push({ pathname: `/petitions/${petitionId}/transactions/${id}`, 
        state: {
          balance: balance, title: title, petitionId, cardholderId,
          walletId: id
        }
      })
    }}>
      <img className='WalletCard__image' src={image} alt=""/>
      <span className='WalletCard__title'> {title} </span>
      <div className='WalletCard__balance'>
        <span className='WalletCard__balance__amount' style={{
          color: balance == 0 ? '#ee404c' : null
        }}>{formatToCurrency(balance)}</span>
        <span className='WalletCard__balance__label'>{t('Transactions.balanceAvailable')}</span>
      </div>
    </div>
  )
};

export default WalletCard;
