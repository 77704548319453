import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../../../components';
import { DisplayUtils } from '../../../../../utils';
import './PetitionSignupSuccess.scss';

const PetitionSignupSuccess = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!location?.state?.redirect) {
      history.goBack();
    } else {
      setShow(true);
    }
  }, []); //eslint-disable-line

  return (
    show && (
      <div className="PetitionSignupSuccess">
        <div className="w-100 d-flex justify-content-center">
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="PetitionSignupSuccess__logo" />
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="PetitionSignupSuccess__container">
            <div className="row m-0 justify-content-center">
              <div className="row m-0 justify-content-center">
                <img
                  src="/misc/icons/signup-success.png"
                  alt="shop-icon"
                  className="PetitionSignupSuccess__container__icon"
                />
              </div>
              <h6>{t('PetitionSignup.success.title')}</h6>
              <p>{t('PetitionSignup.success.description')}</p>
              <p className='PetitionSignupSuccess__container__info'>{t('PetitionSignup.success.info')}</p>
            </div>
            <div className="PetitionSignupSuccess__container__buttons">
              <Button
                className="buttonPrimaryLarge"
                text={t('Globals.next')}
                action={() => history.replace('/')}
                styles={{
                  backgroundColor: DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe',
                  color: DisplayUtils.isMobile() ? '#381940' : '#381940',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PetitionSignupSuccess;
