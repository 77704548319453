const STATUS = {
  CHANGE_ACCOUNT_TYPE: 'CHANGE_ACCOUNT_TYPE',
  VERIFIED_IDENTITY: 'VERIFIED_IDENTITY',
  AUTOMATED_CONSULTATION_AUTHORIZATION: 'AUTOMATED_CONSULTATION_AUTHORIZATION',
  PROVIDED_DATA_REPRESENTATIVE: 'PROVIDED_DATA_REPRESENTATIVE',
  PROVIDED_DATA_BENEFICIARY: 'PROVIDED_DATA_BENEFICIARY',
  PROVIDED_DATA_CARDS: 'PROVIDED_DATA_CARDS',
  PROVIDED_DATA_NOTIFICATION: 'PROVIDED_DATA_NOTIFICATION',
  PROVIDED_DOCUMENTATION: 'PROVIDED_DOCUMENTATION',
  REGISTERED: 'REGISTERED',
  NOT_APPLIED: 'NOT_APPLIED',
  PENDING_FORM: 'PENDING_FORM',
  PENDING_RESPONSIBLE_DECLARATION: 'PENDING_RESPONSIBLE_DECLARATION',
  PENDING_SIGN: 'PENDING_SIGN',
  PENDING_CORRECTION: 'PENDING_CORRECTION',
  PENDING_REVIEW: 'PENDING_REVIEW',
  PENDING_SECOND_REVIEW: 'PENDING_SECOND_REVIEW',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  DISMISSAL: 'DISMISSAL',
  CLAIM: 'PENDING_CLAIM',
  CLAIM_REVIEW: 'PENDING_CLAIM_REVIEW',
};

const showJoin = (status) => {
    if (status === STATUS.NOT_APPLIED || status === STATUS.PENDING_FORM || status === STATUS.PENDING_RESPONSIBLE_DECLARATION || status === STATUS.PENDING_SIGN) {
        return true;
    }
    return false
}

const showStatus = (status) => {
    if (status === STATUS.PENDING_CORRECTION || status === STATUS.PENDING_REVIEW || status === STATUS.PENDING_SECOND_REVIEW || status === STATUS.REJECTED || status === STATUS.ACCEPTED || status === STATUS.CLAIM || status === STATUS.CLAIM_REVIEW) {
        return true;
    }
    return false
}

const showProfileOptions = (status) => {
    if(showStatus(status)) {
        return true
    }
    return false
}

const allowEditProfile = (status) => {
  if(status === STATUS.PENDING_CORRECTION) return true;
  return false;
}


const showTransactions =  (status) => {
    if (status === STATUS.ACCEPTED) {
        return true;
    }
    return false
}

const isInProgress = (status) => {
  return [
    STATUS.VERIFIED_IDENTITY, 
    STATUS.CHANGE_ACCOUNT_TYPE,
    STATUS.AUTOMATED_CONSULTATION_AUTHORIZATION,
    STATUS.PROVIDED_DATA_REPRESENTATIVE,
    STATUS.PROVIDED_DATA_BENEFICIARY,
    STATUS.PROVIDED_DATA_CARDS,
    STATUS.PROVIDED_DATA_NOTIFICATION,
    STATUS.PROVIDED_DOCUMENTATION,
  ].includes(status);
}

export default {
    STATUS,
    showJoin,
    showStatus,
    showProfileOptions,
    allowEditProfile,
    showTransactions,
    isInProgress
}