import { Button } from '../../components';
import { DisplayUtils } from '../../utils';
import './MainLayout.scss';

const MainLayout = ({
  title, 
  onBack,
  children
}) => {

  return (
    <div className="MainLayout">
      <div className="w-100 d-flex justify-content-center">
        {!DisplayUtils.isMobile() ? (
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="MainLayout__logo" />
        ) : (
          <div className="MainLayout__title">
            <Button
              icon="/misc/icons/arrow-left-blue.svg"
              action={onBack}
              className="Button__transparent"
            />
            <h6>{title}</h6>
          </div>
        )}
      </div>
      <div className="w-100 d-flex justify-content-center">
          <div className="MainLayout__container">
            {!DisplayUtils.isMobile() && (
              <div className="MainLayout__container__title">
                <Button
                  icon="/misc/icons/arrow-left-pink.svg"
                  action={onBack}
                  className="Button__transparent"
                  styles={{ width: '50px' }}
                />
                <h6>{title}</h6>
              </div>
            )}
            { children }
          </div>
      </div>
    </div>
  );
}

export default MainLayout;