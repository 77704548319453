import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { DisplayUtils } from '../../../../utils';
import { Button } from '../../../../components';
import './CookiesModal.scss';

const CookiesModal = ({ isShowingModal, action, toggleModal }) => {

    const { t } = useTranslation();

    return isShowingModal
        ? ReactDOM.createPortal(
            <React.Fragment>
            <div aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="CookiesModal">
                    <img className="CookiesModal__close" src='/misc/icons/close-pink.png' alt='close' onClick={() => toggleModal()}/>
                    <img className="CookiesModal__image" src={DisplayUtils.isMobile() ? '/misc/img/cookies-mobile.svg' : '/misc/img/cookies-desktop.svg'} alt='cookies'/>
                    <span className="CookiesModal__info">{t('Cookies.info')}<Link to="/cookies">{t('Cookies.here')}</Link>.</span>
                    <div className="CookiesModal__actions">
                        <Button
                            className="buttonPrimaryLarge"
                            text={t('Cookies.accept')}
                            styles={{ fontSize: '12px', width: '128px', height: '32px', backgroundColor: '#34fdfe', color: '#381940', marginBottom: '24px' }}
                            action={() => action()}
                        />
                        <Button
                            className="buttonPrimaryLarge"
                            text={t('Cookies.reject')}
                            styles={{ fontSize: '12px', width: '128px', height: '32px', backgroundColor: '#34fdfe', color: '#381940', marginBottom: '24px' }}
                            action={() => action()}
                        />
                    </div>
                </div>
            </div>
            </React.Fragment>,
            document.body
        )
        : null;
};

export default CookiesModal;