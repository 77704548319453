import { useTranslation } from 'react-i18next';
import { Button, Loading, NavMenu } from '../../../../../components';
import { useErrorsContext } from '../../../../../contexts/ErrorsContext';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DateUtils, DisplayUtils } from '../../../../../utils';
import './ChangeEmailDetail.scss';
import { useParametrics } from '../../../../../contexts/ParametricsContext';
import { getChangeEmailProcedure } from '../../../../../services/procedureServices';
import { ChangeEmailCard } from '../ChangeEmailCard/ChangeEmailCard';

const ChangeEmailDetail = () => {

  const { t } = useTranslation();
  const { convocationId } = useParametrics();
  const { showError } = useErrorsContext();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [petition, setPetition] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [procedure, setProcedure] = useState();

  const { petitionId } = useParams();
  const { procedureId } = useParams();

  const getDocuments = async () => {
    try {
      setLoading(true);
      const resp = await getChangeEmailProcedure(petitionId, procedureId);
      console.log(resp);
      setDocuments(resp.files);
      setProcedure(resp);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.petition || !procedureId || !petitionId) {
      return history.replace('/petitions')
    }
    setPetition(location.state.petition);
  }, [convocationId]);

  useEffect(() => {
    if(petition) getDocuments();
  }, [petition])

  return (
    <NavMenu>
      <div className="ChangeEmailDetail">
        <div className="content">
          {
            loading ? (
              <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
              </div>
            ) : (
              <>
                <div className="w-100 d-flex justify-content-center">
                  <div className="header">
                    <img src='/misc/icons/arrow-left-blue.svg' alt='' onClick={() => {
                      history.goBack();
                    }}/>
                    <h6>Detalle procedimiento</h6>
                  </div>
                </div>
                <div className="petitions">
                  <ChangeEmailCard 
                    petition={petition}
                    procedure={procedure}
                    hasAction={false}
                  />
                </div>
                {
                  procedure?.verified === 'PENDING' && (
                    <div className="verify-email-button">
                      <Button
                        className="buttonPrimaryLarge"
                        text={t('Petitions.ChangeEmail.ChangeEmailList.verifyEmail')}
                        styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                        action={() => {
                          history.push(`/petitions/${petitionId}/change-email/${procedureId}/verify-email`, {
                            petition,
                            procedureId,
                            changeEmailVerification: true,
                          });
                        }}
                      />
                    </div>
                  )
                }
                <div className="documents">
                  <h5>Documentos subidos</h5>
                  {documents?.filter(d => d.fileId).map((document) => {
                    return (
                        DisplayUtils.isMobile() ? (
                          <div key={document.fileId} className="document" >
                            <img className='icon-left' src="/misc/icons/list-info-pink.png" alt='' />
                            <div className="document-data">
                              <div className="document-data-row">
                                <div className="document-column">
                                  <div className="document-column-title">{t('Petitions.documentation.document')}</div>
                                  <div className="document-column-value">{document.title}</div>
                                </div>
                              </div>
                            </div>
                            <img className='icon-right' src='/misc/icons/download.png' alt='' onClick={() => {
                              window.open(`/pdf-viewer/?id=${document.fileId}`);
                            }}/>
                          </div>
                        ) : (
                          <div key={document.fileId} className="document" >
                            <img className='icon-left' src="/misc/icons/list-info-pink.png" alt='' />
                            <div className="document-data">
                              <div className="document-data-row">
                                <div className="document-column">
                                  <div className="document-column-title">{t('Petitions.documentation.document')}</div>
                                  <div className="document-column-value">{document.title}</div>
                                </div>
                                <div className="document-column">
                                  <div className="document-column-title">{t('Petitions.documentation.date')}</div>
                                  <div className="document-column-value">{DateUtils.getFormatedDate(document.updatedTime)}</div>
                                </div>
                              </div>
                              <div className="document-data-row">
                                <div className="document-column">
                                  <div className="document-column-title">{t('Petitions.documentation.csv')}</div>
                                  <div className="document-column-value">{document.csv}</div>
                                </div>
                              </div>
                            </div>
                            <img className='icon-right' src='/misc/icons/download.png' alt='' onClick={() => {
                              window.open(`/pdf-viewer/?id=${document.fileId}`);
                            }}/>
                          </div>
                        )
                    )
                  })}
                </div>
              </>
            )
          }
        </div>
      </div>
    </NavMenu>
  );
}

export default ChangeEmailDetail;