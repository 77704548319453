import { Button } from '../../../index';
import { DisplayUtils } from '../../../../utils';
import './ViewModal.scss';

const ViewModal = ({
  imageSource,
  title,
  description,
  action,
  secondAction,
  textBtn,
  secondTextBtn,
}) => (
  <div className="View">
    <div className="View__logo">
      <img src="/misc/icons/complete_logo_white.svg" alt="" style={{ height: '100%', width: '100%' }} />
    </div>
    <div className="View__icon">
      <img src={imageSource} alt="" style={{ height: '100%', width: '100%' }} />
    </div>
    <h5 className="View__title">{title}</h5>
    <span className="View__description">{description}</span>
    <div className="View__buttons">
      <Button
        className="buttonPrimaryLarge"
        text={textBtn}
        action={action}
        styles={{
          backgroundColor: DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe',
          color: DisplayUtils.isMobile() ? '#381940' : '#381940',
        }}
      />

      {secondTextBtn && (
        <Button
          className="Button__onboarding"
          text={secondTextBtn}
          action={secondAction}
          styles={{
            marginTop: '25px',
            color: DisplayUtils.isMobile() ? '#ffffff' : '#f838f9',
          }}
        />
      )}
    </div>
  </div>
);

export default ViewModal;
