import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useGeolocationContext } from '../../../../contexts/GeolocationContext';
import './ShopItem.scss';

/**
 * 
 * @param {{
 *  isLoading: boolean,
 *  shop: ShopModel, 
 *  onClick: Function,
 * }} props 
 * @returns 
 */
 const ShopItem = ({ isLoading = false, shop, onClick }) => {

  const { t } = useTranslation();
  const geolocation = useGeolocationContext();

  const getDistance = (localLat, localLong) => {
    if(geolocation?.position) {
      const myLat = geolocation.position.lat;
      const myLong = geolocation.position.lng;
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(localLat-myLat); // deg2rad below
      const dLon = deg2rad(localLong-myLong);
      const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(myLat)) * Math.cos(deg2rad(localLat)) * Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      const d = R * c; // Distance in km

      return `A ${d.toFixed(2)}Km`
    }

    return ""
  }

  const deg2rad = (deg) => {
    return deg * (Math.PI/180)
  }

  return (
    <div className="ShopItem" onClick={onClick}>
      {
        isLoading ? (
          <div className="ShopItem__left">
            <Skeleton width={40} height={60} />
          </div>
        ) : (
          <div className="ShopItem__left">
            <div className="ShopItem__left__image">
              <img alt='icon' src='misc/icons/logo-bono.png' />
            </div>
            <div className="ShopItem__left__distance">
              {getDistance(shop?.latitude, shop?.longitude)}
            </div>
          </div>
        )
      }
      <div className="ShopItem__right">
        <div className="ShopItem__right__name">
          {isLoading ? <Skeleton /> : shop?.name}
        </div>
        <div className="ShopItem__right__address">
          {isLoading ? <Skeleton /> : shop?.address}
        </div>
        {shop?.subCategories.split(",")?.map((item, index) => (
          <span className="ShopItem__right__types d-block" key={index}>
            - {t(`Globals.merchantType.${item.trim()}`)}.
          </span>
        ))}
      </div>
    </div>
  );
}

export default ShopItem;