const TYPE = {
  MOBILE: 'mobile',
  PHABLET: 'phablet',
  TABLET: 'tablet',
  WEB: 'web',
};
const DisplayType = (width) => {
  if (width < 768) {
    return TYPE.MOBILE;
  } else if (width >= 768 && width < 1025) {
    return TYPE.TABLET;
  } else {
    return TYPE.WEB;
  }
};
const isMobile = () => {
  return DisplayType(window.innerWidth) !== TYPE.WEB;
};
const isOnlyMobile = () => {
  return DisplayType(window.innerWidth) === TYPE.MOBILE;
};
const isTablet = () => {
  return DisplayType(window.innerWidth) === TYPE.TABLET;
};

const getCols = () => {
  const width = window.innerWidth;
  if (width < 768) return 12;
  else if (width >= 768 && width < 1200) return 4;
  else return 3;
};
const getPageSize = () => {
  const width = window.innerWidth;
  if (width < 768) return 8;
  else if (width >= 768 && width < 1200) return 6;
  else return 8;
};

const getImageUrl = (imageUrl) => {
  return imageUrl && imageUrl.includes('http:') ? imageUrl.replace('http:', 'https:') : imageUrl;
};

const getInitials = (name) => {
  let initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const generateRandomCode = () => {
  const characterList = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';
  const passwordLength = 15;
  const characterListLength = characterList.length;
  for (let i = 0; i < passwordLength; i++) {
    const characterIndex = Math.round(Math.random() * characterListLength)
    password = password + characterList.charAt(characterIndex)
  }
  return `${password}1`;
}

export default {
  TYPE,
  DisplayType,
  isMobile,
  isTablet,
  getCols,
  isOnlyMobile,
  getPageSize,
  getImageUrl,
  getInitials,
  generateRandomCode
};
