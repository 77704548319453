import { createContext, useContext, useEffect, useState } from "react";
import { constants } from "../utils";
import i18next from 'i18next';

const LanguageContext = createContext();

export const useLanguageContext = () => useContext(LanguageContext);

export const LanguageContextProvider = ({ children }) => {

  const [language, setLanguage] = useState();

  const changeLanguage = (lang) => {
    localStorage.setItem(constants.CURRENT_LANGUAGE_KEY, lang);
    setLanguage(lang);
    i18next.changeLanguage(lang);
  }

  useEffect(() => {
    const currentLang = localStorage.getItem(constants.CURRENT_LANGUAGE_KEY) ?? 'es';
    changeLanguage(currentLang);
  }, []);

  const value = {
    currentLanguage: language,
    changeLanguage: changeLanguage
  }

  return (
    <LanguageContext.Provider value={value}>
      { children }
    </LanguageContext.Provider>
  );
}