import { useTranslation } from 'react-i18next';
import { useShopsContext } from '../../contexts/ShopsContext';
import ShopItem from '../shopItem/ShopItem';
import './List.scss';

/**
 * 
 * @returns 
 */
 const List = ({ showEmptySearch = false }) => {

  const { t } = useTranslation();
  const { loading, shops, selectShop } = useShopsContext();

  if (showEmptySearch) return (
    <div className="List List-no-items">
      <div className="no-items-container">
        <div className="image">
          <img src="/misc/icons/search.svg" alt="" />
        </div>
        <div className="text">
          {t('Shops.list.emptySearch')}
        </div>
      </div>
    </div>
  );

  return (
    <div className="List">
      {loading ? (
        [0, 1, 2, 3, 4].map((index) => (
          <ShopItem key={index} isLoading={true} />
        ))
      ) : (
        shops?.length > 0 ? (
          shops?.map(s => (
            <ShopItem key={s.id} isLoading={false} shop={s} onClick={() => {
              selectShop(s);
            }} />
          ))
        ) : (
          <div className="List List-no-items">
            <div className="no-items-container">
              <div className="image">
                <img src="/misc/icons/charlar.svg" alt="" />
              </div>
              <div className="text">
                <p>
                  {t('Shops.list.noResults2')}
                </p>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default List;