import { useTranslation } from "react-i18next";
import { StatusUtils } from "../../utils";
import { Button } from "../../components";
import "./PetitionCard.scss";
import { useHistory } from "react-router-dom";
import { loginToPetition } from "../../services/petitionServices";
import { useParametrics } from "../../contexts/ParametricsContext"
import { useErrorsContext } from "../../contexts/ErrorsContext";
import { getBeneficiary } from "../../services/authServices";
import { useAuthContext } from "../../contexts/AuthContext";
import { useState } from "react";

export const PetitionInProgressCard = ({
  convocationTitle,
  petition,
  isSso = false,
}) => {
  const { convocationId } = useParametrics();
  const { t } = useTranslation();
  const history = useHistory();
  const { showError } = useErrorsContext();
  const { login } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const redirectToJoin = () => {
    const userType = petition?.accountType === 'REPRESENTATIVE' ? 'representative' : 'beneficiary';
    if(petition.processStatus.nextStatus === StatusUtils.STATUS.CHANGE_ACCOUNT_TYPE)
      history.push(`/join/select-user-type`, { selectUserType: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.VERIFIED_IDENTITY)
      history.push(`/${userType}/join/confirm-identity`, { confirmIdentity: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.AUTOMATED_CONSULTATION_AUTHORIZATION)
      history.push(`/${userType}/join/automated-queries`, { automatedQueries: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_BENEFICIARY)
      history.push(`/${userType}/join/beneficiary-data`, { beneficiaryData: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_REPRESENTATIVE)
      history.push(`/${userType}/join/representative-data`, { representativeData: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_CARDS)
      history.push(`/${userType}/join/card-selector`, { cardTypeSelector: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_NOTIFICATION)
      history.push(`/${userType}/join/notifications-address`, { notificationsAddressInfo: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DOCUMENTATION)
      history.push(`/${userType}/join/upload-documentation`, { uploadDocumentation: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.REGISTERED)
      history.push(`/${userType}/join/sign-request`, { signRequest: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
  }

  const ssoLoginAction = async () => {
    try {
      setLoading(true);
      await loginToPetition(convocationId, petition.petitionId);
      const user = await getBeneficiary();
      login({
        ...user,
        fullName: user.name === null ? null : user.fullName,
      });
      redirectToJoin();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="PetitionCard">
      <div className="convocation-info">
        {
          <div className="convocation-info__title">
            <span>{convocationTitle}</span>
          </div>
        }
      </div>
      <div className="petition-title">{t("Petitions.petitionCard.inProgressTitle")} </div>
      <div className="petition-status">
        <img src={"/misc/icons/status-pending.png"} alt="" />
      </div>
      <div className="data-container">
        {
          petition.beneficiary && (
            <div className="data">
              <b>{t("Petitions.petitionCard.beneficiary")}:</b>{" "}
              {petition.beneficiary?.name} {petition.beneficiary?.firstSurname}{" "}
              {petition.beneficiary?.secondSurname}
            </div>
          )
        }
        {
          petition.representative && (
            <div className="data">
              <b>{t("Petitions.petitionCard.representative")}:</b>{" "}
              {petition.representative.name}{" "}
              {petition.representative.firstSurname}{" "}
              {petition.representative.secondSurname}
            </div>
          )
        }
        {isSso && (
          <Button
            disabled={loading}
            className="buttonPrimaryLarge"
            text={t("Petitions.petitionCard.continue")}
            action={ssoLoginAction}
            styles={{
              backgroundColor: "#34fdfe",
              color: "#381940",
              marginTop: "16px",
              maxWidth: "100%",
            }}
          />
        )}
      </div>
    </div>
  );
};
