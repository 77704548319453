import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import './Correction.scss';
import { DateUtils, DisplayUtils } from '../../../../../utils';
import { Button, Loading, NavMenu, UploadDoc } from '../../../../../components';
import { useErrorsContext } from '../../../../../contexts/ErrorsContext';
import { getCorrection, saveCorrection } from '../../../../../services/procedureServices';
import { useParametrics } from '../../../../../contexts/ParametricsContext';
import { updateFile, uploadFile } from '../../../../../services/fileServices';

const Correction = () => {

    const { t } = useTranslation();
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const location = useLocation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [correctionResponse, setCorrectionResponse] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [observations, setObservations] = useState('');
    const [managerObservations, setManagerObservations] = useState(null);
    const [loading, setLoading] = useState(false);

    const { petitionId } = useParams();

    const procedureId = location?.state?.procedureId;
    const petition = location?.state?.petition;
    const fromSignProcedure = location?.state.fromSignProcedure;

    const getDocuments = async () => {
      try {
        setLoading(true);
        const resp = await getCorrection(petitionId, procedureId);        
        if (!fromSignProcedure && resp.status === 'DATA_COMPLETED') {
          goToSignProcedure();
        }
        setCorrectionResponse(resp);
        setObservations(resp.observations);
        setManagerObservations(resp.managerObservations);
        setLoading(false);
      } catch (e) {
        showError(e);
        history.goBack();
      }
    }

    useEffect(() => {
      if(!convocationId) return;
      if(!location?.state?.correction || !procedureId || !petitionId || !petition) {
          history.goBack()
      } else {
        getDocuments();
        setShow(true);
      }
    }, [convocationId])

    const uploadCorrection = async () => {
      try {
        setLoading(true);
        for (const fileToUpload of filesToUpload) {
          const formData = new FormData();
          formData.append('file', fileToUpload.file);
          const requiredFile = documents.find(f => f.fileType === fileToUpload.fileType);
          let fileId = requiredFile.fileId;
          const fileType = fileToUpload.fileType;
          const petitionProcedureId = procedureId;
          if (fileId) await updateFile({
            convocationId, petitionId, fileId, fileType, formData, petitionProcedureId
          });
          else fileId = await uploadFile({
            convocationId, petitionId, fileType, formData, petitionProcedureId
          });
        }
        await saveCorrection(petitionId, procedureId, observations);
        goToSignProcedure();
        console.log('go to firma')
      } catch (e) {
        console.log(e);
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    const goToSignProcedure = () => {
      history.replace('/sign-procedure', {
        signProcedure: true,
        procedureId,
        petitionId,
        userType: petition.accountType,
        requestType: 'CORRECTION_REQUEST',
        fileType: 'CORRECTION_REQUEST',
        previousPageUrl: `/petitions/${petition.petitionId}/corrections/new`,
        previousPageState: {
          correction: true, procedureId, petition
        },
      });
    }

    const documents = correctionResponse?.files ?? []; 

    const numFiles = documents.filter((document) => {
      if (document.fileId) return true;
      if (filesToUpload.find((file) => file.fileType === document.fileType)) 
        return true;
      return false;
    }).length;
    
    return (
        show && (
          loading ? (
            <div className='vw-100 vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
          <NavMenu>
            <div className='Correction'>
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="Correction__logo" />
                    ) : (
                        <div className="Correction__title">
                            <Button
                                icon="/misc/icons/close-pink.png"
                                action={() => history.goBack()}
                                className="Button__transparent"
                            />
                            <h6>{t('RequestStatus.correctionStatus.head')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="Correction__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="Correction__container__head">
                                <Button
                                    icon="/misc/icons/close-pink.png"
                                    action={() => history.goBack()}
                                    className="Button__transparent"
                                    styles={{width: '50px'}}
                                />
                                <h6>{t('RequestStatus.correctionStatus.head')}</h6>
                            </div>
                        )}
                        <div className="Correction__container__content">
                            <span className="Correction__container__content__description">{t('RequestStatus.correctionStatus.description')}</span>
                            <span className="Correction__container__content__title">{t('RequestStatus.correctionStatus.sectionDocsTitle')}</span>
                            {
                              managerObservations && (
                                <div className='Correction__container__content__observations'>
                                  <img alt='' src='/misc/icons/warning.svg'/>
                                  <p>
                                    {managerObservations}
                                  </p>
                                </div>
                              )
                            }
                            {
                              documents?.map((item, index) => (
                                <UploadDoc
                                  key={index}
                                  fileId={item.fileId}
                                  title={item.title}
                                  info={item.fileId ? t('Join.documentation.uploadDate', { date: DateUtils.getFormatedDate(item.updatedTime), time: DateUtils.getFormatedTime(item.updatedTime)}) : null}
                                  type={item.fileType}
                                  setFilesToUpload={setFilesToUpload}
                                  filesToUpload={filesToUpload} />
                              ))
                            }

                            <div className="Correction__container__content__applicant-observations">
                              <span className='Correction__container__content__document__title'>{t('RequestStatus.correctionStatus.observations')}</span>
                              <textarea 
                                rows={4} 
                                value={observations} 
                                placeholder={t('RequestStatus.correctionStatus.observationsPlaceholder')}
                                onChange={(event) => {
                                  setObservations(event.target.value)
                                }}
                              />
                            </div>

                            <div className="Correction__container__content__actions">
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('RequestStatus.correctionStatus.send')}
                                    styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                    action={uploadCorrection}
                                    disabled={documents.length !== numFiles}
                                />
                            </div>
                            <span className="Correction__container__content__skip" onClick={() => history.goBack()}>
                                {t('RequestStatus.correctionStatus.skip')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </NavMenu>
        )
      )
    )
}

export default Correction;