import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Loading, Button } from '../../../components';
import { DisplayUtils, constants } from '../../../utils';
import './ClaveVerification.scss';
import { getClaveData, getClaveStatus } from '../../../services/claveService';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useParametrics } from '../../../contexts/ParametricsContext';

const ClaveVerification = () => {

    const { t } = useTranslation();
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const location = useLocation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const userType = location?.state?.userType;
    const petitionId = location?.state.petitionId;
    const petitionHasAccount = location?.state?.petitionHasAccount;

    useEffect(() => {
      if(!convocationId) return;
      if(location?.state?.claveData) {
        setShow(true);
        setLoading(true);
        claveCallback(location.state.claveData);
        return;
      }
      if(!location?.state?.claveVerification || !userType) {
        history.goBack();
      } else {
        setShow(true);
      }
    }, [convocationId]) //eslint-disable-line

    const callClave = async () => {
      setLoading(true)
      try {
        const response = await getClaveData(
          convocationId,
        );
        try {
          localStorage.setItem('claveData', JSON.stringify({
            relayState: response.relayState,
            userType: userType,
            petitionId: petitionId,
            petitionHasAccount: petitionHasAccount,
            returnPage: `/${userType}/join/clave-verification`,
          }));
          const child = window.open("", "_self");
          const html = constants.getClaveHtml(response.redirectForm,response.samlRequest,response.relayState)
          child.document.write(html);
          child.document.close();
        } catch(e) {
          setLoading(false);
          showError('warning');
        }
      } catch(error) {
        setLoading(false);
        showError(error);
      }
    }

    const claveCallback = async (claveData) => {
      try {
        const response = await getClaveStatus(convocationId, claveData.relayState);
        if(response.statusCode === 'VERIFIED') {
          setLoading(false);
          history.push({ 
            pathname: `/${claveData.userType}/join/confirm-identity`,
            state: {
              confirmIdentity: true,
              userType: claveData.userType,
              petitionId: claveData.petitionId,
              petitionHasAccount: claveData.petitionHasAccount,
            }
          })
        }
        else {
          throw {error: response.statusMessage};
        }
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }

    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
              <>
                <div className='ClaveVerification'>
                    <div className="w-100 d-flex justify-content-center">
                        {!DisplayUtils.isMobile() ? (
                            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="RequestInfo__logo" />
                        ) : (
                            <div className="ClaveVerification__title">
                                <Button
                                    icon="/misc/icons/arrow-left-blue.svg"
                                    action={() => { history.push('/join/select-user-type', { selectUserType: true, userType: userType, petitionId: petitionId, petitionHasAccount, }) }}
                                    className="Button__transparent"
                                />
                                <h6>{t('Join.claveVerification.head')}</h6>
                            </div>
                        )}
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="ClaveVerification__container">
                            {!DisplayUtils.isMobile() && (
                                <div className="ClaveVerification__container__head">
                                    <Button
                                        icon="/misc/icons/arrow-left-pink.svg"
                                        action={() => { history.push('/join/select-user-type', { selectUserType: true, userType: userType, petitionId: petitionId, petitionHasAccount,  }) }}
                                        className="Button__transparent"
                                        styles={{width: '50px'}}
                                    />
                                    <h6>{t('Join.claveVerification.head')}</h6>
                                </div>
                            )}
                            <div className="ClaveVerification__container__icon">
                                <img
                                    src={`${DisplayUtils.isMobile() ? '/misc/icons/round_back_icon_white.svg' : '/misc/icons/round_back_icon.svg'}`}
                                    alt="shop-icon"
                                    className="ClaveVerification__container__icon__back"
                                />
                                <img
                                    src='/misc/icons/clave_modal.png'
                                    alt="shop-icon"
                                    className="ClaveVerification__container__icon__logo"
                                />
                            </div>
                            <span className="ClaveVerification__container__title">{t('Join.claveVerification.title')}</span>
                            <p className="ClaveVerification__container__description" >
                                {t('Join.claveVerification.description')}
                            </p>
                            <div className="ClaveVerification__container__actions">
                                {DisplayUtils.isMobile() ? (
                                    <span className="ClaveVerification__container__later" onClick={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}>
                                        {t('Join.claveVerification.later')}
                                    </span>
                                ) : (
                                    <Button
                                        className="buttonPrimaryLarge"
                                        text={t('Join.claveVerification.later')}
                                        styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                                        action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                                    />
                                )}
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('Join.claveVerification.action')}
                                    styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                    action={() => {
                                      callClave();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
              </>
            )
        )
    )
}

export default ClaveVerification;