import { Modal, NavMenu } from '../../components';
import './Shops.scss';
import { DisplayUtils } from '../../utils';
import { ShopsContextProvider, useShopsContext } from './contexts/ShopsContext';
import { GeolocationContextProvider } from '../../contexts/GeolocationContext';
import ShopDetail from './components/shopDetail/ShopDetail';
import MobileLayout from './layout/mobile/MobileLayout';
import DesktopLayout from './layout/desktop/DesktopLayout';

const Shops = ({ fromPublic }) => {

  return (
    fromPublic ? (
      <GeolocationContextProvider>
        <ShopsContextProvider>
          <PageContent fromPublic={fromPublic}/>
        </ShopsContextProvider>
      </GeolocationContextProvider>
    ) : (
      <NavMenu >
        <GeolocationContextProvider>
          <ShopsContextProvider>
            <PageContent fromPublic={fromPublic}/>
          </ShopsContextProvider>
        </GeolocationContextProvider>
      </NavMenu>
    )
  );
};

const PageContent = ({ fromPublic }) => {

  const { selectedShop, types, setTypes, setSearchTerm } = useShopsContext();

  return (
    <>
      <div className="Shops">
        {DisplayUtils.isMobile() && (<MobileLayout types={types} setTypes={setTypes} setSearchTerm={setSearchTerm} fromPublic={fromPublic}/>)}
        {!DisplayUtils.isMobile() && (<DesktopLayout types={types} setTypes={setTypes} setSearchTerm={setSearchTerm} fromPublic={fromPublic}/>)}
      </div>
      <Modal 
        isShowingModal={selectedShop != null}
        component={
          <ShopDetail shop={selectedShop} />
        }
      />
    </>
  );
}

export default Shops;
