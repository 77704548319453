import { useTranslation } from 'react-i18next';
import { useShopsContext } from '../../contexts/ShopsContext';
import './ShopDetail.scss';

/**
 * 
 * @param {{shop: ShopModel}} params 
 * @returns 
 */
const ShopDetail = ({
  shop
}) => {

  const { t } = useTranslation();
  const { selectShop } = useShopsContext();

  const getCompleteWeb = (url) => {
    if(!url.includes("http")) {
        return `https://${url}`;
    }
    return url;
  }

  return (
    <div className="ShopDetail">
      <div className="ShopDetail__button-back-container">
        <div className="ShopDetail__button-back-container__button-back" onClick={
          () => {
            selectShop(null)
          }
        }>
          <img src="/misc/icons/arrow-left-pink.svg" alt="" />
        </div>
      </div>
      <div className="ShopDetail__content-container">
        <div className="ShopDetail__content-container__image-container">
          <div className="ShopDetail__content-container__image-container__image">
            <img alt='icon' src={shop?.image || 'misc/icons/logo-bono.png'} />
          </div>
        </div>
        <div className="ShopDetail__content-container__title">
          {shop?.name}
        </div>
        <div className="ShopDetail__content-container__address">
          {shop?.address}
        </div>
        <div className="ShopDetail__content-container__web" onClick={() => window.open(getCompleteWeb(shop?.web))}>
          {shop?.web}
        </div>
        <div className="ShopDetail__content-container__phone">
          {shop?.phoneNumber}
        </div>
        {shop?.subCategories?.split(',')?.map((item, index) => (
          <span className="ShopDetail__content-container__category" key={index}>
            - {t(`Globals.merchantType.${item.trim()}`)}.
          </span>
        ))}
        <div className="ShopDetail__content-container__maps-link">
          <div className="ShopDetail__content-container__maps-link__icon">
            <img alt='img' src="/misc/icons/maps.svg" />
            <a href={`https://www.google.com.sa/maps/search/${shop?.latitude},${shop?.longitude}`} target='_blank' rel="noreferrer">{t('Shops.shopDetail.mapsLink')}</a>
          </div>
        </div>
        {/* <div className="ShopDetail__content-container__description">
          {shop.description}
        </div> */}
      </div>
      {/* <div className="ShopDetail__balance-container">
        <div className="ShopDetail__balance-container__card">
          <img src="/misc/icons/balance-card.svg" />
        </div>
        <div className="ShopDetail__balance-container__balance">
          <div className="ShopDetail__balance-container__balance__title">{t('Shops.shopDetail.balanceTitle')}</div>
          <div className="ShopDetail__balance-container__balance__amount">{t('Shops.shopDetail.balanceAmount')}</div>
        </div>
      </div> */}
    </div>
  );
}

export default ShopDetail;