import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import { DisplayUtils } from '../../utils';
import './Cookies.scss';

const Cookies = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="Cookies">
      <div className="w-100 d-flex justify-content-center">
        {!DisplayUtils.isMobile() ? (
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="Cookies__logo" />
        ) : (
          <div className="Cookies__title">
            <Button
              icon="/misc/icons/arrow-left-blue.svg"
              action={() => history.goBack()}
              className="Button__transparent"
            />
            <h6>{t('Cookies.head')}</h6>
          </div>
        )}
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="Cookies__container">
          {!DisplayUtils.isMobile() && (
            <div className="Cookies__container__title">
              <Button
                icon="/misc/icons/arrow-left-pink.svg"
                action={() => history.goBack()}
                className="Button__transparent"
              />
              <h6>{t('Cookies.head')}</h6>
            </div>
          )}
          <div className='Cookies__container__content'>
            <p dangerouslySetInnerHTML={{ __html: t('Cookies.description') }} className="Cookies__container__content__description" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
