import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loading } from "../../components";

const ClaveCallback = () => {

  const history = useHistory();

  useEffect(() => {
    const claveDataStr = localStorage.getItem('claveData');
    localStorage.removeItem('claveData');
    if(!claveDataStr) return history.replace('/');
    const claveData = JSON.parse(claveDataStr);
    history.replace({
      pathname: claveData.returnPage,
      state: {
        claveData: claveData
      }
    })
  }, []);

  return (
    <div className="ClaveCallback">
      <div className='vh-100 d-flex justify-content-center align-items-center'>
        <Loading />
      </div>
    </div>
  );
}

export default ClaveCallback;