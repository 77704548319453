import { useEffect, useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { fetchClusters, fetchSalesPoints } from "../../../services/shopsServices";

const ShopsContext = createContext();

export const useShopsContext = () => useContext(ShopsContext);



const _readMapStateFromUrl = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const mapsState = {
    zoom: null,
    swLat: null,
    swLng: null,
    neLat: null,
    neLng: null,
    wallet: null,
    search: null,
  };
  searchParams.forEach((value, key) => {
    mapsState[key] = value;
  });
  return mapsState;
}

const _matchFiltersToUrl = (filters) => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const urlFilters = {};
  searchParams.forEach((value, key) => {
    urlFilters[key] = value;
  });
  for (const key in filters) {
    if (filters[key]) {
      urlFilters[key] = filters[key].toString();
    } else if (urlFilters[key]) {
      delete urlFilters[key];
    }
  }
  const updatedSearchParams = new URLSearchParams();
  for (const key in urlFilters) {
    if (Object.hasOwn(urlFilters, key)) {
      updatedSearchParams.set(key, urlFilters[key]);
    }
  }
  history.replaceState({}, null, url.pathname + "?" + updatedSearchParams.toString());
};

export const ShopsContextProvider = ({ children }) => {

  const [loading, setLoading] = useState(true);
  const [selectedShop, setSelectedShop] = useState(null);
  const [mobileMapView, setMobileMapView] = useState(true);
  const [types, setTypes] = useState([]);
  const [clusters, setClusters] = useState(null);
  const [shops, setShops] = useState(null);
  const [fitBounds, setFitBounds] = useState(true);
  const [mapsState, setMapsState] = useState(_readMapStateFromUrl())
  const [searchTerm, setSearchTerm] = useState(mapsState.search ?? '');

  const extractShops = (clusters) => {
    const shops = clusters.filter((c) => c.salesPoints !== null).map((c) => c.salesPoints[0]);
    setShops(shops);
  }

  const _fetchClusters = async (state) => {
    try {
      const newState = {
        ...mapsState,
        ...state
      }
      const {zoom, swLat, swLng, neLat, neLng, wallet, search} = newState;
      const requestZoomLevel = _calculateRequestZoomLevel(zoom);
      const searchParams= new URLSearchParams({
        swLat: swLat,
        swLng: swLng,
        neLat: neLat,
        neLng: neLng,
        zoomLevel: requestZoomLevel,
      })
      if (wallet) searchParams.append('wallet', wallet);
      if (search) searchParams.append('search', search);
      const resp = await fetchClusters(searchParams);
      if (resp.ok) {
        const clusters = await resp.json();
        setLoading(false);
        setMapsState(newState);
        setClusters(clusters);
        extractShops(clusters);
        if (wallet) setTypes([wallet]);
        else setTypes([]);
        if (search) setSearchTerm(search);
        else setSearchTerm('');
      }
      else throw new Error('Error fetching clusters');
    } catch (e) {
      console.log(e);
    }
  }

  const _fetchSalesPoints = async (clusterKey) => {
    try {
      const searchParams= new URLSearchParams()
      if (mapsState.wallet) searchParams.append('wallet', mapsState.wallet);
      if (mapsState.search) searchParams.append('search', mapsState.search);
      const resp = await fetchSalesPoints(clusterKey, searchParams);
      if (resp.ok) {
        const cluster = await resp.json();
        const clusters = [];
        for (const sp of cluster.salesPoints) {
          clusters.push({
            key: sp.key,
            lat: sp.latitude,
            lng: sp.longitude,
            count: 1,
            salesPoints: [sp]
          });
        }
        setClusters(clusters);
        setShops(cluster.salesPoints);
        setFitBounds(true);
      }
      else throw new Error('Error fetching sales points');
    } catch (e) {
      console.log(e);
    }
  }

  const search = async (term) => {
    setSearchTerm(term);
    //await _getShops(term && term !== "" ? term : null);
  }

  const selectShop = (shop) => {
    setSelectedShop(shop);
  }

  const _calculateRequestZoomLevel = (mapZoom) => {
    // Convert map zoom to request zoom level (1 to 8)
    const requestZoomLevel = Math.max(1, Math.min(8, Math.floor(mapZoom / 2.1)));
    return requestZoomLevel;
  };

  const value = {
    loading, mapsState, shops, clusters, fitBounds, searchTerm, selectedShop, mobileMapView, types,
    fetchClusters: _fetchClusters, fetchSalesPoints: _fetchSalesPoints, setFitBounds, search, selectShop, setMobileMapView, setTypes, 
    setSearchTerm
  };

  useEffect(() => {
    if (mapsState.zoom != null) {
      _matchFiltersToUrl(mapsState);
    }
  }, [mapsState])

  useEffect(() => {
    if(mapsState.zoom != null) {
      _fetchClusters(mapsState);
    }
  }, [])

  return (
    <ShopsContext.Provider value={value}>
      {children}
    </ShopsContext.Provider>
  );
}