import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import { DisplayUtils } from '../../utils';
import './Privacy.scss';

const Privacy = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="Privacy">
      <div className="w-100 d-flex justify-content-center">
        {!DisplayUtils.isMobile() ? (
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="Privacy__logo" />
        ) : (
          <div className="Privacy__title">
            <Button
              icon="/misc/icons/arrow-left-blue.svg"
              action={() => history.goBack()}
              className="Button__transparent"
            />
            <h6>{t('Privacy.head')}</h6>
          </div>
        )}
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="Privacy__container">
          {!DisplayUtils.isMobile() && (
            <div className="Privacy__container__title">
              <Button
                icon="/misc/icons/arrow-left-pink.svg"
                action={() => history.goBack()}
                className="Button__transparent"
              />
              <h6>{t('Privacy.head')}</h6>
            </div>
          )}
          <div className='Privacy__container__content'>
            <p dangerouslySetInnerHTML={{ __html: t('Privacy.info') }}  className='Privacy__container__content__description' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
