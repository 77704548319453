import { Button } from '../index';
import { DisplayUtils } from '../../utils';
import { useHistory } from 'react-router-dom';
import './Navbar.scss';

const Navbar = ({ toggleNav, menuMobileOpened, action }) => {

  const history = useHistory();
  
  return (
    <div className="Navbar">
      {DisplayUtils.isMobile() && (
        <div className="Navbar_menu">
          {menuMobileOpened === 'true' ? (
            <Button className="Button_close" icon={'/misc/icons/close-blue.png'} action={toggleNav} />
          ) : (
            <Button className="Button_burger" icon={'/misc/icons/nav-menu.png'} action={toggleNav} />
          )}
          <div className='Navbar_menu__logo'>
            <img src='/misc/icons/complete_logo_white.svg' alt='logo'/>
          </div>
          <div className='Navbar_menu__profile' onClick={() => {
            action(undefined)
            history.push('/profile')
          }}>
            <img src='/misc/icons/profile-menu.png' alt='user' />
          </div>
        </div>
      )}
    </div>
  )
};

export default Navbar;
