import { useState } from 'react';
import { OnlineShops } from '../../screens';
import Shops from '../../screens/shops/Shops';
import './PublicShops.scss';

const PublicShops = () => {

    const [mode, setMode] = useState('physical');

    return (
        <div className="PublicShops">
            <div className="PublicShops__content">
                <div className="w-100 d-flex justify-content-center">
                    <div className="PublicShops__content__title">
                        <h6 className="text-center">Entidades adheridas</h6>
                    </div>
                </div>
                <div className='PublicShops__content__tabs'>
                    <div className={`PublicShops__content__tabs__item ${mode === 'physical' ? 'selected' : ''}`} onClick={() => mode !== 'physical' ? setMode('physical') : {}}>Con puntos de venta</div>
                    <div className={`PublicShops__content__tabs__item ${mode === 'online' ? 'selected' : ''}`} onClick={() => mode !== 'online' ? setMode('online') : {}}>De venta online</div>
                </div>
                <div>
                    {mode === 'physical' ? (
                        <Shops fromPublic />
                    ) : (
                        <OnlineShops fromPublic />
                    )}
                </div>
            </div>
        </div>
    )
}

export default PublicShops;