import { useState, useRef } from 'react';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import './UploadDoc.scss';

const UploadDoc = ({ fileId, title, info, type, setFilesToUpload, filesToUpload }) => {
    const { showError } = useErrorsContext();
    const fileInput = useRef(null);
    const file = filesToUpload?.find(f => f.fileType === type);
    const [doc, setDoc] = useState(file);
    const [currentFileId, setFileId] = useState(fileId);
    const [currentInfo, setInfo] = useState(info ?? file?.name);

    const fileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === "application/pdf") {
                setDoc(file);
                setInfo(file.name)
                setFilesToUpload([...filesToUpload, { file, fileType: type, name: file.name}])
            } else {
                showError("invalidDocFormat")
            }
        }
    };

    const removeFileToUpload = () => {
        setDoc(null);
        setFileId(null);
        setInfo(null);
        const finalDocs = filesToUpload.filter(item => item.fileType !== type);
        setFilesToUpload(finalDocs);
    }

    const openFile = async (fileId) => {
      window.open(`/pdf-viewer/?id=${fileId}`);
    }

    return (
        <div className="UploadDoc__card" >
          <input type="file" onChange={(e) => fileUpload(e)} hidden accept="application/pdf" ref={fileInput} autoComplete="off" />
          <img className="UploadDoc__card__icon" style={{cursor: currentFileId ? 'pointer' : 'initial'}} src='/misc/icons/list-info-pink.png' alt='item' onClick={currentFileId ? () => { openFile(currentFileId) } : null} />
          <div className="UploadDoc__card__info">
            <span className="UploadDoc__card__info__title">{title}</span>
            <span className="UploadDoc__card__info__info">{currentInfo}</span>
          </div>
          { (doc || currentFileId) && (<img className="UploadDoc__card__upload trash" src='/misc/icons/trash-red.png' alt='item' onClick={removeFileToUpload} />) }
          { (!doc && !currentFileId) && (<img className="UploadDoc__card__upload" src='/misc/icons/upload.png' alt='item' onClick={() => { fileInput.current.click() }} />) }
        </div>
    )

}

export default UploadDoc;