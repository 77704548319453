import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useErrorsContext } from '../../contexts/ErrorsContext';
import { useParametrics } from '../../contexts/ParametricsContext';
import { DisplayUtils, constants } from '../../utils';
import { Button, Loading, SignModal } from '../../components';
import './SignProcedure.scss';
import { checkProcedureIdentity } from '../../services/procedureServices';
import useModal from '../../hooks/useModal';
import { getClaveDataSignFileAdvanced, getClaveDataSignFileSimple, getClaveSignatureStatus } from '../../services/claveService';
import { getFile, signProcedureFile } from '../../services/fileServices';
import { generateProcedureReport } from '../../services/procedureServices';
import { base64FileToBlob, downloadFile } from '../../utils/download-file';

const SignProcedure = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [docUrl, setDocUrl] = useState();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState();
    const [showPages, setShowPages] = useState(false);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [signedDocOk, setSignedDocOk] = useState();
    const [signedDocError, setSignedDocError] = useState();
    const { isShowingModal, toggleModal } = useModal();
    const [showClaveOption, setShowClaveOption] = useState(false);
    const [showClaveAdvancedOption, setShowClaveAdvancedOption] = useState(false);
    const [showFirmaOption, setShowFirmaOption] = useState(false);
    
    const petitionId = location?.state?.claveData?.petitionId ?? location?.state?.petitionId;
    const procedureId = location?.state?.claveData?.procedureId ??  location?.state?.procedureId;
    const requestType = location?.state?.claveData?.requestType ??  location?.state?.requestType; 
    const fileType = location?.state?.claveData?.fileType ??  location?.state?.fileType; 
    const userType = location?.state?.claveData?.userType ?? location?.state?.userType
    const previousPageUrl = location?.state?.claveData?.previousPageUrl ?? location?.state?.previousPageUrl;
    const previousPageState = location?.state?.claveData?.previousPageState ?? location?.state?.previousPageState;
    const nextPageUrl = location?.state?.claveData?.nextPageUrl ?? location?.state?.nextPageUrl ?? '/sign-procedure/success';

    const getDocUrl = async () => {
      try {
        setLoading(true);
        const resp = await generateProcedureReport(petitionId, procedureId, 'AUTOSIGNATURE', true);
        setDocUrl(`data:application/pdf;base64,${resp.file}`);
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    const init = () => {
      getDocUrl();
    }

    useEffect(() => {
      if(!convocationId) return;
      if(location?.state?.claveData) {
        setShow(true);
        setLoading(true);
        claveCallback(location.state.claveData);
        return;
      }
      if(!location?.state?.signProcedure || !procedureId || !petitionId || !userType || !requestType) {
          history.goBack()
      } else {
        init();
        setShow(true);
      }
    }, [convocationId])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
        setShowPages(true);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }
    
    const nextPage = () => {
        changePage(1);
    }

    const callClave = async () => {
      try {
        setLoading(true);
        const resp = await generateProcedureReport(petitionId, procedureId, 'NON_CRYPTOGRAPHIC_SIGNATURE', false);
        const fileId = resp.beneficiaryFileId;
        let response;
        if(showClaveAdvancedOption) {
          response = await getClaveDataSignFileAdvanced(convocationId, userType.toUpperCase(), requestType, {
            petitionId: petitionId,
            requestFile: fileId,
            petitionProcedureId: procedureId
          });
        }
        else {
          response = await getClaveDataSignFileSimple(convocationId, userType.toUpperCase(), requestType, {
            petitionId: petitionId,
            requestFile: fileId,
            petitionProcedureId: procedureId
          });
        }
        localStorage.setItem('claveData', JSON.stringify({
          relayState: response.relayState,
          returnPage: `/sign-procedure`,
          userType: userType,
          petitionId: petitionId,
          procedureId: procedureId,
          requestType: requestType,
          fileType: fileType,
          previousPageUrl: previousPageUrl,
          previousPageState: previousPageState,
          nextPageUrl: nextPageUrl,
        }));
        const child = window.open("", "_self");
        const html = constants.getClaveHtml(response.redirectForm,response.samlRequest,response.relayState)
        child.document.write(html);
        child.document.close();
      } catch(e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    const claveCallback = async (claveData) => {
      try {
        setLoading(true);
        const response = await getClaveSignatureStatus(convocationId, claveData.relayState);
        if(response.statusCode === 'VERIFIED') {
          setLoading(false);
          history.push({ 
            pathname: claveData.nextPageUrl,
            state: {
              signProcedureSuccess: true,
              userType: claveData.userType,
              petitionId: claveData.petitionId,
              procedureId: claveData.procedureId
            }
          })
        } else {
          throw {error: response.statusMessage};
        }
      } catch (e) {
        setLoading(false);
        showError(e);
        init();
      }
    }
    
    const startAutoFirma = async () => {
      try {
        setLoading(true);
        const resp = await generateProcedureReport(petitionId, procedureId, 'AUTOSIGNATURE', false);
        const url = `data:application/pdf;base64,${resp.file}`
        try {
          window.downloadRemoteData(url);
        } catch(e) {
          showError('No se pudo contactar con AutoFirma')
          setLoading(false);
        }
      } catch(error) {
        showError(error)
      }
    }

    const uploadAutofirma = async (signedDocOk) => {
        setLoading(true);
        try {
            const response = await fetch(signedDocOk)
            const blob = await response.blob();
            const file = new File([blob], "signed.pdf",{ type: "application/pdf" })
            const formData = new FormData();
            formData.append('file', file);
            const { signFileId } = await signProcedureFile(convocationId, userType.toUpperCase(), petitionId, procedureId, fileType, formData);
            const resp = await getFile(signFileId);
            const url = URL.createObjectURL(
              base64FileToBlob(resp.file, "application/pdf"),
            );
            downloadFile(url, resp.name);
            history.push({ 
              pathname: nextPageUrl,
              state: {
                signProcedureSuccess: true,
                userType: userType,
                petitionId: petitionId,
                procedureId: procedureId,
              }
            })
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    const onContinue = async () => {
      try {
        setLoading(true);
        const resp = await checkProcedureIdentity(petitionId, procedureId);
        if(resp.eligible !== true) 
          showError({ error: resp.statusMessage });
        else {
          setShowClaveOption(resp.nonCryptographicSignature);
          setShowClaveAdvancedOption(resp.nonCryptographicSignatureAdvanced)
          setShowFirmaOption(resp.autosignature);
          toggleModal();
        }
      } catch(e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    window.signError = function(error) {
      console.log(error);
      setLoading(false);
      setSignedDocError(error);
    }

    window.signedOk = function(data) {
      console.log(data);
      setLoading(false);
      setSignedDocOk(data);
    }

    if(signedDocError) {
        showError(signedDocError)
        setSignedDocError()
    }

    if(signedDocOk) {
      uploadAutofirma(signedDocOk)
      setSignedDocOk()
    }

    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
                <>
                  <SignModal 
                    showingModal={isShowingModal}
                    claveAdvanced={showClaveAdvancedOption}
                    hideModal={() => {
                        toggleModal();
                    }}
                    claveSelect={showClaveOption || showClaveAdvancedOption ? () => {
                        callClave();
                        toggleModal();
                    } : null}
                    firmaSelect={showFirmaOption ? () => {
                        startAutoFirma();
                        toggleModal();
                    } : null}
                  />
                  <div className='SignProcedure'>
                      <div className="w-100 d-flex justify-content-center">
                          {!DisplayUtils.isMobile() ? (
                              <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="SignProcedure__logo" />
                          ) : (
                              <div className="SignProcedure__title">
                                  <Button
                                      icon="/misc/icons/arrow-left-blue.svg"
                                      action={() => {
                                        if (!previousPageUrl) history.goBack();
                                        else history.replace(previousPageUrl, {
                                          ...previousPageState, fromSignProcedure: true,
                                        });
                                      }}
                                      className="Button__transparent"
                                  />
                                  <h6>{t('SignProcedure.head')}</h6>
                              </div>
                          )}
                      </div>
                      <div className="w-100 d-flex justify-content-center">
                          <div className="SignProcedure__container">
                              {!DisplayUtils.isMobile() && (
                                  <div className="SignProcedure__container__head">
                                      <Button
                                          icon="/misc/icons/arrow-left-pink.svg"
                                          action={() => {
                                            if (!previousPageUrl) history.goBack();
                                            else history.replace(previousPageUrl, {
                                              ...previousPageState, fromSignProcedure: true,
                                            });
                                          }}
                                          className="Button__transparent"
                                          styles={{width: '50px'}}
                                      />
                                      <h6>{t('SignProcedure.head')}</h6>
                                  </div>
                              )}
                              <div className="SignProcedure__container__content">
                                  {docUrl && (
                                      <div className="SignProcedure__container__content__preview">
                                          <Document file={docUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                              <Page pageNumber={pageNumber} />
                                          </Document>
                                          {showPages && (
                                              <div className="SignProcedure__container__content__preview__pages">
                                                  <Button
                                                      className="buttonPrimaryLarge"
                                                      text='<'
                                                      styles={{ backgroundColor: '#34fdfe', color: '#381940', height: '24px', width: '24px' }}
                                                      action={() => previousPage()}
                                                      disabled={pageNumber <= 1}
                                                  />
                                                  <p>{t('Globals.pages', { number: pageNumber || (numPages ? 1 : "--"), total: numPages || "--" })}</p>
                                                  <Button
                                                      className="buttonPrimaryLarge"
                                                      text='>'
                                                      styles={{ backgroundColor: '#34fdfe', color: '#381940', height: '24px', width: '24px' }}
                                                      action={() => nextPage()}
                                                      disabled={pageNumber >= numPages}
                                                  />
                                              </div>
                                          )}
                                      </div>
                                  )}
                                  <div className="SignProcedure__container__actions">
                                      <Button
                                          className="buttonPrimaryLarge"
                                          text={t('SignProcedure.sign')}
                                          styles={{ backgroundColor: '#34fdfe', color: '#381940', width: '400px' }}
                                          action={() => onContinue()}
                                          disabled={ !docUrl}
                                      />
                                  </div>
                                  <span className="Correction__container__content__skip" onClick={() => {
                                    history.replace('/')
                                  }}>
                                      {t('RequestStatus.correctionStatus.skip')}
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                </>
            )
        )
    )
}

export default SignProcedure;