import { DisplayUtils } from '../../../utils';
import './Pagination.scss'

const Pagination = ({ page, totalPages, onPageChange }) => {

  return (
    <div className="paginations">
        <div
            onClick={() => page > 0 && onPageChange(page - 1)}
            className={`pagination_button ${page > 0 ? '' : 'disabled'} me-3`}
        >
            <img src={DisplayUtils.isMobile() ? '/misc/icons/chevron_left_white.svg' : '/misc/icons/chevron_left_white.svg'} alt='icon-left' />
        </div>
        <span className="pageAll">
            Página <label>{page+1}</label> de {totalPages === 1 ? 1 : totalPages}
        </span>
        <div
            onClick={() => page < totalPages-1 && onPageChange(page + 1)}
            className={`pagination_button ${page < totalPages-1 ? '' : 'disabled'} ms-3`}
        >
            <img src={DisplayUtils.isMobile() ? '/misc/icons/chevron_right_white.svg' : '/misc/icons/chevron_right_white.svg'} alt='icon-right' />
        </div>
    </div>
  );
};

export default Pagination;