import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import { useParametrics } from '../contexts/ParametricsContext';
// eslint-disable-next-line no-undef
export const ALLOW_REGISTER = process.env.REACT_APP_ALLOW_REGISTER;

const RedirectToLogin = () => <Redirect to="/"/>

const RedirectToProfile = () => {
  const { isSso } = useAuthContext();
  if (isSso) return <Redirect to="/petitions-sso"/>
  return <Redirect to="/petitions"/>
}

export const SsoRoute = (props) => {
  const { isSso } = useAuthContext()
  return <Route {...props } component={isSso ? props.component : RedirectToLogin}/>
}

export const AuthenticatedRoute = (props) => {
  const { user } = useAuthContext()
  return <Route {...props } component={user ? props.component : RedirectToLogin}/>
}

export const AuthAdminRoute = (props) => {
  return <Route {...props } component={RedirectToLogin}/>
}

export const NotAuthenticatedRoute = (props) => {
  const { user } = useAuthContext()
  return <Route {...props } component={!user ? props.component : RedirectToProfile}/>
}

export const SignupRoute = (props) => {
  const { user } = useAuthContext()
  const { convocationOpen } = useParametrics();
  return <Route {...props } component={!user ? (convocationOpen ? props.component : RedirectToLogin) : RedirectToProfile}/>
}

export default AuthenticatedRoute
