import { useCallback, useState, createContext, useContext } from 'react';

export const ErrorsContext = createContext();

export const useErrorsContext = () => useContext(ErrorsContext)

export const ErrorsContextProvider = ({ children }) => {
  const [error, setError] = useState()

  const showError = useCallback(error => {
    setError(error)
  }, [])

  const hideError = useCallback(() => {
    setError(null)
  }, [])

  const value = { error, showError, hideError }

  return (
    <ErrorsContext.Provider value={value} >
      {children}
    </ErrorsContext.Provider>
  );
};
