import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es.json';
import 'react-phone-number-input/style.css';
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import localeEs from 'date-fns/locale/es';
import { useErrorsContext } from '../../../../../contexts/ErrorsContext';
import { Loading, Button, AddressForm } from '../../../../../components';
import { DisplayUtils, constants } from '../../../../../utils';
import './RectificationNew.scss';
import { parsePhoneNumber } from 'react-phone-number-input';
import { getParametricsAddressType, getParametricsCountries, getParametricsProvinces } from '../../../../../services/parametricsService';
import { getRectificationsProcedure, saveRectificationsProcedure } from '../../../../../services/procedureServices';
import { useParametrics } from '../../../../../contexts/ParametricsContext';

registerLocale('es', localeEs);


const RectificationNew = () => {

    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [hasRepresentation, setHasRepresentation] = useState(false);
    const { showError } = useErrorsContext();
    const { convocationYear } = useParametrics();
    const [onlyReadMode, setOnlyReadMode] = useState(false); 
    const [addressTypes, setAddressTypes] = useState();
    const [countries, setCountries] = useState();
    const [provinces, setProvinces] = useState();
    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        clearErrors,
        trigger,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const {petitionId} = useParams()
    const procedureId = location?.state?.procedureId;
    const petition = location?.state?.petition;
    const fromSignProcedure = location?.state.fromSignProcedure;

    const onSubmit = async (data) => {
        try {
          setLoading(true);
          const phone = parsePhoneNumber(data.phoneNumber);
          const payload = {
            observations: data.comments,
            notificationAddress: {
              roadTypeId: data.addressTypeId,
              roadNameAddress: data.address,
              numberAddress: data.number,
              blockAddress: data.bloq,
              levelAddress: data.stair,
              floorAddress: data.floor,
              doorAddress: data.door,
              localityId: data.locationId,
              provinceId: data.provinceId,
              postalCodeId: data.postalCodeId,
              countryId: data.countryId,
            },
            notificationEmail: data.email,
            notificationPhonePrefix: phone.countryCallingCode,
            notificationPhoneNumber: phone.nationalNumber,
          }
          if (data.location) payload.notificationAddress.locality = data.location;
          if (data.postalCode) payload.notificationAddress.postalCode = data.postalCode;
          await saveRectificationsProcedure(petitionId, procedureId, payload);
          goToSignProcedure();
        } catch (error) {
          showError(error);
        } finally {
          setLoading(false);
        }
    };

    const goToSignProcedure = () => {
      history.replace('/sign-procedure', {
        signProcedure: true,
        procedureId,
        petitionId,
        userType: petition.accountType,
        requestType: 'DATA_RECTIFICATION_REQUEST',
        fileType: 'PERSONAL_DATA_RECTIFICATION_REQUEST',
        previousPageUrl: `/petitions/${petitionId}/rectifications/new`,
        previousPageState: {
          procedureId, petition,
        }
      });
    }

    const fetchData = async(petitionId, procedureId) => {
      try {
        setLoading(true);
        setHasRepresentation((petition?.beneficiaryType === "REPRESENTATIVE" || petition?.beneficiaryType === "FOREIGN_REPRESENTATIVE") ? true : false);
        const procedure = await getRectificationsProcedure(petitionId, procedureId);
        if (!fromSignProcedure && procedure.status === 'DATA_COMPLETED') {
          goToSignProcedure();
        }
        const addressTypes = await getParametricsAddressType();
        const addressType = addressTypes?.find(type => type.roadTypeId === procedure?.notificationAddress?.roadTypeId)
        setAddressTypes(addressTypes);
        const countries = await getParametricsCountries();
        const country = countries?.find(country => country.countryId === procedure?.notificationAddress?.countryId)
        setCountries(countries);
        const provinces = await getParametricsProvinces();
        const province = provinces?.find(province => province.provinceId === procedure?.notificationAddress?.provinceId)
        setProvinces(provinces);
        reset({
          name: procedure.name,
          birthDate: procedure.birthDate ? new Date(procedure.birthDate.split("/").reverse().join("-")) : null,
          addressType: addressType?.name || null,
          addressTypeId: procedure?.notificationAddress?.roadTypeId || null,
          address: procedure?.notificationAddress?.roadNameAddress || null,
          number: procedure?.notificationAddress?.numberAddress || null,
          bloq: procedure?.notificationAddress?.blockAddress || null,
          stair: procedure?.notificationAddress?.levelAddress || null,
          floor: procedure?.notificationAddress?.floorAddress || null,
          door: procedure?.notificationAddress?.doorAddress || null,
          location: procedure?.notificationAddress?.locality || null,
          locationId: procedure?.notificationAddress?.localityId || null,
          province: province?.name || null,
          provinceId: procedure?.notificationAddress?.provinceId || null,
          postalCode: procedure?.notificationAddress?.postalCode || null,
          postalCodeId: procedure?.notificationAddress?.postalCodeId || null,
          country: country?.name || null,
          countryId: procedure?.notificationAddress?.countryId || null,
          email: procedure.notificationEmail,
          repeatEmail: procedure.notificationEmail,
          phoneNumber: `+${procedure?.notificationPhonePrefix || '34'}${procedure?.notificationPhoneNumber || null}`,
          repeatPhoneNumber: `+${procedure?.notificationPhonePrefix || '34'}${procedure?.notificationPhoneNumber || null}`,
          comments: procedure.observations,
        })
      } catch (e) {
        console.log(e);
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      if (!petitionId || !procedureId) {
        history.goBack()
      } else {
        if(location?.state?.onlyReadMode) setOnlyReadMode(true);
        fetchData(petitionId, procedureId);
      }
    }, []) //eslint-disable-line

    return (
        loading ? (
            <div className='vw-100 vh-100 d-flex justify-content-center align-items-center'>
              <Loading />
            </div>
        ) : (
            <div className="RectificationNew">
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="RectificationNew__logo" />
                    ) : (
                        <div className="RectificationNew__title">
                            <Button
                                icon="/misc/icons/arrow-left-blue.svg"
                                action={() => history.goBack()}
                                className="Button__transparent"
                            />
                            <h6>{t('ChangeData.title')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="RectificationNew__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="RectificationNew__container__title">
                                <Button
                                icon="/misc/icons/arrow-left-pink.svg"
                                action={() => history.goBack()}
                                className="Button__transparent"
                                styles={{ width: '50px' }}
                                />
                                <h6>{t('ChangeData.title')}</h6>
                            </div>
                        )}
                        <div className="RectificationNew__container__content">
                            {/* <div className='RectificationNew__container__content__separator'>
                                <div/>
                                <span>{t('ChangeData.userData')}</span>
                                <div/>
                            </div>
                            <div className="RectificationNew__container__content__form d-flex flex-column pt-3 pb-3">
                                <span className='fw-bold mb-1'>{t('ChangeData.addressDefault')}<span className=' fw-normal'>
                                        {currentAddress} 
                                    </span>
                                </span>
                                <span className='fw-bold mb-1'>{t('ChangeData.emailDefault')}<span className=' fw-normal'>{petition?.email?.value}</span></span>
                                <span className='fw-bold'>{t('ChangeData.phoneDefault')}<span className=' fw-normal'>{petition?.phoneNumber?.value}</span></span>
                            </div>
                            <div className='RectificationNew__container__content__separator mb-5'>
                                <div/>
                            </div>
                            <div className='RectificationNew__container__content__separator'>
                                <div/>
                                <span>{t('ChangeData.changeTitle')}</span>
                                <div/>
                            </div> */}
                            <div className="RectificationNew__container__content__form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <AddressForm 
                                      title={t('ChangeData.address')}
                                      errors={errors} 
                                      register={register}
                                      control={control}
                                      setValue={setValue}
                                      getValues={getValues}
                                      clearErrors={clearErrors}
                                      addressTypes={addressTypes}
                                      countries={countries}
                                      provinces={provinces}/>
                                    <h6 className="AddressForm__title">{"Datos de contacto"}</h6>
                                    <label>{t('ChangeData.email')}<span style={{ color: '#f838f9' }}>*</span></label>
                                    <div className="RectificationNew__container__content__form__box">
                                        <input
                                            disabled={onlyReadMode}
                                            type="text"
                                            className={`${errors.email ? 'warning' : ''}`}
                                            {...register('email', { required: t('Globals.required'), validate: value => constants.EMAIL_PATTERN.test(value) })}
                                            onBlur={() => { trigger("repeatEmail") }}
                                        />
                                        <img src="/misc/icons/email.png" alt="icon" style={{ width: '20px' }} />
                                    </div>
                                    {errors.email && errors.email.type === 'validate' && (
                                        <p className="RectificationNew__container__content__form__error">{t("Errors.wrongEmailError")}</p>
                                    )}
                                    {errors.email && (
                                        <p className="RectificationNew__container__content__form__error">{errors.email.message}</p>
                                    )}
                                    <label>{t('ChangeData.confirmEmail')}<span style={{ color: '#f838f9' }}>*</span></label>
                                    <div className="RectificationNew__container__content__form__box">
                                        <input
                                            disabled={onlyReadMode}
                                            type="text"
                                            className={`${errors.repeatEmail ? 'warning' : ''}`}
                                            {...register('repeatEmail', { required: t('Globals.required'), validate: value => value === getValues("email") })}
                                        />
                                        <img src="/misc/icons/email.png" alt="icon" style={{ width: '20px' }} />
                                    </div>
                                    {errors.repeatEmail && errors.repeatEmail.type === 'validate' && (
                                        <p className="RectificationNew__container__content__form__error">{t("Errors.emailNotEqual")}</p>
                                    )}
                                    {errors.repeatEmail && (
                                        <p className="RectificationNew__container__content__form__error">{errors.repeatEmail.message}</p>
                                    )}
                                    {hasRepresentation && (
                                        <>
                                            <label>{t('ChangeData.representationEmail')}<span style={{ color: '#f838f9' }}>*</span></label>
                                            <div className="RectificationNew__container__content__form__box">
                                                <input
                                                    disabled={onlyReadMode}
                                                    type="text"
                                                    className={`${errors.representativeEmail ? 'warning' : ''}`}
                                                    {...register('representativeEmail', { required: t('Globals.required'), validate: value => constants.EMAIL_PATTERN.test(value) })}
                                                    onBlur={() => { trigger("repeatRepresentativeEmail") }}
                                                />
                                                <img src="/misc/icons/email.png" alt="icon" style={{ width: '20px' }} />
                                            </div>
                                            {errors.representativeEmail && errors.representativeEmail.type === 'validate' && (
                                                <p className="RectificationNew__container__content__form__error">{t("Errors.wrongEmailError")}</p>
                                            )}
                                            {errors.representativeEmail && (
                                                <p className="RectificationNew__container__content__form__error">{errors.representativeEmail.message}</p>
                                            )}
                                            <label>{t('ChangeData.confirmRepresentationEmail')}<span style={{ color: '#f838f9' }}>*</span></label>
                                            <div className="RectificationNew__container__content__form__box">
                                                <input
                                                    disabled={onlyReadMode}
                                                    type="text"
                                                    className={`${errors.repeatRepresentativeEmail ? 'warning' : ''}`}
                                                    {...register('repeatRepresentativeEmail', { required: t('Globals.required'), validate: value => value === getValues("representativeEmail") })}
                                                />
                                                <img src="/misc/icons/email.png" alt="icon" style={{ width: '20px' }} />
                                            </div>
                                            {errors.repeatRepresentativeEmail && errors.repeatRepresentativeEmail.type === 'validate' && (
                                                <p className="RectificationNew__container__content__form__error">{t("Errors.emailNotEqual")}</p>
                                            )}
                                            {errors.repeatRepresentativeEmail && (
                                                <p className="RectificationNew__container__content__form__error">{errors.repeatRepresentativeEmail.message}</p>
                                            )}
                                        </>
                                    )}
                                    <label>{t('ChangeData.phone')}<span style={{ color: '#f838f9' }}>*</span></label>
                                    {onlyReadMode && (
                                      <div className="RectificationNew__container__content__form__box">
                                        <input
                                            disabled
                                            type="text"
                                            className={`${errors.phoneNumber ? 'warning' : ''}`}
                                            {...register('phoneNumber', { required: t('Globals.required'), validate: value => value === getValues("phoneNumber") })}
                                        />
                                        <img src="/misc/icons/phone-pink.png" alt="icon" style={{ width: '20px' }} />
                                      </div>
                                    )}
                                    {!onlyReadMode && (
                                      <Controller
                                          control={control}
                                          name="phoneNumber"
                                          rules={{ required: t('Globals.required'), validate: value => isValidPhoneNumber(value) }}
                                          defaultValue={'es'}
                                          render={({ field }) => {
                                              return (
                                                  <PhoneInput
                                                      disabled={onlyReadMode}
                                                      labels={es}
                                                      international
                                                      countryCallingCodeEditable={false}
                                                      defaultCountry="ES"
                                                      value={field.value}
                                                      onChange={field.onChange}
                                                      onBlur={field.onBlur}
                                                      className={`${errors?.phoneNumber ? 'phoneWarning' : ''}`}
                                                  />
                                              )
                                          }}
                                      />
                                    )}
                                    {errors?.phoneNumber && errors?.phoneNumber?.type === 'validate' && (
                                        <p className="RectificationNew__container__content__form__error">{t("Errors.phoneError")}</p>
                                    )}
                                    {errors?.phoneNumber && (
                                        <p className="RectificationNew__container__content__form__error">{errors?.phoneNumber?.message}</p>
                                    )}
                                    <label>{t('ChangeData.confirmPhone')}<span style={{ color: '#f838f9' }}>*</span></label>
                                    {onlyReadMode && (
                                      <div className="RectificationNew__container__content__form__box">
                                        <input
                                            disabled
                                            type="text"
                                            className={`${errors.repeatPhoneNumber ? 'warning' : ''}`}
                                            {...register('repeatPhoneNumber', { required: t('Globals.required'), validate: value => value === getValues("repeatPhoneNumber") })}
                                        />
                                        <img src="/misc/icons/phone-pink.png" alt="icon" style={{ width: '20px' }} />
                                      </div>
                                    )}
                                    {!onlyReadMode && (
                                      <Controller
                                          control={control}
                                          name="repeatPhoneNumber"
                                          rules={{ required: t('Globals.required'), validate: value => isValidPhoneNumber(value) && value === getValues("phoneNumber") }}
                                          defaultValue={''}
                                          render={({ field }) => {
                                              return (
                                                  <PhoneInput
                                                      disabled={onlyReadMode}
                                                      labels={es}
                                                      international
                                                      countryCallingCodeEditable={false}
                                                      defaultCountry="ES"
                                                      value={field.value}
                                                      onChange={field.onChange}
                                                      onBlur={field.onBlur}
                                                      className={`${errors?.repeatPhoneNumber ? 'phoneWarning' : ''}`}
                                                  />
                                              )
                                          }}
                                      />
                                    )}
                                    {errors?.repeatPhoneNumber && errors?.repeatPhoneNumber?.type === 'validate' && (
                                        <p className="RectificationNew__container__content__form__error">{t("Errors.phoneNotEqual")}</p>
                                    )}
                                    {errors?.repeatPhoneNumber && (
                                        <p className="RectificationNew__container__content__form__error">{errors?.repeatPhoneNumber?.message}</p>
                                    )}
                                    <div style={{height: 30}}></div>
                                    <label>{t('ChangeData.comments')}</label>
                                    <div className="RectificationNew__container__content__form__box mt-3 mb-3">
                                        <textarea
                                            disabled={onlyReadMode}
                                            type="text"
                                            className={`${errors.comments ? 'warning' : ''}`}
                                            placeholder='La rectificación de...'
                                            maxLength={5000}
                                            {...register('comments')}
                                        />
                                    </div>
                                    <div className="RectificationNew__container__content__form__warning mt-3">
                                      <p>*En caso de haber solicitado ya tu tarjeta física, si aún no la has recibido y has rectificado tu dirección de notificaciones postales en el presente formulario, debes llamar al Call Center de Correos Prepago para solicitar el reemplazo de tarjeta:</p>

                                      <p>Teléfono: <a href='tel:+34911239569'> +34 911 23 95 69 </a></p>

                                      <p>Correo electrónico: <a href={`mailto:bonocjoven${convocationYear}@correosprepago.es`}> {`bonocjoven${convocationYear}@correosprepago.es`} </a></p>

                                      <p>Horario de atención: De 9:00 a 18:00 horas, de lunes a viernes laborables de ámbito nacional.</p>
                                    </div>
                                    <div className="RectificationNew__container__content__form__actions">
                                      {
                                          !onlyReadMode && (
                                            <Button
                                                className="buttonPrimaryLarge"
                                                text={t('ChangeData.sign.head')}
                                                styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                                type="submit"
                                            />
                                          )
                                        }
                                    </div>
                                    <span className="RectificationNew__container__content__skip" onClick={() => history.goBack()}>
                                        {t('RequestStatus.claimStatus.skip')}
                                    </span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default RectificationNew;