import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '../../../index';
import { DisplayUtils } from '../../../../utils';
import '../Modal.scss';
import './SignModal.scss';

const SignModal = ({hideModal, showingModal, claveSelect, firmaSelect, claveAdvanced = false}) => {
  const {t} = useTranslation()
  const [clave, setClave] = useState(false);
  const [firma, setFirma] = useState(false);

  return showingModal ? (
    <Modal 
      isShowingModal={true}
      component={(
        <div className="View">
          {!DisplayUtils.isMobile() && (
            <img className='SignModal__close' src='/misc/icons/close-pink.png' alt='close' onClick={hideModal}/>
          )}
          <div className="View__logo">
            <img src="/misc/icons/complete_logo_white.svg" alt="" style={{ height: '100%', width: '100%' }} />
          </div>
          <div className="SignModal__icon">
            <img src={"/misc/icons/sign_modal.svg"} alt="" style={{ height: '100%', width: '100%' }} />
          </div>
          <h5 className="SignModal__title">{t('Join.signRequest.modal.title')}</h5>
          <span className="SignModal__description">{t('Join.signRequest.modal.description')}</span>
          <div className='SignModal__selector'>
            {
              claveSelect && (
                <div className='SignModal__selector__checks' onClick={() => { 
                  setClave(true);
                  setFirma(false);
                }}>
                  <img
                    src={clave ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                    alt="check"
                    className="SignModal__selector__checks__radio"
                  />
                  <div className="SignModal__selector__checks__info">
                    <img className='SignModal__selector__checks__info__clave' src={claveAdvanced ? '/misc/icons/clave_avanzado_modal.jpeg' : '/misc/icons/clave_modal.png'} alt='clave'/>
                    <span className='SignModal__selector__checks__info__title'>{t('Join.signRequest.modal.clave')}</span>
                    <span className='SignModal__selector__checks__info__description'>{t(`Join.signRequest.modal.${claveAdvanced ? 'claveAdvancedInfo' : 'claveInfo'}`)}</span>
                  </div>
                </div>
              )
            }
            {
              firmaSelect && (
                <div className='SignModal__selector__checks' onClick={() => { 
                  setClave(false);
                  setFirma(true);
                }}>
                  <div className="d-flex align-items-center">
                    <img
                      src={firma ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                      alt="check"
                      className="SignModal__selector__checks__radio"
                    />
                    <div className="SignModal__selector__checks__info">
                      <img className='SignModal__selector__checks__info__firma' src={DisplayUtils.isMobile() ? '/misc/img/logo-@firma.png' : '/misc/img/logo-autofirma.png'} alt='autofirma'/>
                      <span className='SignModal__selector__checks__info__title'>{DisplayUtils.isMobile() ? t('Join.signRequest.modal.@firma') : t('Join.signRequest.modal.firma')}</span>
                      <span className='SignModal__selector__checks__info__description'>{DisplayUtils.isMobile() ? t('Join.signRequest.modal.@firmaInfo') : t('Join.signRequest.modal.firmaInfo')}</span>
                    </div>
                  </div>
                </div>
              )
            }
            <Button
              className="buttonPrimaryLarge mt-2"
              text={t('Globals.continue')}
              action={clave === true ? claveSelect : firmaSelect}
              styles={{
                backgroundColor: DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe',
                color: DisplayUtils.isMobile() ? '#381940' : '#381940',
              }}
              disabled={ !clave && !firma }
            />
          </div>
          {DisplayUtils.isMobile() && (
            <div>
              <Button
                className="Button__onboarding"
                text={t('Globals.cancel')}
                action={hideModal}
                styles={{
                  marginTop: '25px',
                  color: DisplayUtils.isMobile() ? '#ffffff' : '#f838f9',
                }}
              />
            </div>
          )}
        </div>
      )}
    />
  ) : null;
};

export default SignModal;
