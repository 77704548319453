import { useTranslation } from 'react-i18next';
import { Modal, ViewModal } from '../../../index';
import { useErrorsContext } from '../../../../contexts/ErrorsContext';
import '../Modal.scss';

const ErrorModal = () => {
  const { error, hideError } = useErrorsContext();
  const {t} = useTranslation()

  return error ? (
    <Modal 
      isShowingModal={true}
      component={(
        <ViewModal
          action={() => hideError()}
          textBtn={t('Globals.retry')}
          imageSource="/misc/icons/warning.png"
          title={t('Errors.error')}
          description={
            error.errorList ? error.errorList[0].message
            : (
              error.error ? error.error : (typeof error === 'string' ? t(`Errors.${error}`) : t('Errors.review'))
            )
          }
          secondTextBtn={t('Globals.cancel')}
          secondAction={() => hideError()}
        />
      )}
    />
  ) : null;
};

export default ErrorModal;
