import { Button } from '../../components';
import '../../screens/info/Info.scss'

const InfoBoxes = ({ image, title, action, color }) => (
  <div className="Info__box" onClick={action} style={{backgroundColor: color }}>
    <div className="Info__box__image">
      <img src={image} alt="icon" />
    </div>
    <div className="Info__box__title">
      <span>{title}</span>
    </div>
    <div className="Info__box__arrow">
      <Button
        icon="/misc/icons/arrow-right-pink.svg"
        className="Button__transparent"
      />
    </div>
  </div>
);

export default InfoBoxes