import http from './base-http-service';
import { constants } from '../utils';

export const signup = (data) => http.post('account-api/v1/public/beneficiaries', data);
export const onLogin = (data) => http.post('account-api/v1/public/login', data);
export const resetPassword = (data) => http.post('account-api/v1/public/account/resetPassword', data);
export const confirmPassword = (data) => http.post('account-api/v1/public/account/resetPassword/confirm', data);
export const getBeneficiary = () => http.get('account-api/v1/beneficiaries');
export const updateLanguage = (data) => http.patch('account-api/v1/account/updateLanguage', data);

export const refreshToken = (data) => http.post(constants.REFRESH_TOKEN_URL, data);
export const logout = () =>
  http
    .post('account-api/v1/logout', {
      refreshToken: localStorage.getItem(constants.CURRENT_REFRESH_KEY),
    })
    .then(() => {
      return { logout: true };
    })
    .catch(() => {
      localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
      localStorage.removeItem(constants.CURRENT_REFRESH_KEY);
      localStorage.removeItem(constants.CURRENT_ID_TOKEN_KEY);
      localStorage.removeItem(constants.CURRENT_USER_KEY);
      localStorage.removeItem(constants.CURRENT_PETITON_STATUS_KEY);
      localStorage.removeItem(constants.KEEP_SESSION);
      window.location.reload()
    });
export const getAccount = () => http.get('accounts-api/account');
export const resetPinCode = (data) => http.post('accounts-api/account/reset/confirmation', data);
export const confirmationCode = (data) => http.post('accounts-api/account/confirm', data);
export const updateMerchant = (data) => http.patch('accounts-api/account/beneficiary', data);
export const checkUserPetition = (userEmail) => http.get(`accounts-api/account/beneficiary/check/${userEmail}`);
export const removeCognitoAccount = (data) => http.post('accounts-api/account/remove/beneficiary', data);
export const createAndActivateAccount = (data) => http.post('accounts-api/account/beneficiary/activate', data);
