import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, InfoBoxes } from '../../components';
import { DisplayUtils } from '../../utils';
import './Info.scss';
import { useParametrics } from '../../contexts/ParametricsContext';

const InfoMenu = ({ setInfo, t, history }) => {
  const { convocationYear } = useParametrics();

  return (
    <div>
      <InfoBoxes
        image="/misc/icons/list-info-pink.png"
        title={t('Info.downloads.title')}
        action={() => setInfo(6)}
      />
      <InfoBoxes
        image="/misc/icons/block-info.png"
        title={t('Info.info.title')}
        action={() => setInfo(1)}
      />
      <InfoBoxes
        image="/misc/icons/list-info.png"
        title={t('Info.program.title')}
        action={() => setInfo(2)}
      />
      <InfoBoxes
        image="/misc/icons/view-list.png"
        title={t('Info.carac.title')}
        action={() => setInfo(3)}
      /> 
      <InfoBoxes
        image="/misc/icons/view-list.png"
        title={t('Info.faq.title')}
        action={() => setInfo(4)}
      />
      <InfoBoxes
        image="/misc/icons/list-info-pink.png"
        title={t('Info.cond.title')}
        action={() => setInfo(5)}
      />
      <InfoBoxes
        image="/misc/icons/list-info-pink.png"
        title={t('Info.privacy.title')}
        action={() => history.push({ pathname: '/privacy' })}
      />
      <div className="Info__container__more">
        <div className="Info__container__more__line"></div>
        <span className="Info__container__more__title">{t('Info.more')}</span>
        <div className="Info__container__more__line"></div>
      </div>
      <div
        className="d-flex justify-content-center flex-column align-items-start"
        style={{ marginBottom: DisplayUtils.isMobile() ? '' : '32px' }}
      >
        {/* <p dangerouslySetInnerHTML={{ __html: t('Info.statusInfo') }} className="Info__container__more__infoTime" /> */}
        {/* <p dangerouslySetInnerHTML={{ __html: t('Info.correosInfo') }} className="Info__container__more__infoTime" /> */}
        <br/>
        {convocationYear === '2023' && (<p dangerouslySetInnerHTML={{ __html: t('Info.correosInfo') }} className="Info__container__more__infoTime" />)}
        <br/>
        <p dangerouslySetInnerHTML={{ __html: t('Info.fnmtInfo') }} className="Info__container__more__infoTime" />
      </div>
      <div className="Info__container__socials">
        <Link to={{ pathname: "https://facebook.com/BonoCulturaJoven/" }} target="_blank" >
          <img
            src="/misc/icons/facebook.png"
            className="Info__container__socials__img"
            alt="social"
          />
        </Link>
        <Link to={{ pathname: "https://twitter.com/BonoCultural" }} target="_blank" >
          <img src="/misc/icons/twitter.png" className="Info__container__socials__img" alt="social" />
        </Link>
        <Link to={{ pathname: "https://instagram.com/bonoculturaljoven/" }} target="_blank" >
          <img
            src="/misc/icons/instagram.png"
            className="Info__container__socials__img"
            alt="social"
          />
        </Link>
        <Link to={{ pathname: "https://tiktok.com/@culturagob" }} target="_blank" >
          <img src="/misc/icons/tik-tok.png" className="Info__container__socials__img" alt="social" />
        </Link>
        <Link to={{ pathname: "https://www.youtube.com/c/MinisteriodeCulturayDeporte" }} target="_blank" >
          <img src="/misc/icons/youtube.png" className="Info__container__socials__img" alt="social" />
        </Link>
        <Link to={{ pathname: 'https://www.linkedin.com/company/ministerio-de-culturaydeporte' }} target="_blank" >
          <img src="/misc/icons/linkedin.svg" className="Info__container__socials__img" alt="social" />
        </Link>
      </div>
    </div>
  );
}

const InfoData = ({ title, description1, description2 }) => (
  <div className="Info__container__data">
    <h5>{title}</h5>
    <p dangerouslySetInnerHTML={{ __html: description1 }} />
    {description2 && (
      <div className="d-flex justify-content-center">
        <img src="/misc/icons/people.png" alt="icon-group" />
      </div>
    )}
    {description2 && (
      <p>
        {description2}
      </p>
    )}
  </div>
);

const Info = ({ initialStep }) => {
  const [info, setInfo] = useState(0);
  const history = useHistory();
  const location = useLocation()
  const state = location?.state?.step ?? initialStep
  const { t } = useTranslation();

  useEffect(() => {
    if (state) {
      setInfo(state)
    }
    if (initialStep) {
      setInfo(initialStep)
    }
  }, []) //eslint-disable-line

  const getView = (view) => {
    switch (view) {
      case 0:
        return <InfoMenu setInfo={setInfo} t={t} history={history} />;
      case 1:
        return (
          <InfoData title={''} description1={t('Info.info.description1')} />
        );
      case 2:
        return <InfoData title={''} description1={t('Info.program.description1')} />;
      case 3:
        return <InfoData title={''} description1={t('Info.carac.description1')} />;
      case 4:
        return <InfoData title={''} description1={t('Info.faq.description1')} />;
      case 5:
        return <InfoData title={''} description1={t('Info.cond.description1')} />;
      case 6:
        return <InfoData title={''} description1={t('Info.downloads.description1')} />;
      case 7:
        return <InfoData title={''} description1={t('Info.tutorials.description1')} />;
      default:
        break;
    }
  };

  const getTitle = (view) => {
    switch (view) {
      case 0:
        return t('Info.bono');
      case 1:
        return t('Info.info.title');
      case 2:
        return t('Info.norm');
      case 3:
        return t('Info.features');
      case 4:
        return t('Info.faqs');
      case 5:
        return t('Info.conditions');
      case 6:
        return t('Info.downloads.title');
      case 7:
        return t('Info.tutorials.title');
      default:
        break;
    }
  };

  const getBack = () => {
    if (state || !info) {
      history.goBack()
    } else {
      setInfo(0)
    }
  }

  return (
    <div className="Info">
      <div className="w-100 d-flex justify-content-center">
        {!DisplayUtils.isMobile() ? (
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="Info__logo" />
        ) : (
          <div className="Info__title">
            <Button
              icon="/misc/icons/arrow-left-blue.svg"
              action={() => getBack()}
              className="Button__transparent"
            />
            <h6>{getTitle(info)}</h6>
          </div>
        )}
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="Info__container">
          {!DisplayUtils.isMobile() && (
            <div className="Info__container__title">
              <Button
                icon="/misc/icons/arrow-left-pink.svg"
                action={() => getBack()}
                className="Button__transparent"
              />
              <h6>{getTitle(info)}</h6>
            </div>
          )}
          {getView(info)}
        </div>
      </div>
    </div>
  );
};

export default Info;
