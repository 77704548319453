import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'
import { useForm } from 'react-hook-form';
import { Button, Loading } from '../../components';
import { constants, DisplayUtils } from '../../utils';
import './PassResetCode.scss';
import { confirmPassword } from '../../services/authServices';
import { useErrorsContext } from '../../contexts/ErrorsContext';

const PassResetCode = () => {
  const { showError } = useErrorsContext();
  const history = useHistory();
  const { search } = useLocation()
  const values = queryString.parse(search, {decode: false})
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await confirmPassword(data);
      history.replace({ pathname: '/reset-password/code/success', state: { redirect: true } });
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue('email', values['user_name'])
    setValue('code', values['confirmation_code'])
  },[]) //eslint-disable-line


  return (
    loading ? (
      <div className='vh-100 d-flex justify-content-center align-items-center'>
        <Loading />
      </div>
    ) : (
      <div className="PassResetCode">
        <div className="w-100 d-flex justify-content-center">
          {!DisplayUtils.isMobile() ? (
            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="PassResetCode__logo" />
          ) : (
            <div className="PassResetCode__title">
              <Button
                icon="/misc/icons/arrow-left-blue.svg"
                action={() => history.replace('/login')}
                className="Button__transparent"
              />
              <h6>{t('PassResetCode.title')}</h6>
            </div>
          )}
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="PassResetCode__container">
            {!DisplayUtils.isMobile() && (
              <div className="PassResetCode__container__title">
                <Button
                  icon="/misc/icons/close-pink.png"
                  action={() => history.replace('/login')}
                  className="Button__transparent"
                  styles={{ width: '50px' }}
                />
                <h6>{t('PassResetCode.title')}</h6>
              </div>
            )}
            <div className="PassResetCode__container__form">
              <p
                className="PassResetCode__container__form__description"
                style={{ marginBottom: '44px' }}
              >
                <h6>{t('PassResetCode.description2')}</h6>
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label>{t('Globals.email')}</label>
                <div className="PassResetCode__container__form__box">
                  <input
                    type="text"
                    placeholder={t('Login.email')}
                    className={`${errors.email || errors.incorrect ? 'warning' : ''}`}
                    {...register('email', {
                      required: t('Globals.required'),
                      validate: (value) =>
                        constants.EMAIL_PATTERN.test(value) || t('Errors.notValid'),
                    })}
                  />
                  <img src="/misc/icons/email.png" alt="icon" style={{ width: '23px' }} />
                </div>
                {errors.email && (
                  <p className="PassResetCode__container__form__error">{errors.email.message}</p>
                )}
                <label>{t('Globals.password')}</label>
                <div className="PassResetCode__container__form__box">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('PassResetCode.password')}
                    className={`${errors.password || errors.incorrect ? 'warning' : ''}`}
                    {...register('password', {
                      required: t('Globals.required'),
                      validate: (value) =>
                        constants.PASSWORD_PATTERN.test(value) || t('Errors.notValid'),
                    })}
                  />
                  <img src={showPassword ? '/misc/icons/eye-show.png' : '/misc/icons/eye-hide.png'} alt="icon" style={{ width: showPassword ? '26px' : '24px', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                </div>
                {errors.password && (
                  <p className="PassResetCode__container__form__error">{errors.password.message}</p>
                )}
                <label>{t('PassResetCode.labelCode')}</label>
                <div className="PassResetCode__container__form__box">
                  <input
                    type="text"
                    min={0}
                    max={999999}
                    placeholder={t('PassResetCode.code')}
                    className={`${errors.code || errors.incorrect ? 'warning' : ''}`}
                    {...register('code', {
                      required: t('Globals.required'),
                      min: { value: 0, message: t('Errors.wrongCode') },
                      max: { value: 999999, message: t('Errors.wrongCode') },
                      // valueAsNumber: true,
                      // validate: (value) => value.length === 6 || t('Errors.notValid'),
                    })}
                    disabled
                  />
                  <img src="/misc/icons/code-icon.svg" alt="icon" style={{ width: '26px', bottom: '22px' }} />
                </div>
                {errors.code && (
                  <p className="PassResetCode__container__form__error">{errors.code.message}</p>
                )}
                {errors.incorrect && (
                  <p className="PassResetCode__container__form__error">{t('PassResetCode.incorrect')}</p>
                )}
                <div className="d-flex justify-content-center">
                  <Button
                    className="buttonPrimaryLarge"
                    text={t('Globals.send')}
                    type="submit"
                    styles={{ backgroundColor: '#34fdfe', color: '#381940', marginTop: '117px' }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PassResetCode;
