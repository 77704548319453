import { useHistory, useParams } from 'react-router-dom';
import { DateUtils } from '../../../utils';
import { formatToCurrency } from '../../../utils/Currency';
import './TransactionCard.scss';

const TransactionCard = ({ item }) => {

    const history = useHistory();

    const { petitionId, wallet } = useParams();

    const getResultInfo = (result) => {
        switch(result) {
            case 'CONFIRMED':
                return <p className="TransactionCard__info__result green">Confirmada</p>
            case 'BLOCKED':
                return <p className="TransactionCard__info__result orange">Pendiente</p>
            case 'CANCELLED':
                return <p className="TransactionCard__info__result red">Cancelada</p>
            default:
                return <></>
        }
    }
  
    return (
        <div className="TransactionCard" onClick={() =>  {
          history.push({ pathname: `/petitions/${petitionId}/transactions/${wallet}/detail/${item.correosTransactionId}` });
        }}>
            <img className='TransactionCard__icon' src='/misc/icons/transaction-card-icon.png' alt='icon'/>
            <div className="TransactionCard__info">
                <span className="TransactionCard__info__id">{item.mccDescription ?? "Sin descripción"}</span>
                <span className="TransactionCard__info__date">{DateUtils.getFormatedDate(item.createdAt)}</span>
                {getResultInfo(item.status)}
            </div>
            <span className="TransactionCard__amount">{formatToCurrency(item.amount)}</span>
        </div>
    )
};

export default TransactionCard;
