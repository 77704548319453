import { createContext, useContext, useEffect, useState } from "react";

const GeolocationContext = createContext();

export const useGeolocationContext = () => useContext(GeolocationContext);

export const GeolocationContextProvider = ({ children }) => {

  const [position, setPosition] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const geolocation = navigator.geolocation;
    if (!geolocation) {
      setPosition(null);
      setLoaded(true);
      return;
    }
    const _success = (position) => {
      setPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
      setLoaded(true);
    }
    const _error = () => {
      setPosition(null);
      setLoaded(true);
    }
    geolocation.getCurrentPosition(_success, _error);
  }, []);

  const value = {
    loaded, position
  };

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  );
}

