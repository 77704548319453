import { useEffect, useState } from 'react';
import { Generals, Request, Programme, Loading, NavMenu, Button } from '../../components';
import { DisplayUtils } from '../../utils';
import { useTranslation } from 'react-i18next';
import { getNotifications } from '../../services/notificationServices';
import { useParametrics } from '../../contexts/ParametricsContext';
import './Notifications.scss';
import { useErrorsContext } from '../../contexts/ErrorsContext';

const Notifications = () => {
  const { t } = useTranslation();
  const { convocationId } = useParametrics();
  const { showError } = useErrorsContext();

  const [view, setView] = useState(0);
  const [isLoading, setLoading] = useState(true)
  const [bonusNotifications, setBonusNotifications] = useState([])
  const [generalNotifications, setGeneralNotifications] = useState([])
  const [programNotifications, setProgramNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(0)

  // TODO: 
  useEffect(() => {
    if (!convocationId) return
    const fetchNotifications = async () => {
      try {
        const resultGeneral = await getNotifications(convocationId, 'GENERAL');
        const resultBonus = await getNotifications(convocationId, 'APPLICATION');
        const resultProgram = await getNotifications(convocationId, 'PROGRAM');
        setGeneralNotifications(resultGeneral)
        setBonusNotifications(resultBonus)
        setProgramNotifications(resultProgram)
        const allUnread = resultGeneral.filter(item => !item.isRead).length + resultBonus.filter(item => !item.isRead).length + resultProgram.filter(item => !item.isRead).length
        setUnreadNotifications(allUnread)
      } catch(e) {
        showError(e)
      } finally {
        setLoading(false);
      }
      setLoading(false)
    }
    fetchNotifications()
  }, [convocationId])

  const getColors = (item) => {
    if (item === view) {
      return {
        backgroundColor: DisplayUtils.isMobile() ? '#829bba' : '#34fdfe',
        color: DisplayUtils.isMobile() ? '#ffffff' : '#35316a',
      };
    }
  };

  const getView = () => {
    switch (view) {
      case 0:
        return <Generals t={t} data={generalNotifications} />;
      case 1:
        return <Request t={t} data={bonusNotifications} />;
      case 2:
        return <Programme t={t} data={programNotifications} />;
      default:
        break;
    }
  };

  return (
    <NavMenu>
      <div className="Notifications">
        <div className="Notifications__content">
          {isLoading ? (
            <div className='vh-100 d-flex justify-content-center align-items-center'>
              <Loading />
            </div>
          ) : (
            <>
              <div className="w-100 d-flex justify-content-center">
                <div className="Notifications__content__title">
                  <h6>{t('Notifications.title')}</h6>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center">
                <div className="Notifications__content__box">
                  <div className="Notifications__content__box__buttons">
                    <div className='Notifications__content__box__buttons__button'>
                      <Button
                        className="buttonNotifications"
                        text={t('Notifications.generals')}
                        action={() => setView(0)}
                        styles={getColors(0)}
                      />
                      {generalNotifications.filter(item => !item.isRead).length > 0 && <div className='Notifications__content__box__buttons__button__badge' />}
                    </div>
                    <div className='Notifications__content__box__buttons__button'>
                      <Button
                        className="buttonNotifications "
                        text={t('Notifications.request')}
                        action={() => setView(1)}
                        styles={getColors(1)}
                      />
                      {bonusNotifications.filter(item => !item.isRead).length > 0 && <div className='Notifications__content__box__buttons__button__badge' />}
                    </div>
                    <div className='Notifications__content__box__buttons__button'>
                      <Button
                        className="buttonNotifications"
                        text={t('Notifications.programme')}
                        action={() => setView(2)}
                        styles={getColors(2)}
                      />
                      {programNotifications.filter(item => !item.isRead).length > 0 && <div className='Notifications__content__box__buttons__button__badge' />}
                    </div>
                  </div>
                  <div className="Notifications__content__box__info">
                    <span>{t('Notifications.info1')}</span>
                    <span className="Notifications__content__box__info__number">{unreadNotifications}</span>
                    <span className='text-center'>{t('Notifications.info2')}</span>
                  </div>
                  {getView()}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </NavMenu>
  )
}

export default Notifications;
