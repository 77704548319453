import http from "./base-http-service";

export const isConvocatoryOpened = async () => {
  return true;
}

export const getConvocationId = async () => {
  const resp = await http.get('parametric-api/v1/parametricValues/CONVOCATION_FRONT_BCJ')
  return resp.value;
}

export const getConvocationByCode = async (code) => {
  const resp = await http.get(`parametric-api/v1/convocations/search/code/${code}`)
  return resp;
}

export const getConvocationById = (convocationId) => http.get(`parametric-api/v1/convocations/${convocationId}`);
export const getParametricsAddressType = () => http.get(`parametric-api/v1/roadType`);
export const getParametricsCountries = () => http.get(`parametric-api/v1/countries`);
export const getParametricsProvinces = () => http.get(`parametric-api/v1/provinces`);
export const getParametricsLocalities = (provinceId) => http.get(`parametric-api/v1/localities/${provinceId}`);
export const getParametricsPostalCodes = (localityId) => http.get(`parametric-api/v1/postalCodes/${localityId}`);
export const getParametricsGenders = () => http.get(`parametric-api/v1/gender`);
export const getFromBirthdate = (convocationId) => http.get(`parametric-api/v1/${convocationId}/parametricValues/BENEFICIARY_BIRTHDAY_FROM`);
export const getToBirthdate = (convocationId) => http.get(`parametric-api/v1/${convocationId}/parametricValues/BENEFICIARY_BIRTHDAY_UNTIL`);