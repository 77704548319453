import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './initializers/i18n';
import { Router } from 'react-router-dom';
import { AuthContextProvider } from './contexts/AuthContext';
import { NotificationsContextProvider } from './contexts/NotificationsContext';
import { ErrorsContextProvider } from './contexts/ErrorsContext';
import { createBrowserHistory } from 'history';
import ScrollToTop from './utils/ScrollToTop';
import { GoogleMapsProvider } from './contexts/GoogleMapsProvider'
import * as serviceWorker from './serviceWorker.js';
import reportWebVitals from './reportWebVitals';
import { LanguageContextProvider } from './contexts/LanguageContext';
import { ParametricsProvider } from './contexts/ParametricsContext';

// eslint-disable-next-line no-undef
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const googleMapsLibraries = [ 'places' ];

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <ScrollToTop />
    <LanguageContextProvider>
      <AuthContextProvider>
        <ParametricsProvider>
          <ErrorsContextProvider>
            <NotificationsContextProvider>
              <GoogleMapsProvider googleMapsApiKey={googleMapsApiKey} language="es" libraries={googleMapsLibraries}>
                <App />
              </GoogleMapsProvider>
            </NotificationsContextProvider>
          </ErrorsContextProvider>
        </ParametricsProvider>
      </AuthContextProvider>
    </LanguageContextProvider>
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
reportWebVitals();