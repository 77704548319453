import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Loading } from '../../components';
import { constants } from '../../utils';
import { resetPassword } from '../../services/authServices';
import { useErrorsContext } from '../../contexts/ErrorsContext.js';
import './PassReset.scss';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import { useParametrics } from '../../contexts/ParametricsContext.js';

const PassReset = () => {
  const [info, setInfo] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const state = location?.state?.profile;
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [loading, setLoading] = useState(false);
  const { showError } = useErrorsContext();
  const { convocationId } = useParametrics();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await resetPassword({
        ...data,
        convocationId
      });
      history.replace({ pathname: '/reset-password/success', state: { redirect: true } });
    } catch (error) {
      if(error?.error === "A008") {
        showError('notValidatedAndSend')
      } else {
        showError(error)
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      setInfo(state);
    }
  }, []); //eslint-disable-line

  return (
    <div className="PassReset">
      {loading ? (
        <div className='vh-100 d-flex justify-content-center align-items-center'>
          <Loading />
        </div>
      ) : (
        <>
          <MainLayout
            title={!info ? t('PassReset.title') : t('PassReset.title2')}
            onBack={() => {
              history.replace('/login');
            }}
          >
            <div className="PassReset__container__form">
              <p
                className="PassReset__container__form__description"
                style={{ marginBottom: '44px' }}
              >
                <h6>{!info ? t('PassReset.description') : t('PassReset.description2')}</h6>
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label>{t('Globals.email')}</label>
                <div className="PassReset__container__form__box">
                  <input
                    type="text"
                    placeholder={t('Login.email')}
                    className={`${errors.email || errors.incorrect ? 'warning' : ''}`}
                    {...register('email', {
                      required: t('Globals.required'),
                      validate: (value) =>
                        constants.EMAIL_PATTERN.test(value) || t('Errors.notValid'),
                    })}
                  />
                  <img src="/misc/icons/email.png" alt="icon" style={{ width: '23px' }} />
                </div>
                {errors.email && (
                  <p className="PassReset__container__form__error">{errors.email.message}</p>
                )}
                {errors.incorrect && (
                  <p className="LoginForm__container__form__error">
                    {t('PassResetCode.incorrect')}
                  </p>
                )}
                <div className="d-flex justify-content-center">
                  <Button
                    className="buttonPrimaryLarge"
                    text={t('Globals.send')}
                    type="submit"
                    styles={{ backgroundColor: '#34fdfe', color: '#381940', marginTop: '117px' }}
                  />
                </div>
              </form>
            </div>
          </MainLayout>
        </>
      )}
    </div>
  );
};

export default PassReset;
