import { useEffect, useState } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import {
  NotFound,
  Init,
  InitSelector,
  Info,
  Signup,
  SignupSuccess,
  Login,
  PassReset,
  PassResetSuccess,
  PassResetCode,
  PassResetCodeSuccess,
  Cookies,
  Privacy,
  Information,
  Profile,
  Language,
  RequestInfo,
  UserTypeSelector,
  LeaveProcess,
  ClaveVerification,
  ClaveCallback,
  AutomatedQueries,
  RepresentativeData,
  BeneficiaryData,
  CardTypeSelector,
  NotificationsAddressInfo,
  ConfirmIdentity,
  UploadDocumentation,
  SignRequest,
  RequestSuccess,
  Notifications,
  CsvSearch,
  PublicShops,
  Shops,
  OnlineShops
} from './screens';
import { CookiesModal } from './components';
import { ErrorModal } from './components';
import { AuthenticatedRoute, NotAuthenticatedRoute, SignupRoute, SsoRoute } from './guards/AuthenticatedRoute';
import { constants } from './utils';
import useModal from './hooks/useModal';
import './App.scss';
import Petitions from './screens/petitions/Petitions';
import PetitionsSso from './screens/petitions/PetitionsSso';
import PetitionDetail from './screens/petitions/petitionDetail/PetitionDetail';
import Pdf from './screens/Pdf/Pdf';
import PetitionDocumentation from './screens/petitions/petitionDetail/petitionDocumentation/PetitionDocumentation';
import Correction from './screens/petitions/petitionDetail/corrections/correction/Correction';
import Corrections from './screens/petitions/petitionDetail/corrections/Corrections';
import AdditionalDocs from './screens/petitions/petitionDetail/additionalDocs/AdditionalDocs';
import AdditionalDocsNew from './screens/petitions/petitionDetail/additionalDocs/additionalDocsNew/AdditionalDocsNew';
import SignProcedure from './screens/SignProcedure/SignProcedure';
import SignProcedureSuccess from './screens/SignProcedure/SignProcedureSuccess/SignProcedureSuccess';
import AdditionalDocsDetail from './screens/petitions/petitionDetail/additionalDocs/additionalDocsDetail/AdditionalDocsDetail';
import CorrectionDetail from './screens/petitions/petitionDetail/corrections/CorrectionDetail/CorrectionDetail';
import Transactions from './screens/transactions/Transactions';
import WalletDetail from './screens/transactions/walletDetail/WalletDetail';
import TransactionsFilters from './screens/transactions/filters/TransactionsFilters';
import TransactionDetails from './screens/transactions/details/TransactionDetails';
import Rectifications from './screens/petitions/petitionDetail/rectifications/Rectifications';
import RectificationNew from './screens/petitions/petitionDetail/rectifications/RectificationNew/RectificationNew';
import RectificationDetail from './screens/petitions/petitionDetail/rectifications/RectificationDetail/RectificationDetail';
import Claims from './screens/petitions/petitionDetail/claims/Claims';
import Claim from './screens/petitions/petitionDetail/claims/claim/Claim';
import ClaimDetail from './screens/petitions/petitionDetail/claims/ClaimDetail/ClaimDetail';
import { HowWorks } from './screens/how-works/HowWorks';
import NotificationDetails from './screens/notifications/notificationDetails/NotificationDetails';
import PetitionFilesDocumentation from './screens/petitions/petitionDetail/petitionFilesDocumentation/PetitionFilesDocumentation';
import ClaveSsoCallback from './screens/clave/ClaveSsoCallback';
import PetitionSignup from './screens/petitions/petitionDetail/signup/PetitionSignup';
import PetitionSignupSuccess from './screens/petitions/petitionDetail/signup/success/PetitionSignupSuccess';
import ChangeEmailDetail from './screens/petitions/petitionDetail/changeEmail/ChangeEmailDetail/ChangeEmailDetail';
import ChangeEmailNew from './screens/petitions/petitionDetail/changeEmail/ChangeEmailNew/ChangeEmailNew';
import ChangeEmail from './screens/petitions/petitionDetail/changeEmail/ChangeEmails';
import ChangeEmailSignProcedureSuccess from './screens/petitions/petitionDetail/changeEmail/SignProcedureSuccess/SignProcedureSuccess'
import ChangeEmailVerification from './screens/petitions/petitionDetail/changeEmail/ChangeEmailVerification/ChangeEmailVerification';
import ChangeEmailFinish from './screens/petitions/petitionDetail/changeEmail/ChangeEmailFinish/ChangeEmailFinish';

const App = ({ location }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { isShowingModal, toggleModal } = useModal();

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [width]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const acceptCookies = () => {
    localStorage.setItem(constants.COOKIES_MODAL, true);
    toggleModal();
  };

  useEffect(() => {
    if (
      !localStorage.getItem(constants.COOKIES_MODAL) ||
      localStorage.getItem(constants.COOKIES_MODAL) === 'false'
    ) {
      toggleModal();
    }
  }, []); // eslint-disable-line

  return (
    <div className="App">
      <Helmet>
        <script type="text/javascript" src="/js/autoscript.js"></script>
      </Helmet>
      <ErrorModal />
      <CookiesModal
        isShowingModal={isShowingModal}
        action={acceptCookies}
        toggleModal={toggleModal}
        showCookiesInfo={() => { }}
      />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={450}>
          <Switch location={location}>
            <NotAuthenticatedRoute exact path="/" component={Init} />
            <NotAuthenticatedRoute exact path="/init-selector" component={InitSelector} />
            <NotAuthenticatedRoute exact path="/login" component={Login} />
            <SignupRoute exact path="/signup" component={Signup} />
            <SignupRoute exact path="/signup/success" component={SignupSuccess} />
            <Route exact path="/entidades-adheridas" component={PublicShops} />
            <AuthenticatedRoute exact path="/shops" component={Shops} />
            <AuthenticatedRoute exact path="/online-shops" component={OnlineShops} />
            <Route exact path="/csv-search" component={CsvSearch} />

            <Route exact path="/reset-password" component={PassReset} />
            <Route exact path="/reset-password/success" component={PassResetSuccess} />
            <Route exact path="/reset-password/code" component={PassResetCode} />
            <Route exact path="/reset-password/code/success" component={PassResetCodeSuccess} />

            <AuthenticatedRoute exact path="/join/request-info" component={RequestInfo} />
            <AuthenticatedRoute exact path="/join/select-user-type" component={UserTypeSelector} />
            <AuthenticatedRoute exact path="/beneficiary/join/clave-verification" component={ClaveVerification} />
            <AuthenticatedRoute exact path="/representative/join/clave-verification" component={ClaveVerification} />
            <AuthenticatedRoute exact path="/beneficiary/join/confirm-identity" component={ConfirmIdentity} />
            <AuthenticatedRoute exact path="/representative/join/confirm-identity" component={ConfirmIdentity} />
            <AuthenticatedRoute exact path="/beneficiary/join/automated-queries" component={AutomatedQueries} />
            <AuthenticatedRoute exact path="/representative/join/automated-queries" component={AutomatedQueries} />
            <AuthenticatedRoute exact path="/representative/join/representative-data" component={RepresentativeData} />
            <AuthenticatedRoute exact path="/beneficiary/join/beneficiary-data" component={BeneficiaryData} />
            <AuthenticatedRoute exact path="/representative/join/beneficiary-data" component={BeneficiaryData} />
            <AuthenticatedRoute exact path="/beneficiary/join/card-selector" component={CardTypeSelector} />
            <AuthenticatedRoute exact path="/representative/join/card-selector" component={CardTypeSelector} />
            <AuthenticatedRoute exact path="/beneficiary/join/notifications-address" component={NotificationsAddressInfo} />
            <AuthenticatedRoute exact path="/representative/join/notifications-address" component={NotificationsAddressInfo} />
            <AuthenticatedRoute exact path="/representative/join/upload-documentation" component={UploadDocumentation} />
            <AuthenticatedRoute exact path="/beneficiary/join/upload-documentation" component={UploadDocumentation} />
            <AuthenticatedRoute exact path="/representative/join/sign-request" component={SignRequest} />
            <AuthenticatedRoute exact path="/beneficiary/join/sign-request" component={SignRequest} />
            <AuthenticatedRoute exact path="/beneficiary/join/request-success" component={RequestSuccess} />
            <AuthenticatedRoute exact path="/representative/join/request-success" component={RequestSuccess} />
            <AuthenticatedRoute exact path="/join/leave" component={LeaveProcess} />

            <AuthenticatedRoute exact path="/profile" component={Profile} />
            <AuthenticatedRoute exact path="/profile/language" component={Language} />
            <Route exact path="/profile/sso/language" component={Language} />

            <AuthenticatedRoute exact path="/petitions" component={Petitions} />
            <SsoRoute exact path="/petitions-sso" component={PetitionsSso} />
            <AuthenticatedRoute exact path="/petitions/:petitionId" component={PetitionDetail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/documentation" component={PetitionDocumentation} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/corrections" component={Corrections} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/corrections/new" component={Correction} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/corrections/:procedureId" component={CorrectionDetail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/claims" component={Claims} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/claims/new" component={Claim} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/claims/:procedureId" component={ClaimDetail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/additional-documents" component={AdditionalDocs} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/additional-documents/new" component={AdditionalDocsNew} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/additional-documents/:procedureId" component={AdditionalDocsDetail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/change-email" component={ChangeEmail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/change-email/new" component={ChangeEmailNew} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/change-email/:procedureId" component={ChangeEmailDetail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/change-email/:procedureId/request-success" component={ChangeEmailSignProcedureSuccess} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/change-email/:procedureId/verify-email" component={ChangeEmailVerification} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/change-email/:procedureId/finish" component={ChangeEmailFinish} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/rectifications" component={Rectifications} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/rectifications/new" component={RectificationNew} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/rectifications/:procedureId" component={RectificationDetail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/transactions" component={Transactions} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/transactions/:wallet" component={WalletDetail} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/transactions/:wallet/filters" component={TransactionsFilters} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/transactions/:wallet/detail/:transactionId" component={TransactionDetails} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/file-documentation" component={PetitionFilesDocumentation} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/signup" component={PetitionSignup} />
            <AuthenticatedRoute exact path="/petitions/:petitionId/signup/success" component={PetitionSignupSuccess} />

            
            <Route exact path="/how-works" component={HowWorks} />
            <Route exact path="/info" component={Info} />
            <Route exact path="/information" component={Information} />
            <Route exact path="/terms" component={() => <Info initialStep={5} />} />

            <Route exact path="/cookies" component={Cookies} />
            <Route exact path="/privacy" component={Privacy} />


            <AuthenticatedRoute exact path="/notifications" component={Notifications} />
            <AuthenticatedRoute exact path="/notifications/details" component={NotificationDetails} />

            <AuthenticatedRoute exact path="/clave-callback" component={ClaveCallback} />
            <Route exact path="/clave-sso-callback" component={ClaveSsoCallback} />
            <Route exact path="/pdf-viewer" component={Pdf} />

            <AuthenticatedRoute exact path="/sign-procedure" component={SignProcedure} />
            <AuthenticatedRoute exact path="/sign-procedure/success" component={SignProcedureSuccess} />

            {!constants.ROUTES_LIST.includes(location.pathname) && <Route component={NotFound} />}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default withRouter(App);
