import { constants } from "../utils";
import http from "./base-http-service";

export const getUserPetitions = async (convocationId) =>
  http.get(`/beneficiary-api/bcj/v1/${convocationId}/petitions`);

export const createPetition = (convocationId, accountType) =>
  http.post(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/`,
    {},
  );

export const getPetitionVerifyIdentity = (
  convocationId,
  accountType,
  petitionId,
) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/verifyIdentity`,
  );

export const updatePetitionVerifyIdentity = (
  convocationId,
  accountType,
  petitionId,
) =>
  http.patch(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/verifyIdentity`,
    {},
  );

export const getPetitionConsultationAuthorization = (
  convocationId,
  accountType,
  petitionId,
) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/consultationAuthorization`,
  );

export const updatePetitionConsultationAuthorization = (
  convocationId,
  accountType,
  petitionId,
  data,
) =>
  http.patch(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/consultationAuthorization`,
    data,
  );

export const getRepresentativeData = (convocationId, accountType, petitionId) =>
  http.get(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/representative`,
  );

export const saveRepresentativeData = (
  convocationId,
  accountType,
  petitionId,
  data,
) =>
  http.patch(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/representative`,
    data,
  );

export const getCards = (convocationId, accountType, petitionId) =>
  http.get(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/cards`,
  );

export const saveCards = (convocationId, accountType, petitionId, data) =>
  http.patch(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/cards`,
    data,
  );

export const getBeneficiaryData = (convocationId, accountType, petitionId) =>
  http.get(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/beneficiary`,
  );

export const saveBeneficiaryData = (
  convocationId,
  accountType,
  petitionId,
  data,
) =>
  http.patch(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/beneficiary`,
    data,
  );

export const getDocumentation = (convocationId, accountType, petitionId) =>
  http.get(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/documentation`,
  );

export const saveDocumentation = (
  convocationId,
  accountType,
  petitionId,
  data,
) =>
  http.patch(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/documentation`,
    data,
  );

export const getNotificationsData = (convocationId, accountType, petitionId) =>
  http.get(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/notification`,
  );

export const saveNotificationsData = (
  convocationId,
  accountType,
  petitionId,
  data,
) =>
  http.patch(
    `/beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/notification`,
    data,
  );

export const getAccountType = (convocationId, accountType, petitionId) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/changeAccountType`,
  );

export const saveAccountType = (convocationId, accountType, petitionId, data) =>
  http.patch(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/changeAccountType`,
    data,
  );

export const getPendingSign = (convocationId, accountType, petitionId) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/pendingSign`,
  );

export const checkIdentity = (convocationId, accountType, petitionId) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/checkIdentity`,
  );

export const autofirma = (convocationId, accountType, petitionId) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/pendingSign/autofirma`,
  );

export const noCrypto = (convocationId, accountType, petitionId) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/pendingSign/NoCrypto`,
  );

export const getRegister = (convocationId, accountType, petitionId) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/${accountType}/petitions/${petitionId}/process/register`,
  );

export const getPetitionFiles = (convocationId, petitionId, fileGroup) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/petitions/${petitionId}/files?fileGroup=${fileGroup}`,
  );

export const getPetitionDossierFiles = (convocationId, petitionId, fileGroup) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/petitions/${petitionId}/dossierFiles?fileGroup=${fileGroup}`,
  );

export const getPetitionProcedureFiles = (
  convocationId,
  petitionId,
  procedureId,
  requestType,
) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/petitions/${petitionId}/files?requestType=${requestType}&petitionProcedureId=${procedureId}`,
  );

export const getComsumptionPeriod = (convocationId, petitionId) =>
  http.get(
    `beneficiary-api/bcj/v1/${convocationId}/petitions/${petitionId}/consumptionPeriod`,
  );

export const getSsoPetitions = async (convocationId) => {
  try {
    const token = localStorage.getItem(constants.CURRENT_CLAVE_TOKEN_KEY);
    return await http.post(`/beneficiary-api/v1/public/sso/${convocationId}/petitions`, {
      token,
    }); 
  } catch (e) {
    if (e?.statusCode === 403) {
      localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_KEY);
      localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY);
      window.location.assign('/');
    }
    throw e;
  }
}

export const loginToPetition = async (convocationId, petitionId) => {
  try {
    const token = localStorage.getItem(constants.CURRENT_CLAVE_TOKEN_KEY);
    const response = await http.post(`/beneficiary-api/v1/public/sso/${convocationId}/petitions/${petitionId}`, {
      token,
    });
    const userToken = response.token;
    localStorage.setItem(constants.CURRENT_TOKEN_KEY, userToken);
  } catch (e) {
    if (e?.statusCode === 403) {
      localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_KEY);
      localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY);
      window.location.assign('/');
    }
    throw e;
  }
}

export const createSsoPetition = async (convocationId) => {
  try {
    const token = localStorage.getItem(constants.CURRENT_CLAVE_TOKEN_KEY);
    const response = await http.post(`/beneficiary-api/v1/public/sso/${convocationId}/petitions/create`, {
      token,
    });
    const userToken = response.token;
    localStorage.setItem(constants.CURRENT_TOKEN_KEY, userToken);
    return response.petition;
  } catch (e) {
    if (e?.statusCode === 403) {
      localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_KEY);
      localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY);
      window.location.assign('/');
    }
    throw e;
  }
}

export const petitionSignup = ({petitionId, convocationId, email, password}) => http.post(`beneficiary-api/bcj/v1/${convocationId}/petitions/${petitionId}/signup`, {
  email,
  password,
});