import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Loading } from "../../components";
import { getClaveSsoStatus } from "../../services/claveService";
import { useParametrics } from "../../contexts/ParametricsContext";
import { useErrorsContext } from "../../contexts/ErrorsContext";
import { DateUtils, constants } from "../../utils";
import { useAuthContext } from "../../contexts/AuthContext";

const ClaveSsoCallback = () => {

  const history = useHistory();
  const location = useLocation();
  const { showError } = useErrorsContext(); 
  const { convocationId } = useParametrics();
  const { setIsSso } = useAuthContext();

  useEffect(() => {
    const claveDataStr = localStorage.getItem('claveData');
    localStorage.removeItem('claveData');
    if(!claveDataStr) return history.replace('/');
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const exp = searchParams.get('exp');
    searchParams.delete('token');
    searchParams.delete('exp');
    const claveData = JSON.parse(claveDataStr);
    claveCallback(claveData.relayState, token, exp);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, []);

  const claveCallback = async (relayState, token, exp) => {
    try {
      const response = await getClaveSsoStatus(convocationId, relayState);
      if(response.statusCode === 'VERIFIED') {
        localStorage.setItem(constants.CURRENT_CLAVE_TOKEN_KEY, token)
        const expirationDate = DateUtils.addHoursToDate(new Date(), parseInt(exp));
        localStorage.setItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY, expirationDate.toISOString());
        localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
        setIsSso(true);
        history.replace({
          pathname: '/petitions-sso',
        });
      }
      else {
        throw {error: response.statusMessage};
      }
    } catch (e) {
      showError(e);
    }
  }

  return (
    <div>
      <div className='vh-100 d-flex justify-content-center align-items-center'>
        <Loading />
      </div>
    </div>
  );
}

export default ClaveSsoCallback;