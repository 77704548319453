import './Button.scss';

const Button = ({ action, disabled, text, className, type, icon, name, component, styles, badge }) => (
  <button
    type={type ?? 'button'}
    disabled={disabled}
    onClick={action}
    className={`Button ${className}`}
    name={name}
    style={styles}
  >
    {icon && <img alt="img" className="Button menu_icon" src={icon} />}
    {text && <span className="Button_text">{text}</span>}
    {badge && <span className='Button_menu_badge'>{badge}</span>}
    {component}
  </button>
);

export default Button;
