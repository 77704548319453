import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShopsContext } from '../../contexts/ShopsContext';
import './Search.scss';

const Search = ({
  onClick,
  backgroundColor = null,
  autofocus = false
}) => {

  const { t } = useTranslation();
  const { searchTerm, fetchClusters } = useShopsContext();
  const [value, setValue] = useState(searchTerm);

  useEffect(() => {
    setValue(searchTerm || '');
  }, [searchTerm])

  return (
    <div className="Search">
      <div className={`Search__content ${backgroundColor ?? ''}`} onClick={onClick}>
        <input
          type="text" placeholder={t('Shops.searchShop')} autoFocus={autofocus}
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') fetchClusters({
              search: value,
              zoom: 6,
            });
          }}
        />
        <div className="icon" onClick={() => {
          fetchClusters({
            search: value,
            zoom: 6
          });
        }}>
          <img src="/misc/icons/search.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Search;