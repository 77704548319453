import http from "./base-http-service";

export const getCorrection = (petitionId, procedureId) =>
  http.get(
    `/beneficiary-api/v1/${petitionId}/procedures/correction/${procedureId}`,
  );

export const getCorrections = (petitionId) =>
  http.get(`/beneficiary-api/v1/${petitionId}/procedures/CORRECTION`);

export const getCorrectionProcedure = (petitionId, procedureId) =>
  http.get(
    `/beneficiary-api/v1/${petitionId}/procedures/correction/${procedureId}`,
  );

export const saveCorrection = (petitionId, procedureId, observations) =>
  http.patch(
    `/beneficiary-api/v1/${petitionId}/procedures/correction/${procedureId}/dataCompleted`,
    {
      observations,
    },
  );

export const getClaim = (petitionId, procedureId) =>
  http.get(`/beneficiary-api/v1/${petitionId}/procedures/claim/${procedureId}`);

export const getClaims = (petitionId) =>
  http.get(`/beneficiary-api/v1/${petitionId}/procedures/CLAIM`);

export const getClaimProcedure = (petitionId, procedureId) =>
  http.get(`/beneficiary-api/v1/${petitionId}/procedures/claim/${procedureId}`);

export const saveClaim = (petitionId, procedureId, observations) =>
  http.patch(
    `/beneficiary-api/v1/${petitionId}/procedures/claim/${procedureId}/dataCompleted`,
    {
      observations,
    },
  );

export const getAdditionalDocsProcedure = (petitionId, procedureId) =>
  http.get(
    `/beneficiary-api/v1/${petitionId}/procedures/additionalDocumentation/${procedureId}`,
  );

export const getAdditionalDocsProcedures = (petitionId) =>
  http.get(
    `/beneficiary-api/v1/${petitionId}/procedures/ADDITIONAL_DOCUMENTATION`,
  );

export const createAdditionalDocsProcedure = (petitionId) =>
  http.post(
    `/beneficiary-api/v1/${petitionId}/procedures/additionalDocumentation`,
  );

export const saveAdditionalDocsProcedure = (
  petitionId,
  procedureId,
  observations,
) =>
  http.patch(
    `/beneficiary-api/v1/${petitionId}/procedures/additionalDocumentation/${procedureId}/dataCompleted`,
    {
      observations,
    },
  );

export const getRectificationsProcedure = (petitionId, procedureId) =>
  http.get(
    `/beneficiary-api/v1/${petitionId}/procedures/dataRectification/${procedureId}`,
  );

export const getRectificationsProcedures = (petitionId) =>
  http.get(`/beneficiary-api/v1/${petitionId}/procedures/DATA_RECTIFICATION`);

export const createRectificationsProcedure = (petitionId) =>
  http.post(`/beneficiary-api/v1/${petitionId}/procedures/dataRectification`);

export const saveRectificationsProcedure = (petitionId, procedureId, payload) =>
  http.patch(
    `/beneficiary-api/v1/${petitionId}/procedures/dataRectification/${procedureId}/dataCompleted`,
    payload,
  );

export const getChangeEmailProcedure = (petitionId, procedureId) =>
  http.get(
    `/beneficiary-api/v1/${petitionId}/procedures/changeEmail/${procedureId}`,
  );

export const getChangeEmailProcedures = (petitionId) =>
  http.get(
    `/beneficiary-api/v1/${petitionId}/procedures/CHANGE_EMAIL`,
  );

export const createChangeEmailProcedure = (petitionId) =>
  http.post(`/beneficiary-api/v1/${petitionId}/procedures/changeEmail`);

export const saveChangeEmailProcedure = (petitionId, procedureId, payload) =>
  http.patch(
    `/beneficiary-api/v1/${petitionId}/procedures/changeEmail/${procedureId}/dataCompleted`,
    payload,
  );

export const changeEmailSendAuthCode = (petitionId, procedureId, payload) =>
  http.patch(`/beneficiary-api/v1/${petitionId}/procedures/changeEmail/${procedureId}/authCodeCompleted`, payload);

export const changeEmailResendCode = (petitionId, procedureId) =>
  http.put(`/beneficiary-api/v1/${petitionId}/procedures/changeEmail/${procedureId}/resendCode`);

export const generateProcedureReport = (
  petitionId,
  procedureId,
  signedType,
  preview,
) =>
  http.post(
    `/beneficiary-api/v1/${petitionId}/procedures/${procedureId}/generateRequestReport`,
    {
      signedType,
      preview,
    },
  );

export const checkProcedureIdentity = (petitionId, procedureId) =>
  http.get(
    `beneficiary-api/v1/${petitionId}/procedures/${procedureId}/checkIdentity`,
  );

export const getRegisterData = (petitionId, procedureId) =>
  http.get(
    `beneficiary-api/v1/${petitionId}/procedures/${procedureId}/getRegisterData`,
  );
