import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Loading, Button } from '../../../components';
import { DisplayUtils } from '../../../utils';
import './RequestInfo.scss';
import { useAuthContext } from '../../../contexts/AuthContext';

const RequestInfo = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { isSso } = useAuthContext();
    const [show, setShow] = useState(false);
    const [loading] = useState(false);

    const userType = location?.state?.userType;
    const petitionId = location?.state?.petitionId;
    const petitionHasAccount = location?.state?.petitionHasAccount;

    useEffect(() => {
        if(!location?.state?.requestInfo) {
            history.goBack()
        } else {
            setShow(true);
        }
      }, []) //eslint-disable-line

    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
                <div className='RequestInfo'>
                    <div className="w-100 d-flex justify-content-center">
                        {!DisplayUtils.isMobile() ? (
                            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="RequestInfo__logo" />
                        ) : (
                            <div className="RequestInfo__title">
                                {/* TODO: volver */}
                                <Button
                                    icon="/misc/icons/arrow-left-blue.svg"
                                    action={() => {
                                      if (isSso) history.push(`/petitions-sso`)
                                      else history.push(`/petitions`)
                                    }}
                                    className="Button__transparent"
                                />
                                <h6>{t('Join.requestInfo.head')}</h6>
                            </div>
                        )}
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="RequestInfo__container">
                            {!DisplayUtils.isMobile() && (
                                <div className="RequestInfo__container__head">
                                    {/* TODO: volver */}
                                    <Button
                                        icon="/misc/icons/arrow-left-pink.svg"
                                        action={() => {
                                          if (isSso) history.push(`/petitions-sso`)
                                          else history.push(`/petitions`)
                                        }}
                                        className="Button__transparent"
                                        styles={{width: '50px'}}
                                    />
                                    <h6>{t('Join.requestInfo.head')}</h6>
                                </div>
                            )}
                            <p className="RequestInfo__container__description" >
                            {t('Join.requestInfo.description1')}
                            </p>
                            <p className="RequestInfo__container__description" >
                            {t('Join.requestInfo.description11')}
                            </p>
                            <div className="RequestInfo__container__card">
                                {DisplayUtils.isMobile() && <span className="RequestInfo__container__card__title">{t('Join.requestInfo.need')}</span>}
                                <div className="RequestInfo__container__card__content">
                                    <img src='/misc/icons/steps-pink.png' alt='step'/>
                                    <div className="RequestInfo__container__card__content__info">
                                        <span>{t('Join.requestInfo.req1')}</span>
                                        <span>{t('Join.requestInfo.req2')}</span>
                                    </div>
                                </div>
                            </div>
                            <p className="RequestInfo__container__description" >
                            {t('Join.requestInfo.description12')}
                            </p>
                            <div className="RequestInfo__container__card">
                                {DisplayUtils.isMobile() && <span className="RequestInfo__container__card__title">{t('Join.requestInfo.need')}</span>}
                                <div className="RequestInfo__container__card__content">
                                    <img className='RequestInfo__container__card__content__single-image' src='/misc/icons/steps-pink-single.png' alt='step'/>
                                    <div className="RequestInfo__container__card__content__info">
                                        <span> {t('Join.requestInfo.req3')} </span>
                                    </div>
                                </div>
                            </div>
                            <p style={{fontWeight: 'bold'}} className="RequestInfo__container__description" dangerouslySetInnerHTML={{ __html: t('Join.requestInfo.parents') }} />
                            <p className="RequestInfo__container__description" dangerouslySetInnerHTML={{ __html: t('Join.requestInfo.description2') }} />
                            <Link to={{ pathname: "https://firmaelectronica.gob.es/Home/Descargas.html" }} target="_blank" className="RequestInfo__container__logo">
                                <img src='/misc/img/logo-autofirma.png' alt='autofirma'/>
                            </Link>
                            <p className="RequestInfo__container__description" dangerouslySetInnerHTML={{ __html: t('Join.requestInfo.description4') }} />
                            <p className="RequestInfo__container__description" dangerouslySetInnerHTML={{ __html: t('Join.requestInfo.description5') }} />
                            <p className="RequestInfo__container__description" dangerouslySetInnerHTML={{ __html: t('Join.requestInfo.description3') }} />
                            <div className="RequestInfo__container__actions">
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('Join.requestInfo.gotIt')}
                                    styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                    action={() => history.push({ pathname: '/join/select-user-type', state: { selectUserType: true, petitionId, userType, petitionHasAccount } })}
                                />
                            </div>
                            <span className="RequestInfo__container__later" onClick={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}>
                                {t('Join.requestInfo.later')}
                            </span>
                        </div>
                    </div>
                </div>
            )
        )
    )
}

export default RequestInfo;