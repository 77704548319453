import { useEffect, useState, forwardRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { isNIE, isNIF } from 'better-dni';
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
    TextField,
    ListItemText,
    MenuItem,
    makeStyles,
    FormControl
} from '@material-ui/core';
import { AddressForm, Button, Loading } from '../../../components';
import { DisplayUtils, DateUtils } from '../../../utils';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { getBeneficiaryData, saveBeneficiaryData } from '../../../services/petitionServices';
import { 
    getParametricsAddressType,
    getParametricsCountries,
    getParametricsProvinces,
    getParametricsGenders,
    getFromBirthdate,
    getToBirthdate
} from '../../../services/parametricsService';
import './BeneficiaryData.scss';

const BeneficiaryData = () => {
  
    const { t } = useTranslation();
    registerLocale("es", es);
    const location = useLocation();
    const history = useHistory();
    const userType = location?.state?.userType
    const petitionId = location?.state?.petitionId;
    const petitionHasAccount = location?.state?.petitionHasAccount;
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [genders, setGenders] = useState();
    const [addressTypes, setAddressTypes] = useState();
    const [countries, setCountries] = useState();
    const [provinces, setProvinces] = useState();
    const [writedDni, setWritedDni] = useState();
    const [asylumCheck, setAsylumCheck] = useState(false);
    const [protectionCheck, setProtectionCheck] = useState(false);
    const [residenceCheck, setResidenceCheck] = useState(false);
    const [fromBirthDate, setFromBirthDate] = useState();
    const [toBirthDate, setToBirthDate] = useState();

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    useEffect(() => {
        if(!location?.state?.beneficiaryData || !userType || !petitionId || petitionHasAccount === undefined) {
            history.goBack()
        } else {
            setShow(true)
        }
    }, []) //eslint-disable-line

    const onSubmit = async (data) => {
        let finalData = {
            beneficiary: {
                nif: data.dni,
                name: data.name,
                firstSurname: data.firstSurname,
                secondSurname: data.secondSurname === '' ? null : data.secondSurname,
                nationalityId: data.nationalityId,
                birthdate: DateUtils.getFormatedDateToSend(data.birthDate),
                gender: genders.find(gender => data.gender === gender.title).value,
                address: {
                    roadTypeId: data.addressTypeId,
                    roadNameAddress: data.address,
                    numberAddress: `${data.number}`,
                    blockAddress: data.bloq === '' ? null : data.bloq,
                    levelAddress: data.stair === '' ? null : data.stair,
                    floorAddress: data.floor === '' ? null : data.floor,
                    doorAddress: data.door === '' ? null : data.door,
                    countryId: data.countryId,
                    provinceId: data.provinceId === '' ? null : data.provinceId,
                    localityId: data.locationId === '' ? null : data.locationId,
                    postalCodeId: data.postalCodeId === '' ? null : data.postalCodeId,
                    locality: (!data.locationId || data.locationId === '') ? (data.location === '' ? null : data.location) : null,
                    postalCode: (!data.postalCodeId || data.postalCodeId === '') ? (data.postalCode === '' ? null : data.postalCode) : null
                }
            },
            asylumSeeker: isNIE(data.dni) ?  asylumCheck : false,
            temporaryProtection: isNIE(data.dni) ?  protectionCheck : false,
            formerWardForeign: isNIE(data.dni) ?  residenceCheck : false
        }
        try {
            setLoading(true);
            await saveBeneficiaryData(convocationId, userType.toUpperCase(), petitionId, finalData);
            history.push({ pathname: `/${userType}/join/card-selector`, state: { cardTypeSelector: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    const fetchData = async(data) => {
        try {
            setLoading(true);
            const gendersData = await getParametricsGenders();
            setGenders(gendersData);
            const addressTypes = await getParametricsAddressType();
            const addressType = addressTypes?.find(type => type.roadTypeId === data?.beneficiary?.address?.roadTypeId)
            setAddressTypes(addressTypes);
            const countries = await getParametricsCountries();
            const country = countries?.find(country => country.countryId === data?.beneficiary?.address?.countryId)
            const nationality = countries?.find(country => country.countryId === data?.beneficiary?.nationalityId)
            setCountries(countries);
            const provinces = await getParametricsProvinces();
            const province = provinces?.find(province => province.provinceId === data?.beneficiary?.address?.provinceId)
            setProvinces(provinces);
            userType === 'representative' && setWritedDni(data?.beneficiary?.nif);
            reset({
                name: userType === 'representative' ? (data?.beneficiary?.name || '') : (`${data?.beneficiary?.name || ''} ${data?.beneficiary?.firstSurname || ''} ${data?.beneficiary?.secondSurname || ''}`),
                dni: data?.beneficiary?.nif || '',
                firstSurname: data?.beneficiary?.firstSurname || '',
                secondSurname: data?.beneficiary?.secondSurname || '',
                birthDate: data?.beneficiary?.birthdate ? new Date(data?.beneficiary?.birthdate) : '' || '',
                addressType: addressType?.name || '',
                addressTypeId: data?.beneficiary?.address?.roadTypeId || '',
                address: data?.beneficiary?.address?.roadNameAddress || '',
                number: data?.beneficiary?.address?.numberAddress || '',
                bloq: data?.beneficiary?.address?.blockAddress || '',
                stair: data?.beneficiary?.address?.levelAddress || '',
                floor: data?.beneficiary?.address?.floorAddress || '',
                door: data?.beneficiary?.address?.doorAddress || '',
                location: data?.beneficiary?.address?.locality || '',
                locationId: data?.beneficiary?.address?.localityId || '',
                province: province?.name || '',
                provinceId: data?.beneficiary?.address?.provinceId || '',
                postalCode: data?.beneficiary?.address?.postalCode || '',
                postalCodeId: data?.beneficiary?.address?.postalCodeId || '',
                country: country?.name || '',
                countryId: data?.beneficiary?.address?.countryId || '',
                gender: data?.beneficiary?.gender ? gendersData.find(gender => gender.value === data?.beneficiary?.gender).title : '',
                nationality: nationality?.name || '',
                nationalityId: data?.beneficiary?.nationalityId || ''
            })
            setAsylumCheck(data?.asylumSeeker || false)
            setProtectionCheck(data?.temporaryProtection || false)
            setResidenceCheck(data?.formerWardForeign || false)
        } catch (e) {
          console.log(e);
            showError(e);
        } finally {
            setLoading(false);
        }
    }

    const getBeneData = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getBeneficiaryData(convocationId, userType.toUpperCase(), petitionId);
            if(data) {
                const fromDate = await getFromBirthdate(convocationId);
                setFromBirthDate(fromDate?.value);
                const toDate = await getToBirthdate(convocationId);
                setToBirthDate(toDate?.value);
                await fetchData(data);
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [showError, convocationId]); // eslint-disable-line

    useEffect(() => {
        convocationId && getBeneData();
    }, [convocationId]) //eslint-disable-line

    const useNationalityStyles = makeStyles({
        formControl: () => ({
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 55,
          borderRadius: 10,
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          '& .MuiSelect-select:focus': {
            backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: 55,
              borderRadius: 10,
              color: '#381940',
              borderColor: (errors?.nationality?.message || errors?.nationality?.type === 'validate') ? 'red !important' : 'transparent !important',
            },
          },
        }),
        root: {
          color: '#381940',
          marginTop: (errors?.nationality?.message || errors?.nationality?.type === 'validate') ? 5 : 0,
          borderRadius: 10,
        },
        empty: {
          color: '#b7b7b7 !important',
          paddingTop: (errors?.nationality?.message || errors?.nationality?.type === 'validate') ? 13.5 : 18.5,
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        filled: {
          color: '#381940 !important',
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        iconStyle: {
          display: 'inline-block',
          color: '#c0c0c0',
          width: '24px',
          pointerEvents: 'none',
        },
        textField: {
          width: '100%',
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          borderRadius: 10,
        },
        cssFocused: {
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          color: '#381940',
          borderRadius: 10,
        },
    });

    const useGenderStyles = makeStyles({
        formControl: () => ({
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 55,
          borderRadius: 10,
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          '& .MuiSelect-select:focus': {
            backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: 55,
              borderRadius: 10,
              color: '#381940',
              borderColor: (errors?.gender?.message || errors?.gender?.type === 'validate') ? 'red !important' : 'transparent !important',
            },
          },
        }),
        root: {
          color: '#381940',
          marginTop: (errors?.gender?.message || errors?.gender?.type === 'validate') ? 5 : 0,
          borderRadius: 10,
        },
        empty: {
          color: '#b7b7b7 !important',
          paddingTop: (errors?.gender?.message || errors?.gender?.type === 'validate') ? 13.5 : 18.5,
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        filled: {
          color: '#381940 !important',
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        iconStyle: {
          display: 'inline-block',
          color: '#c0c0c0',
          width: '24px',
          pointerEvents: 'none',
        },
        textField: {
          width: '100%',
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          borderRadius: 10,
        },
        cssFocused: {
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          color: '#381940',
          borderRadius: 10,
        },
    });

    const nationalityClasses = useNationalityStyles(); 
    const genderClasses = useGenderStyles(); 

    const CustomDateField = forwardRef(({ value, onClick }, ref) => (
        <div className={`react-custom-field ${errors.birthDate ? 'warning' : ''}`} onClick={onClick} ref={ref}>
            {value}
            <img src="/misc/icons/filters/calendar-pink.png" alt="icon" style={{ width: '20px' }} />
        </div>
    ));

    CustomDateField.displayName = 'CustomDateField';

    const handleDniChange = (e) => {
        setWritedDni(e.target.value);
        setValue('nationalityId', null);
        setValue('nationality', null);
    }

    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
                <div className='BeneficiaryData'>
                    <div className="w-100 d-flex justify-content-center">
                        {!DisplayUtils.isMobile() ? (
                            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="BeneficiaryData__logo" />
                        ) : (
                            <div className="BeneficiaryData__title">
                                <Button
                                    icon="/misc/icons/arrow-left-blue.svg"
                                    action={() => {
                                        if(userType === 'beneficiary') {
                                            history.push({ pathname: `/${userType}/join/automated-queries`, state: { automatedQueries: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                                        } else {
                                            history.push({ pathname: `/representative/join/representative-data`, state: { representativeData: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                                        }
                                    }}
                                    className="Button__transparent"
                                />
                                <h6>{t('Join.beneficiaryData.head')}</h6>
                            </div>
                        )}
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="BeneficiaryData__container">
                            {!DisplayUtils.isMobile() && (
                                <div className="BeneficiaryData__container__head">
                                    <Button
                                        icon="/misc/icons/arrow-left-pink.svg"
                                        action={() => {
                                            if(userType === 'beneficiary') {
                                                history.push({ pathname: `/${userType}/join/automated-queries`, state: { automatedQueries: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                                            } else {
                                                history.push({ pathname: `/representative/join/representative-data`, state: { representativeData: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                                            }
                                        }}
                                        className="Button__transparent"
                                        styles={{width: '50px'}}
                                    />
                                    <h6>{t('Join.beneficiaryData.head')}</h6>
                                </div>
                            )}
                            <div className="BeneficiaryData__container__form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h6 className="BeneficiaryData__container__form__title">{t('Join.beneficiaryData.dataTitle')}</h6>
                                    {userType === 'beneficiary' ? (
                                        <>
                                            <label>{t('Join.beneficiaryData.name')}<span style={{ color: '#f838f9' }}>*</span></label>
                                            <div className="BeneficiaryData__container__form__box">
                                                <p className="BeneficiaryData__container__form__disabled">{getValues('name')}</p>
                                                <img src="/misc/icons/user-pink.png" alt="icon" style={{ width: '20px' }} />
                                            </div>
                                        </>
                                    ) : (
                                        <div className='row m-0 w-100 mb-2'>
                                            <div className='col-12 p-0 mb-2'>
                                                <label>{t('Join.beneficiaryData.simpleName')}<span style={{ color: '#f838f9' }}>*</span></label>
                                                <div className="BeneficiaryData__container__form__box">
                                                    <input
                                                        type="text"
                                                        placeholder={t('Join.beneficiaryData.simpleName')}
                                                        className={`${errors?.name ? 'warning' : ''}`}
                                                        {...register('name', { required: t('Globals.required') })}
                                                    />
                                                    <img src="/misc/icons/user-pink.png" alt="icon" style={{ width: '20px' }} />
                                                </div>
                                                {errors.name && (
                                                    <p className="BeneficiaryData__container__form__error">{errors.name.message}</p>
                                                )}
                                            </div>
                                            <div className={`${DisplayUtils.isMobile() ? '' : ''} p-0 mb-2`}>
                                                <label>{t('Join.beneficiaryData.firstSurname')}<span style={{ color: '#f838f9' }}>*</span></label>
                                                <div className="BeneficiaryData__container__form__box">
                                                    <input
                                                        type="text"
                                                        placeholder={t('Join.beneficiaryData.firstSurname')}
                                                        className={`${errors?.firstSurname ? 'warning' : ''}  noPadding`}
                                                        {...register('firstSurname', { required: t('Globals.required') })}
                                                    />
                                                </div>
                                                {errors.firstSurname && (
                                                    <p className="BeneficiaryData__container__form__error">{errors.firstSurname.message}</p>
                                                )}
                                            </div>
                                            <div className={`${DisplayUtils.isMobile() ? '' : ''} p-0 mb-2`}>
                                                <label>{t('Join.beneficiaryData.secondSurname')}</label>
                                                <div className="BeneficiaryData__container__form__box">
                                                    <input
                                                        type="text"
                                                        placeholder={t('Join.beneficiaryData.secondSurname')}
                                                        className={`${errors?.secondSurname ? 'warning' : ''}  noPadding`}
                                                        {...register('secondSurname', { })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='row m-0 w-100 mb-2'>
                                        <div className={`${DisplayUtils.isMobile() ? '' : (isNIE(userType === 'beneficiary' ? getValues('dni') : writedDni) ? 'col-6 pe-2' : '')} p-0 mb-2`}>
                                            <label>{t('Join.beneficiaryData.dni')}<span style={{ color: '#f838f9' }}>*</span></label>
                                            <div className="BeneficiaryData__container__form__box">
                                                {userType === 'beneficiary' ? (
                                                    <p className="BeneficiaryData__container__form__disabled">{getValues('dni')}</p>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        placeholder={t('Join.beneficiaryData.dni')}
                                                        className={`${errors?.dni ? 'warning' : ''}`}
                                                        {...register('dni', { required: t('Globals.required'), validate: value => isNIE(value) || isNIF(value) })}
                                                        onChange={handleDniChange}
                                                    />
                                                )}
                                                <img src="/misc/icons/card-pink.png" alt="icon" style={{ width: '20px' }} />
                                            </div>
                                            {errors.dni && errors.dni.type === 'validate' && (
                                                <p className="BeneficiaryData__container__form__error">{t("Errors.invalidNifOrNie")}</p>
                                            )}
                                            {errors.dni && (
                                                <p className="BeneficiaryData__container__form__error">{errors.dni.message}</p>
                                            )}
                                        </div>
                                        {isNIE(userType === 'beneficiary' ? getValues('dni') : writedDni) && (
                                            <div className={`${DisplayUtils.isMobile() ? '' : 'col-6 ps-2'} p-0 mb-2`}>
                                                <label>{t('Join.beneficiaryData.nationality')}<span style={{ color: '#f838f9' }}>*</span></label>
                                                <div className="BeneficiaryData__container__form__box">
                                                    <FormControl className={nationalityClasses.formControl}>
                                                        <Controller
                                                            control={control}
                                                            name="nationality"
                                                            rules={{ required: t('Errors.requiredField') }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <TextField
                                                                        select
                                                                        classes={{ root: nationalityClasses.root }}
                                                                        inputRef={field.ref}
                                                                        SelectProps={{
                                                                            IconComponent: () => (
                                                                                <img src="/misc/icons/arrow-down.png" className={nationalityClasses.iconStyle} alt="icon"/>
                                                                            ),
                                                                            multiple: false,
                                                                            displayEmpty: true,
                                                                            value: field.value,
                                                                            defaultValue: '',
                                                                            classes: { select: field.value ? nationalityClasses.filled : nationalityClasses.empty },
                                                                            renderValue: () => field.value ? field.value : t('Join.beneficiaryData.nationality'),
                                                                            onChange: (e) => {
                                                                                field.onChange(e);
                                                                                const nationality = countries.find(nationality => nationality.countryId === e.target.value)
                                                                                setValue('nationalityId',nationality.countryId)
                                                                                setValue('nationality', nationality.name)
                                                                            }
                                                                        }}
                                                                        error={errors?.nationality?.message}
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        InputProps={{ classes: { focused: nationalityClasses.cssFocused } }}
                                                                    >
                                                                        {countries?.map((item, index) => (
                                                                            <MenuItem value={item.countryId} key={index}>
                                                                                <ListItemText primary={item.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                                {errors.nationality && (
                                                    <p className="AddressForm__error">{errors.nationality.message}</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className='row m-0 w-100 mb-2'>
                                        <div className={`${DisplayUtils.isMobile() ? '' : 'col-6 pe-2'} p-0 mb-2`}>
                                            <label>{t('Join.beneficiaryData.birthDate')}<span style={{ color: '#f838f9' }}>*</span></label>
                                            <div className="BeneficiaryData__container__form__box">
                                                <Controller
                                                    control={control}
                                                    name="birthDate"
                                                    rules={{ required: t('Globals.required') }}
                                                    defaultValue={''}
                                                    render={({ field }) => {
                                                        return (
                                                            <DatePicker
                                                                locale="es"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={field.value}
                                                                onChange={field.onChange}
                                                                minDate={new Date(fromBirthDate)}
                                                                maxDate={new Date(toBirthDate)}
                                                                customInput={<CustomDateField />}/>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            {errors?.birthDate && (
                                                <p className="BeneficiaryData__container__form__error">{errors?.birthDate?.message}</p>
                                            )}
                                        </div>
                                        <div className={`${DisplayUtils.isMobile() ? '' : 'col-6 ps-2'} p-0 mb-2`}>
                                            <label>{t('Join.beneficiaryData.gender')}<span style={{ color: '#f838f9' }}>*</span></label>
                                            <FormControl className={genderClasses.formControl}>
                                                <Controller
                                                    control={control}
                                                    name="gender"
                                                    rules={{ required: t('Errors.requiredField') }}
                                                    render={({ field }) => {
                                                        return (
                                                            <TextField
                                                                select
                                                                classes={{ root: genderClasses.root }}
                                                                inputRef={field.ref}
                                                                SelectProps={{
                                                                    IconComponent: () => (
                                                                        <img src="/misc/icons/arrow-down.png" className={genderClasses.iconStyle} alt="icon"/>
                                                                    ),
                                                                    multiple: false,
                                                                    displayEmpty: true,
                                                                    value: field.value,
                                                                    defaultValue: '',
                                                                    classes: { select: field.value ? genderClasses.filled : genderClasses.empty },
                                                                    renderValue: () => field.value ? field.value : t('Join.beneficiaryData.gender'),
                                                                    onChange: field.onChange
                                                                }}
                                                                error={errors?.gender?.message}
                                                                variant="outlined"
                                                                margin="normal"
                                                                InputProps={{ classes: { focused: genderClasses.cssFocused } }}
                                                            >
                                                                {genders?.map((item, index) => (
                                                                    <MenuItem value={item.title} key={index}>
                                                                        <ListItemText primary={item.title} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                            {errors.gender && (
                                                <p className="BeneficiaryData__container__form__error">{errors.gender.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    {isNIE(userType === 'beneficiary' ? getValues('dni') : writedDni) && (
                                        <>
                                            <h6 className="BeneficiaryData__container__form__title mt-5">{t('Join.beneficiaryData.foreignTitle')}</h6>
                                            <div className='row m-0 w-100 mb-2'>
                                                <div className={`${DisplayUtils.isMobile() ? '' : 'col-6 pe-2'} p-0 mb-2`}>
                                                    <label>{t('Join.beneficiaryData.asylumCheck')}<span style={{ color: '#f838f9' }}>*</span></label>
                                                    <div className='BeneficiaryData__container__form__checks'>
                                                        <div className='d-flex' 
                                                            onClick={() => { 
                                                                setAsylumCheck(true);
                                                                setProtectionCheck(false);
                                                                setResidenceCheck(false);
                                                            }}>
                                                            <div className="d-flex align-items-center">
                                                                <span>{t('Globals.yes')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={asylumCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                                                    alt="check"
                                                                    className="BeneficiaryData__container__form__checks__radio"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex ms-4' 
                                                            onClick={() => {
                                                                setAsylumCheck(false);
                                                            }}>
                                                            <div className="d-flex align-items-center">
                                                            <span>{t('Globals.no')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={!asylumCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                                                    alt="check"
                                                                    className="BeneficiaryData__container__form__checks__radio"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${DisplayUtils.isMobile() ? '' : 'col-6 ps-2'} p-0 mb-2`}>
                                                    <label>{t('Join.beneficiaryData.protectionCheck')}<span style={{ color: '#f838f9' }}>*</span></label>
                                                    <div className='BeneficiaryData__container__form__checks'>
                                                        <div className='d-flex' 
                                                            onClick={() => { 
                                                                setAsylumCheck(false);
                                                                setProtectionCheck(true);
                                                                setResidenceCheck(false);
                                                            }}>
                                                            <div className="d-flex align-items-center">
                                                                <span>{t('Globals.yes')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={protectionCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                                                    alt="check"
                                                                    className="BeneficiaryData__container__form__checks__radio"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex ms-4' 
                                                            onClick={() => {
                                                                setProtectionCheck(false);
                                                            }}>
                                                            <div className="d-flex align-items-center">
                                                            <span>{t('Globals.no')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={!protectionCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                                                    alt="check"
                                                                    className="BeneficiaryData__container__form__checks__radio"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row m-0 w-100 mb-2'>
                                                <div className='p-0 mb-2'>
                                                    <label>{t('Join.beneficiaryData.residenceCheck')}<span style={{ color: '#f838f9' }}>*</span></label>
                                                    <div className='BeneficiaryData__container__form__checks'>
                                                        <div className='d-flex' 
                                                            onClick={() => { 
                                                                setAsylumCheck(false);
                                                                setProtectionCheck(false);
                                                                setResidenceCheck(true);
                                                            }}>
                                                            <div className="d-flex align-items-center">
                                                                <span>{t('Globals.yes')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={residenceCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                                                    alt="check"
                                                                    className="BeneficiaryData__container__form__checks__radio"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex ms-4' 
                                                            onClick={() => {
                                                                setResidenceCheck(false);
                                                            }}>
                                                            <div className="d-flex align-items-center">
                                                            <span>{t('Globals.no')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={!residenceCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                                                    alt="check"
                                                                    className="BeneficiaryData__container__form__checks__radio"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className='mt-5'>
                                        <AddressForm 
                                            title={t('Join.beneficiaryData.addressTitle')}
                                            errors={errors} 
                                            register={register}
                                            control={control}
                                            setValue={setValue}
                                            getValues={getValues}
                                            clearErrors={clearErrors}
                                            addressTypes={addressTypes}
                                            countries={countries}
                                            provinces={provinces}/>
                                    </div>
                                    <p className='m-0 mt-5 mb-4' dangerouslySetInnerHTML={{ __html: t('Globals.noOptional') }} />
                                    <div className="ClaveVerification__container__actions">
                                        <Button
                                            className="buttonPrimaryLarge"
                                            text={t('Join.beneficiaryData.later')}
                                            styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                                            action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                                        />
                                        <Button
                                            className="buttonPrimaryLarge"
                                            text={t('Join.beneficiaryData.action')}
                                            styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                            type="input"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    );
};

export default BeneficiaryData;