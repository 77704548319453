import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { DisplayUtils, constants } from '../../../utils';
import { Button, Loading, SignModal } from '../../../components';
import './SignRequest.scss';
import { autofirma, checkIdentity, getPendingSign, noCrypto, getDocumentation } from '../../../services/petitionServices';
import useModal from '../../../hooks/useModal';
import { getClaveDataSignFileAdvanced, getClaveDataSignFileSimple, getClaveSignatureStatus } from '../../../services/claveService';
import { getFile, signFile } from '../../../services/fileServices';
import { base64FileToBlob, downloadFile } from '../../../utils/download-file';

const SignRequest = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const userType = location?.state?.claveData?.userType ?? location?.state?.userType
  const petitionId = location?.state?.claveData?.petitionId ?? location?.state?.petitionId;
  const petitionHasAccount = location?.state?.claveData?.petitionHasAccount ?? location?.state?.petitionHasAccount;
  const { showError } = useErrorsContext();
  const { convocationId, convocationOpen } = useParametrics();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docUrl, setDocUrl] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState();
  const [showPages, setShowPages] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [signedDocOk, setSignedDocOk] = useState();
  const [signedDocError, setSignedDocError] = useState();
  const [accepted, setAccepted] = useState(false);
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const [acceptedInfo, setAcceptedInfo] = useState(false);
  const [acceptedDeclare, setAcceptedDeclare] = useState(false);
  const { isShowingModal, toggleModal } = useModal();
  const [showClaveOption, setShowClaveOption] = useState(false);
  const [showClaveAdvancedOption, setShowClaveAdvancedOption] = useState(false);
  const [showFirmaOption, setShowFirmaOption] = useState(false);
  const [userDocs, setUserDocs] = useState([]);

  const getDocUrl = async () => {
    try {
      setLoading(true);
      const resp = await getPendingSign(convocationId, userType.toUpperCase(), petitionId);
      setDocUrl(`data:application/pdf;base64,${resp.file}`);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  const getDocuments = async () => {
    try {
      const resp = await getDocumentation(convocationId, userType.toUpperCase(), petitionId);
      setUserDocs(resp.documentList)
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  const init = () => {
    getDocUrl();
    getDocuments();
  }

  useEffect(() => {
    console.log(location?.state?.noDocs);
    if (!convocationId) return;
    if (location?.state?.claveData) {
      setShow(true);
      setLoading(true);
      claveCallback(location.state.claveData);
      return;
    }
    if (!location?.state?.signRequest || !userType || !petitionId || petitionHasAccount === undefined) {
      history.goBack()
    } else {
      init();
      setShow(true);
    }
  }, [convocationId])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setShowPages(true);
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  const callClave = async () => {
    try {
      setLoading(true);
      const resp = await noCrypto(convocationId, userType.toUpperCase(), petitionId);
      const fileId = resp.fileId;
      let response;
      if (showClaveAdvancedOption) {
        response = await getClaveDataSignFileAdvanced(convocationId, userType.toUpperCase(), 'BENEFICIARY_REQUEST', {
          petitionId: petitionId,
          requestFile: fileId,
        });
      }
      else {
        response = await getClaveDataSignFileSimple(convocationId, userType.toUpperCase(), 'BENEFICIARY_REQUEST', {
          petitionId: petitionId,
          requestFile: fileId,
        });
      }
      localStorage.setItem('claveData', JSON.stringify({
        relayState: response.relayState,
        userType: userType,
        petitionId: petitionId,
        petitionHasAccount: petitionHasAccount,
        returnPage: `/${userType}/join/sign-request`,
      }));
      const child = window.open("", "_self");
      const html = constants.getClaveHtml(response.redirectForm, response.samlRequest, response.relayState)
      child.document.write(html);
      child.document.close();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  const claveCallback = async (claveData) => {
    try {
      setLoading(true);
      const response = await getClaveSignatureStatus(convocationId, claveData.relayState);
      if (response.statusCode === 'VERIFIED') {
        setLoading(false);
        history.push({
          pathname: `/${claveData.userType}/join/request-success`,
          state: {
            requestSuccess: true,
            userType: claveData.userType,
            petitionId: claveData.petitionId,
            petitionHasAccount: claveData.petitionHasAccount,
          }
        })
      } else {
        throw { error: response.statusMessage };
      }
    } catch (e) {
      setLoading(false);
      showError(e);
      init();
    }
  }

  const startAutoFirma = async () => {
    try {
      setLoading(true);
      const resp = await autofirma(convocationId, userType.toUpperCase(), petitionId);
      const url = `data:application/pdf;base64,${resp.file}`
      try {
        window.downloadRemoteData(url);
      } catch (e) {
        showError('No se pudo contactar con AutoFirma')
        setLoading(false);
      }
    } catch (error) {
      showError(error)
    }
  }

  const uploadAutofirma = async (signedDocOk) => {
    setLoading(true);
    try {
      const response = await fetch(signedDocOk)
      const blob = await response.blob();
      const file = new File([blob], "signed.pdf", { type: "application/pdf" })
      const formData = new FormData();
      formData.append('file', file);
      const { signFileId } = await signFile(convocationId, userType.toUpperCase(), petitionId, 'BONUS_REQUEST', formData);
      const resp = await getFile(signFileId);
      const url = URL.createObjectURL(
        base64FileToBlob(resp.file, "application/pdf"),
      );
      downloadFile(url, resp.name);
      history.push({ pathname: `/${userType}/join/request-success`, state: { requestSuccess: true, userType: userType, petitionId: petitionId, petitionHasAccount, } })
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }

  const onContinue = async () => {
    try {
      setLoading(true);
      if (!convocationOpen) {
        return showError({ error: t('Join.signRequest.closed') });
      }
      const resp = await checkIdentity(convocationId, userType.toUpperCase(), petitionId);
      if (resp.eligible !== true)
        showError({ error: resp.statusMessage });
      else {
        setShowClaveOption(resp.nonCryptographicSignature);
        setShowClaveAdvancedOption(resp.nonCryptographicSignatureAdvanced)
        setShowFirmaOption(resp.autosignature);
        toggleModal();
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  window.signError = function (error) {
    console.log(error);
    setLoading(false);
    setSignedDocError(error);
  }

  window.signedOk = function (data) {
    console.log(data);
    setLoading(false);
    setSignedDocOk(data);
  }

  if (signedDocError) {
    showError(signedDocError)
    setSignedDocError()
  }

  if (signedDocOk) {
    uploadAutofirma(signedDocOk)
    setSignedDocOk()
  }

  return (
    show && (
      loading ? (
        <div className='vh-100 d-flex justify-content-center align-items-center'>
          <Loading />
        </div>
      ) : (
        <>
          <SignModal
            showingModal={isShowingModal}
            claveAdvanced={showClaveAdvancedOption}
            hideModal={() => {
              toggleModal();
            }}
            claveSelect={showClaveOption || showClaveAdvancedOption ? () => {
              callClave();
              toggleModal();
            } : null}
            firmaSelect={showFirmaOption ? () => {
              startAutoFirma();
              toggleModal();
            } : null}
          />
          <div className='SignRequest'>
            <div className="w-100 d-flex justify-content-center">
              {!DisplayUtils.isMobile() ? (
                <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="SignRequest__logo" />
              ) : (
                <div className="SignRequest__title">
                  <Button
                    icon="/misc/icons/arrow-left-blue.svg"
                    action={() => {
                      if (userDocs && userDocs.length > 0) {
                        history.push({ pathname: `/${userType}/join/upload-documentation`, state: { uploadDocumentation: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                      } else {
                        history.push({ pathname: `/${userType}/join/notifications-address`, state: { notificationsAddressInfo: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                      }
                    }}
                    className="Button__transparent"
                  />
                  <h6>{t('Join.signRequest.head')}</h6>
                </div>
              )}
            </div>
            <div className="w-100 d-flex justify-content-center">
              <div className="SignRequest__container">
                {!DisplayUtils.isMobile() && (
                  <div className="SignRequest__container__head">
                    <Button
                      icon="/misc/icons/arrow-left-pink.svg"
                      action={() => {
                        if (userDocs && userDocs.length > 0) {
                          history.push({ pathname: `/${userType}/join/upload-documentation`, state: { uploadDocumentation: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                        } else {
                          history.push({ pathname: `/${userType}/join/notifications-address`, state: { notificationsAddressInfo: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
                        }
                      }}
                      className="Button__transparent"
                      styles={{ width: '50px' }}
                    />
                    <h6>{t('Join.signRequest.head')}</h6>
                  </div>
                )}
                <div className="SignRequest__container__content">
                  {docUrl && (
                    <div className="SignRequest__container__content__preview">
                      <Document file={docUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                      </Document>
                      {showPages && (
                        <div className="SignRequest__container__content__preview__pages">
                          <Button
                            className="buttonPrimaryLarge"
                            text='<'
                            styles={{ backgroundColor: '#34fdfe', color: '#381940', height: '24px', width: '24px' }}
                            action={() => previousPage()}
                            disabled={pageNumber <= 1}
                          />
                          <p>{t('Globals.pages', { number: pageNumber || (numPages ? 1 : "--"), total: numPages || "--" })}</p>
                          <Button
                            className="buttonPrimaryLarge"
                            text='>'
                            styles={{ backgroundColor: '#34fdfe', color: '#381940', height: '24px', width: '24px' }}
                            action={() => nextPage()}
                            disabled={pageNumber >= numPages}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="SignRequest__container__content__accept">
                    <input type="checkbox" name="conditions" id="conditions" checked={acceptedConditions} onChange={() => setAcceptedConditions(!acceptedConditions)} />
                    <label htmlFor="conditions" dangerouslySetInnerHTML={{ __html: t('Join.signRequest.accept') }} />
                  </div>
                  <div className="SignRequest__container__content__accept">
                    <input type="checkbox" name="privacy" id="privacy" checked={accepted} onChange={() => setAccepted(!accepted)} />
                    <label htmlFor="privacy" dangerouslySetInnerHTML={{ __html: t('Join.signRequest.acceptPolicy') }} />
                  </div>
                  <div className="SignRequest__container__content__accept">
                    <input type="checkbox" name="info" id="info" checked={acceptedInfo} onChange={() => setAcceptedInfo(!acceptedInfo)} />
                    <label htmlFor="info" dangerouslySetInnerHTML={{ __html: t('Join.signRequest.acceptInfo') }} />
                  </div>
                  <div className="SignRequest__container__content__accept">
                    <input type="checkbox" name="declare" id="declare" checked={acceptedDeclare} onChange={() => setAcceptedDeclare(!acceptedDeclare)} />
                    <label htmlFor="declare" dangerouslySetInnerHTML={{ __html: t('Join.signRequest.acceptDeclare') }} />
                  </div>
                  <div className="SignRequest__container__actions">
                    <Button
                      className="buttonPrimaryLarge"
                      text={t('Join.signRequest.later')}
                      styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                      action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                    />
                    <Button
                      className="buttonPrimaryLarge"
                      text={t('Join.signRequest.sign')}
                      styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                      action={() => onContinue()}
                      disabled={!docUrl || !accepted || !acceptedConditions || !acceptedInfo || !acceptedDeclare}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    )
  )
}

export default SignRequest;