import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

const Modal = ({
  isShowingModal,
  component,
  styles
}) => {
  const [top, setTop] = useState(0);

  const modalRef = useRef();

  useEffect(() => {
    try {
      const modalHeight = modalRef.current.offsetHeight;
      const screenHeight = window.document.body.clientHeight;
      const top = Math.max(0, (screenHeight - modalHeight) / 2);
      setTop(top);
    } catch (e) { /* empty */ }
  }, [isShowingModal])

  return isShowingModal
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            style={{top: top}}
            className="modal-wrapper animated"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="Modal" style={styles} ref={modalRef}>
             {component}
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default Modal;
