import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';
import { DisplayUtils } from '../../../utils';
import { updateLanguage } from '../../../services/authServices';
import './Language.scss';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useLanguageContext } from '../../../contexts/LanguageContext';
import { useAuthContext } from '../../../contexts/AuthContext';

const Language = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { showError } = useErrorsContext();
  const { currentLanguage, changeLanguage } = useLanguageContext(); 
  const { user, isSso, setUserLanguage } = useAuthContext();
  const [language, setLanguage] = useState(currentLanguage);

  const Boxes = ({ icon, title, item }) => (
    <div className="Language__container__form__box">
      <div className="d-flex align-items-center">
        <img src={icon} alt="shop-icon" className="Profile__content__box__boxes__contain__icon" />
        <span style={{ marginLeft: '10px' }}>{title}</span>
      </div>
      <div className="d-flex align-items-center">
        <img
          src={language === item ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'}
          alt="shop-icon"
          className="Language__container__form__box__boxes__contain__icon"
          onClick={() => setLanguage(item)}
        />
      </div>
    </div>
  );

  const _changeLanguage = async () => {
    try{
      changeLanguage(language);
      if (user) {
        await updateLanguage({language: convertLanguageToApiEnum(language)})
        setUserLanguage(language);
      }
      if (user) {
        history.push('/profile')
      }
      else if (isSso) {
        history.push('/petitions-sso')
      }
      else {
        history.push('/')
      }
    } catch(error){
      showError(error)
    }
  }

  const convertLanguageToApiEnum = (lang) => {
    switch(lang) {
      case 'es': 
        return 'SPANISH';
      case 'ca':
        return 'CATALAN';
      case 'eu':
        return 'BASQUE';
      case 'ga':
        return 'GALICIAN';
      case 'va':
        return 'VALENCIAN';
      default:
        return 'SPANISH'
    }
  }

  return (
    <div className="Language">
      <div className="w-100 d-flex justify-content-center">
        {!DisplayUtils.isMobile() ? (
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="Language__logo" />
        ) : (
          <div className="Language__title">
            <Button
              icon="/misc/icons/arrow-left-blue.svg"
              action={() => history.goBack()}
              className="Button__transparent"
            />
            <h6>{t('Language.title')}</h6>
          </div>
        )}
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="Language__container">
          {!DisplayUtils.isMobile() && (
            <div className="Language__container__title">
              <Button
                icon="/misc/icons/arrow-left-pink.svg"
                action={() => history.goBack()}
                className="Button__transparent"
                styles={{ width: '50px' }}
              />
              <h6>{t('Language.title')}</h6>
            </div>
          )}
          <div className="Language__container__form">
            <Boxes
              icon="/misc/icons/es.png"
              item="es"
              title={t('Language.spanish')}
            />
            <Boxes icon="/misc/icons/ca.png" item="ca" title={t('Language.catalan')} />
            <Boxes icon="/misc/icons/valenciano.svg" item="va" title={t('Language.valenciano')} />
            <Boxes icon="/misc/icons/gallego.svg" item="ga" title={t('Language.gallego')} />
            <Boxes icon="/misc/icons/euskera.svg" item="eu" title={t('Language.euskera')} />
            <div className="d-flex justify-content-center">
              <Button
                className="buttonPrimaryLarge"
                text={t('Globals.apply')}
                action={_changeLanguage}
                styles={{ backgroundColor: '#34fdfe', color: '#381940', margin: '72px 0 23px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Language;
