import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Loading, Button, UploadDoc } from '../../../components';
import { DateUtils, DisplayUtils } from '../../../utils';
import './UploadDocumentation.scss';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { getDocumentation } from '../../../services/petitionServices';
import { updateFile, uploadFile } from '../../../services/fileServices';
import { saveDocumentation } from '../../../services/petitionServices';

const UploadDocumentation = () => {
    const { t } = useTranslation();
    const { convocationId } = useParametrics();
    const location = useLocation();
    const history = useHistory();
    const { showError } = useErrorsContext();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [requiredFiles, setRequiredFiles] = useState([]);

    const userType = location?.state?.userType;
    const petitionId = location?.state?.petitionId;
    const petitionHasAccount = location?.state?.petitionHasAccount;

    const getPetition = async () => {
      try {
        const resp = await getDocumentation(convocationId, userType.toUpperCase(), petitionId);
        if(!resp.documentList || resp.documentList.length <= 0) {
            uploadDocumentation()
        } else {
            setRequiredFiles(resp.documentList);
            setLoading(false);
        }
      } catch (e) {
        showError(e);
      }
    }

    useEffect(() => {
      if(!convocationId) return;
      if(!location?.state?.uploadDocumentation || !userType || !petitionId || petitionHasAccount === undefined) {
          history.goBack()
      } else {
        getPetition();
        setShow(true);
      }
    }, [convocationId])

    const uploadDocumentation = async () => {
      try {
        setLoading(true);
        for (const fileToUpload of filesToUpload) {
          const formData = new FormData();
          formData.append('file', fileToUpload.file);
          const requiredFile = requiredFiles.find(f => f.fileType === fileToUpload.fileType);
          let fileId = requiredFile.fileId;
          const fileType = fileToUpload.fileType;
          if (fileId) await updateFile({
            convocationId, petitionId, fileId, fileType, formData
          });
          else fileId = await uploadFile({
            convocationId, petitionId, fileType, formData
          });
          requiredFile.fileId = fileId;
        }
        setRequiredFiles([...requiredFiles]);
        await saveDocumentation(convocationId, userType.toUpperCase(), petitionId);
        history.push(`/${userType}/join/sign-request`, { signRequest: true, userType: userType, petitionId: petitionId, petitionHasAccount, })
      } catch (e) {
        console.log(e);
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
                <div className='UploadDocumentation'>
                    <div className="w-100 d-flex justify-content-center">
                        {!DisplayUtils.isMobile() ? (
                            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="UploadDocumentation__logo" />
                        ) : (
                            <div className="UploadDocumentation__title">
                                <Button
                                    icon="/misc/icons/arrow-left-blue.svg"
                                    action={() => history.push({ pathname: `/${userType}/join/notifications-address`, state: { notificationsAddressInfo: true, userType: userType, petitionId: petitionId, petitionHasAccount, } })}
                                    className="Button__transparent"
                                />
                                <h6>{t('Join.uploadDocumentation.head')}</h6>
                            </div>
                        )}
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="UploadDocumentation__container">
                            {!DisplayUtils.isMobile() && (
                                <div className="UploadDocumentation__container__head">
                                    <Button
                                        icon="/misc/icons/arrow-left-pink.svg"
                                        action={() => history.push({ pathname: `/${userType}/join/notifications-address`, state: { notificationsAddressInfo: true, userType: userType, petitionId: petitionId, petitionHasAccount, } })}
                                        className="Button__transparent"
                                        styles={{ width: '50px' }}
                                    />
                                    <h6>{t('Join.uploadDocumentation.head')}</h6>
                                </div>
                            )}
                            <p className="UploadDocumentation__container__description" >
                                {t('Join.uploadDocumentation.description')}
                            </p>
                            <p className="UploadDocumentation__container__description__info" >
                                {t('Join.uploadDocumentation.info')}
                            </p>



                            <div className="UploadDocumentation__container__content">
                                <div className="UploadDocumentation__container__content__items">
                                    {requiredFiles?.map((item) => (
                                        <UploadDoc
                                            key={item.fileType}
                                            fileId={item.fileId}
                                            title={item.title}
                                            info={item.fileId ? t('Join.documentation.uploadDate', { date: DateUtils.getFormatedDate(item.updatedTime), time: DateUtils.getFormatedTime(item.updatedTime)}) : null}
                                            type={item.fileType}
                                            setFilesToUpload={setFilesToUpload}
                                            filesToUpload={filesToUpload} />
                                    ))}
                                    {requiredFiles?.map((item, index) => {
                                        if(item.fileType === "REPRESENTATION_ACCREDITATION") {
                                            return (
                                                <div key={index} className='UploadDocumentation__container__content__template'>
                                                    <p dangerouslySetInnerHTML={{ __html: t('Join.documentation.template') }} />
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>


                            <div className="UploadDocumentation__container__actions">
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('Join.uploadDocumentation.later')}
                                    styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                                    action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                                />
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('Join.uploadDocumentation.action')}
                                    styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                    action={() => uploadDocumentation()}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            )
        )
    )

}

export default UploadDocumentation;