import { useState } from 'react';
import { Button, Loading, NavMenu } from '../../components';
import './Petitions.scss';
import { useEffect } from 'react';
import { useErrorsContext } from '../../contexts/ErrorsContext';
import { useTranslation } from 'react-i18next';
import { PetitionCard } from '../../components/petitionCard/PetitionCard';
import { useParametrics } from '../../contexts/ParametricsContext';
import { useHistory } from 'react-router-dom';
import { StatusUtils } from '../../utils';
import { getUserPetitions } from '../../services/petitionServices';

const Petitions = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const { showError } = useErrorsContext();
  const { convocationId, convocation } = useParametrics();

  const [loading, setLoading] = useState(true);
  const [petitions, setPetitions] = useState();
  const [showNoPetitions, setShowNoPetitions] = useState(false);
  const [showInProgressPetitions, setShowInProgressPetitons] = useState(false);
  const [showPetitions, setShowPetitions] = useState(false);

  const loadPetitions = async () => {
    try {
      setLoading(true);
      const resp = await getUserPetitions(
        convocationId
      );
      const petitions = resp;
      setPetitions(petitions);
      setShowNoPetitions(false);
      setShowInProgressPetitons(false);
      setShowPetitions(false);
      if(petitions.length === 0) setShowNoPetitions(true);
      else if(petitions.some((p) => StatusUtils.isInProgress(p?.processStatus?.status))) setShowInProgressPetitons(true);
      else setShowPetitions(true);
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!convocationId || !convocation) return;
    loadPetitions();
  }, [convocationId, convocation])

  const inProgressPetition = showInProgressPetitions ? 
    petitions.find((p) => StatusUtils.isInProgress(p?.processStatus?.status))
    : null

  const userType = inProgressPetition?.accountType === 'REPRESENTATIVE' ? 'representative' : 'beneficiary';

  return (
    <NavMenu>
      <div className="Petitions">
        <div className="content">
          {
            loading ? (
              <div className='vh-100 d-flex justify-content-center align-items-center'>
                  <Loading />
              </div>
            ) : (
              <>
                <div className="w-100 d-flex justify-content-center">
                  <div className="">
                    <h6>{t('Petitions.title')}</h6>
                  </div>
                </div>
                {
                  showPetitions && (
                    <div className="petitions">
                      {
                        petitions.map((petition) => (
                          <PetitionCard 
                            key={petition.petitionId} petition={petition}
                            convocationTitle={convocation?.title}
                          />
                        ))
                      }
                    </div>
                  )
                }
                {
                  showInProgressPetitions && (
                    <div className="in-progress-petitions">
                      <WarningCard 
                        title={t('Petitions.warning.inProgressTitle')}
                        message={t('Petitions.warning.inProgressMessage')}
                        buttonLabel={t('Petitions.warning.inProgressButton')}
                        action={() => {
                          if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.CHANGE_ACCOUNT_TYPE)
                            history.push(`/join/select-user-type`, { selectUserType: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.VERIFIED_IDENTITY)
                            history.push(`/${userType}/join/clave-verification`, { claveVerification: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.AUTOMATED_CONSULTATION_AUTHORIZATION)
                            history.push(`/${userType}/join/automated-queries`, { automatedQueries: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_BENEFICIARY)
                            history.push(`/${userType}/join/beneficiary-data`, { beneficiaryData: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_REPRESENTATIVE)
                            history.push(`/${userType}/join/representative-data`, { representativeData: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_CARDS)
                            history.push(`/${userType}/join/card-selector`, { cardTypeSelector: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_NOTIFICATION)
                            history.push(`/${userType}/join/notifications-address`, { notificationsAddressInfo: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DOCUMENTATION)
                            history.push(`/${userType}/join/upload-documentation`, { uploadDocumentation: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                          else if(inProgressPetition.processStatus.nextStatus === StatusUtils.STATUS.REGISTERED)
                            history.push(`/${userType}/join/sign-request`, { signRequest: true, userType: userType, petitionId: inProgressPetition.petitionId, petitionHasAccount: inProgressPetition.accountEmail !== null});
                        }}
                      />
                    </div>
                  )
                }
                {
                  showNoPetitions && (
                    <div className="no-petitions">
                      <WarningCard 
                        title={t('Petitions.warning.noPetitionsTitle')}
                        message={t('Petitions.warning.noPetitionsMessage')}
                        buttonLabel={t('Petitions.warning.noPetitionsButton')}
                        action={() => {
                          history.push('/join/request-info', { requestInfo: true });
                        }}
                      />
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      </div>
    </NavMenu>
  );
}

export default Petitions;

const WarningCard = ({
  title, message, buttonLabel, action, 
}) => {
  const { t } = useTranslation();
  const { convocationOpen } = useParametrics();

  return (
    <div className="warning-card">
      <h1 className='head'>{t('Petitions.warning.head')}</h1>
      <img src="/misc/icons/petitions-warning.png" alt="" />
      <p className='title'>{title}</p>
      <p className='message'>{message}</p>
      {
        convocationOpen ? (
          <Button 
            className="buttonPrimaryLarge"
            action={action}
            text={buttonLabel}
            styles={{
              backgroundColor: '#34fdfe',
              color: '#381940',
              marginTop: '30px'
            }}
          />
        ) : (
          <p className='convocation-closed'>{t('Petitions.warning.convocationClosed')}</p>
        )
      }
    </div>
  );
}