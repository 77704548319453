import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Loading, NavMenu } from "../../../components";
import { useLanguageContext } from "../../../contexts/LanguageContext";
import { useParametrics } from "../../../contexts/ParametricsContext";
import { getNotificationById } from "../../../services/notificationServices";
import { DateUtils } from "../../../utils";
import "./NotificationDetails.scss"

const NotificationDetails = () => {
  const [notification, setNotification] = useState()
  const [notificationContent, setNotificationContent] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const { t } = useTranslation();
  const { convocationId } = useParametrics()
  const { currentLanguage } = useLanguageContext()
  const history = useHistory();

  useEffect(() => {
    if (!convocationId) return;
    if (!history.location.state) return;
    setNotificationContent({
      title: history.location.state.title,
      type: history.location.state.type,
      text: history.location.state.text,
      date: DateUtils.getFormatedDate(history.location.state.date),
      status: history.location.state.status
    })
    const fetchNotification = async () => {
      const result = await getNotificationById(convocationId, history.location.state.id)
      setNotification(result)
      setIsLoading(false)
    }
    fetchNotification()

  }, [convocationId])

  useEffect(() => {
    if (!currentLanguage) return;
    if (currentLanguage === 'es') {
      return setNotificationContent((data) => ({
        ...data,
        title: notification?.title,
        text: notification?.text,
      }))
    }
    if (currentLanguage === 'va') {
      return setNotificationContent((data) => ({
        ...data,
        title: notification?.titleVa,
        text: notification?.textVa,
      }))
    }
    if (currentLanguage === 'eu') {
      return setNotificationContent((data) => ({
        ...data,
        title: notification?.titleVa,
        text: notification?.textVa,
      }))
    }
    if (currentLanguage === 'ca') {
      return setNotificationContent((data) => ({
        ...data,
        title: notification?.titleVa,
        text: notification?.textVa,
      }))
    }
    if (currentLanguage === 'gl') {
      return setNotificationContent((data) => ({
        ...data,
        title: notification?.titleVa,
        text: notification?.textVa,
      }))
    }
  }, [currentLanguage, notification])


  return (
    <NavMenu>
      <div className="NotificationDetails">
        <div className="NotificationDetails__content">
          {isLoading ? (
            <div className='vh-100 d-flex justify-content-center align-items-center'>
              <Loading />
            </div>
          ) : (
            <>
              <div className="NotificationDetails__content__header">
                <img
                  className="NotificationDetails__content__header__back-arrow"
                  src="/misc/icons/arrow-left-blue.svg"
                  alt="back arrow"
                  onClick={() => {
                    history.goBack();
                  }}
                />
                <h2 className="NotificationDetails__content__header__title">{t("Details.title")}</h2>
              </div>
              <div className="NotificationDetails__card">
                <h3 className="mb-4">{notificationContent.title}</h3>
                <div className="d-flex justify-content-between flex-wrap gap-2">
                  <p>{t("Notifications.Details.type")}: <span>{notificationContent.type}</span></p>
                  <p>{t("Notifications.Details.status")}: <span>{notificationContent.status}</span></p>
                  <p>{t("Notifications.Details.date")}: <span>{notificationContent.date}</span></p>
                </div>
                <div className="mt-4 border h-100 p-2 rounded NotificationDetails__card__text">
                  <div dangerouslySetInnerHTML={{ __html: notificationContent.text }}></div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </NavMenu>
  );
};

export default NotificationDetails;
