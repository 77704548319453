import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components';
import './PassResetSuccess.scss';

const PassResetSuccess = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!location?.state?.redirect) {
      history.goBack();
    } else {
      setShow(true);
    }
  }, []); //eslint-disable-line

  return (
    show && (
      <div className="PassResetSuccess">
        <div className="w-100 d-flex justify-content-center">
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="PassResetSuccess__logo" />
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="PassResetSuccess__container">
            <div className="row m-0 justify-content-center">
              <div className="row m-0 justify-content-center">
                <img
                  src="/misc/icons/signup-success.png"
                  alt="shop-icon"
                  className="PassResetSuccess__container__icon"
                />
              </div>
              <h6>{t('PassReset.success.title')}</h6>
              <p>{t('PassReset.success.description')}</p>
            </div>
            <div className="PassResetSuccess__container__buttons">
              <Button
                className="buttonPrimaryLarge"
                text={t('Globals.accept')}
                action={() => location?.state?.profile ? history.replace('/profile') : history.replace('/login')}
                styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PassResetSuccess;
