import { useEffect, useState } from 'react';
import { Button } from '../../../components';
import { DisplayUtils } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import './LeaveProcess.scss';

const LeaveProcess = () => {
  
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(!location?.state?.leave) {
        history.goBack()
    } else {
        setShow(true)
    }
  }, []) //eslint-disable-line

  return (
      show && (
        <div className="LeaveProcess">
            <div className="w-100 d-flex justify-content-center">
                <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="LeaveProcess__logo" />
            </div>
            <div className="w-100 d-flex justify-content-center">
                <div className="LeaveProcess__container">
                    <div className="row m-0 justify-content-center">
                        <div className="row m-0 justify-content-center">
                            <img
                                src={`${DisplayUtils.isMobile() ? '/misc/icons/warning-mobile.png' : '/misc/icons/warning-desk.png'}`}
                                alt="shop-icon"
                                className="LeaveProcess__container__icon"
                            />
                        </div>
                        <h6>{t('Join.leaveProcess.title')}</h6>
                        <p>{t('Join.leaveProcess.description')}</p>
                    </div>
                    <div className="LeaveProcess__container__buttons">
                        <Button
                            className="buttonPrimaryLarge"
                            text={t('Globals.go')}
                            action={() => history.goBack()}
                            styles={{ backgroundColor: `${DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe'}`, color: `${DisplayUtils.isMobile() ? '#381940' : '#381940'}` }}
                        />
                        {/* TODO: ir a pantalla principal */}
                        <span className="LeaveProcess__container__buttons__skip" onClick={() => history.push("/")/*history.push({ pathname: '/join', state: { skip: true } })*/}>
                            {t('Join.leaveProcess.skip')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
      )
  );
};

export default LeaveProcess;