import { useEffect, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input/mobile';
import es from 'react-phone-number-input/locale/es.json';
import 'react-phone-number-input/style.css';
import { AddressForm, Button, CardInfoModal, Loading } from '../../../components';
import { DisplayUtils, constants } from '../../../utils';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { getNotificationsData, saveNotificationsData } from '../../../services/petitionServices';
import { 
    getParametricsAddressType,
    getParametricsCountries,
    getParametricsProvinces
} from '../../../services/parametricsService';
import './NotificationsAddressInfo.scss';

const NotificationsAddressInfo = () => {
  
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const userType = location?.state?.userType
    const petitionId = location?.state?.petitionId;
    const petitionHasAccount = location?.state?.petitionHasAccount;
    const { showError } = useErrorsContext();
    const { user } = useAuthContext();
    const { convocationId } = useParametrics();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addressTypes, setAddressTypes] = useState();
    const [countries, setCountries] = useState();
    const [provinces, setProvinces] = useState();
    const [differentAddress, setDifferentAddress] = useState(false);
    const [differentEmail, setDifferentEmail] = useState(false);
    const [reciveAlerts, setReciveAlerts] = useState(false);
    const [beneficiaryAddress, setBeneficiaryAddress] = useState();
    const [notificationsAddress, setNotificationsAddress] = useState();
    const [initialEmail, setInitialEmail] = useState();
    const [showInfoModal, setShowInfoModal] = useState();
    const [petitionHasUser, setPetitionHasUser] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    useEffect(() => {
        if(!location?.state?.notificationsAddressInfo || !userType || !petitionId || petitionHasAccount === undefined) {
            history.goBack()
        } else {
            setShow(true)
        }
    }, []) //eslint-disable-line

    const fetchData = async(data) => {
        try {
            setLoading(true);
            const addressTypes = await getParametricsAddressType();
            const addressType = addressTypes?.find(type => type.roadTypeId === data?.notificationAddress?.roadTypeId)
            const beneficiaryAddressType = addressTypes?.find(type => type.roadTypeId === data?.beneficiaryAddress?.roadTypeId)
            setAddressTypes(addressTypes);
            const countries = await getParametricsCountries();
            const country = countries?.find(country => country.countryId === data?.notificationAddress?.countryId)
            const beneficiaryCountry = countries?.find(country => country.countryId === data?.beneficiaryAddress?.countryId)
            setCountries(countries);
            const provinces = await getParametricsProvinces();
            const province = provinces?.find(province => province.provinceId === data?.notificationAddress?.provinceId)
            const beneficiaryProvince = provinces?.find(province => province.provinceId === data?.beneficiaryAddress?.provinceId)
            setProvinces(provinces);

            setBeneficiaryAddress(
                {
                    ...data?.beneficiaryAddress,
                    addressType: beneficiaryAddressType,
                    province: beneficiaryProvince,
                    country: beneficiaryCountry
                }
            )

            setNotificationsAddress(
                {
                    ...data?.notificationAddress,
                    addressType: province,
                    province: beneficiaryProvince,
                    country: country
                }
            )

            reset({
                addressType: data?.differentFromBeneficiaryAddress ? (addressType?.name || '') : (beneficiaryAddressType?.name || ''),
                addressTypeId: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.roadTypeId || '') : (data?.beneficiaryAddress?.roadTypeId || ''),
                address: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.roadNameAddress || '') : (data?.beneficiaryAddress?.roadNameAddress || ''),
                number: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.numberAddress || '') : (data?.beneficiaryAddress?.numberAddress || ''),
                bloq: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.blockAddress || '') : (data?.beneficiaryAddress?.blockAddress || ''),
                stair: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.levelAddress || '') : (data?.beneficiaryAddress?.levelAddress || ''),
                floor: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.floorAddress || '') : (data?.beneficiaryAddress?.floorAddress || ''),
                door: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.doorAddress || '') : (data?.beneficiaryAddress?.doorAddress || ''),
                location: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.locality || '') : (data?.beneficiaryAddress?.locality || ''),
                locationId: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.localityId || '') : (data?.beneficiaryAddress?.localityId || ''),
                province: data?.differentFromBeneficiaryAddress ? (province?.name || '') : (beneficiaryProvince?.name || ''),
                provinceId: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.provinceId || '') : (data?.beneficiaryAddress?.provinceId || ''),
                postalCode: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.postalCode || '') : (data?.beneficiaryAddress?.postalCode || ''),
                postalCodeId: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.postalCodeId || '') : (data?.beneficiaryAddress?.postalCodeId || ''),
                country: data?.differentFromBeneficiaryAddress ? (country?.name || '') : (beneficiaryCountry?.name || ''),
                countryId: data?.differentFromBeneficiaryAddress ? (data?.notificationAddress?.countryId || '') : (data?.beneficiaryAddress?.countryId || ''),
                email: data?.notificationEmail || '',
                confirmEmail: data?.differentFromRegistrationEmail ? data?.notificationEmail : '',
                phoneNumber: `+${data?.notificationPhonePrefix || '34'}${data?.notificationPhoneNumber || ''}`,
                confirmPhoneNumber: `+${data?.notificationPhonePrefix || '34'}${data?.notificationPhoneNumber || ''}`,
            })
            setInitialEmail(data?.notificationEmail)
            setDifferentAddress(data?.differentFromBeneficiaryAddress || false)
            setPetitionHasUser(petitionHasAccount);
            setDifferentEmail((!petitionHasAccount) ? true : (data?.differentFromRegistrationEmail || false))
            setReciveAlerts(data?.receiveEmailAlerts || false)
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    }

    const getNotiData = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getNotificationsData(convocationId, userType.toUpperCase(), petitionId);
            if(data) {
                await fetchData(data);
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [showError, convocationId]); // eslint-disable-line

    useEffect(() => {
      if (!convocationId) return;
      getNotiData();
    }, [convocationId]) //eslint-disable-line

    const onSubmit = async (data) => {
        setShowInfoModal(data);
    }

    const preventCopyPaste = (e) => {
        e.preventDefault();
        return false;
    }

    const saveAllData = async(data) => {
        const finalData = {
            notificationAddress: {
                roadTypeId: data.addressTypeId,
                roadNameAddress: data.address,
                numberAddress: `${data.number}`,
                blockAddress: data.bloq === '' ? null : data.bloq,
                levelAddress: data.stair === '' ? null : data.stair,
                floorAddress: data.floor === '' ? null : data.floor,
                doorAddress: data.door === '' ? null : data.door,
                countryId: data.countryId,
                provinceId: data.provinceId === '' ? null : data.provinceId,
                localityId: data.locationId === '' ? null : data.locationId,
                postalCodeId: data.postalCodeId === '' ? null : data.postalCodeId,
                locality: (!data.locationId || data.locationId === '') ? (data.location === '' ? null : data.location) : null,
                postalCode: (!data.postalCodeId || data.postalCodeId === '') ? (data.postalCode === '' ? null : data.postalCode) : null
            },
            differentFromBeneficiaryAddress: differentAddress || false,
            differentFromRegistrationEmail: differentEmail || false,
            notificationEmail: data.email,
            notificationPhonePrefix: parsePhoneNumber(data?.phoneNumber).countryCallingCode,
            notificationPhoneNumber: parsePhoneNumber(data?.phoneNumber).nationalNumber,
            receiveEmailAlerts: reciveAlerts || false
        }
        try {
            setShowInfoModal();
            setLoading(true);
            await saveNotificationsData(convocationId, userType.toUpperCase(), petitionId, finalData);
            history.push({ pathname: `/${userType}/join/upload-documentation`, state: { uploadDocumentation: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
                <>
                    <CardInfoModal 
                        showingModal={showInfoModal}
                        hideModal={() => setShowInfoModal()}
                        onAccept={async () => {
                            saveAllData(showInfoModal);
                        }}
                    />
                    <div className='NotificationsAddressInfo'>
                        <div className="w-100 d-flex justify-content-center">
                            {!DisplayUtils.isMobile() ? (
                                <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="NotificationsAddressInfo__logo" />
                            ) : (
                                <div className="NotificationsAddressInfo__title">
                                    <Button
                                        icon="/misc/icons/arrow-left-blue.svg"
                                        action={() => history.push({ pathname: `/${userType}/join/card-selector`, state: { cardTypeSelector: true, userType: userType, petitionId: petitionId, petitionHasAccount } })}
                                        className="Button__transparent"
                                    />
                                    <h6>{t('Join.notificationsAddressInfo.head')}</h6>
                                </div>
                            )}
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <div className="NotificationsAddressInfo__container">
                                {!DisplayUtils.isMobile() && (
                                    <div className="NotificationsAddressInfo__container__head">
                                        <Button
                                            icon="/misc/icons/arrow-left-pink.svg"
                                            action={() => history.push({ pathname: `/${userType}/join/card-selector`, state: { cardTypeSelector: true, userType: userType, petitionId: petitionId, petitionHasAccount } })}
                                            className="Button__transparent"
                                            styles={{width: '50px'}}
                                        />
                                        <h6>{t('Join.notificationsAddressInfo.head')}</h6>
                                    </div>
                                )}
                                <div className="NotificationsAddressInfo__container__form">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className=''>
                                            <AddressForm 
                                                title={t('Join.notificationsAddressInfo.addressTitle')}
                                                errors={errors} 
                                                register={register}
                                                control={control}
                                                setValue={setValue}
                                                getValues={getValues}
                                                clearErrors={clearErrors}
                                                addressTypes={addressTypes}
                                                countries={countries}
                                                provinces={provinces}
                                                disabled={!differentAddress}/>
                                        </div>
                                        <div className="d-flex align-middle mb-3">
                                            <input
                                                className='flex-shrink-0'
                                                type="checkbox"
                                                name="differentAddress"
                                                id="differentAddress"
                                                checked={differentAddress}
                                                onChange={() => {
                                                    setDifferentAddress(!differentAddress);
                                                    clearErrors(['addressType','address','number','bloq','stair','floor','door','location','province','postalCode','country']);
                                                    if(differentAddress === false) {
                                                        const addressType = addressTypes?.find(type => type.roadTypeId === notificationsAddress?.roadTypeId)
                                                        const province = provinces?.find(province => province.provinceId === notificationsAddress?.provinceId)
                                                        const country = countries?.find(country => country.countryId === notificationsAddress?.countryId)
                                                        setValue('addressType', addressType?.name);
                                                        setValue('addressTypeId', notificationsAddress?.roadTypeId);
                                                        setValue('address', notificationsAddress?.roadNameAddress);
                                                        setValue('number', notificationsAddress?.numberAddress);
                                                        setValue('bloq', notificationsAddress?.blockAddress);
                                                        setValue('stair', notificationsAddress?.levelAddress);
                                                        setValue('floor', notificationsAddress?.floorAddress);
                                                        setValue('door', notificationsAddress?.doorAddress);
                                                        setValue('location', notificationsAddress?.locality);
                                                        setValue('locationId', notificationsAddress?.localityId);
                                                        setValue('province', province?.name);
                                                        setValue('provinceId', notificationsAddress?.provinceId);
                                                        setValue('postalCode', notificationsAddress?.postalCode);
                                                        setValue('postalCodeId', notificationsAddress?.postalCodeId);
                                                        setValue('country', country?.name);
                                                        setValue('countryId', notificationsAddress?.countryId);
                                                    } else {
                                                        const addressType = addressTypes?.find(type => type.roadTypeId === beneficiaryAddress?.roadTypeId)
                                                        const province = provinces?.find(province => province.provinceId === beneficiaryAddress?.provinceId)
                                                        const country = countries?.find(country => country.countryId === beneficiaryAddress?.countryId)
                                                        setValue('addressType', addressType?.name);
                                                        setValue('addressTypeId', beneficiaryAddress?.roadTypeId);
                                                        setValue('address', beneficiaryAddress?.roadNameAddress);
                                                        setValue('number', beneficiaryAddress?.numberAddress);
                                                        setValue('bloq', beneficiaryAddress?.blockAddress);
                                                        setValue('stair', beneficiaryAddress?.levelAddress);
                                                        setValue('floor', beneficiaryAddress?.floorAddress);
                                                        setValue('door', beneficiaryAddress?.doorAddress);
                                                        setValue('location', beneficiaryAddress?.locality);
                                                        setValue('locationId', beneficiaryAddress?.localityId);
                                                        setValue('province', province?.name);
                                                        setValue('provinceId', beneficiaryAddress?.provinceId);
                                                        setValue('postalCode', beneficiaryAddress?.postalCode);
                                                        setValue('postalCodeId', beneficiaryAddress?.postalCodeId);
                                                        setValue('country', country?.name);
                                                        setValue('countryId', beneficiaryAddress?.countryId);
                                                    }
                                                }}
                                            />{' '}
                                            <label htmlFor="differentAddress" className='flex-shrink-1'>{t('Join.notificationsAddressInfo.differentAddress')}</label>
                                        </div>
                                        <div className="d-flex align-middle mb-5">
                                            <p className="NotificationsAddressInfo__container__form__cardInfo">{t('Join.notificationsAddressInfo.cardInfo')}</p>
                                        </div>
                                        <h6 className="NotificationsAddressInfo__container__form__title">{t('Join.notificationsAddressInfo.dataTitle')}</h6>
                                        <label> {t('Join.notificationsAddressInfo.email')}<span style={{ color: '#f838f9' }}>*</span></label>
                                        <div className="NotificationsAddressInfo__container__form__box">
                                            <input
                                                type="text"
                                                onPaste={preventCopyPaste}
                                                onCopy={preventCopyPaste}
                                                placeholder={t('Join.notificationsAddressInfo.email')}
                                                className={`${errors.email ? 'warning' : ''}`}
                                                {...register('email', {
                                                    required: t('Globals.required'),
                                                    validate: (value) => constants.EMAIL_PATTERN.test(value) || t('Errors.notValid') })
                                                }
                                                disabled={!differentEmail}
                                            />
                                            <img src="/misc/icons/email.png" alt="icon" style={{ width: '23px' }} />
                                        </div>
                                        {errors.email && (
                                            <p className="NotificationsAddressInfo__container__form__error">{errors.email.message}</p>
                                        )}
                                        {differentEmail && (
                                            <>
                                                <label> {t('Join.notificationsAddressInfo.confirmEmail')}<span style={{ color: '#f838f9' }}>*</span></label>
                                                <div className="NotificationsAddressInfo__container__form__box">
                                                    <input
                                                        type="text"
                                                        onPaste={preventCopyPaste}
                                                        onCopy={preventCopyPaste}
                                                        placeholder={t('Join.notificationsAddressInfo.confirmEmail')}
                                                        className={`${errors.confirmEmail ? 'warning' : ''}`}
                                                        {...register('confirmEmail', { 
                                                            required: t('Globals.required'), 
                                                            validate: (value) => 
                                                                constants.EMAIL_PATTERN.test(value) ? 
                                                                    (getValues("email") === getValues("confirmEmail") || t('Errors.emailNotEqual')) 
                                                                    : 
                                                                    t('Errors.notValid') 
                                                            })
                                                        }
                                                    />
                                                    <img src="/misc/icons/email.png" alt="icon" style={{ width: '23px' }} />
                                                </div>
                                                {errors.confirmEmail && (
                                                    <p className="NotificationsAddressInfo__container__form__error">{errors.confirmEmail.message}</p>
                                                )}
                                            </>
                                        )}
                                        {
                                          petitionHasUser && (
                                            <div className="d-flex align-middle mb-2">
                                                <input
                                                    className='flex-shrink-0'
                                                    type="checkbox"
                                                    name="differentEmail"
                                                    id="differentEmail"
                                                    checked={differentEmail}
                                                    onChange={() => {
                                                        setDifferentEmail(!differentEmail);
                                                        clearErrors(["email","confirmEmail"]);
                                                        if(differentEmail === false) {
                                                            setValue("email", initialEmail);
                                                        } else {
                                                            setValue("email", user?.email);
                                                            setValue("confirmEmail",'');
                                                        }
                                                    }}
                                                />{' '}
                                                <label htmlFor="differentEmail" className='flex-shrink-1'>{t('Join.notificationsAddressInfo.differentEmail')}</label>
                                            </div>
                                          )
                                        }
                                        <label>{t('Join.notificationsAddressInfo.phone')}<span style={{ color: '#f838f9' }}>*</span></label>
                                        <Controller
                                            control={control}
                                            name="phoneNumber"
                                            rules={{ required: t('Globals.required'), validate: value => isValidPhoneNumber(value) || t("Errors.phoneError") }}
                                            defaultValue={''}
                                            render={({ field }) => {
                                                return (
                                                    <PhoneInput
                                                        labels={es}
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="ES"
                                                        value={field.value}
                                                        onPaste={preventCopyPaste}
                                                        onCopy={preventCopyPaste}
                                                        onChange={field.onChange}
                                                        onBlur={field.onBlur}
                                                        className={`${errors?.phoneNumber ? 'phoneWarning' : ''}`}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors?.phoneNumber && (
                                            <p className="NotificationsAddressInfo__container__form__error">{errors?.phoneNumber?.message}</p>
                                        )}
                                        <label>{t('Join.notificationsAddressInfo.confirmPhone')}<span style={{ color: '#f838f9' }}>*</span></label>
                                        <Controller
                                            control={control}
                                            name="confirmPhoneNumber"
                                            rules={{ 
                                                required: t('Globals.required'), 
                                                validate: value => 
                                                    isValidPhoneNumber(value) ? 
                                                        (getValues('phoneNumber') === getValues('confirmPhoneNumber') || t("Errors.phoneNotEqual") ) 
                                                        : 
                                                        t("Errors.phoneError") 
                                            }}
                                            defaultValue={''}
                                            render={({ field }) => {
                                                return (
                                                    <PhoneInput
                                                        labels={es}
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="ES"
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        onBlur={field.onBlur}
                                                        className={`${errors?.confirmPhoneNumber ? 'phoneWarning' : ''}`}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors?.confirmPhoneNumber && (
                                            <p className="NotificationsAddressInfo__container__form__error">{errors?.confirmPhoneNumber?.message}</p>
                                        )}
                                        <p className='m-0 mt-5 mb-4' dangerouslySetInnerHTML={{ __html: t('Globals.noOptional') }} />
                                        <div className="d-flex mb-3">
                                            <input
                                                className='flex-shrink-0'
                                                type="checkbox"
                                                name="reciveAlerts"
                                                id="reciveAlerts"
                                                checked={reciveAlerts}
                                                onChange={() => {
                                                    setReciveAlerts(!reciveAlerts);
                                                }}
                                            />{' '}
                                            <label htmlFor="reciveAlerts" className='flex-shrink-1'>{t('Join.notificationsAddressInfo.reciveAlerts')}</label>
                                        </div>
                                        <div className="NotificationsAddressInfo__container__actions">
                                            <Button
                                                className="buttonPrimaryLarge"
                                                text={t('Join.notificationsAddressInfo.later')}
                                                styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                                                action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                                            />
                                            <Button
                                                className="buttonPrimaryLarge"
                                                text={t('Join.notificationsAddressInfo.action')}
                                                styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                                type="input"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        )
    )

}

export default NotificationsAddressInfo;