import { useTranslation } from 'react-i18next';
import { formatToCurrency } from '../../../utils/Currency';
import './GlobalCard.scss';

const GlobalCard = ({
  balance
}) => {

    const {t} = useTranslation();
  
    return (
        <div className="GlobalCard">
            <img className='GlobalCard__image' src="/misc/icons/transactions/global.svg" alt=""/>
            <div className='GlobalCard__title'>
              <span className='GlobalCard__title__title'> {t('Transactions.global')} </span>
              <span className='GlobalCard__title__subtitle'> {t('Transactions.globalBalance')} </span>
            </div>
            <div className='GlobalCard__balance'>
              <span className='GlobalCard__balance__amount' style={{
                color: balance == 0 ? '#ee404c' : null
              }}>{formatToCurrency(balance)}</span>
              <span className='GlobalCard__balance__label'>{t('Transactions.balanceAvailable')}</span>
            </div>
        </div>
    )
};

export default GlobalCard;
