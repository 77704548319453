import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useErrorsContext } from '../../../../contexts/ErrorsContext';
import { Loading, NavMenu } from '../../../../components';
import { useParametrics } from '../../../../contexts/ParametricsContext';
import { getClaims } from '../../../../services/procedureServices';
import { PetitionCard } from '../../../../components/petitionCard/PetitionCard';
import { ClaimCard } from './ClaimCard/ClaimCard';
import './Claims.scss';

const Claims = () => {

  const { t } = useTranslation();
  const { convocation, convocationId } = useParametrics();
  const { showError } = useErrorsContext();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [petition, setPetition] = useState(null);
  const [claims, setClaims] = useState(null);

  const _getClaims = async () => {
    try {
      setLoading(true);
      const resp = await getClaims(petition.petitionId);
      setClaims(resp);
      setLoading(false);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.petition) {
      return history.replace('/petitions')
    }
    setPetition(location.state.petition);
  }, [convocationId]);

  useEffect(() => {
    if(petition) _getClaims();
  }, [petition])

  return (
    <NavMenu>
      <div className="Claims">
        <div className="content">
          {
            loading ? (
              <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
              </div>
            ) : (
              <>
                <div className="w-100 d-flex justify-content-center">
                  <div className="header">
                    <img src='/misc/icons/arrow-left-blue.svg' alt='' onClick={() => {
                      history.goBack();
                    }}/>
                    <h6>{t('Petitions.detail.claim')} </h6>
                  </div>
                </div>
                <div className="petitions">
                  <PetitionCard convocationTitle={convocation?.title} petition={petition} isButton={false}/>
                </div>
                <div className="claims">
                  {
                    claims?.map((claim) => {
                      return (
                        <ClaimCard 
                          key={claim.id}
                          claim={claim}
                          petition={petition}
                          convocationTitle={convocation?.title}
                        />
                      )
                    })
                  }
                </div>
              </>
            )
          }
        </div>
      </div>
    </NavMenu>
  );
}

export default Claims;