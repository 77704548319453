import { Button } from '../../components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DisplayUtils } from '../../utils';
import './NotFound.scss';

const NotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="NotFound">
      <div className="NotFound__container">
        <div className="NotFound__container__logo">
          <img src="/misc/icons/complete_logo_white.svg" alt="" style={{ height: '100%', width: '100%' }} />
        </div>
          <h1>404</h1>
          <h6>Oops!</h6>
          <p>No hemos encontrado la página que buscas</p>
          <Button
            className="buttonPrimaryLarge"
            text={t('Globals.retry')}
            action={() => history.push('/')}
            styles={{
              backgroundColor: DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe',
              color: DisplayUtils.isMobile() ? '#381940' : '#381940',
            }}
          />
      </div>
    </div>
  );
};

export default NotFound;
