import { useTranslation } from "react-i18next";
import { Button, Loading, NavMenu } from "../../../components";
import "./PetitionDetail.scss";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PetitionCard } from "../../../components/petitionCard/PetitionCard";
import { getClaims, getCorrections } from "../../../services/procedureServices";
import { useParametrics } from "../../../contexts/ParametricsContext";
import { DisplayUtils } from "../../../utils";

const PetitionDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { convocation, convocationId } = useParametrics();

  const [loading, setLoading] = useState(true);
  const [petition, setPetition] = useState(null);
  const [corrections, setCorrections] = useState(null);
  const [claims, setClaims] = useState(null);

  const _getData = async (petitionId) => {
    try {
      setLoading(true);
      const resp = await getCorrections(petitionId);
      setCorrections(resp);
      const claimsResp = await getClaims(petitionId);
      setClaims(claimsResp);
    } catch (e) {
      /* empty */
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location?.state?.petition) {
      return history.replace("/petitions");
    }
    setPetition(location.state.petition);
    _getData(location.state.petition.petitionId);
  }, [convocationId]);

  const BUTTONS = useMemo(() => {
    return [
      {
        show: petition?.cardRequested,
        url: `/petitions/${petition?.petitionId}/transactions`,
        state: { petition: petition },
        label: t("Petitions.detail.transactions"),
        icon: "/misc/icons/transaction-card-icon.png"
      },
      {
        show: true,
        url: `/petitions/${petition?.petitionId}/documentation`,
        state: { petition: petition },
        label: t("Petitions.detail.documentation"),
      },
      {
        show: corrections?.length > 0,
        url: `/petitions/${petition?.petitionId}/corrections`,
        state: { petition: petition },
        label: t("Petitions.detail.correction"),
      },
      {
        show: true,
        url: `/petitions/${petition?.petitionId}/additional-documents`,
        state: { petition: petition },
        label: t("Petitions.detail.addDocumentation"),
      },
      {
        show: true,
        url: `/petitions/${petition?.petitionId}/rectifications`,
        state: { petition: petition },
        label: t("Petitions.detail.rectify"),
      },
      {
        show: claims?.length > 0,
        url: `/petitions/${petition?.petitionId}/claims`,
        state: { petition: petition },
        label: t("Petitions.detail.claims"),
      },
      {
        show: petition?.processStatus?.status === "ACCEPTED",
        url: `/petitions/${petition?.petitionId}/file-documentation`,
        state: { petition: petition },
        label: t("Petitions.detail.fileDocumentation"),
      },
      {
        show: petition?.accountEmail,
        url: `/petitions/${petition?.petitionId}/change-email`,
        state: { petition: petition },
        label: t("Petitions.detail.changeEmail"),
      },
    ];
  }, [petition, corrections, claims]);

  if (loading) return (
    <div className='vh-100 d-flex justify-content-center align-items-center'>
        <Loading />
    </div>
  )

  return (
    <NavMenu>
      <div className="PetitionDetail">
        <div className="content">
          {loading ? (
            <div className="vh-100 d-flex justify-content-center align-items-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="w-100 d-flex justify-content-center">
                <div className="header">
                  <img
                    src="/misc/icons/arrow-left-blue.svg"
                    alt=""
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                  <h6>
                    {t("Petitions.petitionCard.title")}{" "}
                    {petition.registrationNumber}
                  </h6>
                </div>
              </div>
              <div className="petitions">
                <PetitionCard
                  convocationTitle={convocation?.title}
                  petition={petition}
                  isButton={false}
                />
              </div>
              {
                !petition.accountEmail && (
                  <div className="create-user" onClick={() => {history.push(`/petitions/${petition.petitionId}/signup`)}}>
                    <img src={"/misc/icons/profile-menu.png"} alt="shop-icon" className="Profile__content__box__boxes__contain__icon" />
                    <span className="create-user__title">{t('Petitions.detail.createUser')}</span>
                    <span className="create-user__detail">{t('Petitions.detail.createUserDetail')}</span>
                    <Button
                      className="buttonPrimaryLarge"
                      text={t('Petitions.detail.createUserButton')}
                      styles={{ backgroundColor: `${DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe'}`, color: `${DisplayUtils.isMobile() ? '#381940' : '#381940'}` }}
                      action={() => {history.push(`/petitions/${petition.petitionId}/signup`)}}
                    />
                  </div>
                )
              }
              <div className="buttons">
                {BUTTONS.filter((b) => b.show).map((button) => {
                  return (
                    <div
                      key={button.url}
                      className="button"
                      onClick={() => {
                        history.push(button.url, button.state);
                      }}
                    >
                      <img
                        className="icon-left"
                        src={button.icon ?? "/misc/icons/block-info.png"}
                        alt=""
                      />
                      <h3>{button.label}</h3>
                      <img
                        className="icon-right"
                        src="/misc/icons/arrow-right-pink.svg"
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </NavMenu>
  );
};

export default PetitionDetail;
