import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '../../../../../components';
import { constants, DisplayUtils } from '../../../../../utils';
import { useParametrics } from '../../../../../contexts/ParametricsContext';
import './ChangeEmailFinish.scss';

const ChangeEmailFinish = () => {
  
  const { t } = useTranslation();
  const { convocationId } = useParametrics();
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const resetUser = () => {
    localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
    localStorage.removeItem(constants.CURRENT_REFRESH_KEY);
    localStorage.removeItem(constants.CURRENT_ID_TOKEN_KEY);
    localStorage.removeItem(constants.CURRENT_USER_KEY);
    localStorage.removeItem(constants.CURRENT_PETITON_STATUS_KEY);
    localStorage.removeItem(constants.KEEP_SESSION);
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.ChangeEmailFinish) {
        history.goBack()
    } else {
      resetUser();
      setShow(true);
    }
  }, [convocationId])

  return (
      show && (
        <div className="ChangeEmailFinish">
          <div className="w-100 d-flex justify-content-center">
              <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="ChangeEmailFinish__logo" />
          </div>
          <div className="w-100 d-flex justify-content-center">
              <div className="ChangeEmailFinish__container">
                  <div className="row m-0 justify-content-center">
                      <div className="row m-0 justify-content-center">
                          <img
                              src={`${DisplayUtils.isMobile() ? '/misc/icons/doc-success-2.png' : '/misc/icons/doc-success.png'}`}
                              alt="shop-icon"
                              className="ChangeEmailFinish__container__icon"
                          />
                      </div>
                      <h6>{t('Petitions.ChangeEmail.finish.title')}</h6>
                      <p>
                          {t('Petitions.ChangeEmail.finish.description')}
                      </p>
                      <div className='SignProcedureSuccess__container__request-data align-center'>
                      </div>
                  </div>
                  <div className="ChangeEmailFinish__container__buttons">
                      <Button
                          className="buttonPrimaryLarge"
                          text={t('Petitions.ChangeEmail.finish.next')}
                          styles={{ backgroundColor: `${DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe'}`, color: `${DisplayUtils.isMobile() ? '#381940' : '#381940'}` }}
                          action={() => {
                            localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
                            localStorage.removeItem(constants.CURRENT_REFRESH_KEY);
                            localStorage.removeItem(constants.CURRENT_ID_TOKEN_KEY);
                            localStorage.removeItem(constants.CURRENT_USER_KEY);
                            localStorage.removeItem(constants.CURRENT_PETITON_STATUS_KEY);
                            localStorage.removeItem(constants.KEEP_SESSION);
                            window.location.assign('/login');
                          }}
                      />
                  </div>
              </div>
          </div>
        </div>
      )
  );
};

export default ChangeEmailFinish;
