import { useTranslation } from "react-i18next";
import { Loading, NavMenu } from "../../../../components";
import { useErrorsContext } from "../../../../contexts/ErrorsContext";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PetitionCard } from "../../../../components/petitionCard/PetitionCard";
import { DateUtils, DisplayUtils } from "../../../../utils";
import "./PetitionFilesDocumentation.scss";
import { useParametrics } from "../../../../contexts/ParametricsContext";
import { getPetitionDossierFiles } from "../../../../services/petitionServices";

const PetitionFilesDocumentation = () => {
  const { t } = useTranslation();
  const { convocation, convocationId } = useParametrics();
  const { showError } = useErrorsContext();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [petition, setPetition] = useState(null);
  const [documents, setDocuments] = useState([]);

  const getFilesDocuments = async () => {
    try {
      setLoading(true);
      const resolutionDocumentation = await getPetitionDossierFiles(
        convocationId,
        petition.petitionId,
        "MANAGER_RESOLUTION_ESTIMATE",
      );
      const dossierDocumentation = await getPetitionDossierFiles(
        convocationId,
        petition.petitionId,
        "MANAGER_DOSSIER_NOTIFICATION",
      );
      setDocuments([...resolutionDocumentation, ...dossierDocumentation]);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!convocationId) return;
    if (!location?.state?.petition) {
      return history.replace("/petitions");
    }
    setPetition(location.state.petition);
  }, [convocationId]);

  useEffect(() => {
    if (petition) getFilesDocuments();
  }, [petition]);

  return (
    <NavMenu>
      <div className="PetitionFilesDocumentation">
        <div className="content">
          {loading ? (
            <div className="vh-100 d-flex justify-content-center align-items-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="w-100 d-flex justify-content-center">
                <div className="header">
                  <img
                    src="/misc/icons/arrow-left-blue.svg"
                    alt=""
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                  <h6>{t("Petitions.detail.fileDocumentation")}</h6>
                </div>
              </div>
              <div className="petitions">
                <PetitionCard
                  convocationTitle={convocation?.title}
                  petition={petition}
                  isButton={false}
                />
              </div>
              <div className="documents">
                {documents?.length > 0 ? (
                  documents?.map((document) => {
                    return DisplayUtils.isMobile() ? (
                      <div key={document.fileId} className="document">
                        <img
                          className="icon-left"
                          src="/misc/icons/list-info-pink.png"
                          alt=""
                        />
                        <div className="document-data">
                          <div className="document-data-row">
                            <div className="document-column">
                              <div className="document-column-title">
                                {t("Petitions.documentation.document")}
                              </div>
                              <div className="document-column-value">
                                {document.title}
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="icon-right"
                          src="/misc/icons/download.png"
                          alt=""
                          onClick={() => {
                            window.open(`/pdf-viewer/?id=${document.fileId}&type=dossierFile`);
                          }}
                        />
                      </div>
                    ) : (
                      <div key={document.fileId} className="document">
                        <img
                          className="icon-left"
                          src="/misc/icons/list-info-pink.png"
                          alt=""
                        />
                        <div className="document-data">
                          <div className="document-data-row">
                            <div className="document-column">
                              <div className="document-column-title">
                                {t("Petitions.documentation.document")}
                              </div>
                              <div className="document-column-value">
                                {document.title}
                              </div>
                            </div>
                            <div className="document-column">
                              <div className="document-column-title">
                                {t("Petitions.documentation.date")}
                              </div>
                              <div className="document-column-value">
                                {DateUtils.getFormatedDate(
                                  document.updatedTime,
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="document-data-row">
                            <div className="document-column">
                              <div className="document-column-title">
                                {t("Petitions.documentation.csv")}
                              </div>
                              <div className="document-column-value">
                                {document.csv}
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="icon-right"
                          src="/misc/icons/download.png"
                          alt=""
                          onClick={() => {
                            window.open(`/pdf-viewer/?id=${document.fileId}&type=dossierFile`);
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <h2 className="empty-title">{t("Petitions.empty")}</h2>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </NavMenu>
  );
};

export default PetitionFilesDocumentation;
