import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { AddressForm, Button, Loading } from '../../../components';
import { DisplayUtils } from '../../../utils';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { getRepresentativeData, saveRepresentativeData } from '../../../services/petitionServices';
import { 
    getParametricsAddressType,
    getParametricsCountries,
    getParametricsProvinces
} from '../../../services/parametricsService';
import './RepresentativeData.scss';

const RepresentativeData = () => {
  
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const userType = location?.state?.userType
    const petitionId = location?.state?.petitionId;
    const petitionHasAccount = location?.state?.petitionHasAccount;
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addressTypes, setAddressTypes] = useState();
    const [countries, setCountries] = useState();
    const [provinces, setProvinces] = useState();
    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    useEffect(() => {
        if(!location?.state?.representativeData || !userType || !petitionId || petitionHasAccount === undefined) {
            history.goBack()
        } else {
            setShow(true)
        }
    }, []) //eslint-disable-line

    const fetchData = async(data) => {
        try {
            setLoading(true);
            const addressTypes = await getParametricsAddressType();
            const addressType = addressTypes?.find(type => type.roadTypeId === data?.address?.roadTypeId)
            setAddressTypes(addressTypes);
            const countries = await getParametricsCountries();
            const country = countries?.find(country => country.countryId === data?.address?.countryId)
            setCountries(countries);
            const provinces = await getParametricsProvinces();
            const province = provinces?.find(province => province.provinceId === data?.address?.provinceId)
            setProvinces(provinces);
            reset({
                name: `${data?.representative?.name || ''} ${data?.representative?.firstSurname || ''} ${data?.representative?.secondSurname || ''}` || '',
                dni: data?.representative?.nif || '',
                addressType: addressType?.name || '',
                addressTypeId: data?.address?.roadTypeId || '',
                address: data?.address?.roadNameAddress || '',
                number: data?.address?.numberAddress || '',
                bloq: data?.address?.blockAddress || '',
                stair: data?.address?.levelAddress || '',
                floor: data?.address?.floorAddress || '',
                door: data?.address?.doorAddress || '',
                location: data?.address?.locality || '',
                locationId: data?.address?.localityId || '',
                province: province?.name || '',
                provinceId: data?.address?.provinceId || '',
                postalCode: data?.address?.postalCode || '',
                postalCodeId: data?.address?.postalCodeId || '',
                country: country?.name || '',
                countryId: data?.address?.countryId || ''
            })
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    }

    const getRepreData = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getRepresentativeData(convocationId, userType.toUpperCase(), petitionId);
            if(data) {
                await fetchData(data);
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [showError, convocationId]); // eslint-disable-line

    useEffect(() => {
        convocationId && getRepreData();
    }, [convocationId]) //eslint-disable-line

    const onSubmit = async (data) => {
        const finalData = {
            address: {
                roadTypeId: data.addressTypeId,
                roadNameAddress: data.address,
                numberAddress: `${data.number}`,
                blockAddress: data.bloq === '' ? null : data.bloq,
                levelAddress: data.stair === '' ? null : data.stair,
                floorAddress: data.floor === '' ? null : data.floor,
                doorAddress: data.door === '' ? null : data.door,
                countryId: data.countryId,
                provinceId: data.provinceId === '' ? null : data.provinceId,
                localityId: data.locationId === '' ? null : data.locationId,
                postalCodeId: data.postalCodeId === '' ? null : data.postalCodeId,
                locality: (!data.locationId || data.locationId === '') ? (data.location === '' ? null : data.location) : null,
                postalCode: (!data.postalCodeId || data.postalCodeId === '') ? (data.postalCode === '' ? null : data.postalCode) : null
            }
        }
        try {
            setLoading(true);
            await saveRepresentativeData(convocationId, userType.toUpperCase(), petitionId, finalData);
            history.push({ pathname: `/${userType}/join/beneficiary-data`, state: { beneficiaryData: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
                <div className='RepresentativeData'>
                    <div className="w-100 d-flex justify-content-center">
                        {!DisplayUtils.isMobile() ? (
                            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="RequestInfo__logo" />
                        ) : (
                            <div className="RepresentativeData__title">
                                <Button
                                    icon="/misc/icons/arrow-left-blue.svg"
                                    action={() => history.push({ pathname: `/${userType}/join/automated-queries`, state: { automatedQueries: true, userType: userType, petitionId: petitionId, petitionHasAccount } })}
                                    className="Button__transparent"
                                />
                                <h6>{t('Join.representativeData.head')}</h6>
                            </div>
                        )}
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="RepresentativeData__container">
                            {!DisplayUtils.isMobile() && (
                                <div className="RepresentativeData__container__head">
                                    <Button
                                        icon="/misc/icons/arrow-left-pink.svg"
                                        action={() => history.push({ pathname: `/${userType}/join/automated-queries`, state: { automatedQueries: true, userType: userType, petitionId: petitionId, petitionHasAccount } })}
                                        className="Button__transparent"
                                        styles={{width: '50px'}}
                                    />
                                    <h6>{t('Join.representativeData.head')}</h6>
                                </div>
                            )}
                            <div className="RepresentativeData__container__form">
                                <form onSubmit={handleSubmit(onSubmit/*, onInvalid*/)}>
                                    <h6 className="RepresentativeData__container__form__title">{t('Join.representativeData.dataTitle')}</h6>
                                    <label>{t('Join.representativeData.name')}</label>
                                    <div className="RepresentativeData__container__form__box">
                                        <p className="RepresentativeData__container__form__disabled">{getValues('name')}</p>
                                        <img src="/misc/icons/user-pink.png" alt="icon" style={{ width: '20px' }} />
                                    </div>
                                    {errors.name && (
                                        <p className="RepresentativeData__container__form__error">{errors.name.message}</p>
                                    )}
                                    <label>{t('Join.representativeData.dni')}</label>
                                    <div className="RepresentativeData__container__form__box">
                                        <p className="RepresentativeData__container__form__disabled">{getValues('dni')}</p>
                                        <img src="/misc/icons/card-pink.png" alt="icon" style={{ width: '20px' }} />
                                    </div>
                                    {errors.dni && errors.dni.type === 'validate' && (
                                        <p className="RepresentativeData__container__form__error">{t("Errors.invalidNif")}</p>
                                    )}
                                    {errors.dni && (
                                        <p className="RepresentativeData__container__form__error">{errors.dni.message}</p>
                                    )}
                                    <div className='mt-5'>
                                        <AddressForm 
                                            title={t('Join.representativeData.addressTitle')}
                                            errors={errors} 
                                            register={register}
                                            control={control}
                                            setValue={setValue}
                                            getValues={getValues}
                                            clearErrors={clearErrors}
                                            addressTypes={addressTypes}
                                            countries={countries}
                                            provinces={provinces}/>
                                    </div>
                                    <p className='m-0 mt-5 mb-4' dangerouslySetInnerHTML={{ __html: t('Globals.noOptional') }} />
                                    <div className="ClaveVerification__container__actions">
                                        <Button
                                            className="buttonPrimaryLarge"
                                            text={t('Join.representativeData.later')}
                                            styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                                            action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                                        />
                                        <Button
                                            className="buttonPrimaryLarge"
                                            text={t('Join.representativeData.action')}
                                            styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                            type="input"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    );
};

export default RepresentativeData;