import { useState, useCallback } from 'react';
import { Loading, NavMenu } from '../../components';
import { DisplayUtils, DateUtils } from '../../utils';
import { useErrorsContext } from '../../contexts/ErrorsContext.js';
import { useAuthContext } from '../../contexts/AuthContext';
import { resetPassword } from '../../services/authServices';
// import { getUserPetition } from '../../services/petitionServices';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Profile.scss';
import { useParametrics } from '../../contexts/ParametricsContext.js';

const Profile = () => {
  const { t } = useTranslation();
  const { showError } = useErrorsContext();
  const { user } = useAuthContext();
  const { convocationId } = useParametrics();
  // const [petition, setPetition] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onPassReset = useCallback(async () => {
    try {
      setLoading(true);
      await resetPassword({
        convocationId: convocationId,
        email: user.email,
      });
      history.push({ pathname: '/reset-password/success', state: { redirect: true, profile: true } });
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }, []); // eslint-disable-line

  const Box = ({ title, icon, action, padding }) => (
    <div className={`${DisplayUtils.isMobile() ? 'col-6' : 'col-6 '} ${padding} mt-3 mr-0 justify-content-center d-flex`} onClick={action} style={{ cursor: 'pointer' }}>
      <div className={`Profile__content__box__boxes__contain`} >
        <img src={icon} alt="shop-icon" className="Profile__content__box__boxes__contain__icon" />
        <span>{title}</span>
      </div>
    </div>
  );

  // const showItems = StatusUtils.showProfileOptions(petition?.petitionStatus);

  const getFlag = () => {
    if(user && user.language) {
      const lang = user.language;
      console.log(lang);
      switch(lang) {
        case 'es':
          return '/misc/icons/es.png';
        case 'ca':
          return '/misc/icons/ca.png';
        case 'va':
          return '/misc/icons/valenciano.svg';
        case 'ga':
          return '/misc/icons/gallego.svg';
        case 'eu':
          return '/misc/icons/euskera.svg';
        case 'ar':
          return '/misc/icons/aranes.svg';
        default:
          return '/misc/icons/es.png'
      }
    }
    return '/misc/icons/es.png'
  }

  // const getBirthDate = value => {
  //   if(value.includes("/")) return value
  //   else DateUtils.getFormatedDate(petition?.birthDate?.value)
  // }

  return (
    <NavMenu>
      <div className="Profile">
        <div className="Profile__content">
          {loading ? (
            <div className='vh-100 d-flex justify-content-center align-items-center'>
              <Loading />
            </div>
          ) : (
            <>
              <div className="w-100 d-flex justify-content-center">
                <div className="Profile__content__title">
                  <h6>{t('Profile.title')}</h6>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center">
                <div className="Profile__content__box">
                  <div className="Profile__content__box__info">
                    <>
                      <img
                        src="/misc/icons/Avatar_perfil.png"
                        alt="shop-icon"
                        className="Profile__content__box__info__icon"
                      />
                      <h6>{user?.fullName}</h6>
                      <span>{user?.email}</span>
                      <span>{t('Profile.registerDate')}: {DateUtils.getFormatedDate(user?.creationTime)}</span>
                      {/* 
                      <h6>{user?.email}</h6>
                      {showItems && (
                        <>
                          {petition?.name?.value && <span>{petition?.name?.value || user?.email}</span>}
                          {petition?.dni?.value && <span>{petition?.dni?.value}</span>}
                          {petition?.birthDate?.value && <span>{getBirthDate(petition?.birthDate?.value)}</span>}
                          {petition?.phoneNumber?.value && <span>{petition?.phoneNumber?.value}</span>}
                          {(petition?.representativeDni?.value && petition?.representativeName?.value) && (
                            <>
                              <h6 style={{ marginTop: '12px' }}>Representante</h6>
                              {petition?.representativeName?.value && <span>{petition?.representativeName?.value}</span>}
                              {petition?.representativeDni?.value && <span>{petition?.representativeDni?.value}</span>}
                            </>
                          )}
                        </>
                      )} 
                      */}
                    </>
                  </div>
                  <div className="Profile__content__box__boxes row m-0 justify-content-center">
                    {
                      user.email && (
                        <Box
                          title={t('Profile.passReset')}
                          icon="/misc/icons/passReset.png"
                          action={onPassReset}
                          padding={DisplayUtils.isMobile() ? 'pl-0' : 'p-0'}
                        />
                      )
                    }
                    <Box
                      title={t('Profile.language')}
                      icon={getFlag()}
                      action={() => history.push('/profile/language')}
                      padding={DisplayUtils.isMobile() ? 'pr-0' : 'p-0'}
                    />
                    {/* {showItems && (
                      <>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </NavMenu>
  );
};

export default Profile;
