import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayUtils } from '../../utils';
import { NavMenu, Loading } from '../../components';
import './Transactions.scss';
import WalletCard from '../../components/transactions/walletCard/WalletCard';
import GlobalCard from '../../components/transactions/globalCard/GlobalCard';
import { useErrorsContext } from '../../contexts/ErrorsContext';
import { getCard } from '../../services/transactionsService';
import { useHistory, useParams } from 'react-router-dom';
import { useParametrics } from '../../contexts/ParametricsContext';

const Transactions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { convocationId } = useParametrics();
  const [loading, setLoading] = useState(true);
  const [cardholderId] = useState(null);
  const [list, setList] = useState([]);
  const [globalBalance, setGlobalBalance] = useState(0);
  const [cardStatus, setCardStatus] = useState('');
  const {showError} = useErrorsContext();

  const { petitionId } = useParams();

  const _getBalances = async () => {
    try {
      setLoading(true);
      // const resp = await getCardholderId();
      // setCardholderId(resp.cardHolderId);
      //const balances = await getBalances(resp.cardHolderId);
      const cardInfo = await getCard(convocationId, petitionId);
      const items = [
        {
          id: 'LIVE_ARTS',
          image: '/misc/icons/transactions/eventos-culturales.svg', 
          title: 'Eventos culturales', 
          balance: (cardInfo["balance"]?.["wallets"].find(b => b['wallet'] === 'LIVE_ARTS')?.['amount']?.['available'] ?? 0)
        },
        {
          id: 'DIGITAL_SUPPORT',
          image: '/misc/icons/transactions/consumo-digital.svg', 
          title: 'Consumo digital', 
          balance: (cardInfo["balance"]?.["wallets"].find(b => b['wallet'] === 'DIGITAL_SUPPORT')?.['amount']?.['available'] ?? 0)
        },
        {
          id: 'PHYSICAL_SUPPORT',
          image: '/misc/icons/transactions/productos-fisicos.svg', 
          title: 'Productos físicos', 
          balance: (cardInfo["balance"]?.["wallets"].find(b => b['wallet'] === 'PHYSICAL_SUPPORT')?.['amount']?.['available'] ?? 0)
        },
      ];
      setList(items);
      setGlobalBalance(items.reduce((val, item) => item.balance + val, 0));
      setCardStatus(cardInfo['status']['statusDescription']);
    } catch(e) {
      showError(e);
    } finally {
      setLoading(false);
    } 
  };

  useEffect(() => {
    if (!convocationId) return;
    if (!petitionId) return history.goBack();
    setShow(true);
    _getBalances()
  }, [convocationId]) // eslint-disable-line

  return (
    <NavMenu>
      {show && (loading ? (
        <Loading />
      ) : (
        <div className="Transactions">
          <div className="Transactions__content">
            {!DisplayUtils.isMobile() && <div className="Transactions__content__backGrey" />}
            <div className="w-100 d-flex justify-content-center">
              <div className="header">
                <img src='/misc/icons/arrow-left-blue.svg' alt='' onClick={() => {
                  history.goBack();
                }}/>
                <h6>{t('Transactions.title')} </h6>
              </div>
            </div>
            <div className='Transactions__content__global'>
              <GlobalCard
                balance={globalBalance}
                statusTitle={cardStatus}
              />
            </div>
            <div className="Transactions__content__list">
              <div className="Transactions__content__list__title">{t('Transactions.myWallets')}</div>
              {!list || list.length < 1 ? (
                <div className="Transactions__content__list__empty">
                  <img src="/misc/icons/transactions-empty.png" alt="icon" />
                  <span>{t('Transactions.noData')}</span>
                </div>
              ) : (
                list?.map((item, index) => <WalletCard 
                  key={index} 
                  id={item.id}
                  image={item.image}
                  title={item.title}
                  balance={item.balance}
                  cardholderId={cardholderId}
                  petitionId={petitionId}
                />)
              )}
            </div>
          </div>
        </div>
      ))}
    </NavMenu>
  );
};

export default Transactions;
