import { Link, useHistory } from 'react-router-dom';
import { InfoBoxes, NavMenu } from '../../components';
import { useTranslation } from 'react-i18next';
import { DisplayUtils } from '../../utils';
import './Information.scss';
import { useParametrics } from '../../contexts/ParametricsContext';

const Information = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { convocationYear } = useParametrics();

  return (
    <NavMenu>
      <div className="Information">
        <div className="Information__content">
          <div className="w-100 d-flex justify-content-center">
            <div className="Information__content__title">
              <h6>{t('Info.bono')}</h6>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div className="Information__content__box">
              <InfoBoxes
                image="/misc/icons/list-info-pink.png"
                title={t('Info.downloads.title')}
                color={'white'}
                action={() => history.push({ pathname: '/info', state: { step: 6 } })}
              />
              <InfoBoxes
                image="/misc/icons/list-videos-red.png"
                title={t('Info.tutorials.title')}
                color={'white'}
                action={() => history.push({ pathname: '/info', state: { step: 7 } })}
              />
              <InfoBoxes
                image="/misc/icons/block-info.png"
                title={t('Info.info.title')}
                color={'white'}
                action={() => history.push({ pathname: '/info', state: { step: 1 } })}
              />
              <InfoBoxes
                image="/misc/icons/list-info.png"
                title={t('Info.program.title')}
                color={'white'}
                action={() => history.push({ pathname: '/info', state: { step: 2 } })}
              />
              <InfoBoxes
                image="/misc/icons/view-list.png"
                title={t('Info.carac.title')}
                color={'white'}
                action={() => history.push({ pathname: '/info', state: { step: 3 } })}
              />
              <InfoBoxes
                image="/misc/icons/view-list.png"
                title={t('Info.faq.title')}
                color={'white'}
                action={() => history.push({ pathname: '/info', state: { step: 4 } })}
              />
              <InfoBoxes
                image="/misc/icons/list-info-pink.png"
                title={t('Info.cond.title')}
                color={'white'}
                action={() => history.push({ pathname: '/info', state: { step: 5 } })}
              />
              <InfoBoxes
                image="/misc/icons/list-info-pink.png"
                title={t('Info.privacy.title')}
                color={'white'}
                action={() => history.push({ pathname: '/privacy' })}
              />
              {DisplayUtils.isMobile() && (
                <div className="Info__container__more">
                  <div className="Info__container__more__line"></div>
                  <span className="Info__container__more__title">{t('Info.more')}</span>
                  <div className="Info__container__more__line"></div>
                </div>
              )}
              <div className="Information__content__box__container">
                <div className="Info__container__socials">
                  <Link
                    to={{ pathname: 'https://facebook.com/BonoCulturaJoven/' }}
                    target="_blank"
                  >
                    <img
                      src="/misc/icons/facebook.png"
                      className="Info__container__socials__img"
                      alt="social"
                    />
                  </Link>
                  <Link to={{ pathname: 'https://twitter.com/BonoCultural' }} target="_blank">
                    <img
                      src="/misc/icons/twitter.png"
                      className="Info__container__socials__img"
                      alt="social"
                    />
                  </Link>
                  <Link to={{ pathname: 'https://instagram.com/bonoculturaljoven/' }} target="_blank">
                    <img
                      src="/misc/icons/instagram.png"
                      className="Info__container__socials__img"
                      alt="social"
                    />
                  </Link>
                  <Link to={{ pathname: 'https://tiktok.com/@culturagob' }} target="_blank">
                    <img
                      src="/misc/icons/tik-tok.png"
                      className="Info__container__socials__img"
                      alt="social"
                    />
                  </Link>
                  <Link
                    to={{ pathname: 'https://www.youtube.com/c/MinisteriodeCulturayDeporte' }}
                    target="_blank"
                  >
                    <img
                      src="/misc/icons/youtube.png"
                      className="Info__container__socials__img"
                      alt="social"
                    />
                  </Link>
                  <Link
                    to={{ pathname: 'https://www.linkedin.com/company/ministerio-de-culturaydeporte' }}
                    target="_blank"
                  >
                    <img
                      src="/misc/icons/linkedin.svg"
                      className="Info__container__socials__img"
                      alt="social"
                    />
                  </Link>
                </div>
                <div
                  className="d-flex justify-content-center flex-column align-items-center"
                  style={{ marginBottom: DisplayUtils.isMobile() ? '' : '55px' }}
                >
                  <h6 className="Information__content__box__container__title">{t('Info.title')}</h6>
                  {/* <p dangerouslySetInnerHTML={{ __html: t('Info.applyInfo') }} className="Init__content__box__buttons__infoTime" /> */}
                  {convocationYear === '2023' && (<p dangerouslySetInnerHTML={{ __html: t('Info.correosInfo') }} className="Init__content__box__buttons__infoTime" />)}
                  <p dangerouslySetInnerHTML={{ __html: t('Info.fnmtInfo') }} className="Init__content__box__buttons__infoTime" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavMenu>
  );
};

export default Information;
