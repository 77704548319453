import  { useEffect, useState } from 'react';
import { Button } from '../../../components';
import { DisplayUtils, constants } from '../../../utils';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import './TransactionsFilters.scss';

const TransactionsFilters = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [filters, setFilters] = useLocalStorage(constants.FILTERS, null);
    const [show, setShow] = useState(true);
    const [fromDate, setFromDate] = useState(filters?.fromDate && new Date(filters?.fromDate));
    const [toDate, setToDate] = useState(filters?.toDate && new Date(filters?.toDate));

    useEffect(() => {
        if (!location?.state?.filters) {
            history.goBack()
        } else {
            setShow(true)
        }
    }, []) //eslint-disable-line

    const clearFilters = () => {
        localStorage.removeItem(constants.FILTERS);
        history.goBack();
    }

    const applyFilters = () => {
        const filters = {
            fromDate,
            toDate
        }
        setFilters(filters);
        history.goBack();
    }

    return (
        show && (
            <div className='TransactionsFilters'>
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="TransactionsFilters__logo" />
                    ) : (
                        <div className="TransactionsFilters__title">
                            <Button
                                icon="/misc/icons/arrow-left-blue.svg"
                                action={() => history.goBack()}
                                className="Button__transparent"
                            />
                            <h6>{t('Transactions.filters.head')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="TransactionsFilters__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="TransactionsFilters__container__head">
                                <Button
                                    icon="/misc/icons/close-pink.png"
                                    action={() => history.goBack()}
                                    className="Button__transparent"
                                    styles={{ width: '50px' }}
                                />
                                <h6>{t('Transactions.filters.head')}</h6>
                            </div>
                        )}
                        <div className="TransactionsFilters__container__container-filters">
                            <span className="TransactionsFilters__container__container-filters__title">{t('Transactions.filters.dateTitle')}</span>
                            <span className="TransactionsFilters__container__container-filters__label">{t('Transactions.filters.fromLabel')}</span>
                            <DatePicker
                                value={fromDate}
                                onChange={(value) => setFromDate(value)}
                                clearIcon={null}
                                format="dd/MM/yyyy"
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                yearPlaceholder="YYYY"
                                maxDate={toDate ? new Date(toDate) : new Date()}
                            />
                            <span className="TransactionsFilters__container__container-filters__label">{t('Transactions.filters.toLabel')}</span>
                            <DatePicker
                                value={toDate}
                                onChange={(value) => setToDate(value)}
                                clearIcon={null}
                                format="dd/MM/yyyy"
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                yearPlaceholder="YYYY"
                                minDate={fromDate ? new Date(fromDate) : undefined}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="TransactionsFilters__container__actions">
                            <Button
                                className="buttonPrimaryLarge"
                                text={t('Transactions.filters.clear')}
                                styles={{ backgroundColor: `${DisplayUtils.isMobile() ? '#4b4b4b40' : '#f4f5fb'}`, color: '#312d6b' }}
                                action={() => clearFilters()}
                            />
                            <Button
                                className="buttonPrimaryLarge"
                                text={t('Globals.apply')}
                                styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                action={() => applyFilters()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default TransactionsFilters;