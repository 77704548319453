import http from "./base-http-service";

// eslint-disable-next-line no-undef
const MERCHANT_API_URL = process.env.REACT_APP_MERCHANT_API_URL;

export const getShops = (data) => http.post('https://sgw.bonoculturajoven.gob.es/petitions-api/merchants/simple/map', data); //sample
export const getPublicShops = (data) => http.post('https://sgw.bonoculturajoven.gob.es/petitions-api/merchants/public', data); //sample

export const fetchClusters = async (searchParams) => {
  return await fetch(`${MERCHANT_API_URL}merchant-api/map/v1/salespoints/clusters?${searchParams}`, {
    method: 'GET',  
  });
}
export const fetchSalesPoints = async (clusterKey, searchParams) => {
  return await fetch(`${MERCHANT_API_URL}merchant-api/map/v1/salespoints/clusters/${clusterKey}?${searchParams}`, {
    method: 'GET',  
  });
}
export const getOnlineShops = async (searchParams) => {
  return await fetch(`${MERCHANT_API_URL}merchant-api/map/v1/salespoints/online?${searchParams}`, {
    method: 'GET',
  });
}
