import { useTranslation } from "react-i18next";
import { DisplayUtils } from "../../utils";
import { NavMenu } from "../../components";


export const HowWorks = () => {
  const { t } = useTranslation();

  return (
    <NavMenu>
      <div className="Info">
        <div className="w-100 d-flex justify-content-center">
          {!DisplayUtils.isMobile() ? (
            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="Info__logo" />
          ) : (
            <div className="Info__title">
              <h6>{t('HowWorks.title')}</h6>
            </div>
          )}
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="Info__container">
            {!DisplayUtils.isMobile() && (
              <div className="Info__container__title">
                <h6>{t('HowWorks.title')}</h6>
              </div>
            )}
            <div className="Info__container__data">
            <p dangerouslySetInnerHTML={{ __html: t('HowWorks.description') }} />
          </div>
          </div>
        </div>
      </div>
    </NavMenu>
  );
};