import axios from 'axios';
import { DateUtils, constants } from '../utils/index';
import { refreshToken } from './authServices';

// eslint-disable-next-line no-undef
export const API_URL = process.env.REACT_APP_API_URL;


const http = axios.create({
  baseURL: API_URL,
  headers: {
    withCredentials: true,
  },
});

export const convertLanguageToApiFormat = (lang) => {
  if(lang === 'ga') return 'gl';
  return lang;
}

http.interceptors.request.use(
  async (config) => {
    if(localStorage.getItem(constants.CURRENT_TOKEN_KEY)) {
      const headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        'Accept-Language': localStorage.getItem(convertLanguageToApiFormat(constants.CURRENT_LANGUAGE_KEY) ?? 'es'),
      };
      if(!config.url.includes('public/') && !config.url.includes("/simple/map") && !config.url.includes("merchants/merchants-online")) { // TO REMOVE, this is for sample
      //if(!config.url.includes('public/')) {
        headers['Authorization'] = `Bearer ${localStorage.getItem(constants.CURRENT_TOKEN_KEY)}`
      }
      config.headers = headers;
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        'Accept-Language': localStorage.getItem(convertLanguageToApiFormat(constants.CURRENT_LANGUAGE_KEY) ?? 'es'),
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    localStorage.setItem('lastApiCallDate', new Date().toISOString());
    return response.data;
  },
  async (error) => {
    const user = JSON.parse(localStorage.getItem(constants.CURRENT_USER_KEY));
    const _refreshToken = localStorage.getItem(constants.CURRENT_REFRESH_KEY);
    const originalRequest = error.config;
    const url = originalRequest?.url;
    const lastApiCallDate = localStorage.getItem('lastApiCallDate');
    let minutes = 100000;
    if(lastApiCallDate) {
      minutes = DateUtils.getMinutesBetweenDates(new Date(), new Date(lastApiCallDate));
    }
    if (!url.includes(constants.REFRESH_TOKEN_URL) && error.response.status === 401 && originalRequest && !originalRequest.__isRetryRequest) {
      if(user && (minutes < 60 || localStorage.getItem(constants.KEEP_SESSION)) ) {
        try {
          const resp = await refreshToken({
            refreshToken: _refreshToken, email: user.email
          });
          localStorage.setItem(constants.CURRENT_TOKEN_KEY, resp.idToken);
          error.config.__isRetryRequest = true
          error.config.headers.Authorization = `Bearer ${resp.idToken}`;
          return http(error.config);
        } catch (e) { /* empty */ }
      }
      localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
      localStorage.removeItem(constants.CURRENT_REFRESH_KEY);
      localStorage.removeItem(constants.CURRENT_ID_TOKEN_KEY);
      localStorage.removeItem(constants.CURRENT_USER_KEY);
      localStorage.removeItem(constants.CURRENT_PETITON_STATUS_KEY);
      localStorage.removeItem(constants.KEEP_SESSION);
      window.location.assign('/');
      return Promise.reject('relogin');
    } else {
      return Promise.reject(error.response.data);
    }
  }
);

export default http;
