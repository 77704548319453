import { useGeolocationContext } from '../../../../contexts/GeolocationContext';
import './ShopSquareItem.scss';

/**
 * 
 * @param {{
 *  shop: ShopModel, 
 *  onClick: Function,
 * }} props 
 * @returns 
 */
const ShopSquareItem = ({ shop, onClick }) => {

  const geolocation = useGeolocationContext();

  const getDistance = (localLat, localLong) => {
    if(geolocation?.position) {
      const myLat = geolocation.position.lat;
      const myLong = geolocation.position.lng;
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(localLat-myLat); // deg2rad below
      const dLon = deg2rad(localLong-myLong);
      const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(myLat)) * Math.cos(deg2rad(localLat)) * Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      const d = R * c; // Distance in km

      return `A ${d.toFixed(2)}Km`
    }

    return ""
  }

  const deg2rad = (deg) => {
    return deg * (Math.PI/180)
  }

  return (
    <div className="ShopSquareItem" onClick={onClick}>
      <div className="ShopSquareItem__image">
        <img alt='icon' src={shop.image || 'misc/icons/logo-bono.png'} />
      </div>
      <div className="ShopSquareItem__info">
        <div className="ShopSquareItem__info__name">
          {shop?.localName}
        </div>
        <div className="ShopSquareItem__info__distance">
          {getDistance(shop?.latitude, shop?.longitude)}
        </div>
        <div className="ShopSquareItem__info__address">
          {shop?.location}
        </div>
        {/* {shop?.categoryTypes?.map((item, index) => (
          <span className="ShopSquareItem__info__types d-block" key={index}>
            - {t(`Globals.merchantType.${item.trim()}`)}.
          </span>
        ))} */}
      </div>
    </div>
  );
}

export default ShopSquareItem;