import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import './ChangeEmailNew.scss';
import { constants, DisplayUtils } from '../../../../../utils';
import { Button, Loading, NavMenu } from '../../../../../components';
import { useErrorsContext } from '../../../../../contexts/ErrorsContext';
import { useParametrics } from '../../../../../contexts/ParametricsContext';
import { getChangeEmailProcedure, saveChangeEmailProcedure } from '../../../../../services/procedureServices';
import { useForm } from 'react-hook-form';

const ChangeEmailNew = () => {

    const { t } = useTranslation();
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const location = useLocation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [observations, setObservations] = useState('');
    const [loading, setLoading] = useState(false);
    const newEmail = useRef();
    const confirmEmail = useRef();

    const { petitionId } = useParams();

    const procedureId = location?.state?.procedureId;
    const petition = location?.state?.petition;
    const fromSignProcedure = location?.state.fromSignProcedure;

    const {
      register,
      handleSubmit,
      trigger,
      watch,
      setValue,
      formState: { errors },
    } = useForm({ mode: 'onChange', defaultValues: { newEmail: '', confirmEmail: '' } });

    newEmail.current = watch('newEmail', '');
    confirmEmail.current = watch('confirmEmail', '');

    const getProcedure = async () => {
      try {
        setLoading(true);
        const resp = await getChangeEmailProcedure(petitionId, procedureId);
        if (!fromSignProcedure && resp.status === 'DATA_COMPLETED') {
          goToSignProcedure();
        }
        setValue('newEmail', resp.newEmail);
        setValue('confirmEmail', resp.newEmail);
        setObservations(resp.observations);
        setLoading(false);
      } catch (e) {
        showError(e);
        history.goBack();
      }
    }

    useEffect(() => {
      if(!convocationId) return;
      if(!location?.state?.changeEmail || !procedureId || !petitionId || !petition) {
          history.goBack()
      } else {
        getProcedure();
        setShow(true);
      }
    }, [convocationId])

    const onSubmit = async (data) => {
      try {
        setLoading(true);
        await saveChangeEmailProcedure(petitionId, procedureId, {
          newEmail: data.newEmail,
          observations,
        });
        goToSignProcedure();
      } catch (e) {
        console.log(e);
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    const handleCopyCutPaste = (event) => {
      event.preventDefault();
    };

    const goToSignProcedure = () => {
      history.replace('/sign-procedure', {
        signProcedure: true,
        procedureId,
        petitionId,
        userType: petition.accountType,
        requestType: 'CHANGE_EMAIL_REQUEST',
        fileType: 'CHANGE_EMAIL_REQUEST',
        previousPageUrl: `/petitions/${petitionId}/change-email/new`,
        nextPageUrl: `/petitions/${petitionId}/change-email/${procedureId}/request-success`,
        previousPageState: {
          changeEmail: true, procedureId, petition,
        }
      });
    }
    
    return (
        show && (
          loading ? (
            <div className='vw-100 vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
          <NavMenu>
            <div className='ChangeEmailNew'>
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="ChangeEmailNew__logo" />
                    ) : (
                        <div className="ChangeEmailNew__title">
                            <Button
                                icon="/misc/icons/close-pink.png"
                                action={() => history.goBack()}
                                className="Button__transparent"
                            />
                            <h6>{t('RequestStatus.changeEmail.head')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="ChangeEmailNew__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="ChangeEmailNew__container__head">
                                <Button
                                    icon="/misc/icons/close-pink.png"
                                    action={() => history.goBack()}
                                    className="Button__transparent"
                                    styles={{width: '50px'}}
                                />
                                <h6>{t('RequestStatus.changeEmail.head')}</h6>
                            </div>
                        )}
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="ChangeEmailNew__container__content">
                              <span className="ChangeEmailNew__container__content__description">{t('RequestStatus.changeEmail.description')}</span>
                              <div className="ChangeEmailNew__container__content__applicant-observations">
                                <span className='ChangeEmailNew__container__content__document__title'>{t('RequestStatus.changeEmail.newEmail')}<b style={{"color": "#f838f9"}}>*</b></span>
                                <input 
                                  type="text"
                                  placeholder={t('RequestStatus.changeEmail.newEmailPlaceholder')}
                                  onBlur={() => trigger('confirmEmail')}
                                  onCopy={handleCopyCutPaste}
                                  onCut={handleCopyCutPaste}
                                  onPaste={handleCopyCutPaste}
                                  {...register('newEmail', {
                                    required: t('Globals.required'),
                                    validate: (value) =>
                                      constants.EMAIL_PATTERN.test(value) || t('Errors.notValid'),
                                  })}
                                />
                                {errors.newEmail && (
                                  <p className="Signup__container__form__error">{errors.newEmail.message}</p>
                                )}
                              </div>
                              <div className="ChangeEmailNew__container__content__applicant-observations">
                                <span className='ChangeEmailNew__container__content__document__title'>{t('RequestStatus.changeEmail.confirmEmail')}<b style={{"color": "#f838f9"}}>*</b></span>
                                <input 
                                  type="text"
                                  placeholder={t('Signup.confirmEmail.placeholder')}
                                  onBlur={() => trigger('newEmail')}
                                  onCopy={handleCopyCutPaste}
                                  onCut={handleCopyCutPaste}
                                  onPaste={handleCopyCutPaste}
                                  {...register('confirmEmail', {
                                    required: t('Globals.required'),
                                    validate: (value) => {
                                      return value === newEmail.current || t('Errors.emailNotEqual');
                                    },
                                  })}
                                />
                                {errors.confirmEmail && (
                                  <p className="Signup__container__form__error">{errors.confirmEmail.message}</p>
                                )}
                              </div>
                              <div className="ChangeEmailNew__container__content__applicant-observations">
                                <span className='ChangeEmailNew__container__content__document__title'>{t('RequestStatus.changeEmail.observations')}</span>
                                <textarea 
                                  rows={4} 
                                  value={observations} 
                                  placeholder={t('RequestStatus.changeEmail.observationsPlaceholder')}
                                  onChange={(event) => {
                                    setObservations(event.target.value)
                                  }}
                                />
                              </div>

                              <div className="ChangeEmailNew__container__content__actions">
                                  <Button
                                      className="buttonPrimaryLarge"
                                      text={t('RequestStatus.changeEmail.send')}
                                      styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                      type="submit"
                                  />
                              </div>
                              <span className="ChangeEmailNew__container__content__skip" onClick={() => history.goBack()}>
                                  {t('RequestStatus.changeEmail.skip')}
                              </span>
                          </div>
                        </form>
                    </div>
                </div>
            </div>
          </NavMenu>
        )
      )
    )
}

export default ChangeEmailNew;