import './Steps.scss';

const Steps = ({ step, onChangeStep, list, color }) => (
  <div className="Steps col-4 p-0">
    {list.map((item) => (
      <div
        key={item}
        onClick={() => onChangeStep(item)}
        style={{backgroundColor: color}}
        className={` ${
          item === step
            ? 'Steps__circle__active'
            : 'Steps__circle'
        }`}
      ></div>
    ))}
  </div>
);

export default Steps;
