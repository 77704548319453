import { addMinutes, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { constants, DisplayUtils } from '../../../../../utils';
import { changeEmailResendCode, changeEmailSendAuthCode, getChangeEmailProcedure } from '../../../../../services/procedureServices';
import { Button, Loading, NavMenu } from '../../../../../components';
import { useErrorsContext } from '../../../../../contexts/ErrorsContext';
import { useParametrics } from '../../../../../contexts/ParametricsContext';
import './ChangeEmailVerification.scss';

const ChangeEmailVerification = () => {

    const { t } = useTranslation();
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const location = useLocation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [expired, setExpired] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [sendCodeSuccess, setSendCodeSuccess] = useState(false);

    const { petitionId } = useParams();

    const procedureId = location?.state?.procedureId;
    const petition = location?.state?.petition;

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({ mode: 'onBlur' });

    const getProcedure = async () => {
      try {
        setLoading(true);
        const procedure = await getChangeEmailProcedure(petitionId, procedureId);
        setNewEmail(procedure.newEmail);
        const expireDate = addMinutes(
          new Date(procedure.authCodeCreationTime),
          procedure.authCodeExpirationMinutes,
        );
        console.log(expireDate);
        setExpired(isBefore(expireDate, new Date()));
        setLoading(false);
      } catch (e) {
        showError(e);
        history.goBack();
      }
    }

    const sendCode = async (data) => {
      try {
        setLoading(true);
        setSendCodeSuccess(false);
        await changeEmailSendAuthCode(petitionId, procedureId, {
          authCode: data.code,
          password: data.password
        });
        history.replace(`/petitions/${petitionId}/change-email/${procedureId}/finish`, {
          ChangeEmailFinish: true
        })
      } catch (e) {
        console.log(e);
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    const resendCode = async () => {
      try {
        setLoading(true);
        setSendCodeSuccess(false);
        await changeEmailResendCode(petitionId, procedureId);
        setExpired(false);
        setSendCodeSuccess(true);
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      if(!convocationId) return;
      if(!location?.state?.changeEmailVerification || !procedureId || !petitionId || !petition) {
          history.goBack()
      } else {
        getProcedure();
        setShow(true);
      }
    }, [convocationId])
    
    return (
        show && (
          loading ? (
            <div className='vw-100 vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
          <NavMenu>
            <div className='ChangeEmailVerification'>
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="ChangeEmailVerification__logo" />
                    ) : (
                        <div className="ChangeEmailVerification__title">
                            <Button
                                icon="/misc/icons/close-pink.png"
                                action={() => history.replace(`/petitions/${petitionId}/change-email/${procedureId}`, {
                                  petition: petition
                                })}
                                className="Button__transparent"
                            />
                            <h6>{t('Petitions.ChangeEmail.verification.title')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="ChangeEmailVerification__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="ChangeEmailVerification__container__head">
                                <Button
                                    icon="/misc/icons/close-pink.png"
                                    action={() => history.replace(`/petitions/${petitionId}/change-email/${procedureId}`, {
                                      petition: petition
                                    })}
                                    className="Button__transparent"
                                    styles={{width: '50px'}}
                                />
                                <h6>{t('Petitions.ChangeEmail.verification.title')}</h6>
                            </div>
                        )}
                        <form onSubmit={handleSubmit(sendCode)}>
                          <div className="ChangeEmailVerification__container__content">
                              <span className="ChangeEmailVerification__container__content__description">{t('Petitions.ChangeEmail.verification.description')}</span>
                              <div className="ChangeEmailVerification__container__content__applicant-observations">
                                <span className='ChangeEmailVerification__container__content__document__title'>{t('Petitions.ChangeEmail.verification.code')}<b style={{"color": "#f838f9"}}>*</b></span>
                                <div className='input-container'>
                                  <input 
                                    autoComplete='off'
                                    name='code'
                                    type='text'
                                    {...register('code', {
                                      required: t('Globals.required'),
                                    })}
                                  />
                                </div>
                                {errors.code && (
                                  <p className="PassResetCode__container__form__error">{errors.code.message}</p>
                                )}
                                {
                                  expired && (
                                    <span className="ChangeEmailVerification__container__content__resend" onClick={() => resendCode()}>
                                        {t('Petitions.ChangeEmail.verification.resendButton')}
                                    </span>
                                  )
                                }
                                {
                                  sendCodeSuccess && (
                                    <span className="ChangeEmailVerification__container__content__resend-success" onClick={() => resendCode()}>
                                        {t('Petitions.ChangeEmail.verification.resendButtonSuccess', {email: newEmail})}
                                    </span>
                                  )
                                }
                              </div>

                              <div className="ChangeEmailVerification__container__content__applicant-observations">
                                <span className='ChangeEmailVerification__container__content__document__title'>{t('Petitions.ChangeEmail.verification.password')}<b style={{"color": "#f838f9"}}>*</b></span>
                                <div className='input-container'>
                                  <input 
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete='off'
                                    name='password'
                                    {...register('password', {
                                      required: t('Globals.required'),
                                      validate: (value) =>
                                        constants.PASSWORD_PATTERN.test(value) || t('Signup.password.invalidFormat'),
                                    })}
                                  />
                                  <img src={showPassword ? '/misc/icons/eye-show.png' : '/misc/icons/eye-hide.png'} alt="icon" onClick={() => setShowPassword(!showPassword)} />
                                </div>
                                {errors.password && (
                                  <p className="PassResetCode__container__form__error">{errors.password.message}</p>
                                )}
                              </div>

                              <div className="ChangeEmailVerification__container__content__actions">
                                  <Button
                                      className="buttonPrimaryLarge"
                                      text={t('Petitions.ChangeEmail.verification.verifyButton')}
                                      styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                      type="submit"
                                  />
                              </div>
                              <span className="ChangeEmailVerification__container__content__skip" onClick={() => history.replace(`/petitions/${petitionId}/change-email/${procedureId}`, {
                                petition: petition
                              })}>
                                  {t('Petitions.ChangeEmail.verification.leaveButton')}
                              </span>
                          </div>
                        </form>
                    </div>
                </div>
            </div>
          </NavMenu>
        )
      )
    )
}

export default ChangeEmailVerification;