import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Loading, Button } from '../../../components';
import { DisplayUtils } from '../../../utils';
import './AutomatedQueries.scss';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { updatePetitionConsultationAuthorization } from '../../../services/petitionServices';

const AutomatedQueries = () => {

  const { t } = useTranslation();
  const { convocationId } = useParametrics();
  const { showError } = useErrorsContext();
  const location = useLocation();
  const history = useHistory();
  const userType = location?.state?.userType;
  const petitionId = location?.state?.petitionId;
  const petitionHasAccount = location?.state?.petitionHasAccount;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const nextStep = async (auth) => {
    try {
      await updatePetitionConsultationAuthorization(
        convocationId, userType.toUpperCase(), petitionId,
        {
          automatedConsultationAuthorization: auth
        }
      );
      if (userType === "representative") {
        history.push({ pathname: `/${userType}/join/representative-data`, state: { representativeData: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
      } else {
        history.push({ pathname: `/${userType}/join/beneficiary-data`, state: { beneficiaryData: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (!convocationId) return;
    if (!location?.state?.automatedQueries || !userType || !petitionId || petitionHasAccount === undefined) {
      history.goBack()
    } else {
      setShow(true);
    }
  }, [convocationId]) //eslint-disable-line

  return (
    show && (
      loading ? (
        <div className='vh-100 d-flex justify-content-center align-items-center'>
          <Loading />
        </div>
      ) : (
        <div className='AutomatedQueries'>
          <div className="w-100 d-flex justify-content-center">
            {!DisplayUtils.isMobile() ? (
              <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="RequestInfo__logo" />
            ) : (
              <div className="AutomatedQueries__title">
                <Button
                  icon="/misc/icons/arrow-left-blue.svg"
                  action={() => { history.push({ pathname: `/${userType}/join/confirm-identity`, state: { confirmIdentity: true, userType: userType, petitionId: petitionId, petitionHasAccount } }) }}
                  className="Button__transparent"
                />
                <h6>{t('Join.automatedQueries.head')}</h6>
              </div>
            )}
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div className="AutomatedQueries__container">
              {!DisplayUtils.isMobile() && (
                <div className="AutomatedQueries__container__head">
                  <Button
                    icon="/misc/icons/arrow-left-pink.svg"
                    action={() => { history.push({ pathname: `/${userType}/join/confirm-identity`, state: { confirmIdentity: true, userType: userType, petitionId: petitionId, petitionHasAccount } }) }}
                    className="Button__transparent"
                    styles={{ width: '50px' }}
                  />
                  <h6>{t('Join.automatedQueries.head')}</h6>
                </div>
              )}
              <p className="AutomatedQueries__container__description" >
                {t('Join.automatedQueries.description1')}
              </p>
              <p className="AutomatedQueries__container__description" >
                {t('Join.automatedQueries.description2')}
              </p>
              <div className="ClaveVerification__container__actions">
                <Button
                  className="buttonPrimaryLarge"
                  text={t('Join.automatedQueries.later')}
                  styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                  action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                />
                <Button
                  className="buttonPrimaryLarge"
                  text={t('Join.automatedQueries.action')}
                  styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                  action={() => {
                    nextStep(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )
    )
  )
}

export default AutomatedQueries;