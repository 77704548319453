
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Loading, NavMenu, TransactionCard } from '../../../components';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { getTransactions } from '../../../services/transactionsService';
import { constants, DateUtils, DisplayUtils } from '../../../utils';
import { formatToCurrency } from '../../../utils/Currency';
import './WalletDetail.scss';
import { useParametrics } from '../../../contexts/ParametricsContext';

const WalletDetail = () => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const {showError} = useErrorsContext();
  const { convocationId } = useParametrics();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [balance, setBalance] = useState(0);
  const [title, setTitle] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [filters] = useLocalStorage(constants.FILTERS, null);

  const { petitionId, wallet } = useParams();

  const generateFiltersObject = (page) => {
    const filtersObject = {
      page,
      wallet: wallet,
    };
    if (filters?.['fromDate']) filtersObject['dateIni'] = DateUtils.formatToServerDateTime(filters['fromDate'], '00:00:00');
    if (filters?.['toDate']) filtersObject['dateEnd'] = DateUtils.formatToServerDateTime(filters['toDate'], '00:00:00');
    if (filters?.['status']) filtersObject['status'] = filters['status'];
    return filtersObject;
  }

  const _getTransactions = async () => {
    try {
      setLoading(true);
      const response = await getTransactions(convocationId, petitionId,
        generateFiltersObject(0),  
      );
      setTransactions(response.content);
      setHasMore(!response.last);
    } catch(e) {
      setHasMore(false);
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  const loadMore = async () => {
    try {
      setLoadingMore(true);
      setPage((page) => page + 1);
      const response = await getTransactions(convocationId, petitionId,
        generateFiltersObject(page+1),
      );
      setHasMore(!response.last);
      setTransactions([
        ...transactions,
        ...response.content,
      ]);
    } catch (e) {
      showError(e);
    } finally {
      setLoadingMore(false);
    }
  }

  useEffect(() => {
    if (!convocationId) return;
    const state = location.state;
    if(!state || !state.title) {
      history.goBack();
      return;
    }
    setShow(true);
    setBalance(state.balance);
    setTitle(state.title);
    _getTransactions();
  }, [convocationId]); // eslint-disable-line

  return (
    <NavMenu>
      {show && (
        loading ? (
          <Loading />
        ) : (
          <div className="WalletDetail">
            {DisplayUtils.isMobile() && (
              <>
                <div className="WalletDetail__content__head">
                  <Button
                      icon="/misc/icons/arrow-left-blue.svg"
                      action={() => history.goBack()}
                      className="Button__transparent"
                      styles={{width: '50px'}}
                  />
                  <h6>{title}</h6>
                  <img
                    className="WalletDetail__content__head__filters"
                    src="/misc/icons/filters.png"
                    alt="filters"
                    onClick={() => {
                      history.push({ pathname: `/petitions/${petitionId}/transactions/${wallet}/filters`, state: { filters: true } });
                    }}
                  />
                </div>
                <div className="WalletDetail__content__balance">
                  <div className='WalletDetail__content__balance__balance-container'>
                    <span>{t('Transactions.youHaveLeft')}</span>
                    <div className="WalletDetail__content__balance__balance-container__amount">
                      {formatToCurrency(balance)}
                    </div>
                    <span>{title}</span>
                  </div>
                </div>
              </>
            )}
            <div className='WalletDetail__content'>
              {!DisplayUtils.isMobile() && (
                <>
                  <div className="WalletDetail__content__backGrey" />
                  <div className="WalletDetail__content__head">
                    <Button
                        icon="/misc/icons/arrow-left-blue.svg"
                        action={() => history.goBack()}
                        className="Button__transparent"
                        styles={{width: '50px'}}
                    />
                    <h6>{title}</h6>
                    <div style={{width: '50px'}}></div>
                  </div>
                  <div className="WalletDetail__content__balance">
                    <div className='WalletDetail__content__balance__balance-container'>
                      <span>{t('Transactions.youHaveLeft')}</span>
                      <div className="WalletDetail__content__balance__balance-container__amount">
                        {formatToCurrency(balance)}
                      </div>
                      <span>{title}</span>
                    </div>
                  </div>
                </>
              )}
              <div className="WalletDetail__content__transactions">
                <div className="WalletDetail__content__transactions__head">
                  <div style={{width: '50px'}}></div>
                  <h4>{t('Transactions.title')}</h4>
                  <img
                    className="WalletDetail__content__transactions__filters"
                    src="/misc/icons/filters.png"
                    alt="filters"
                    onClick={() => {
                      history.push({ pathname: `/petitions/${petitionId}/transactions/${wallet}/filters`, state: { filters: true } });
                    }}
                  />
                </div>
                <div className='WalletDetail__content__transactions__list'>
                  {
                    transactions && transactions.map((t) => (
                      <TransactionCard key={t.id} item={t}/>    
                    ))
                  }
                </div>
                {
                  hasMore && (
                    <div className="WalletDetail__content__transactions__button">
                      <Button
                        disabled={loadingMore}
                        className="buttonPrimaryLarge"
                        text={t('Transactions.loadMore')}
                        action={loadMore}
                        styles={{
                          width: '200px',
                          backgroundColor: '#34fdfe',
                          color:'#381940',
                          marginTop: '16px'
                        }}
                      >
                      </Button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        )
      )}
    </NavMenu>
  );
}

export default WalletDetail;