import { useTranslation } from "react-i18next";
import { DateUtils } from "../../../../../utils";
import './AdditionalDocCard.scss';
import { useHistory } from "react-router-dom";

export const AdditionalDocCard = ({
  addDoc, petition, hasAction = true
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const clickable = hasAction && ['PENDING', 'DATA_COMPLETED', 'REGISTERED'].includes(addDoc.status);
  return (
    <div key={addDoc.id} className="AdditionalDocCard" 
      style={{ cursor: clickable ? 'pointer' : null }}
      onClick={clickable ? () => {
        if (addDoc.status === 'REGISTERED') history.push(`/petitions/${petition.petitionId}/additional-documents/${addDoc.id}`, {
          petition: petition
        })
        else history.push(`/petitions/${petition.petitionId}/additional-documents/new`, {
          additionalDocs: true, procedureId: addDoc.id, petition
        })
      } : null}
    >
      <img 
        className='icon-left'
        src={addDoc.status === 'REGISTERED' ? "/misc/icons/step-ok.png" : "/misc/icons/status-pendingDoc.png"}
      />
      <div className="document-data">
        <div className="document-data-row">
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.status')}</div>
            <div className="document-column-value">{addDoc.statusTitle}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.creationDate')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(addDoc.creationTime)}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{addDoc.status === 'REGISTERED' ? t('Petitions.additionalDocs.additionalDocsList.presentationDate') : t('Petitions.additionalDocs.additionalDocsList.updateTime')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(addDoc.statusTime)}</div>
          </div>
          {
            addDoc.registerNumber && (
              <div className="document-column">
                <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.registerNumber')}</div>
                <div className="document-column-value">{addDoc.registerNumber}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}