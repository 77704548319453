import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Menu } from '../index';
import { constants, DisplayUtils } from '../../utils';

const NavMenu = ({ children }) => {
  const location = useLocation()
  const [menuMobileOpened, setMenuMobileOpened] = useState(undefined);

  const selected = () => {
    let select = location.pathname?.includes('transactions') ? 'transactions' : location.pathname.split('/')[1];
    
    if (select === '') {
      select = constants.MENU_BUTTONS[0].name;
    } else {
      if (select === 'status') select = 'join';
    }
    return select;
  };

  const toggleNav = () => {
    if (menuMobileOpened === 'true') setMenuMobileOpened('false');
    else setMenuMobileOpened('true');
  };
  
  const checkOpenedMenu = () => {
    if (DisplayUtils.isMobile() && menuMobileOpened === 'true') {
      return 'showMenu';
    } else if (DisplayUtils.isMobile() && menuMobileOpened === 'false') {
      return 'hideMenu';
    }
  };

    return (
      <div className="Dashboard">
        <div className={`Dashboard__menu animated ${checkOpenedMenu()}`} id="myMenu">
          <Menu active={selected()} closeMenu={toggleNav} />
        </div>
        <div className="Dashboard__box">
          <Navbar
            toggleNav={toggleNav}
            menuMobileOpened={menuMobileOpened}
            action={setMenuMobileOpened}
          />
          <div className="Dashboard__box__container">{children}</div>
        </div>
      </div>
    );
  };

 export default NavMenu


