import { useCallback, useState, createContext, useContext } from 'react';
import {constants} from '../utils';
import { useLanguageContext } from './LanguageContext';

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext)

const getInitialUser = () => {
  let userJson;
  try {
    userJson = JSON.parse(localStorage.getItem(constants.CURRENT_USER_KEY))
  } catch(e) { /* empty */ }
  return userJson;
}

const checkInitialIsSso = () => {
  let isSso = false;
  try {
    const claveToken = localStorage.getItem(constants.CURRENT_CLAVE_TOKEN_KEY);
    const expirationTokenDate = localStorage.getItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY);
    if (!claveToken || !expirationTokenDate) {
      throw {};
    }
    const expirationDate = new Date(expirationTokenDate);
    if (expirationDate.getTime() > new Date().getTime()) {
      isSso = true;
    }
  } catch(e) { /* empty */ }
  if (!isSso) {
    localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_KEY);
    localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY);
  }
  return isSso;
}

export const AuthContextProvider = ({ children }) => {

  const { changeLanguage } = useLanguageContext();

  const [user, setUser] = useState(getInitialUser());
  const [isSso, setIsSso] = useState(checkInitialIsSso());
  const [userPetitionStatus, setUserPetitionStatus] = useState(localStorage.getItem(constants.CURRENT_PETITON_STATUS_KEY))

  const convertLanguageFromApiFormat = (lang) => {
    if(lang === 'gl') return 'ga';
    return lang;
  }

  const login = useCallback((user) => {
    user.language = convertLanguageFromApiFormat(user.language);
    updateUser(user);
    const { language } = user;
    changeLanguage(language)
  }, [])

  const updateUser = (user) => {
    localStorage.setItem(constants.CURRENT_USER_KEY, JSON.stringify(user));
    setUser(user);
  }

  const onLogout = useCallback(() => {
    localStorage.removeItem(constants.CURRENT_USER_KEY);
    localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_KEY);
    localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY);
    setUser(null)
    setIsSso(false)
    setUserPetitionStatus(null)
  }, [])

  const setPetitionStatus = (status) => {
    localStorage.setItem(constants.CURRENT_PETITON_STATUS_KEY, status)
    setUserPetitionStatus(status)
  }

  const setUserLanguage = (lang) => {
    const _user = {
      ...user,
      language: lang
    }
    localStorage.setItem(constants.CURRENT_USER_KEY, JSON.stringify(_user));
    setUser(_user);
  }

  const removeSso = () => {
    localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_KEY);
    localStorage.removeItem(constants.CURRENT_CLAVE_TOKEN_EXPIRATION_KEY);
    setIsSso(false);
  }

  const value = { 
    user, isSso, setIsSso, removeSso, login, onLogout, userPetitionStatus, setPetitionStatus, setUserLanguage, updateUser
  }

  return (
    <AuthContext.Provider value={value} >
      {children}
    </AuthContext.Provider>
  );
};
