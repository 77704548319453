import { useTranslation } from "react-i18next";
import { DateUtils, StatusUtils } from "../../utils";
import { Button } from "../../components";
import "./PetitionCard.scss";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getComsumptionPeriod } from "../../services/petitionServices";
import { useParametrics } from "../../contexts/ParametricsContext"

export const PetitionCard = ({
  convocationTitle,
  petition,
  isButton = true,
  isSso = false,
  ssoLoginAction,
}) => {
  const { convocationId } = useParametrics();
  const { t } = useTranslation();
  const history = useHistory();

  const [bonusAvailableDays, setBonusAvailableDays] = useState(null);

  useEffect(() => {
    if (!isSso && petition.processStatus.status === StatusUtils.STATUS.ACCEPTED)
      getBonusAvailableDays();
  }, []);

  const getStatusIcon = () => {
    const status = petition.processStatus.status;
    switch (status) {
      case StatusUtils.STATUS.PENDING_CORRECTION:
        return "/misc/icons/status-correction.png";
      case StatusUtils.STATUS.CLAIM:
        return "/misc/icons/status-pending.png";
      case StatusUtils.STATUS.ACCEPTED:
        return "/misc/icons/status-correction.png";
      case StatusUtils.STATUS.REJECTED:
        return "/misc/icons/status-refused.png";
      default:
        return "/misc/icons/status-pending.png";
    }
  };

  const getStatusKey = () => {
    const status = petition.processStatus.status;
    switch (status) {
      case StatusUtils.STATUS.PENDING_CORRECTION:
        return "correction";
      case StatusUtils.STATUS.CLAIM:
        return "claim";
      case StatusUtils.STATUS.ACCEPTED:
        return "accepted";
      case StatusUtils.STATUS.REJECTED:
        return "refused";
      default:
        return "pending";
    }
  };

  const statusKey = getStatusKey();

  const petitionInProgress = StatusUtils.isInProgress(petition.processStatus.status);

  const getBonusAvailableDays = async () => {
    try {
      const response = await getComsumptionPeriod( convocationId, petition.petitionId);
      setBonusAvailableDays(response.days);
    } catch (error) {
      console.error(error);
      setBonusAvailableDays(null)
    }
  };

  return (
    <div className="PetitionCard">
      <div className="convocation-info">
        {
          <div className="convocation-info__title">
            <span>{convocationTitle}</span>
          </div>
        }
      </div>
      <div className="petition-title">{t("Petitions.petitionCard.title")} </div>
      <div className="petition-status">
        <img src={getStatusIcon()} alt="" />
        {!petitionInProgress && <span className={statusKey}>{petition.processStatus.statusTitle}</span>}
      </div>
      {bonusAvailableDays && (
        <div className="available-days">
          <span>
            {t("Petitions.petitionCard.availableDays", {
              bonusAvailableDays: bonusAvailableDays,
            })}
          </span>
        </div>
      )}
      <div className="data-container">
        {
          petition.beneficiary && (
            <div className="data">
              <b>{t("Petitions.petitionCard.beneficiary")}:</b>{" "}
              {petition.beneficiary?.name} {petition.beneficiary?.firstSurname}{" "}
              {petition.beneficiary?.secondSurname}
            </div>
          )
        }
        {
          petition.representative && (
            <div className="data">
              <b>{t("Petitions.petitionCard.representative")}:</b>{" "}
              {petition.representative.name}{" "}
              {petition.representative.firstSurname}{" "}
              {petition.representative.secondSurname}
            </div>
          )
        }
        {
          !petitionInProgress && (
            <>
              <div className="data">
                <b>{t("Petitions.petitionCard.registerNumber")}:</b>{" "}
                {petition.registrationNumber}
              </div>
              <div className="data">
                <b>{t("Petitions.petitionCard.presentationDate")}:</b>{" "}
                {DateUtils.getFormatedDate(petition.presentationTime)}
              </div>   
            </>
          )
        }
        {isSso && (
          <Button
            className="buttonPrimaryLarge"
            text={petitionInProgress ? t("Petitions.petitionCard.continue") : t("Petitions.petitionCard.login")}
            action={() => {ssoLoginAction(petition)}}
            styles={{
              backgroundColor: "#34fdfe",
              color: "#381940",
              marginTop: "16px",
              maxWidth: "100%",
            }}
          />
        )}
        {isButton && (
          <Button
            className="buttonPrimaryLarge"
            text={t("Petitions.petitionCard.action")}
            action={() =>
              history.push(`/petitions/${petition.petitionId}`, {
                petition: petition,
                convocationTitle: convocationTitle,
              })
            }
            styles={{
              backgroundColor: "#34fdfe",
              color: "#381940",
              marginTop: "16px",
              maxWidth: "100%",
            }}
          />
        )}
        {isButton && statusKey === "correction" && petition.correctionId && (
          <span
            className="button button-correction"
            onClick={() =>
              history.push({
                pathname: `/petitions/${petition.petitionId}/corrections/new`,
                state: {
                  correction: true,
                  procedureId: petition.correctionId,
                  petition: petition,
                },
              })
            }
          >
            {t("RequestStatus.correctionAction")}
          </span>
        )}
        {isButton && statusKey === "claim" && petition.claimId && (
          <span
            className="button button-pending"
            onClick={() =>
              history.push({
                pathname: `/petitions/${petition.petitionId}/claims/new`,
                state: {
                  claim: true,
                  procedureId: petition.claimId,
                  petition: petition,
                },
              })
            }
          >
            {t("RequestStatus.claimAction")}
          </span>
        )}
      </div>
    </div>
  );
};
