import { useState } from 'react';
import { Button, Loading, NavMenu} from '../../components';
import './Petitions.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PetitionCard } from '../../components/petitionCard/PetitionCard';
import { useParametrics } from '../../contexts/ParametricsContext';
import { useHistory } from 'react-router-dom';
import { useErrorsContext } from '../../contexts/ErrorsContext';
import { createSsoPetition, getSsoPetitions, loginToPetition } from '../../services/petitionServices';
import { getBeneficiary } from '../../services/authServices';
import { useAuthContext } from '../../contexts/AuthContext';
import { StatusUtils } from '../../utils';
import { PetitionInProgressCard } from '../../components/petitionCard/PetitionInProgressCard';

const PetitionsSso = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const { convocationId, convocation, convocationOpen } = useParametrics();
  const { showError } = useErrorsContext();
  const { login } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [petitions, setPetitions] = useState();
  const [newSsoPetitionAllowed, setNewSsoPetitionAllowed] = useState(false);

  const loadPetitions = async () => {
    try {
      setLoading(true);
      const resp = await getSsoPetitions(convocationId);
      setPetitions(resp.petitions);
      setNewSsoPetitionAllowed(resp.newPetitionAllowed);
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const redirectToJoin = (petition) => {
    const userType = petition?.accountType === 'REPRESENTATIVE' ? 'representative' : 'beneficiary';
    if(petition.processStatus.nextStatus === StatusUtils.STATUS.CHANGE_ACCOUNT_TYPE)
      history.push(`/join/select-user-type`, { selectUserType: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.VERIFIED_IDENTITY)
      history.push(`/${userType}/join/confirm-identity`, { confirmIdentity: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.AUTOMATED_CONSULTATION_AUTHORIZATION)
      history.push(`/${userType}/join/automated-queries`, { automatedQueries: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_BENEFICIARY)
      history.push(`/${userType}/join/beneficiary-data`, { beneficiaryData: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_REPRESENTATIVE)
      history.push(`/${userType}/join/representative-data`, { representativeData: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_CARDS)
      history.push(`/${userType}/join/card-selector`, { cardTypeSelector: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DATA_NOTIFICATION)
      history.push(`/${userType}/join/notifications-address`, { notificationsAddressInfo: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.PROVIDED_DOCUMENTATION)
      history.push(`/${userType}/join/upload-documentation`, { uploadDocumentation: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
    else if(petition.processStatus.nextStatus === StatusUtils.STATUS.REGISTERED)
      history.push(`/${userType}/join/sign-request`, { signRequest: true, userType: userType, petitionId: petition.petitionId, petitionHasAccount: petition.accountEmail !== null});
  }

  const ssoNewPetition = async () => {
    try {
      setLoading(true);
      const petition = await createSsoPetition(convocationId);
      const user = await getBeneficiary();
      await loadPetitions();
      login({
        ...user,
        fullName: user.name === null ? null : user.fullName,
      });
      const accountType = petition.accountType;
      history.push(`/join/request-info`, { requestInfo: true, userType: accountType === 'REPRESENTATIVE' ? 'representative' : 'beneficiary', petitionId: petition.petitionId, petitionHasAccount: false});
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  }

  const ssoLoginAction = async (petition) => {
    const petitionInProgress = StatusUtils.isInProgress(petition.processStatus.status);
    try {
      setLoading(true);
      await loginToPetition(convocationId, petition.petitionId);
      const user = await getBeneficiary();
      login({
        ...user,
        fullName: user.name === null ? null : user.fullName,
      });
      if (petitionInProgress) {
        redirectToJoin(petition);
      }
      else {
        history.push(`/petitions/${petition.petitionId}`, {
          petition: petition,
        });
      }
    } catch (e) {
      showError(e);
      setLoading(false);
    } 
  }

  useEffect(() => {
    if(!convocationId || !convocation) return;
    loadPetitions();
  }, [convocationId, convocation])

  if (loading) return (
    <div className='vh-100 d-flex justify-content-center align-items-center'>
        <Loading />
    </div>
  )

  return (
    <NavMenu>
      <div className="Petitions">
        <div className="content">
          <div className="w-100 d-flex justify-content-center">
            <div className="">
              <h6>{t('Petitions.title')}</h6>
            </div>
          </div>
          <div className='sso-description'>
            <p>{t('Petitions.ssoDescription')}</p>
          </div>
          {
            petitions?.length > 0 && newSsoPetitionAllowed && convocationOpen && (
              <div className='new-sso-petition'>
                <Button
                  className="buttonPrimaryLarge"
                  text={t('Petitions.new')}
                  action={ssoNewPetition}
                  styles={{
                    backgroundColor: "#34fdfe",
                    color: "#381940",
                    marginTop: "16px",
                  }}
                />
              </div>
            )
          }
          <div className="petitions">
            {
              petitions?.map((petition) => {
                const inProgressPetition = StatusUtils.isInProgress(petition?.processStatus?.status);
                if (inProgressPetition) return (
                  <PetitionInProgressCard
                    key={petition.petitionId} petition={petition}
                    convocationTitle={convocation?.title}
                    isButton={false}
                    isSso={true}
                  />
                )
                return  (
                  <PetitionCard 
                    key={petition.petitionId} petition={petition}
                    convocationTitle={convocation?.title}
                    isButton={false}
                    isSso={true}
                    ssoLoginAction={ssoLoginAction}
                  />
                )
              }) ?? []
            }
          </div>
          {
            petitions?.length === 0 && (
              <div className="no-petitions-message">
                <WarningCard 
                  title={t('Petitions.warning.noPetitionsTitle')}
                  message={t('Petitions.warning.noPetitionsMessage')}
                  buttonLabel={t('Petitions.warning.noPetitionsButton')}
                  action={ssoNewPetition}
                  newSsoPetitionAllowed={newSsoPetitionAllowed && convocationOpen}
                />
              </div>
            )
          }
        </div>
      </div>
    </NavMenu>
  );
}

export default PetitionsSso;

const WarningCard = ({
  newSsoPetitionAllowed, title, message, buttonLabel, action, 
}) => {
  const { t } = useTranslation();

  return (
    <div className="warning-card">
      <h1 className='head'>{t('Petitions.warning.head')}</h1>
      <img src="/misc/icons/petitions-warning.png" alt="" />
      <p className='title'>{title}</p>
      <p className='message'>{message}</p>
      {
        newSsoPetitionAllowed ? (
          <Button 
            className="buttonPrimaryLarge"
            action={action}
            text={buttonLabel}
            styles={{
              backgroundColor: '#34fdfe',
              color: '#381940',
              marginTop: '30px'
            }}
          />
        ) : (
          <p className='convocation-closed'>{t('Petitions.warning.convocationClosed')}</p>
        )
      }
    </div>
  );
}