import { EmptyNotification, CardNotification } from '../../index';

const Request = ({ t, data }) => {

  data?.sort((a, b) => {
    return new Date(b.creationTime) - new Date(a.creationTime);
  });

  return data?.length > 0 ? (
    <div className='d-flex flex-column w-100'>
      {data?.map((notification) => {
        return (
          <CardNotification
            key={notification.id}
            id={notification.id}
            icon="/misc/icons/logo-noti.png"
            type={notification.type}
            status={notification.petitionStatus}
            date={notification.creationTime}
            isRead={notification.isRead}
            title={notification.title}
          />
        )
      })}
    </div>
  ) : (
    <EmptyNotification t={t} />
  );

};

export default Request;
