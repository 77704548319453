const months = [
  'ENE',
  'FEB',
  'MAR',
  'ABR',
  'MAY',
  'JUN',
  'JUL',
  'AGO',
  'SEP',
  'OCT',
  'NOV',
  'DIC'
];

const DURATION_IN_SECONDS = {
  epochs: ['año', 'mes', 'dia', 'h', 'min'],
  año: 31536000,
  mes: 2592000,
  dia: 86400,
  h: 3600,
  min: 60,
};

const getDuration = (seconds) => {
 let epoch, interval;

  for (let i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
    epoch = DURATION_IN_SECONDS.epochs[i];
    interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
    if (interval >= 1) {
      return {
        interval: interval,
        epoch: epoch,
      };
    }
  }
}

const getDayMonth = item => {
  const d = new Date(item);
  const date = d.getDate();
  const month = d.getMonth();

  return date + " " + months[month];
};


const getDateCollection = (date) => {
  const dateData = new Date(date)
  const newDate = new Date()

  if(dateData.getTime() >= newDate.getTime()) {
    return new Date().toISOString().split('T')[0]
  }
  return date
}
const timeSince = (dateString, complete) => {
  const date = Date.parse(dateString);
  const event = new Date()
  const hours = event.getHours()
  event.setHours(hours)
  const seconds = Math.floor((event - new Date(date)) / 1000);
  const duration = getDuration(seconds);
  if (duration !== undefined) {
    if(complete) {
      return `Hace ${duration.interval} ${duration.epoch}`;
    } else {
      return duration.interval + " " + duration.epoch;
    }
    
  } else {
    return '1min';
  }
}

const getUserAgeBetweenDates = (firstDate, secondDate) => {
  var ageNow = firstDate.getFullYear() - secondDate.getFullYear()
  const month = firstDate.getMonth() - secondDate.getMonth()
  if(month < 0 || (month === 0 && firstDate.getDate() < secondDate.getDate())) {
    ageNow -= 1
  }
  return ageNow
}

const getMinutesBetweenDates = (date1, date2) => {
  const diff = Math.abs(date1 - date2);
  return Math.floor((diff/1000)/60);
}

const getFormatedDate = item => {
  const d = new Date(item);
  const date = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  return `${date.toString().length === 1 ? `0${date}` : date }/${month.toString().length === 1 ? `0${month}` : month}/${year}`;
};

const getFormatedTime = item => {
  const d = new Date(item);
  const hours = d.getHours();
  const mins = d.getMinutes();
  const secs = d.getSeconds();

  return `${hours.toString().length === 1 ? `0${hours}` : hours }:${mins.toString().length === 1 ? `0${mins}` : mins }:${secs.toString().length === 1 ? `0${secs}` : secs }`;
};

const getFormatedDateToSend = item => {
  const d = new Date(item);
  const date = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  return `${year}-${month.toString().length === 1 ? `0${month}` : month}-${date.toString().length === 1 ? `0${date}` : date }`;
}

const formatToServerDateTime = (date, timeString) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  return `${year}-${month.toString().length === 1 ? `0${month}` : month}-${day.toString().length === 1 ? `0${day}` : day }${timeString ? `T${timeString}` : ''}`;
}

const daysToDate = (date) =>{
  const dateMillis = new Date(date).getTime();
  const diffMillis = dateMillis - Date.now();
  const days = Math.ceil(diffMillis / (1000 * 60 * 60 * 24));
  if (days < 0) return 0;
  return days;
} 

const addHoursToDate = (date, hours) => {
  const dateInMillis = date.getTime();
  const oneHourInMillis = hours * 60 * 60 * 1000;
  const futureInMillis = dateInMillis + oneHourInMillis;
  return new Date(futureInMillis);
}

const addMinutesToDate = (date, minutes) => {
  const dateInMillis = date.getTime();
  const oneHourInMillis = minutes * 60 * 1000;
  const futureInMillis = dateInMillis + oneHourInMillis;
  return new Date(futureInMillis);
}

export default {
  getDayMonth,
  months,
  getDateCollection,
  getUserAgeBetweenDates,
  getMinutesBetweenDates,
  timeSince,
  getDuration,
  getFormatedDate,
  getFormatedTime,
  getFormatedDateToSend,
  formatToServerDateTime,
  daysToDate,
  addHoursToDate,
  addMinutesToDate,
}