import { useTranslation } from 'react-i18next';
import MainLayout from '../../../layouts/mainLayout/MainLayout';
import './ConfirmIdentity.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Loading, Modal, ViewModal } from '../../../components';
import { useEffect, useState } from 'react';
import { getBeneficiary } from '../../../services/authServices';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { createPetition, getPetitionVerifyIdentity, updatePetitionVerifyIdentity } from '../../../services/petitionServices';
import { useParametrics } from '../../../contexts/ParametricsContext';

const ConfirmIdentity = () => {
  
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  
  const { updateUser, isSso } = useAuthContext();
  const { showError } = useErrorsContext();
  const { convocationId } = useParametrics();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fullName, setFullName] = useState('');
  const [dni, setDni] = useState('');

  const userType = location?.state?.userType;
  const petitionId = location?.state?.petitionId;
  const petitionHasAccount = location?.state?.petitionHasAccount;

  const [petition, setPetition] = useState(null);

  const _updateUser = async () => {
    try {
      setLoading(true);
      const user = await getBeneficiary();
      setFullName(user.fullName);
      setDni(user.nif);
      updateUser(user);
      if(petitionId) {
        const petition = await getPetitionVerifyIdentity(convocationId, userType.toUpperCase(), petitionId);
        setPetition(petition);
      }
    } catch(e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.confirmIdentity || !userType) {
      history.goBack();
    }
    else {
      setShow(true);
      _updateUser();
    }
  }, [convocationId])

  const nextStep = async () => {
    try {
      setLoading(true);
      let _petitionId = petitionId;
      let _petitionHasAccount = petitionHasAccount;
      if(petitionId) {
        await updatePetitionVerifyIdentity(convocationId, userType.toUpperCase(), petitionId);
      }
      else {
        const petition = await createPetition(convocationId, userType.toUpperCase());
        _petitionId = petition.petitionId;
        _petitionHasAccount = true;
      }
      history.push({ pathname: `/${userType}/join/automated-queries`, state: { automatedQueries: true, userType: userType, petitionId: _petitionId, petitionHasAccount: _petitionHasAccount } })
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      setLoading(false)
    }
  }

  return show && (
    <div className="ConfirmIdentity">
      {
        loading ? (
          <div className='vh-100 d-flex justify-content-center align-items-center'>
              <Loading />
          </div>
        ) : (
          <>
            <MainLayout
              title={t('Join.ConfirmIdentity.title')}
              onBack={() => {
                if (isSso) history.push('/join/select-user-type', { selectUserType: true, userType: userType, petitionId: petitionId, petitionHasAccount  })
                else history.push({ pathname: `/${userType}/join/clave-verification`, state: { claveVerification: true, userType: userType, petitionId: petitionId, petitionHasAccount } }) 
              }}
            >
              <div className="identity-card">
                <img className='avatar' src="/misc/icons/userType/user-type-selector-beneficiary.png" alt="" />
                <p className='requester-name'>{t('Join.ConfirmIdentity.requester')}: {fullName}</p>
                <p className='requester-dni'>{dni}</p>
                {
                  !isSso && (
                    <span className="later" onClick={() => history.push({ pathname: `/${userType}/join/clave-verification`, state: { claveVerification: true, userType: userType, petitionId: petitionId, petitionHasAccount } })}>
                        {t('Join.ConfirmIdentity.backClave')}
                    </span>
                  )
                }
              </div>
              <div className="buttons mt-5">
                <Button 
                  text={t('Join.ConfirmIdentity.leave')}
                  className="buttonPrimaryLarge"
                  styles={{ backgroundColor: '#f4f5fb', color: '#381940', marginBottom: '23px' }}
                  action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                />
                <Button 
                  text={t('Join.ConfirmIdentity.confirm')}
                  className="buttonPrimaryLarge"
                  styles={{ backgroundColor: '#34fdfe', color: '#381940', marginBottom: '23px' }}
                  action={() => {
                    if(!isSso && petition && petition.processStatus?.status !== 'CHANGE_ACCOUNT_TYPE') {
                      setShowConfirmModal(true);
                      return;
                    }
                    nextStep();
                  }}
                />
              </div>
            </MainLayout>
            <Modal
              isShowingModal={showConfirmModal}
              component={(
                <ViewModal
                  imageSource={'/misc/icons/warning.png'}
                  title={t('Join.claveVerification.confirmationModal.title')}
                  description={t('Join.claveVerification.confirmationModal.description')}
                  action={() => {
                    setShowConfirmModal(false);
                    nextStep();
                  }}
                  secondAction={() => {
                    setShowConfirmModal(false);
                  }}
                  textBtn={t('Join.claveVerification.confirmationModal.textButton')}
                  secondTextBtn={t('Join.claveVerification.confirmationModal.secondTextButton')}
                />
              )}
            />
          </>
        )
      }
    </div>
  );
}

export default ConfirmIdentity;