import axios from 'axios';
import http, { API_URL, convertLanguageToApiFormat } from './base-http-service';
import constants from '../utils/constants';

export const uploadFile = ({convocationId, petitionId, fileType, petitionProcedureId, formData}) => {
  let params = `fileType=${fileType}`;
  if (petitionProcedureId) params += `&petitionProcedureId=${petitionProcedureId}`;
  return http.post(`beneficiary-file-api/v1/${convocationId}/${petitionId}?${params}`, formData) 
}

export const updateFile = ({convocationId, petitionId, fileId, fileType, petitionProcedureId, formData}) => {
  let params = `fileType=${fileType}`;
  if (petitionProcedureId) params += `&petitionProcedureId=${petitionProcedureId}`;
  return http.patch(`beneficiary-file-api/v1/${convocationId}/${petitionId}/${fileId}?${params}`, formData) 
}

export const getFile = (fileId) => http.get(`beneficiary-file-api/v1/${fileId}`);

export const getDossierFile = (fileId) => http.get(`beneficiary-file-api/v1/dossierFile/${fileId}`);

export const getTicketFile = async (fileId) => {
  const resp = await axios.get(`${API_URL}beneficiary-file-api/v1/correos/download/${fileId}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem(constants.CURRENT_TOKEN_KEY)}`,
      'Accept-Language': localStorage.getItem(convertLanguageToApiFormat(constants.CURRENT_LANGUAGE_KEY) ?? 'es'),
    }
  })
  return resp;
}

export const signFile = (convocationId, accountType, petitionId, fileType, data) => 
  http.post(`beneficiary-file-api/v1/sign/${convocationId}/${accountType}/${petitionId}?fileType=${fileType}`, data);

export const signProcedureFile = (convocationId, accountType, petitionId, procedureId, fileType, data) => 
  http.post(`beneficiary-file-api/v1/sign/${convocationId}/${accountType}/${petitionId}?fileType=${fileType}&petitionProcedureId=${procedureId}`, data);

export const searchByCsv = async (csv) => http.post(`beneficiary-file-api/v1/public/verifyCsv`, { csv: csv }) //http.post(`files-api/file/csv/url`, { csv: csv });

export const removeTicketFile = (transactionId) => http.delete(`beneficiary-file-api/v1/correos/delete-ticket/${transactionId}`);

/**
 * 
 * LLAMADAS VIEJAS
 * 
 */

export const getUnsubscribeUrl = () => http.get(`files-api/file/download`, { params: { type: 'RESPONSIBLE_DECLARATION_REQUIREMENTS' } });

export const getDocUrlByType = (fileType) => http.get(`files-api/file/type/${fileType}/url`);

export const getDocUrlByFileId = (fileId) => http.get(`files-api/file/${fileId}/url`);

export const uploadDoc = (data) => http.post(`files-api/file/beneficiary`, data);

export const getUserFiles = () => http.get('files-api/files/me', { params: { pageSize: 1000 } });

export const getDocUrl = (fileId) =>  http.get(`files-api/file/${fileId}/url`);

export const getTransactionUrl = (fileId) => http.get(`files-api/file/transaction/${fileId}/url`);

export const uploadTransactionDoc = (data) => http.post(`files-api/file/transaction`, data);