import { useTranslation } from "react-i18next";
import { DateUtils } from "../../../../../utils";
import './RectificationCard.scss';
import { useHistory } from "react-router-dom";

export const RectificationCard = ({
  rectification, petition, hasAction = true
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const clickable = hasAction && ['PENDING', 'DATA_COMPLETED', 'REGISTERED'].includes(rectification.status);
  return (
    <div key={rectification.id} className="RectificationCard" 
      style={{ cursor: clickable ? 'pointer' : null }}
      onClick={clickable ? () => {
        if (rectification.status === 'REGISTERED') history.push(`/petitions/${petition.petitionId}/rectifications/${rectification.id}`, {
          petition: petition
        })
        else history.push(`/petitions/${petition.petitionId}/rectifications/new`, {
          rectifications: true, procedureId: rectification.id, petition
        })
      } : null}
    >
      <img 
        className='icon-left'
        src={rectification.status === 'REGISTERED' ? "/misc/icons/step-ok.png" : "/misc/icons/status-pendingDoc.png"}
      />
      <div className="document-data">
        <div className="document-data-row">
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.rectifications.rectificationsList.status')}</div>
            <div className="document-column-value">{rectification.statusTitle}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.rectifications.rectificationsList.creationDate')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(rectification.creationTime)}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{rectification.status === 'REGISTERED' ? t('Petitions.rectifications.rectificationsList.presentationDate') : t('Petitions.rectifications.rectificationsList.updateTime')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(rectification.statusTime)}</div>
          </div>
          {
            rectification.registerNumber && (
              <div className="document-column">
                <div className="document-column-title">{t('Petitions.rectifications.rectificationsList.registerNumber')}</div>
                <div className="document-column-value">{rectification.registerNumber}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}