import http from "./base-http-service";

export const getClaveData = (convocationId) => http.get(`clave-adapter-api/v1/${convocationId}/generate/BENEFICIARY`);

export const getClaveDataSignFileSimple = (convocationId, userType, fileType, data) => http.post(`clave-adapter-api/v1/signature/beneficiary/${convocationId}/${userType}/${fileType}/generate`,  data );

export const getClaveDataSignFileAdvanced = (convocationId, userType, fileType, data) => http.post(`clave-adapter-api/v1/signature/beneficiary/${convocationId}/${userType}/${fileType}/generate/advanced`, data );

export const getClaveDataSso = (convocationId) => http.get(`clave-adapter-api/v1/public/sso/${convocationId}/generate/BENEFICIARY`);

export const getClaveStatus = (convocationId, relayState) => http.get(`clave-adapter-api/v1/${convocationId}/${relayState}`);

export const getClaveSignatureStatus = (convocationId, relayState) => http.get(`clave-adapter-api/v1/signature/${convocationId}/${relayState}`);

export const getClaveSsoStatus = (convocationId, relayState) => http.get(`clave-adapter-api/v1/public/sso/${convocationId}/${relayState}`);