import { useCallback, useState, createContext, useContext } from 'react';
import {constants} from '../utils';

export const NotificationsContext = createContext();

export const useNotificationsContext = () => useContext(NotificationsContext)

export const NotificationsContextProvider = ({ children }) => {
  const [badgeCount, setBadgeCount] = useState(localStorage.getItem(constants.NOTIFICATIONS_COUNT) || null)

  const addBadge = useCallback(count => {
    localStorage.setItem(constants.NOTIFICATIONS_COUNT, count)
    setBadgeCount(count)
  }, [])

  const value = { badgeCount, addBadge }

  return (
    <NotificationsContext.Provider value={value} >
      {children}
    </NotificationsContext.Provider>
  );
};
