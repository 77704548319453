import { useTranslation } from 'react-i18next';
import List from '../../components/list/List';
import Map from '../../components/map/Map';
import Search from '../../components/search/Search';
import './DesktopLayout.scss';
import { useShopsContext } from '../../contexts/ShopsContext';

const DesktopLayout = ({ types, fromPublic }) => {

  const { t } = useTranslation();
  const { fetchClusters } = useShopsContext();

  const changeFilter = (selected) => {
    fetchClusters({
      wallet: selected
    })
  }

  return (
    <div className={`DesktopLayout ${fromPublic ? 'fromPublic' : ''}`}>
      <div className="DesktopLayout__layout">
        <div className="DesktopLayout__layout__mapContainer">
          <Map />
        </div>
        <div className="DesktopLayout__layout__shopsListContainer">
          <Search />
          <div className='DesktopLayout__layout__shopsListContainer__chips'>
            <div className={`DesktopLayout__layout__shopsListContainer__chips__item ${!types || types?.length < 1 ? 'selected' : ''}`} onClick={() => changeFilter(null)}>{t('Shops.filters.all')}</div>
            <div className={`DesktopLayout__layout__shopsListContainer__chips__item ${!types || types?.includes('LIVE_ARTS') ? 'selected' : ''}`} onClick={() => changeFilter('LIVE_ARTS')}>{t('Shops.filters.cultural')}</div>
            <div className={`DesktopLayout__layout__shopsListContainer__chips__item ${!types || types?.includes('DIGITAL_SUPPORT') ? 'selected' : ''}`} onClick={() => changeFilter('DIGITAL_SUPPORT')}>{t('Shops.filters.digital')}</div>
            <div className={`DesktopLayout__layout__shopsListContainer__chips__item ${!types || types?.includes('PHYSICAL_SUPPORT') ? 'selected' : ''}`} onClick={() => changeFilter('PHYSICAL_SUPPORT')}>{t('Shops.filters.physical')}</div>
          </div>
          <List isHorizontal={false} />
        </div>
      </div>
    </div>
  );
}

export default DesktopLayout;