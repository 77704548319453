import './Pdf.scss';
import { Document, Page, pdfjs } from "react-pdf";
import { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../../components/shared/loading/Loading';
import { base64FileToBlob, downloadFile } from '../../utils/download-file';
import { Button } from '../../components';
import { getFile, getDossierFile } from '../../services/fileServices';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Pdf = () => {

  const [numPages, setNumPages] = useState(0);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState(null);

  const _getUrl = async (fileId, type) => {
    try {
      let resp;
      if (type === 'dossierFile') {
        resp = await getDossierFile(fileId);
      }
      else{
        resp = await getFile(fileId);
      }
      
      const isPdf = resp.name?.toLowerCase().endsWith('.pdf');
      setName(resp.name);
      const blob = base64FileToBlob(resp.file, isPdf ? 'application/pdf' : null);
      const url = URL.createObjectURL(blob);
      setUrl(url);
      if (!isPdf) {
        downloadFile(url, resp.name);
        window.close();
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fileId = urlParams.get('id');
    const type = urlParams.get('type');
    _getUrl(fileId, type);
  }, []);

  const rendederedPages = () => {
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push((<Page key={i} pageNumber={i + 1} />))
    }
    return pages;
  }

  if (!url) return (
    <div className='vh-100 d-flex justify-content-center align-items-center'>
        <Loading />
    </div>
  );

  return (
    <div className='Pdf'>
      <div className="button-container">
        <Button
          className="buttonPrimaryLarge"
          styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
          action={() => {
            downloadFile(url, name);
          }}
          text="Descargar"
        />
      </div>
      <div className="document-container">
        <Document onLoadSuccess={(data) => { setNumPages(data.numPages) }} file={url} onContextMenu={(e) => e.preventDefault()}>
          {rendederedPages()}
        </Document>
      </div>
    </div>
  );
}

export default Pdf;