import { useTranslation } from "react-i18next";
import { DateUtils } from "../../../../../utils";
import './ClaimCard.scss';
import { useHistory } from "react-router-dom";

export const ClaimCard = ({
  claim, petition, hasAction = true
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const clickable = hasAction && ['PENDING', 'DATA_COMPLETED', 'REGISTERED'].includes(claim.status);
  return (
    <div key={claim.id} className="ClaimCard" 
      style={{ cursor: clickable ? 'pointer' : null }}
      onClick={clickable ? () => {
        if (claim.status === 'REGISTERED') history.push(`/petitions/${petition.petitionId}/claims/${claim.id}`, {
          petition: petition
        })
        else history.push(`/petitions/${petition.petitionId}/claims/new`, {
          claim: true, procedureId: claim.id, petition
        })
      } : null}
    >
      <img 
        className='icon-left'
        src={claim.status === 'REGISTERED' ? "/misc/icons/step-ok.png" : "/misc/icons/status-pendingDoc.png"}
      />
      <div className="document-data">
        <div className="document-data-row">
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.status')}</div>
            <div className="document-column-value">{claim.statusTitle}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.creationDate')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(claim.creationTime)}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{claim.status === 'REGISTERED' ? t('Petitions.additionalDocs.additionalDocsList.presentationDate') : t('Petitions.additionalDocs.additionalDocsList.updateTime')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(claim.statusTime)}</div>
          </div>
          {
            claim.registerNumber && (
              <div className="document-column">
                <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.registerNumber')}</div>
                <div className="document-column-value">{claim.registerNumber}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}