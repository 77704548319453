import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useErrorsContext } from '../../../../contexts/ErrorsContext';
import { useEffect, useState } from 'react';
import { Button, Loading, NavMenu } from '../../../../components';
import { useParametrics } from '../../../../contexts/ParametricsContext';
import { PetitionCard } from '../../../../components/petitionCard/PetitionCard';
import { createRectificationsProcedure, getRectificationsProcedures } from '../../../../services/procedureServices';
import { RectificationCard } from './RectificationCard/RectificationCard';
import { StatusUtils } from '../../../../utils';
import './Rectifications.scss';

const Rectifications = () => {

  const { t } = useTranslation();
  const { convocation, convocationId } = useParametrics();
  const { showError } = useErrorsContext();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [creatingNewProcedure, setCreatingNewProcedure] = useState(false);
  const [petition, setPetition] = useState(null);
  const [rectifications, setRectifications] = useState(null);

  const petitionId = location?.state?.petition?.petitionId;

  const _getRectifications = async () => {
    try {
      setLoading(true);
      const resp = await getRectificationsProcedures(petition.petitionId);
      setRectifications(resp);
      setLoading(false);
    } catch (e) {
      showError(e);
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.petition) {
      return history.replace('/petitions')
    }
    setPetition(location.state.petition);
  }, [convocationId]);

  useEffect(() => {
    if(petition) _getRectifications();
  }, [petition])

  const newProcedure = async () => {
    try {
      setCreatingNewProcedure(true);
      const {id: procedureId} = await createRectificationsProcedure(petitionId);
      history.push(`/petitions/${petitionId}/rectifications/new`, {
        rectifications: true, procedureId, petition,
      })
    } catch(e) {
      showError(e);
    } finally {
      setLoading(false);
      setCreatingNewProcedure(false);
    }
  }

  const showNewProcedureButton = ![
    StatusUtils.STATUS.REJECTED, 
  ].includes(petition?.processStatus.status)

  return (
    <NavMenu>
      <div className="Rectifications">
        <div className="content">
          {
            loading ? (
              <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
              </div>
            ) : (
              <>
                <div className="w-100 d-flex justify-content-center">
                  <div className="header">
                    <img src='/misc/icons/arrow-left-blue.svg' alt='' onClick={() => {
                      history.goBack();
                    }}/>
                    <h6>{t('Petitions.rectifications.title')} </h6>
                  </div>
                </div>
                <div className="petitions">
                  <PetitionCard convocationTitle={convocation?.title} petition={petition} isButton={false}/>
                </div>
                {
                  showNewProcedureButton && (
                    <div className="new-procedure">
                      <Button
                          disabled={creatingNewProcedure}
                          className="buttonPrimaryLarge"
                          text={t('Petitions.rectifications.rectificationsList.newProcedure')}
                          styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                          action={newProcedure}
                      />
                    </div>
                  )
                }
                <div className="rectifications">
                  {
                    rectifications.map((rectification) => {
                      return (
                        <RectificationCard
                          key={rectification.id}
                          petition={petition}
                          rectification={rectification}
                        />
                      )
                    })
                  }
                </div>
              </>
            )
          }
        </div>
      </div>
    </NavMenu>
  );
}

export default Rectifications;