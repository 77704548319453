import { useState, useEffect, useCallback } from 'react';
import { Loading, NavMenu, Pagination } from '../../components';
import { getOnlineShops } from '../../services/shopsServices';
import { useErrorsContext } from '../../contexts/ErrorsContext.js';
import { useTranslation } from 'react-i18next';
import './OnlineShops.scss';

const OnlineShops = ({ fromPublic }) => {

    const { t } = useTranslation();
    const { showError } = useErrorsContext();
    const [loading, setLoading] = useState(true);
    const [merchants, setMerchants] = useState();
    const [value, setValue] = useState();
    const [type, setType] = useState();
    const [shopSelected, setShopSelected] = useState();
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 10;

    const getShops = useCallback(async () => {
        try {
            setLoading(true);
            const searchParams = new URLSearchParams()
            searchParams.append('page', page);
            searchParams.append('pageSize', pageSize);
            if (value) searchParams.append('search', value);
            if (type) searchParams.append('wallet', type);
            const response = await getOnlineShops(searchParams);
            const json = await response.json()
            setTotalPages(json.totalPages);
            setMerchants(json.content);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [page, value, type]); // eslint-disable-line

    useEffect(() => {
        getShops();
    }, [page, type]) // eslint-disable-line

    const merchantItem = (data, index) => {
        return (
            <div className="OnlineShops__content__list__item" key={index} onClick={() => setShopSelected(data)}>
                <div className="OnlineShops__content__list__item__left">
                    <div className="OnlineShops__content__list__item__left__image">
                        <img alt='icon' src={data?.image || 'misc/icons/logo-bono.png'} />
                    </div>
                </div>
                <div className="OnlineShops__content__list__item__right">
                    <div className="OnlineShops__content__list__item__right__name">
                        {data?.name || "-"}
                    </div>
                    <div className="OnlineShops__content__list__item__right__web">
                        {data?.webAddress}
                    </div>
                    {data?.subCategories?.split(',').map((item, index) => (
                        <span className="OnlineShops__content__list__item__right__types d-block" key={index}>
                            - {t(`Globals.merchantType.${item.trim()}`)}.
                        </span>
                    ))}
                </div>
            </div>
        )
    }

    const getCompleteWeb = (url) => {
        if(!url.includes("http")) {
            return `https://${url}`;
        }
        return url;
    }

    const details = () => (
        <div className="OnlineShops__content__shopDetail">
            <div className="OnlineShops__content__shopDetail__container">
                <div className="OnlineShops__content__shopDetail__container__button-back-container">
                    <div className="OnlineShops__content__shopDetail__container__button-back-container__button-back" onClick={() => {setShopSelected()}}>
                        <img src="/misc/icons/arrow-left-pink.svg" alt="" />
                    </div>
                </div>
                <div className="OnlineShops__content__shopDetail__container__content-container">
                    <div className="OnlineShops__content__shopDetail__container__content-container__image-container">
                        <div className="OnlineShops__content__shopDetail__container__content-container__image-container__image">
                            <img alt='icon' src={shopSelected?.image || 'misc/icons/logo-bono.png'} />
                        </div>
                    </div>
                    <div className="OnlineShops__content__shopDetail__container__content-container__title">
                        {shopSelected?.name}
                    </div>
                    <div className="OnlineShops__content__shopDetail__container__content-container__web" onClick={() => window.open(getCompleteWeb(shopSelected?.web))}>
                        {shopSelected?.webAddress}
                    </div>
                    {shopSelected?.subCategories?.split(',').map((item, index) => (
                        <span className="OnlineShops__content__shopDetail__container__content-container__category" key={index}>
                        - {t(`Globals.merchantType.${item.trim()}`)}.
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )

    const changeFilter = (selected) => {
        setType(selected);
    }

    const content = (
        <div className={`OnlineShops ${fromPublic ? 'fromPublic' : ''}`}>
            <div className={`OnlineShops__content`}>
                {shopSelected ? (
                    details()
                ) : (
                    loading ? (
                      <div className='vh-100 d-flex justify-content-center align-items-center'>
                          <Loading />
                      </div>
                    ) : (
                        <>
                            {!fromPublic && <div className="w-100 d-flex justify-content-center">
                                <div className="OnlineShops__content__title">
                                    <h6 className="text-center">{t('Shops.onlineSales')}</h6>
                                </div>
                            </div>}
                            <div className={`OnlineShops__content__search`}>
                                <input
                                    type="text" placeholder={t('Shops.searchShop')}
                                    value={value}
                                    onChange={(event) => {
                                        setValue(event.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            !page ? getShops() : setPage(0);
                                        }
                                    }}
                                />
                                <div className="icon" onClick={() => !page ? getShops() : setPage(0) }>
                                    <img src="/misc/icons/search.svg" alt="" />
                                </div>
                            </div>
                            <div className='OnlineShops__content__chips'>
                                <div className={`OnlineShops__content__chips__item ${!type ? 'selected' : ''}`} onClick={() => changeFilter(null)}>{t('Shops.filters.all')}</div>
                                <div className={`OnlineShops__content__chips__item ${type == 'LIVE_ARTS' ? 'selected' : ''}`} onClick={() => changeFilter('LIVE_ARTS')}>{t('Shops.filters.cultural')}</div>
                                <div className={`OnlineShops__content__chips__item ${type == 'DIGITAL_SUPPORT' ? 'selected' : ''}`} onClick={() => changeFilter('DIGITAL_SUPPORT')}>{t('Shops.filters.digital')}</div>
                                <div className={`OnlineShops__content__chips__item ${type == 'PHYSICAL_SUPPORT' ? 'selected' : ''}`} onClick={() => changeFilter('PHYSICAL_SUPPORT')}>{t('Shops.filters.physical')}</div>
                            </div>
                            <div className='OnlineShops__content__list'>
                                {merchants?.length > 0 ? (
                                    merchants?.map((item, index) => (
                                        merchantItem(item, index)
                                    ))
                                ): (
                                    <p className='OnlineShops__content__list__empty'>No se han encontrado empresas de venta online</p>
                                )}
                            </div>
                            <Pagination
                                page={page}
                                totalPages={totalPages}
                                onPageChange={(selected) => {
                                    setPage(selected);
                                }}
                            />
                        </>
                    )
                )}
            </div>
        </div>  
    )

    return (
        fromPublic ? (
            content
        ) : (
            <NavMenu>
                {content}    
            </NavMenu>
        )
    );
};

export default OnlineShops;
