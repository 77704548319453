import { useEffect, useState } from 'react';
import { Button, Loading } from '../../../components';
import { DateUtils, DisplayUtils } from '../../../utils';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import './RequestSuccess.scss';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { getRegister } from '../../../services/petitionServices';

const RequestSuccess = () => {
  
  const { t } = useTranslation();
  const { convocationId } = useParametrics();
  const location = useLocation();
  const history = useHistory();
  const { showError } = useErrorsContext();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState();
  const [registrationNumber, setRegistrationNumber] = useState(null);
  const [presentationTime, setPresentationTime] = useState();

  const userType = location?.state?.userType;
  const petitionId = location?.state?.petitionId;
  const petitionHasAccount = location?.state?.petitionHasAccount;

  const getPetition = async () => {
    try {
      setLoading(true);
      const resp = await getRegister(convocationId, userType.toUpperCase(), petitionId);
      setFileId(resp.registeredFileId);
      setRegistrationNumber(resp.registrationNumber);
      setPresentationTime(resp.presentationTime);
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.requestSuccess || !userType || !petitionId || petitionHasAccount === undefined) {
        history.goBack()
    } else {
      getPetition();
      setShow(true);
    }
  }, [convocationId])

  return (
      show && (
        loading ? (
            <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
            <div className="RequestSuccess">
                <div className="w-100 d-flex justify-content-center">
                    <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="RequestSuccess__logo" />
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="RequestSuccess__container">
                        <div className="row m-0 justify-content-center">
                            <div className="row m-0 justify-content-center">
                                <img
                                    src={`${DisplayUtils.isMobile() ? '/misc/icons/doc-success-2.png' : '/misc/icons/doc-success.png'}`}
                                    alt="shop-icon"
                                    className="RequestSuccess__container__icon"
                                />
                            </div>
                            <h6>{t('Join.RequestSuccess.title')}</h6>
                            <p>
                                {t('Join.RequestSuccess.description')}
                            </p>
                            <p dangerouslySetInnerHTML={{ __html: t('Join.RequestSuccess.info') }} />
                            <div className='RequestSuccess__container__request-data align-center'>
                              <div><b>{t('Join.RequestSuccess.registerNumber')}:</b> {registrationNumber} </div>
                              <div><b>{t('Join.RequestSuccess.registerDate')}:</b> {DateUtils.getFormatedDate(presentationTime)} </div>
                            </div>
                        </div>
                        <div className="RequestSuccess__container__doc-card">
                            <img
                                className="RequestSuccess__container__doc-card__icon"
                                src="/misc/icons/list-info-pink.png"
                                alt="item"
                            />
                            <div className="RequestSuccess__container__doc-card__info">
                                <span className="RequestSuccess__container__doc-card__info__title">
                                    {t('Documents.RECEIPT_TEMPLATE_BENEFICIARY2')}
                                </span>
                            </div>
                            {fileId && (
                                <img
                                    className="RequestSuccess__container__doc-card__download"
                                    src="/misc/icons/download.png"
                                    alt="item"
                                    onClick={() => window.open(`/pdf-viewer/?id=${fileId}`)}
                                />
                            )}
                        </div>
                        {
                          !petitionHasAccount && (
                            <>
                              <div className="create-user" >
                                <img src={"/misc/icons/profile-menu.png"} alt="shop-icon" className="Profile__content__box__boxes__contain__icon" />
                                <span className="create-user__title">{t('Petitions.detail.createUser')}</span>
                                <span className="create-user__detail">{t('Petitions.detail.createUserDetail')}</span>
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('Petitions.detail.createUserButton')}
                                    styles={{ backgroundColor: `${DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe'}`, color: `${DisplayUtils.isMobile() ? '#381940' : '#381940'}` }}
                                    action={() => history.push(`/petitions/${petitionId}/signup`)}
                                />
                              </div>
                              <div className="RequestSuccess__container__buttons">
                                <span className="RequestInfo__container__later" onClick={() => history.push('/')}>
                                    {t('Join.requestInfo.later')}
                                </span>
                              </div>
                            </>
                          )
                        }
                        {petitionHasAccount && (
                          <div className="RequestSuccess__container__buttons">
                            <Button
                              className="buttonPrimaryLarge"
                              text={t('Globals.accept2')}
                              styles={{ backgroundColor: `${DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe'}`, color: `${DisplayUtils.isMobile() ? '#381940' : '#381940'}` }}
                              action={() => history.push('/')}
                            />
                          </div>
                        )}
                    </div>
                </div>
            </div>
        )
      )
  );
};

export default RequestSuccess;
