import { useTranslation } from "react-i18next";
import { DateUtils } from "../../../../../utils";
import './CorrectionCard.scss';
import { useHistory } from "react-router-dom";

export const CorrectionCard = ({
  correction, petition, hasAction = true
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const clickable = hasAction && ['PENDING', 'DATA_COMPLETED', 'REGISTERED'].includes(correction.status);
  return (
    <div key={correction.id} className="CorrectionCard" 
      style={{ cursor: clickable ? 'pointer' : null }}
      onClick={clickable ? () => {
        if (correction.status === 'REGISTERED') history.push(`/petitions/${petition.petitionId}/corrections/${correction.id}`, {
          petition: petition
        })
        else history.push(`/petitions/${petition.petitionId}/corrections/new`, {
          correction: true, procedureId: correction.id, petition
        })
      } : null}
    >
      <img 
        className='icon-left'
        src={correction.status === 'REGISTERED' ? "/misc/icons/step-ok.png" : "/misc/icons/status-pendingDoc.png"}
      />
      <div className="document-data">
        <div className="document-data-row">
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.status')}</div>
            <div className="document-column-value">{correction.statusTitle}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.creationDate')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(correction.creationTime)}</div>
          </div>
          <div className="document-column">
            <div className="document-column-title">{correction.status === 'REGISTERED' ? t('Petitions.additionalDocs.additionalDocsList.presentationDate') : t('Petitions.additionalDocs.additionalDocsList.updateTime')}</div>
            <div className="document-column-value">{DateUtils.getFormatedDate(correction.statusTime)}</div>
          </div>
          {
            correction.registerNumber && (
              <div className="document-column">
                <div className="document-column-title">{t('Petitions.additionalDocs.additionalDocsList.registerNumber')}</div>
                <div className="document-column-value">{correction.registerNumber}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}