import { useEffect, useState } from 'react';
import { Button, Loading } from '../../../components';
import { DateUtils, DisplayUtils } from '../../../utils';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import './SignProcedureSuccess.scss';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { getRegisterData } from '../../../services/procedureServices';

const SignProcedureSuccess = () => {
  
  const { t } = useTranslation();
  const { convocationId } = useParametrics();
  const location = useLocation();
  const history = useHistory();
  const { showError } = useErrorsContext();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState();
  const [registrationNumber, setRegistrationNumber] = useState(null);
  const [presentationTime, setPresentationTime] = useState();

  const userType = location?.state?.userType;
  const petitionId = location?.state?.petitionId;
  const procedureId = location?.state?.procedureId;

  const getPetition = async () => {
    try {
      setLoading(true);
      const resp = await getRegisterData(petitionId, procedureId);
      setFileId(resp.registeredFileId);
      setRegistrationNumber(resp.registrationNumber);
      setPresentationTime(resp.presentationTime);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.signProcedureSuccess || !userType || !petitionId) {
        history.goBack()
    } else {
      getPetition();
      setShow(true);
    }
  }, [convocationId])

  return (
      show && (
        loading ? (
            <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
            <div className="SignProcedureSuccess">
                <div className="w-100 d-flex justify-content-center">
                    <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="SignProcedureSuccess__logo" />
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="SignProcedureSuccess__container">
                        <div className="row m-0 justify-content-center">
                            <div className="row m-0 justify-content-center">
                                <img
                                    src={`${DisplayUtils.isMobile() ? '/misc/icons/doc-success-2.png' : '/misc/icons/doc-success.png'}`}
                                    alt="shop-icon"
                                    className="SignProcedureSuccess__container__icon"
                                />
                            </div>
                            <h6>{t('SignProcedure.Success.title')}</h6>
                            <p>
                                {t('SignProcedure.Success.description')}
                            </p>
                            <div className='SignProcedureSuccess__container__request-data align-center'>
                              <div><b>{t('SignProcedure.Success.registerNumber')}:</b> {registrationNumber} </div>
                              <div><b>{t('SignProcedure.Success.registerDate')}:</b> {DateUtils.getFormatedDate(presentationTime)} </div>
                            </div>
                        </div>
                        <div className="SignProcedureSuccess__container__doc-card">
                            <img
                                className="SignProcedureSuccess__container__doc-card__icon"
                                src="/misc/icons/list-info-pink.png"
                                alt="item"
                            />
                            <div className="SignProcedureSuccess__container__doc-card__info">
                                <span className="SignProcedureSuccess__container__doc-card__info__title">
                                    {t('Documents.RECEIPT_TEMPLATE_BENEFICIARY2')}
                                </span>
                            </div>
                            {fileId && (
                                <img
                                    className="SignProcedureSuccess__container__doc-card__download"
                                    src="/misc/icons/download.png"
                                    alt="item"
                                    onClick={() => window.open(`/pdf-viewer/?id=${fileId}`)}
                                />
                            )}
                        </div>
                        <div className="SignProcedureSuccess__container__buttons">
                            <Button
                                className="buttonPrimaryLarge"
                                text={t('Globals.accept2')}
                                styles={{ backgroundColor: `${DisplayUtils.isMobile() ? '#34fdfe' : '#34fdfe'}`, color: `${DisplayUtils.isMobile() ? '#381940' : '#381940'}` }}
                                action={() => history.push('/')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
      )
  );
};

export default SignProcedureSuccess;
