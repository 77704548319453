import { useTranslation } from "react-i18next";
import { DateUtils } from "../../../../../utils";
import './ChangeEmailCard.scss';
import { useHistory } from "react-router-dom";

export const ChangeEmailCard = ({
  procedure, petition, hasAction = true
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const clickable = hasAction && ['PENDING', 'DATA_COMPLETED', 'REGISTERED'].includes(procedure.status);

  const success = procedure.verified === 'SUCCESS' || procedure.verified === undefined;

  return (
    <div key={procedure.id} className="ChangeEmailCard" 
      style={{ cursor: clickable ? 'pointer' : null }}
      onClick={clickable ? () => {
        if (procedure.status === 'REGISTERED') history.push(`/petitions/${petition.petitionId}/change-email/${procedure.id}`, {
          petition: petition
        })
        else history.push(`/petitions/${petition.petitionId}/change-email/new`, {
          changeEmail: true, procedureId: procedure.id, petition
        })
      } : null}
    >
      <div className="ChangeEmailCard-container">
        <img 
          className='icon-left'
          src={procedure.status === 'REGISTERED' && success === true ? "/misc/icons/step-ok.png" : "/misc/icons/status-pendingDoc.png"}
        />
        <div className="document-data">
          <div className="document-data-row">
            <div className="document-column">
              <div className="document-column-title">{t('Petitions.ChangeEmail.ChangeEmailList.status')}</div>
              <div className="document-column-value">{procedure.statusTitle}</div>
            </div>
            <div className="document-column">
              <div className="document-column-title">{t('Petitions.ChangeEmail.ChangeEmailList.creationDate')}</div>
              <div className="document-column-value">{DateUtils.getFormatedDate(procedure.creationTime)}</div>
            </div>
            <div className="document-column">
              <div className="document-column-title">{procedure.status === 'REGISTERED' ? t('Petitions.ChangeEmail.ChangeEmailList.presentationDate') : t('Petitions.ChangeEmail.ChangeEmailList.updateTime')}</div>
              <div className="document-column-value">{DateUtils.getFormatedDate(procedure.statusTime)}</div>
            </div>
            {
              procedure.registerNumber && (
                <div className="document-column">
                  <div className="document-column-title">{t('Petitions.ChangeEmail.ChangeEmailList.registerNumber')}</div>
                  <div className="document-column-value">{procedure.registerNumber}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {
        !success && (
          <div className="change-email-warning">
            <span>
              {t("Petitions.ChangeEmail.ChangeEmailList.pending")}
            </span>
          </div>
        )
      }
    </div>
  );
}