import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Loading } from '../../components';
import { DisplayUtils, constants } from '../../utils';
import './Init.scss';
import { useLanguageContext } from '../../contexts/LanguageContext';
import { useErrorsContext } from '../../contexts/ErrorsContext';
import { getClaveDataSso } from '../../services/claveService';
import { useParametrics } from '../../contexts/ParametricsContext';
import { useState } from 'react';

const Init = () => {
  const history = useHistory();
  const {showError} = useErrorsContext();
  const { t } = useTranslation();
  const { currentLanguage, changeLanguage } = useLanguageContext();
  const { convocationId, convocationOpen, convocationYear } = useParametrics();

  const [loading, setLoading] = useState(false);

  const initClave = async () => {
    try {
      setLoading(true)
      const response = await getClaveDataSso(convocationId);
      try {
        localStorage.setItem('claveData', JSON.stringify({
          relayState: response.relayState,
        }));
        const child = window.open("", "_self");
        const html = constants.getClaveHtml(response.redirectForm,response.samlRequest,response.relayState)
        child.document.write(html);
        child.document.close();
      } catch(e) {
        setLoading(false);
        showError(e);
      }
    } catch(error) {
      setLoading(false);
      showError(error);
    }
  }

  if (loading) return (
    <div className='vh-100 d-flex justify-content-center align-items-center'>
        <Loading />
    </div>
  )

  return (
      <div className="Init">
        {DisplayUtils.isMobile() && <div className="Init__concertBackground"><img src="/misc/img/concert-background.png" alt="back" /></div>}
        <img className="Init__18" src="/misc/icons/18.png" alt="18" />
        <img className="Init__mainLogo" src="/misc/icons/white_logo_simple.png" alt="logo" />
        <div className='Init__containerLang'>
          <div className="Init__containerLang__lang">
            <div className={`Init__containerLang__lang__tooltip ${currentLanguage !== "es" ? "disabled" : ""}`} onClick={() => changeLanguage("es")}>
              <img src='/misc/icons/newFlags/espanol.svg' alt='lang' />
              <span className="tooltiptext">{t('Language.spanish')}</span>
            </div>
            <div className={`Init__containerLang__lang__tooltip ${currentLanguage !== "ca" ? "disabled" : ""}`} onClick={() => changeLanguage("ca")}>
              <img src='/misc/icons/newFlags/catalan.svg' alt='lang' />
              <span className="tooltiptext">{t('Language.catalan')}</span>
            </div>
            <div className={`Init__containerLang__lang__tooltip ${currentLanguage !== "ga" ? "disabled" : ""}`} onClick={() => changeLanguage("ga")}>
              <img src='/misc/icons/newFlags/gallego.svg' alt='lang' />
              <span className="tooltiptext">{t('Language.gallego')}</span>
            </div>
            <div className={`Init__containerLang__lang__tooltip ${currentLanguage !== "eu" ? "disabled" : ""}`} onClick={() => changeLanguage("eu")}>
              <img src='/misc/icons/newFlags/euskera.svg' alt='lang' />
              <span className="tooltiptext">{t('Language.euskera')}</span>
            </div>
            <div className={`Init__containerLang__lang__tooltip ${currentLanguage !== "va" ? "disabled" : ""}`} onClick={() => changeLanguage("va")}>
              <img src='/misc/icons/newFlags/valenciano.svg' alt='lang' />
              <span className="tooltiptext">{t('Language.valenciano')}</span>
            </div>
          </div>
        </div>
        {DisplayUtils.isMobile() && (
          <div className="Init__icon">
            <img src={`/misc/icons/beneficiarios-${currentLanguage}.png`} alt="icon-group" className="Init__icon__main" />
          </div>
        )}
        <div className="Init__content">
          {!DisplayUtils.isMobile() && (
            <>
              <div className="Init__content__backConcert">
                <img src='/misc/img/concert-background.png' alt='back' />
              </div>
              <div className="Init__content__backGrey" />
            </>
          )}
          {!DisplayUtils.isMobile() && (
            <div className="Init__icon">

              <img src={`/misc/icons/beneficiarios-${currentLanguage}.png`}  alt="icon-group" className="Init__icon__main" />
            </div>
          )}
          <div className='Init__content__box'>
            {/* <h6 className="Init__content__box__title">{t('Init.title')} <b>2023</b></h6> */}
            <h6 className="Init__content__box__title">{t('Init.title')}</h6>
            <div className="Init__content__box__buttons">
              <Button
                text={t('InitSelector.init')}
                className="buttonPrimaryLarge"
                styles={{ backgroundColor: '#34fdfe', color: '#381940', marginBottom: '23px' }}
                action={() => {
                  history.push('/login');
                }}
              />
              <div className='Init__content__box__buttons__clave'>
                <Button
                  className={"buttonPrimaryLarge"}
                  styles={{ backgroundColor: '#ffffff', color: '#381940', marginBottom: '23px' }}
                  loading
                  component={(
                    <div style={{ display: "flex", justifyContent: "center", gap: 10, alignItems: "center" }}>
                      {t('InitSelector.initClave')}
                      <img src='/misc/icons/clave_modal.png' height={30} />
                    </div>
                  )}
                  action={initClave}
                />
              </div>
              {convocationOpen && (
                <Button
                  text={t('InitSelector.register')}
                  className="buttonPrimaryLarge"
                  styles={{ backgroundColor: '#ffffff', color: '#381940', marginBottom: '23px' }}
                  action={() => {
                    history.push('/signup');
                  }}
                />
              )}
            <div
              className="d-flex justify-content-center flex-column align-items-start"
              style={{ marginBottom: DisplayUtils.isMobile() ? '12px' : '', maxWidth: "650px" }}
            >
              {/* <p dangerouslySetInnerHTML={{ __html: t('Info.applyInfo') }} className="Init__content__box__buttons__infoTime" /> */}
              <p dangerouslySetInnerHTML={{ __html: t('Info.helpTitle') }} className="Init__content__box__buttons__infoTime" />
              {convocationYear === '2023' && (<p dangerouslySetInnerHTML={{ __html: t('Info.correosInfo') }} className="Init__content__box__buttons__infoTime" />)}
              <p dangerouslySetInnerHTML={{ __html: t('Info.fnmtInfo') }} className="Init__content__box__buttons__infoTime" />
            </div>
            <span className='Init__content__box__buttons__info'><Link to='/info'>{t('Login.info')}</Link></span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Init;
