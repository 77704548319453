import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import './Corrections.scss';
import { useErrorsContext } from '../../../../contexts/ErrorsContext';
import { useEffect, useState } from 'react';
import { Loading, NavMenu } from '../../../../components';
import { useParametrics } from '../../../../contexts/ParametricsContext';
import { getCorrections } from '../../../../services/procedureServices';
import { PetitionCard } from '../../../../components/petitionCard/PetitionCard';
import { CorrectionCard } from './CorrectionCard/CorrectionCard';

const Corrections = () => {

  const { t } = useTranslation();
  const { convocation, convocationId } = useParametrics();
  const { showError } = useErrorsContext();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [petition, setPetition] = useState(null);
  const [corrections, setCorrections] = useState(null);

  const _getCorrections = async () => {
    try {
      setLoading(true);
      const resp = await getCorrections(petition.petitionId);
      setCorrections(resp);
      setLoading(false);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.petition) {
      return history.replace('/petitions')
    }
    setPetition(location.state.petition);
  }, [convocationId]);

  useEffect(() => {
    if(petition) _getCorrections();
  }, [petition])

  return (
    <NavMenu>
      <div className="Corrections">
        <div className="content">
          {
            loading ? (
              <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
              </div>
            ) : (
              <>
                <div className="w-100 d-flex justify-content-center">
                  <div className="header">
                    <img src='/misc/icons/arrow-left-blue.svg' alt='' onClick={() => {
                      history.goBack();
                    }}/>
                    <h6>{t('Petitions.correction.title')} </h6>
                  </div>
                </div>
                <div className="petitions">
                  <PetitionCard convocationTitle={convocation?.title} petition={petition} isButton={false}/>
                </div>
                <div className="corrections">
                  {
                    corrections?.map((correction) => {
                      return (
                        <CorrectionCard 
                          key={correction.id}
                          correction={correction}
                          petition={petition}
                        />
                      )
                    })
                  }
                </div>
              </>
            )
          }
        </div>
      </div>
    </NavMenu>
  );
}

export default Corrections;