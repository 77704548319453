import { useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import './PetitionSignup.scss';
import { useErrorsContext } from '../../../../contexts/ErrorsContext';
import { useLanguageContext } from '../../../../contexts/LanguageContext';
import { useParametrics } from '../../../../contexts/ParametricsContext';
import { Button, Loading } from '../../../../components';
import MainLayout from '../../../../layouts/mainLayout/MainLayout';
import { DisplayUtils, constants } from '../../../../utils';
import { petitionSignup } from '../../../../services/petitionServices';

const PetitionSignup = () => {
  const history = useHistory();
  const location = useLocation();
  const correosSignup = location?.state?.correos;
  const { t } = useTranslation();
  const { showError } = useErrorsContext();
  const { currentLanguage, changeLanguage } = useLanguageContext();
  const { convocationId } = useParametrics();
  const { petitionId } = useParams();

  const localData = JSON.parse(localStorage.getItem('dataSignup'));
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: localData?.email || ''
    },
  });
  const password = useRef();
  const confirmPassword = useRef();
  const email = useRef();
  const confirmEmail = useRef();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [captchaOk, setCaptchaOk] = useState(false);

  password.current = watch('password', '');
  confirmPassword.current = watch('confirmPassword', '');
  email.current = watch('email', '');
  confirmEmail.current = watch('confirmEmail', '');

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await petitionSignup({
        convocationId,
        petitionId,
        email: data.email,
        password: data.password,
      })
      history.replace(`/petitions/${petitionId}/signup/success`, {redirect: true});
    } catch(e) {
      setCaptchaOk(false);
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangeRecaptcha = () => {
    setCaptchaOk(true);
  }

  const handleCopyCutPaste = (event) => {
    event.preventDefault();
  };

  const exit = () => {
    history.replace("/");
  }

  return (
    <div className="PetitionSignup">
      {loading ? (
        <div className='vh-100 d-flex justify-content-center align-items-center'>
          <Loading />
        </div>
      ) : (
        <>
          <MainLayout
            title={t('PetitionSignup.title')}
            onBack={exit}
          >
            <div className="PetitionSignup__container__form">
              {correosSignup && (
                <div className='PetitionSignup__containerLang'>
                  <div className="PetitionSignup__containerLang__lang">
                    <div className={`PetitionSignup__containerLang__lang__tooltip ${currentLanguage !== "es" ? "disabled" : ""}`} onClick={() => changeLanguage("es")}>
                      <img src='/misc/icons/es.png' alt='lang' />
                      <span className="tooltiptext">{t('Language.spanish')}</span>
                    </div>
                    <div className={`PetitionSignup__containerLang__lang__tooltip ${currentLanguage !== "ca" ? "disabled" : ""}`} onClick={() => changeLanguage("ca")}>
                      <img src='/misc/icons/ca.png' alt='lang' />
                      <span className="tooltiptext">{t('Language.catalan')}</span>
                    </div>
                    <div className={`PetitionSignup__containerLang__lang__tooltip ${currentLanguage !== "ga" ? "disabled" : ""}`} onClick={() => changeLanguage("ga")}>
                      <img src='/misc/icons/gallego.svg' alt='lang' />
                      <span className="tooltiptext">{t('Language.gallego')}</span>
                    </div>
                    <div className={`PetitionSignup__containerLang__lang__tooltip ${currentLanguage !== "eu" ? "disabled" : ""}`} onClick={() => changeLanguage("eu")}>
                      <img src='/misc/icons/euskera.svg' alt='lang' />
                      <span className="tooltiptext">{t('Language.euskera')}</span>
                    </div>
                    <div className={`PetitionSignup__containerLang__lang__tooltip ${currentLanguage !== "va" ? "disabled" : ""}`} onClick={() => changeLanguage("va")}>
                      <img src='/misc/icons/valenciano.svg' alt='lang' />
                      <span className="tooltiptext">{t('Language.valenciano')}</span>
                    </div>
                  </div>
                </div>
              )}
              <p className="PetitionSignup__container__description">{t('PetitionSignup.description')}</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label> {t('PetitionSignup.email.label')}</label>
                <div className="PetitionSignup__container__form__box">
                  <input
                    type="text"
                    placeholder={t('PetitionSignup.email.placeholder')}
                    className={`${errors.email ? 'warning' : ''}`}
                    {...register('email', {
                      required: t('Globals.required'),
                      validate: (value) =>
                        constants.EMAIL_PATTERN.test(value) || t('Errors.notValid'),
                    })}
                    onBlur={() => trigger('confirmEmail')}
                    onCopy={handleCopyCutPaste}
                    onCut={handleCopyCutPaste}
                    onPaste={handleCopyCutPaste}
                  />
                  <img src="/misc/icons/email.png" alt="icon" style={{ width: '23px' }} />
                </div>
                {errors.email && (
                  <p className="PetitionSignup__container__form__error">{errors.email.message}</p>
                )}
                <label> {t('PetitionSignup.confirmEmail.label')}</label>
                <div className="PetitionSignup__container__form__box">
                  <input
                    type="text"
                    placeholder={t('PetitionSignup.confirmEmail.placeholder')}
                    className={`${errors.confirmEmail ? 'warning' : ''}`}
                    {...register('confirmEmail', {
                      required: t('Globals.required'),
                      validate: (value) => {
                        return value === email.current || t('Errors.emailNotEqual');
                      }
                    })}
                    onBlur={() => trigger('email')}
                    onCopy={handleCopyCutPaste}
                    onCut={handleCopyCutPaste}
                    onPaste={handleCopyCutPaste}
                  />
                  <img src="/misc/icons/email.png" alt="icon" style={{ width: '23px' }} />
                </div>
                {errors.confirmEmail && (
                  <p className="PetitionSignup__container__form__error">{errors.confirmEmail.message}</p>
                )}
                {!correosSignup && (
                  <>
                    <label>{t('PetitionSignup.password.label')}</label>
                    <div className="PetitionSignup__container__form__box">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder={t('PetitionSignup.password.placeholder')}
                        className={`${errors.password ? 'warning' : ''}`}
                        {...register('password', {
                          required: t('Globals.required'),
                          onChange: () => {
                            setTimeout(() => {
                              if(confirmPassword.current !== '') trigger('confirmPassword');
                            }, 100)
                          },
                          validate: (value) => {
                            return constants.PASSWORD_PATTERN.test(value) || t('PetitionSignup.password.invalidFormat');
                          }
                        })}
                        onCopy={handleCopyCutPaste}
                        onCut={handleCopyCutPaste}
                        onPaste={handleCopyCutPaste}
                      />
                      <img src={showPassword ? '/misc/icons/eye-show.png' : '/misc/icons/eye-hide.png'} alt="icon" style={{ width: showPassword ? '26px' : '24px', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                    </div>
                    {errors.password && (
                      <p className="PetitionSignup__container__form__error">{errors.password.message}</p>
                    )}

                    <label>{t('PetitionSignup.confirm.label')}</label>
                    <div className="PetitionSignup__container__form__box">
                      <input
                        type={showRepeatPassword ? 'text' : 'password'}
                        placeholder={t('PetitionSignup.confirm.placeholder')}
                        className={`${errors.confirmPassword ? 'warning' : ''}`}
                        {...register('confirmPassword', {
                          required: t('Globals.required'),
                          validate: (value) => {
                            return value === password.current || t('Errors.confNotEqual');
                          }
                        })}
                        onCopy={handleCopyCutPaste}
                        onCut={handleCopyCutPaste}
                        onPaste={handleCopyCutPaste}
                      />
                      <img src={showRepeatPassword ? '/misc/icons/eye-show.png' : '/misc/icons/eye-hide.png'} alt="icon" style={{ width: showRepeatPassword ? '26px' : '24px', cursor: 'pointer' }} onClick={() => setShowRepeatPassword(!showRepeatPassword)} />
                    </div>
                    {errors.confirmPassword && (
                      <p className="PetitionSignup__container__form__error">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                    <p
                      className="PetitionSignup__container__description"
                      style={{ marginBottom: '32px', marginTop: '20px' }}
                    >
                      {t('PetitionSignup.required')}
                    </p>
                  </>
                )}
                <p dangerouslySetInnerHTML={{ __html: t('PetitionSignup.info') }} className="PetitionSignup__container__form__info" style={{ marginTop: correosSignup ? '20px' : '0px' }} />
                <div className="d-flex justify-content-center" style={{ marginTop: "24px", marginBottom: DisplayUtils.isMobile() ? '24px' : '24px' }}>
                  <ReCAPTCHA
                    sitekey="6LclvPAgAAAAAK7ePe7Kb8sSKkMOjAlkUnrgzOXO"
                    onChange={onChangeRecaptcha}
                  />
                </div>
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <Button
                    className="buttonPrimaryLarge"
                    text={t('Globals.signup')}
                    disabled={!captchaOk || Object.keys(errors).length !== 0 }
                    type="submit"
                    styles={{
                      backgroundColor: '#34fdfe',
                      color: '#381940',
                      marginBottom: '23px',
                    }}
                  />
                  <span className="RequestInfo__container__later" onClick={exit}>
                      {t('Join.requestInfo.later')}
                  </span>
                </div>
              </form>
            </div>
          </MainLayout>
        </>
      )}
    </div>
  );
};

export default PetitionSignup;
