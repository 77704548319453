import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Loading } from '../../components';
import { DisplayUtils } from '../../utils';
import { useErrorsContext } from '../../contexts/ErrorsContext';
import { searchByCsv } from '../../services/fileServices';
import './CsvSearch.scss';

const CsvSearch = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { showError } = useErrorsContext();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const response = await searchByCsv(data.csv);
            const { file, name } = response;
            let a = document.createElement("a");
            a.href = "data:application/octet-stream;base64,"+file;
            a.download = name
            a.click();
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        loading ? (
            <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
            <div className="CsvSearch">
                <div className="w-100 d-flex justify-content-center">
                {!DisplayUtils.isMobile() ? (
                    <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="CsvSearch__logo" />
                ) : (
                    <div className="CsvSearch__title">
                    <Button
                        icon="/misc/icons/close-blue.png"
                        action={() => history.push('/')}
                        className="Button__transparent"
                    />
                    <h6>{t('CsvSearch.head')}</h6>
                    </div>
                )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="CsvSearch__container">
                        {!DisplayUtils.isMobile() && (
                        <div className="CsvSearch__container__title">
                            <Button
                            icon="/misc/icons/close-pink.png"
                            action={() => history.push('/')}
                            className="Button__transparent"
                            />
                            <h6>{t('CsvSearch.head')}</h6>
                        </div>
                        )}
                        <div className='CsvSearch__container__content'>
                            <span className='CsvSearch__container__content__description'>{t('CsvSearch.info')}</span>
                            <div className="CsvSearch__container__content__form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="CsvSearch__container__content__form__box mt-3">
                                    <input
                                        type="text"
                                        placeholder={t('CsvSearch.placeholder')}
                                        className={`${errors.csv ? 'warning' : ''} pe-4`}
                                        {...register('csv', { required: t('Globals.required') })}
                                    />
                                </div>
                                {errors.csv && (
                                    <p className="CsvSearch__container__content__form__error">{errors.csv.message}</p>
                                )}
                                <div className="CsvSearch__container__content__form__actions">
                                    <Button
                                        className="buttonPrimaryLarge"
                                        text={t('CsvSearch.action')}
                                        styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                        type="submit"
                                    />
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default CsvSearch;