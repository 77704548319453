import { useTranslation } from 'react-i18next';
import { Modal, ViewModal } from '../../../index';
import '../Modal.scss';

const CardInfoModal = ({hideModal, showingModal, onAccept}) => {
  const {t} = useTranslation();

  return showingModal ? (
    <Modal 
      isShowingModal={showingModal}
      component={(
        <ViewModal
          action={() => onAccept()}
          textBtn={t('Globals.accept')}
          imageSource="/misc/icons/warning.png"
          title={t('Globals.remember')}
          description={t('Join.notificationsAddressInfo.cardInfo2')}
          secondTextBtn={t('Globals.cancel')}
          secondAction={() => hideModal()}
        />
      )}
    />
  ) : null;
};

export default CardInfoModal;
