/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { Loading, Button } from '../../../components';
import { DisplayUtils } from '../../../utils';
import './CardTypeSelector.scss';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { getCards, saveCards } from '../../../services/petitionServices';

const CardTypeSelector = () => {

    const { t } = useTranslation();
    const { convocationId } = useParametrics();
    const location = useLocation();
    const history = useHistory();
    const { showError } = useErrorsContext();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nfcCheck, setNfcCheck] = useState(true);
    const [cardCheck, setCardCheck] = useState(false);

    const userType = location?.state?.userType;
    const petitionId = location?.state?.petitionId;
    const petitionHasAccount = location?.state?.petitionHasAccount;

    const getPetition = async () => {
      try {
        const cards = await getCards(convocationId, userType.toUpperCase(), petitionId);
        setNfcCheck(cards.virtualCard);
        setCardCheck(cards.physicalCard);
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
    //   if(!convocationId) return;
    //   if(!location?.state?.cardTypeSelector || !userType || !petitionId) {
    //       history.goBack()
    //   } else {
        //getPetition();
        setShow(true);
      //}
    }, [convocationId]) //eslint-disable-line

    const sendCardType = async () => {
      if(!nfcCheck && !cardCheck) {
        showError('invalidNoNfcAndNoCard');
        return;
      }
      try {
        setLoading(true);
        await saveCards(convocationId, userType.toUpperCase(), petitionId, {
          virtualCard: nfcCheck,
          physicalCard: cardCheck
        });
        history.push({ pathname: `/${userType}/join/notifications-address`, state: { notificationsAddressInfo: true, userType: userType, petitionId: petitionId, petitionHasAccount } })
      } catch(e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    }
    
    return (
        show && (
            loading ? (
                <div className='vh-100 d-flex justify-content-center align-items-center'>
                    <Loading />
                </div>
            ) : (
                <div className='CardTypeSelector'>
                    <div className="w-100 d-flex justify-content-center">
                        {!DisplayUtils.isMobile() ? (
                            <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="CardTypeSelector__logo" />
                        ) : (
                            <div className="CardTypeSelector__title">
                                <Button
                                    icon="/misc/icons/arrow-left-blue.svg"
                                    action={() => history.push({ pathname: `/${userType}/join/beneficiary-data`, state: { beneficiaryData: true, userType: userType, petitionId: petitionId, petitionHasAccount } })}
                                    className="Button__transparent"
                                />
                                <h6>{t('Join.cardTypeSelector.head')}</h6>
                            </div>
                        )}
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="CardTypeSelector__container">
                            {!DisplayUtils.isMobile() && (
                                <div className="CardTypeSelector__container__head">
                                    <Button
                                        icon="/misc/icons/arrow-left-pink.svg"
                                        action={() => history.push({ pathname: `/${userType}/join/beneficiary-data`, state: { beneficiaryData: true, userType: userType, petitionId: petitionId, petitionHasAccount } })}
                                        className="Button__transparent"
                                        styles={{width: '50px'}}
                                    />
                                    <h6>{t('Join.cardTypeSelector.head')}</h6>
                                </div>
                            )}
                            <span className='CardTypeSelector__container__description'>{t('Join.cardTypeSelector.description')}</span>
                            <span className='CardTypeSelector__container__typeTitle'>{t('Join.cardTypeSelector.nfcCheckTitle')}</span>
                            <p className='CardTypeSelector__container__typeDescription'>{t('Join.cardTypeSelector.nfcCheckDescription')}</p>
                            <div className='CardTypeSelector__container__checks'>
                                <div className='d-flex' onClick={() => { 
                                        setNfcCheck(true);
                                        setCardCheck(false);
                                    }}>
                                    <div className="d-flex align-items-center">
                                        <span>{t('Globals.yes')}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={nfcCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                            alt="check"
                                            className="CardTypeSelector__container__checks__radio"
                                        />
                                    </div>
                                </div>
                                <div className='d-flex ms-4' onClick={() => { setNfcCheck(false) }}>
                                    <div className="d-flex align-items-center">
                                    <span>{t('Globals.no')}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={!nfcCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'}
                                            alt="check"
                                            className="CardTypeSelector__container__checks__radio"
                                        />
                                    </div>
                                </div>
                            </div>
                            <span className='CardTypeSelector__container__typeTitle mt-5'>{t('Join.cardTypeSelector.cardCheckTitle')}</span>
                            <p className='CardTypeSelector__container__typeDescription'>{t('Join.cardTypeSelector.cardCheckDescription')}</p>
                            <div className='CardTypeSelector__container__checks'>
                                <div className='d-flex' onClick={() => { 
                                        setNfcCheck(false);
                                        setCardCheck(true);
                                    }}>
                                    <div className="d-flex align-items-center">
                                        <span>{t('Globals.yes')}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={cardCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
                                            alt="check"
                                            className="CardTypeSelector__container__checks__radio"
                                        />
                                    </div>
                                </div>
                                <div className='d-flex ms-4' onClick={() => { setCardCheck(false) }}>
                                    <div className="d-flex align-items-center">
                                    <span>{t('Globals.no')}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={!cardCheck ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'}
                                            alt="check"
                                            className="CardTypeSelector__container__checks__radio"
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className='CardTypeSelector__container__cardInfo m-0 mt-3 mb-4'>{t('Join.cardTypeSelector.cardInfo')}</p>
                            <p className='m-0 mt-5 mb-4' dangerouslySetInnerHTML={{ __html: t('Globals.noOptional') }} />
                            <div className="CardTypeSelector__container__actions">
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('Join.cardTypeSelector.later')}
                                    styles={{ backgroundColor: '#f4f5fb', color: '#312c6a' }}
                                    action={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}
                                />
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('Join.cardTypeSelector.action')}
                                    styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                    action={() => sendCardType()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    )
}

export default CardTypeSelector;