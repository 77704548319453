import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Loading, UploadDoc } from '../../../../../components';
import { useErrorsContext } from '../../../../../contexts/ErrorsContext';
import { DateUtils, DisplayUtils } from '../../../../../utils';
import './Claim.scss';
import { NavMenu } from '../../../../../components';
import { useParametrics } from '../../../../../contexts/ParametricsContext';
import { updateFile, uploadFile } from '../../../../../services/fileServices';
import { getClaim as getClaimService, saveClaim } from '../../../../../services/procedureServices';

const Claim = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { showError } = useErrorsContext();
  const { convocationId } = useParametrics();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [observations, setObservations] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [claimResponse, setClaimResponse] = useState(null);

  const { petitionId } = useParams();

  const procedureId = location?.state?.procedureId;
  const petition = location?.state?.petition;
  const fromSignProcedure = location?.state.fromSignProcedure;

  const getClaim = async () => {
    try {
      setLoading(true);
      const resp = await getClaimService(petitionId, procedureId);        
      if (!fromSignProcedure && resp.status === 'DATA_COMPLETED') {
        goToSignProcedure();
      }
      setClaimResponse(resp);
      setObservations(resp.observations);
      setLoading(false);
    } catch (e) {
      showError(e);
      history.goBack();
    }
  }

  useEffect(() => {
    if(!convocationId) return;
    console.log(location?.state?.claim, procedureId, petitionId, petition);
    if(!location?.state?.claim || !procedureId || !petitionId || !petition) {
        history.goBack()
    } else {
      getClaim();
      setShow(true);
    }
  }, [convocationId])

  const upload = async () => {
    try {
      setLoading(true);
      for (const fileToUpload of filesToUpload) {
        const formData = new FormData();
        formData.append('file', fileToUpload.file);
        const requiredFile = documents.find(f => f.fileType === fileToUpload.fileType);
        let fileId = requiredFile.fileId;
        const fileType = fileToUpload.fileType;
        const petitionProcedureId = procedureId;
        if (fileId) await updateFile({
          convocationId, petitionId, fileId, fileType, formData, petitionProcedureId
        });
        else fileId = await uploadFile({
          convocationId, petitionId, fileType, formData, petitionProcedureId
        });
      }
      await saveClaim(petitionId, procedureId, observations);
      goToSignProcedure();
    } catch(e) {
      console.log(e);
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  const goToSignProcedure = () => {
    //TODO(enol): set proper params
    history.replace('/sign-procedure', {
      signProcedure: true,
      procedureId,
      petitionId,
      userType: petition.accountType,
      requestType: 'CLAIM_REQUEST',
      fileType: 'CLAIM_REQUEST',
      previousPageUrl: `/petitions/${petition.petitionId}/claims/new`,
      previousPageState: {
        claim: true, procedureId, petition
      },
    });
  }

  const documents = claimResponse?.files ?? []; 

  return (
    show && (
      loading ? (
        <div className='vw-100 vh-100 d-flex justify-content-center align-items-center'>
            <Loading />
        </div>
    ) : (
      <NavMenu>
        <div className='Claim'>
            <div className="w-100 d-flex justify-content-center">
                {!DisplayUtils.isMobile() ? (
                    <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="Claim__logo" />
                ) : (
                    <div className="Claim__title">
                        <Button
                            icon="/misc/icons/close-pink.png"
                            action={() => history.goBack()}
                            className="Button__transparent"
                        />
                        <h6>{t('RequestStatus.claimStatus.head')}</h6>
                    </div>
                )}
            </div>
            <div className="w-100 d-flex justify-content-center">
                <div className="Claim__container">
                    {!DisplayUtils.isMobile() && (
                        <div className="Claim__container__head">
                            <Button
                                icon="/misc/icons/close-pink.png"
                                action={() => history.goBack()}
                                className="Button__transparent"
                                styles={{width: '50px'}}
                            />
                            <h6>{t('RequestStatus.claimStatus.head')}</h6>
                        </div>
                    )}
                    <div className="Claim__container__content">
                        <span className="Claim__container__content__description">{t('RequestStatus.claimStatus.description')}</span>
                        <div className="Claim__container__content__applicant-observations">
                          <span className='Claim__container__content__document__title'>{t('RequestStatus.claimStatus.observations')}</span>
                          <textarea 
                            maxLength={1500}
                            rows={4} 
                            value={observations} 
                            placeholder={t('RequestStatus.claimStatus.observationsPlaceholder')}
                            onChange={(event) => {
                              setObservations(event.target.value)
                            }}
                          />
                        </div>
                        <div className='Claim__container__content__document'>
                          <span className='Claim__container__content__document__title'>{t('RequestStatus.claimStatus.fileTitle')}</span>
                          {
                            documents?.map((item, index) => (
                              <UploadDoc
                                key={index}
                                fileId={item.fileId}
                                title={item.title}
                                info={item.fileId ? t('Join.documentation.uploadDate', { date: DateUtils.getFormatedDate(item.updatedTime), time: DateUtils.getFormatedTime(item.updatedTime)}) : null}
                                type={item.fileType}
                                setFilesToUpload={setFilesToUpload}
                                filesToUpload={filesToUpload} 
                              />
                            ))
                          }
                        </div>
                        <div className="Claim__container__content__actions">
                            <Button
                                className="buttonPrimaryLarge"
                                text={t('RequestStatus.claimStatus.send')}
                                styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                action={() => upload()}
                                disabled={observations === ""}
                            />
                            
                        </div>
                        <span className="Claim__container__content__skip" onClick={() => history.goBack()}>
                            {t('RequestStatus.claimStatus.skip')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
      </NavMenu>
    )
  )
)
}

export default Claim;