import { useHistory, useLocation } from 'react-router-dom';
import './UserTypeSelector.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, Loading, Modal, ViewModal } from '../../../components';
import { DisplayUtils, StatusUtils } from '../../../utils';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { getAccountType, saveAccountType } from '../../../services/petitionServices';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useAuthContext } from '../../../contexts/AuthContext';

const UserTypeSelector = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { convocationId } = useParametrics();
  const { isSso } = useAuthContext();
  const { showError } = useErrorsContext();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState('beneficiary');
  const userType = location?.state?.userType;
  const petitionId = location?.state.petitionId;
  const petitionHasAccount = location?.state?.petitionHasAccount;
  const [petition, setPetition] = useState(null);

  const getPetition = async () => {
    try {
      const petition = await getAccountType(convocationId, userType.toUpperCase(), petitionId);
      setPetition(petition);
      if(petition.accountType === 'REPRESENTATIVE') setSelectedUserType('representative');
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      setLoading(false);
    }
  }

  const changeAccountType = async () => {
    try {
      setLoading(true);
      await saveAccountType(convocationId, userType.toUpperCase(), petitionId, {
        accountType: selectedUserType.toUpperCase()
      });
      if (isSso) history.push({ pathname: `/${selectedUserType}/join/confirm-identity`, state: { confirmIdentity: true, userType: selectedUserType, petitionId: petitionId, petitionHasAccount, } });
      else history.push({ pathname: `/${selectedUserType}/join/clave-verification`, state: { claveVerification: true, userType: selectedUserType, petitionId: petitionId, petitionHasAccount, } });
    } catch(e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    if(!convocationId) return;
    if(!location?.state?.selectUserType) {
        history.goBack()
    } else {
        if(petitionId && userType) getPetition();
        setShow(true);
    }
  }, [convocationId]);

  return (
    show && (
        loading ? (
            <div className='vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
          <>
            <div className='UserTypeSelector'>
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="UserTypeSelector__logo" />
                    ) : (
                        <div className="UserTypeSelector__title">
                            <Button
                                icon="/misc/icons/arrow-left-blue.svg"
                                action={() => { history.push('/join/request-info', { requestInfo: true, userType: userType, petitionId: petitionId, petitionHasAccount, }) }}
                                className="Button__transparent"
                            />
                            <h6>{t('Join.selectUserType.title')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="UserTypeSelector__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="UserTypeSelector__container__head">
                                <Button
                                    icon="/misc/icons/arrow-left-pink.svg"
                                    action={() => { history.push('/join/request-info', { requestInfo: true, userType: userType, petitionId: petitionId, petitionHasAccount, }) }}
                                    className="Button__transparent"
                                    styles={{width: '50px'}}
                                />
                                <h6>{t('Join.selectUserType.title')}</h6>
                            </div>
                        )}
                        <div className="cards">
                          <Card 
                            title={t('Join.selectUserType.applicant')}
                            selected={selectedUserType === 'beneficiary'}
                            userType="beneficiary"
                            onSelect={() => {
                              setSelectedUserType('beneficiary');
                            }}
                          />
                          <Card 
                            title={t('Join.selectUserType.representative')}
                            selected={selectedUserType === 'representative'}
                            userType="representative"
                            onSelect={() => {
                              setSelectedUserType('representative');
                            }}
                          />
                        </div>
                        <div className="message text-center mt-5">
                          {t('Join.selectUserType.message')}
                        </div>
                        <div className="UserTypeSelector__container__actions">
                            <Button
                                className="buttonPrimaryLarge"
                                text={t('Join.selectUserType.continue')}
                                styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                action={() => {
                                  if(petition && petition.accountType.toLowerCase() !== selectedUserType) {
                                    if (isSso) {
                                      if(petition.processStatus.status === StatusUtils.STATUS.CHANGE_ACCOUNT_TYPE) changeAccountType()
                                      else setShowConfirmModal(true);
                                    }
                                    else setShowConfirmModal(true);
                                  }
                                  else {
                                    if (isSso) history.push({ pathname: `/${selectedUserType}/join/confirm-identity`, state: { confirmIdentity: true, userType: selectedUserType, petitionId: petitionId, petitionHasAccount, } });
                                    else history.push({ pathname: `/${selectedUserType}/join/clave-verification`, state: { claveVerification: true, userType: selectedUserType, petitionId: petitionId, petitionHasAccount, } });
                                  }
                                }}
                            />
                        </div>
                        <span className="UserTypeSelector__container__later" onClick={() => history.push({ pathname: `/join/leave`, state: { leave: true } })}>
                            {t('Join.selectUserType.later')}
                        </span>
                    </div>
                </div>
            </div>
            <Modal
              isShowingModal={showConfirmModal}
              component={(
                <ViewModal
                  imageSource={'/misc/icons/warning.png'}
                  title={t('Join.selectUserType.confirmationModal.title')}
                  description={t('Join.selectUserType.confirmationModal.description')}
                  action={() => {
                    setShowConfirmModal(false);
                    changeAccountType();
                  }}
                  secondAction={() => {
                    setShowConfirmModal(false);
                  }}
                  textBtn={t('Join.selectUserType.confirmationModal.textButton')}
                  secondTextBtn={t('Join.selectUserType.confirmationModal.secondTextButton')}
                />
              )}
            />
          </>
        )
    )
  )
}

const Card = ({ title, selected, onSelect, userType  }) => {
  return (
    <div 
      onClick={onSelect}
      className="d-flex flex-column align-items-center user-card"
    >
      <img className='avatar' src={`/misc/icons/userType/user-type-selector-${userType}.png`} alt="" />
      <p className='text'>{title}</p>
      <img 
        className='width-5 check'
        src={selected ? '/misc/icons/radio_active.svg' : '/misc/icons/radio.svg'} 
        alt="check"
      />
    </div>
  );
}

export default UserTypeSelector;