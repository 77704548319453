import { useHistory } from 'react-router-dom';
import { readNotification } from '../../../services/notificationServices';
import { useParametrics } from '../../../contexts/ParametricsContext';

const CardNotification = ({ icon, type, status, isRead, id, date, title }) => {
  const history = useHistory();
  const { convocationId } = useParametrics()

  return (
    <div
      className="Notifications__card shadow"
      onClick={async () => {
        history.push({ pathname: '/notifications/details', state: { id, type, status, date } });
        await readNotification(convocationId, id);
      }}
    >
      <div className="d-flex flex-row align-items-center overflow-hidden">
        <img src={icon} alt="shop-icon" className="Notifications__card__icon" />
        <div className='d-flex flex-column'>
          <span style={{ marginLeft: '10px' }}>{title}</span>
          <span style={{ marginLeft: '10px', color: '#9695a0' }}>{status}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        {!isRead && <div className="Notifications__card__badge"></div>}
      </div>
    </div>
  );
}

export default CardNotification;
