import { useHistory } from 'react-router-dom';
import './InitSelector.scss';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import { Button } from '../../components';

const InitSelector = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="InitSelector">
      <MainLayout 
        title={t('InitSelector.title')}
        onBack={() => { history.goBack() }}
      >
        {/* <div className="cards">
          <Card 
            title={t('InitSelector.applicant')}
            selected={selectedUserType === 'beneficiary'}
            onSelect={() => {
              setSelectedUserType('beneficiary');
            }}
          />
          <Card 
            title={t('InitSelector.representative')}
            selected={selectedUserType === 'representative'}
            onSelect={() => {
              setSelectedUserType('representative');
            }}
          />
        </div> */}
        <div className="image">
          <img className='avatar' src="/misc/icons/userType/user-type-selector-beneficiary.png" alt="" />
        </div>
        <div className="message text-center mt-5">
          {t('InitSelector.message')}
        </div>
        <div className="buttons mt-5">
          <Button 
            text={t('InitSelector.register')}
            className="buttonPrimaryLarge"
            styles={{ backgroundColor: '#f4f5fb', color: '#381940', marginBottom: '23px' }}
            action={() => {
              history.push('/signup');
            }}
          />
          <Button 
            text={t('InitSelector.init')}
            className="buttonPrimaryLarge"
            styles={{ backgroundColor: '#34fdfe', color: '#381940', marginBottom: '23px' }}
            action={() => {
              history.push('/login');
            }}
          />
        </div>
      </MainLayout>
    </div>
  );
}

export default InitSelector;