import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, LogoutModal } from '../../components';
import { constants, DisplayUtils } from '../../utils';
import { useAuthContext } from '../../contexts/AuthContext';
import { useNotificationsContext } from '../../contexts/NotificationsContext';
import { useParametrics } from '../../contexts/ParametricsContext';
import { logout } from '../../services/authServices';
// import { getNotifications } from '../../services/notificationServices';
import useModal from '../../hooks/useModal';
import './Menu.scss';

const Menu = ({ active, closeMenu }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showSubmenus, setShowSubmenus] = useState();
  const [selectedMenu, setSelectedMenu] = useState();
  const { isShowingModal, toggleModal } = useModal();
  const { onLogout, user, isSso } = useAuthContext();
  const { convocationId, convocation } = useParametrics();
  const { badgeCount } = useNotificationsContext();
  const [convocationTitle, setConvocationTitle] = useState();

  useEffect(() => {
    active && setSelectedMenu(active);
  }, [active]);

  // const getNotificationsCount = useCallback(async () => {
  //   try {
  //       const result = await getNotifications();
  //       addBadge(result.filter(item => !item.read).length);
  //   } catch (error) {
  //       console.log(error)
  //   }
  // }, []); // eslint-disable-line

  // useEffect(() => {
  //   let unmounted = false;
  //   if (!unmounted) {
  //     getNotificationsCount();
  //   }

  //   return () => {
  //     unmounted = true;
  //   };
  // }, [getNotificationsCount]);

  const getConvocationInfo = useCallback(async () => {
    try {
      setConvocationTitle(convocation?.title);
    } catch (error) {
      console.log(error)
    }
  }, [convocationId, convocation]); // eslint-disable-line

  useEffect(() => {
    convocationId && convocation && getConvocationInfo();  
  }, [convocationId, convocation]) //eslint-disable-line

  const onClick = (item) => {
    if (item.route === '/logout') {
      toggleModal();
    } else {
      setSelectedMenu(item.name);
      if (item?.submenus?.length > 0) {
        setShowSubmenus(item.name);
        onClickSubmenu(item?.submenus[0]);
      } else {
        setShowSubmenus();
        history.push(`${item.route}`);
        DisplayUtils.isMobile() && closeMenu();
      }
    }
  };

  const onClickSubmenu = (item) => {
    history.push(`${item.route}`);
    DisplayUtils.isMobile() && closeMenu();
  };

  const list = () => {
    let items = DisplayUtils.isMobile()
      ? constants.MENU_BUTTONS.filter((item) => item.name !== 'profile')
      : constants.MENU_BUTTONS;
    return items.filter(i => {
      if (i.name === 'petitions-sso' && !isSso) return false;
      if (i.name === 'petitions' && isSso) return false;
      if (i.name === 'profile' && !user) return false;
      if (i.name === 'change-language' && user) return false;
      if (i.name === 'notifications' && !user) return false;
      if (i.name === 'shops' && !user) return false;
      return true;
    })
  }

  const checkIsMySubmenu = (item) => {
    return item?.submenus?.filter((submenu) => submenu.name === selectedMenu).length > 0;
  };

  const getMenuText = (name) => {
    return t(`Menu.${name}`).toUpperCase();
  };

  const showMenuSection = (item) => {
    return (
      <div key={item.name}>
        <Button
          key={item.name}
          name={item.name}
          text={getMenuText(item.name)}
          icon={
            checkIsMySubmenu(item)
              ? item.icon_active
              : item?.submenus?.length > 0 && showSubmenus === item.name
              ? item.icon_active
              : active === item.name && selectedMenu === item.name
              ? item.icon_active
              : item.icon
          }
          className={
            checkIsMySubmenu(item)
              ? 'Button_menu_active Button_menu'
              : `${
                  item?.submenus?.length > 0 && showSubmenus === item.name
                    ? 'Button_menu_active'
                    : active === item.name && selectedMenu === item.name
                    ? 'Button_menu_active'
                    : ''
                } Button_menu ${item.name === 'logout' ? 'Button_menu_logout' : ''}`
          }
          badge={item.name === 'notifications' ? ((badgeCount && badgeCount > 0) ? badgeCount : undefined) : undefined}
          action={() => onClick(item)}
        />
        {(checkIsMySubmenu(item) || (item?.submenus?.length > 0 && showSubmenus === item.name)) &&
          item?.submenus.map((item) => (
            <div key={item.name}>
              <Button
                name={item.name}
                text={t(`Menu.${item.text}`)}
                className={`${
                  active === item.name ? 'Button_menu_active' : ''
                } Button_menu submenu`}
                badge={undefined}
                action={() => onClickSubmenu(item)}
              />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="Menu">
      <LogoutModal
        showingModal={isShowingModal}
        hideModal={toggleModal}
        onLogout={async () => {
          await logout();
          localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
          localStorage.removeItem(constants.CURRENT_REFRESH_KEY);
          localStorage.removeItem(constants.CURRENT_ID_TOKEN_KEY);
          localStorage.removeItem(constants.CURRENT_USER_KEY);
          localStorage.removeItem(constants.CURRENT_PETITON_STATUS_KEY);
          localStorage.removeItem(constants.KEEP_SESSION);
          onLogout();
          history.push('/');
        }}
      />
      {!DisplayUtils.isMobile() && (
        <div className="Menu__background">
          <img src="/misc/img/menu-background.png" alt="back" />
        </div>
      )}
      {!DisplayUtils.isMobile() && (
        <div className="Menu__logo">
          <img src="/misc/icons/complete_logo_white.svg" alt="logo" />
        </div>
      )}
      {convocationTitle && (
        <div className='Menu__convo-info'>
          <div className='Menu__convo-info__title'>
            <span>{convocationTitle}</span>
          </div>
        </div>
      )}
      {user && (
        <div className='Menu__user-info'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='Menu__user-info__user'>
              <div className='Menu__user-info__user__hello'>{t('Menu.hello')}</div>
              { user?.fullName && (<span className='Menu__user-info__user__name'>{user.fullName}</span>) } 
            </div>
          </div>
          <div className='Menu__user-info__email'>
            { user?.email && (<span>{user.email}</span>) } 
          </div>
        </div>
      )}
      {list().map((item) => showMenuSection(item))}
    </div>
  );
};

export default Menu;
