import { useTranslation } from 'react-i18next';
import { Modal, ViewModal } from '../../../index';
import '../Modal.scss';

const LogoutModal = ({hideModal, showingModal, onLogout}) => {
  const {t} = useTranslation();

  return showingModal ? (
    <Modal 
      isShowingModal={showingModal}
      component={(
        <ViewModal
          action={() => onLogout()}
          textBtn={t('Globals.yes')}
          imageSource="/misc/icons/warning.png"
          title={t('Profile.onLogout')}
          secondTextBtn={t('Globals.no')}
          secondAction={() => hideModal()}
        />
      )}
    />
  ) : null;
};

export default LogoutModal;
