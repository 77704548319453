import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import './AdditionalDocsNew.scss';
import { DateUtils, DisplayUtils } from '../../../../../utils';
import { Button, Loading, NavMenu, UploadDoc } from '../../../../../components';
import { useErrorsContext } from '../../../../../contexts/ErrorsContext';
import { useParametrics } from '../../../../../contexts/ParametricsContext';
import { updateFile, uploadFile } from '../../../../../services/fileServices';
import { getAdditionalDocsProcedure, saveAdditionalDocsProcedure } from '../../../../../services/procedureServices';

const AdditionalDocsNew = () => {

    const { t } = useTranslation();
    const { showError } = useErrorsContext();
    const { convocationId } = useParametrics();
    const location = useLocation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [additionalDocsResponse, setAdditionalDocsResponse] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [observations, setObservations] = useState('');
    const [loading, setLoading] = useState(false);

    const { petitionId } = useParams();

    const procedureId = location?.state?.procedureId;
    const petition = location?.state?.petition;
    const fromSignProcedure = location?.state.fromSignProcedure;

    const getDocuments = async () => {
      try {
        setLoading(true);
        const resp = await getAdditionalDocsProcedure(petitionId, procedureId);
        if (!fromSignProcedure && resp.status === 'DATA_COMPLETED') {
          goToSignProcedure();
        }
        setAdditionalDocsResponse(resp);
        setObservations(resp.observations);
        setLoading(false);
      } catch (e) {
        showError(e);
        history.goBack();
      }
    }

    useEffect(() => {
      if(!convocationId) return;
      if(!location?.state?.additionalDocs || !procedureId || !petitionId || !petition) {
          history.goBack()
      } else {
        getDocuments();
        setShow(true);
      }
    }, [convocationId])

    const uploadAdditionalDocsNew = async () => {
      try {
        setLoading(true);
        for (const fileToUpload of filesToUpload) {
          const formData = new FormData();
          formData.append('file', fileToUpload.file);
          const requiredFile = documents.find(f => f.fileType === fileToUpload.fileType);
          let fileId = requiredFile.fileId;
          const fileType = fileToUpload.fileType;
          const petitionProcedureId = procedureId;
          if (fileId) await updateFile({
            convocationId, petitionId, fileId, fileType, formData, petitionProcedureId
          });
          else fileId = await uploadFile({
            convocationId, petitionId, fileType, formData, petitionProcedureId
          });
        }
        await saveAdditionalDocsProcedure(petitionId, procedureId, observations);
        goToSignProcedure();
      } catch (e) {
        console.log(e);
        showError(e);
      } finally {
        setLoading(false);
      }
    }

    const goToSignProcedure = () => {
      history.replace('/sign-procedure', {
        signProcedure: true,
        procedureId,
        petitionId,
        userType: petition.accountType,
        requestType: 'ADDITIONAL_DOCUMENT',
        fileType: 'ADDITIONAL_DOCUMENT_REQUEST',
        previousPageUrl: `/petitions/${petitionId}/additional-documents/new`,
        previousPageState: {
          additionalDocs: true, procedureId, petition,
        }
      });
    }

    const documents = additionalDocsResponse?.files ?? []; 

    const numFiles = documents.filter((document) => {
      if (document.fileId) return true;
      if (filesToUpload.find((file) => file.fileType === document.fileType)) 
        return true;
      return false;
    }).length;

    console.log(numFiles);
    
    return (
        show && (
          loading ? (
            <div className='vw-100 vh-100 d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
        ) : (
          <NavMenu>
            <div className='AdditionalDocsNew'>
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="AdditionalDocsNew__logo" />
                    ) : (
                        <div className="AdditionalDocsNew__title">
                            <Button
                                icon="/misc/icons/close-pink.png"
                                action={() => history.goBack()}
                                className="Button__transparent"
                            />
                            <h6>{t('RequestStatus.additionalDocs.head')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="AdditionalDocsNew__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="AdditionalDocsNew__container__head">
                                <Button
                                    icon="/misc/icons/close-pink.png"
                                    action={() => history.goBack()}
                                    className="Button__transparent"
                                    styles={{width: '50px'}}
                                />
                                <h6>{t('RequestStatus.additionalDocs.head')}</h6>
                            </div>
                        )}
                        <div className="AdditionalDocsNew__container__content">
                            <span className="AdditionalDocsNew__container__content__description">{t('RequestStatus.additionalDocs.description')}</span>
                            <span className="AdditionalDocsNew__container__content__title">{t('RequestStatus.additionalDocs.sectionDocsTitle')}</span>
                            {
                              documents?.map((item, index) => (
                                <UploadDoc
                                  key={index}
                                  fileId={item.fileId}
                                  title={item.title}
                                  info={item.fileId ? t('Join.documentation.uploadDate', { date: DateUtils.getFormatedDate(item.updatedTime), time: DateUtils.getFormatedTime(item.updatedTime)}) : null}
                                  type={item.fileType}
                                  setFilesToUpload={setFilesToUpload}
                                  filesToUpload={filesToUpload} 
                                />
                              ))
                            }
                            <div className="AdditionalDocsNew__container__content__applicant-observations">
                              <span className='AdditionalDocsNew__container__content__document__title'>{t('RequestStatus.additionalDocs.observations')}</span>
                              <textarea 
                                rows={4} 
                                value={observations} 
                                placeholder={t('RequestStatus.additionalDocs.observationsPlaceholder')}
                                onChange={(event) => {
                                  setObservations(event.target.value)
                                }}
                              />
                            </div>

                            <div className="AdditionalDocsNew__container__content__actions">
                                <Button
                                    className="buttonPrimaryLarge"
                                    text={t('RequestStatus.additionalDocs.send')}
                                    styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
                                    action={uploadAdditionalDocsNew}
                                    disabled={numFiles == 0}
                                />
                            </div>
                            <span className="AdditionalDocsNew__container__content__skip" onClick={() => history.goBack()}>
                                {t('RequestStatus.additionalDocs.skip')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
          </NavMenu>
        )
      )
    )
}

export default AdditionalDocsNew;