const EmptyNotification = ({t}) => (
  <div className="Notifications__content__box__empty">
    <img
      src="/misc/icons/empty-noti.png"
      alt="noti"
      className="Notifications__content__box__empty__image"
    />
    <h6 className="Notifications__content__box__empty__title">{t('Notifications.empty')}</h6>
  </div>
);

export default EmptyNotification