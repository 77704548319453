import { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import {
    TextField,
    ListItemText,
    MenuItem,
    makeStyles,
    FormControl
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DisplayUtils } from '../../../utils';
import { getParametricsLocalities, getParametricsPostalCodes } from '../../../services/parametricsService';
import './AddressForm.scss';

const AddressForm = ({title, errors, register, control, addressTypes, countries, provinces, setValue, getValues, clearErrors, disabled }) => {

    const { t } = useTranslation();
    const [localities, setLocalities] = useState();
    const [postalCodes, setPostalCodes] = useState();
    const [selectedCountry, setSelectedCountry] = useState();

    const handleNumberChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        const max = e.target.maxLength - 1;
        const limitedValue = inputValue.slice(0, max);
        e.target.value = limitedValue;
    }

    const getLocalities = async (provinceId) => {
        const localities = await getParametricsLocalities(provinceId);
        setLocalities(localities);
    }

    const getPostalCodes = async (localityId) => {
        const postalCodes = await getParametricsPostalCodes(localityId);
        setPostalCodes(postalCodes);
    }

    useEffect(() => {
        const getFirstLocalities = async () => {
            const localitiesData = await getParametricsLocalities(getValues('provinceId'));
            const locality = localitiesData?.find(locality => locality.localityId === getValues('locationId'));
            setValue('location', locality.name);
            setLocalities(localitiesData);
        };

        const getFirstPostalCodes = async () => {
            const postalCodesData = await getParametricsPostalCodes(getValues('locationId'));
            const postalCode = postalCodesData?.find(postalCode => postalCode.postalCodeId === getValues('postalCodeId'))
            setValue('postalCode', postalCode.code);
            setPostalCodes(postalCodesData);
        };

        (getValues('provinceId') && getValues('locationId')) && getFirstLocalities();
        (getValues('locationId') && getValues('postalCodeId')) && getFirstPostalCodes();
        if(getValues('countryId')) {
            const countryFiltered = countries?.find(country => country.countryId === getValues('countryId'))
            setSelectedCountry(countryFiltered.name);
        }
    }, [disabled]) //eslint-disable-line
    

    const useAddressTypesStyles = makeStyles({
        formControl: () => ({
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 55,
          borderRadius: 10,
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          '& .MuiSelect-select:focus': {
            backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: 55,
              borderRadius: 10,
              color: '#381940',
              borderColor: (errors?.addressType?.message || errors?.addressType?.type === 'validate') ? 'red !important' : 'transparent !important',
            },
          },
        }),
        root: {
          color: '#381940',
          marginTop: (errors?.addressType?.message || errors?.addressType?.type === 'validate') ? 5 : 0,
          borderRadius: 10,
        },
        empty: {
          color: '#b7b7b7 !important',
          paddingTop: (errors?.addressType?.message || errors?.addressType?.type === 'validate') ? 13.5 : 18.5,
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        filled: {
          color: '#381940 !important',
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        iconStyle: {
          display: 'inline-block',
          color: '#c0c0c0',
          width: '24px',
          pointerEvents: 'none',
        },
        textField: {
          width: '100%',
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          borderRadius: 10,
        },
        cssFocused: {
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          color: '#381940',
          borderRadius: 10,
        },
    });

    const useCountriesStyles = makeStyles({
        formControl: () => ({
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 55,
          borderRadius: 10,
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          '& .MuiSelect-select:focus': {
            backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: 55,
              borderRadius: 10,
              color: '#381940',
              borderColor: (errors?.country?.message || errors?.country?.type === 'validate') ? 'red !important' : 'transparent !important',
            },
          },
        }),
        root: {
          color: '#381940',
          marginTop: (errors?.country?.message || errors?.country?.type === 'validate') ? 5 : 0,
          borderRadius: 10,
        },
        empty: {
          color: '#b7b7b7 !important',
          paddingTop: (errors?.country?.message || errors?.country?.type === 'validate') ? 13.5 : 18.5,
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        filled: {
          color: '#381940 !important',
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        iconStyle: {
          display: 'inline-block',
          color: '#c0c0c0',
          width: '24px',
          pointerEvents: 'none',
        },
        textField: {
          width: '100%',
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          borderRadius: 10,
        },
        cssFocused: {
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          color: '#381940',
          borderRadius: 10,
        },
    });

    const useProvincesStyles = makeStyles({
        formControl: () => ({
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 55,
          borderRadius: 10,
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          '& .MuiSelect-select:focus': {
            backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: 55,
              borderRadius: 10,
              color: '#381940',
              borderColor: (errors?.province?.message || errors?.province?.type === 'validate') ? 'red !important' : 'transparent !important',
            },
          },
        }),
        root: {
          color: '#381940',
          marginTop: (errors?.province?.message || errors?.province?.type === 'validate') ? 5 : 0,
          borderRadius: 10,
        },
        empty: {
          color: '#b7b7b7 !important',
          paddingTop: (errors?.province?.message || errors?.province?.type === 'validate') ? 13.5 : 18.5,
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        filled: {
          color: '#381940 !important',
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        iconStyle: {
          display: 'inline-block',
          color: '#c0c0c0',
          width: '24px',
          pointerEvents: 'none',
        },
        textField: {
          width: '100%',
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          borderRadius: 10,
        },
        cssFocused: {
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          color: '#381940',
          borderRadius: 10,
        },
    });

    const useLocalitiesStyles = makeStyles({
        formControl: () => ({
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 55,
          borderRadius: 10,
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          '& .MuiSelect-select:focus': {
            backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: 55,
              borderRadius: 10,
              color: '#381940',
              borderColor: (errors?.location?.message || errors?.location?.type === 'validate') ? 'red !important' : 'transparent !important',
            },
          },
        }),
        root: {
          color: '#381940',
          marginTop: (errors?.location?.message || errors?.location?.type === 'validate') ? 5 : 0,
          borderRadius: 10,
        },
        empty: {
          color: '#b7b7b7 !important',
          paddingTop: (errors?.location?.message || errors?.location?.type === 'validate') ? 13.5 : 18.5,
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        filled: {
          color: '#381940 !important',
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        iconStyle: {
          display: 'inline-block',
          color: '#c0c0c0',
          width: '24px',
          pointerEvents: 'none',
        },
        textField: {
          width: '100%',
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          borderRadius: 10,
        },
        cssFocused: {
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          color: '#381940',
          borderRadius: 10,
        },
    });

    const usePostalCodesStyles = makeStyles({
        formControl: () => ({
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 55,
          borderRadius: 10,
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          '& .MuiSelect-select:focus': {
            backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: 55,
              borderRadius: 10,
              color: '#381940',
              borderColor: (errors?.postalCode?.message || errors?.postalCode?.type === 'validate') ? 'red !important' : 'transparent !important',
            },
          },
        }),
        root: {
          color: '#381940',
          marginTop: (errors?.postalCode?.message || errors?.postalCode?.type === 'validate') ? 5 : 0,
          borderRadius: 10,
        },
        empty: {
          color: '#b7b7b7 !important',
          paddingTop: (errors?.postalCode?.message || errors?.postalCode?.type === 'validate') ? 13.5 : 18.5,
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        filled: {
          color: '#381940 !important',
          borderRadius: 10,
          fontFamily: 'Poppins',
          width: '90%',
        },
        iconStyle: {
          display: 'inline-block',
          color: '#c0c0c0',
          width: '24px',
          pointerEvents: 'none',
        },
        textField: {
          width: '100%',
          fontFamily: 'Poppins',
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          borderRadius: 10,
        },
        cssFocused: {
          backgroundColor: DisplayUtils.isMobile() ? 'white' : '#f4f5fb',
          color: '#381940',
          borderRadius: 10,
        },
    });

    const addressTypesClasses = useAddressTypesStyles(); 
    const countriesClasses = useCountriesStyles(); 
    const provincesClasses = useProvincesStyles(); 
    const localitiesClasses = useLocalitiesStyles(); 
    const postalCodesClasses = usePostalCodesStyles(); 

    return (
        <div className="AddressForm">
            <h6 className="AddressForm__title">{title || "Dirección del representante"}</h6>
            <div className='row m-0 w-100'>
                <div className={`${DisplayUtils.isMobile() ? 'col-12 p-0' : 'col-4 pe-3 ps-0'} mb-2`}>
                    <label>{t('AddressForm.addressType')}<span style={{ color: '#f838f9' }}>*</span></label>
                    <div className="AddressForm__box">
                        <FormControl className={addressTypesClasses.formControl}>
                            <Controller
                                control={control}
                                name="addressType"
                                rules={{ required: t('Errors.requiredField') }}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            select
                                            classes={{ root: addressTypesClasses.root }}
                                            inputRef={field.ref}
                                            SelectProps={{
                                                IconComponent: () => (
                                                    <img src="/misc/icons/arrow-down.png" className={addressTypesClasses.iconStyle} alt="icon"/>
                                                ),
                                                multiple: false,
                                                displayEmpty: true,
                                                value: field.value,
                                                defaultValue: '',
                                                classes: { select: field.value ? addressTypesClasses.filled : addressTypesClasses.empty },
                                                renderValue: () => field.value ? field.value : t('AddressForm.addressType'),
                                                onChange: (e) => {
                                                    field.onChange(e);
                                                    const type = addressTypes.find(type => type.roadTypeId === e.target.value)
                                                    setValue('addressTypeId',type.roadTypeId)
                                                    setValue('addressType', type.name)
                                                }
                                            }}
                                            error={errors?.addressType?.message}
                                            variant="outlined"
                                            margin="normal"
                                            InputProps={{ classes: { focused: addressTypesClasses.cssFocused } }}
                                            disabled={disabled}
                                        >
                                            {addressTypes?.map((item, index) => (
                                                <MenuItem value={item.roadTypeId} key={index}>
                                                    <ListItemText primary={item.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                    {errors.addressType && (
                        <p className="AddressForm__error">{errors.addressType.message}</p>
                    )}
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'col-12 p-0' : 'col-8 pe-0 ps-2'} mb-2`}>
                    <label>{t('AddressForm.addressName')}<span style={{ color: '#f838f9' }}>*</span></label>
                    <div className="AddressForm__box">
                        <input
                            type="text"
                            maxLength={255}
                            placeholder={t('AddressForm.addressName')}
                            className={`${errors?.address ? 'warning' : ''}`}
                            {...register('address', { required: t('Globals.required') })}
                            disabled={disabled}
                        />
                        <img src="/misc/icons/location-pink.png" alt="icon" style={{ width: '20px' }} />
                    </div>
                    {errors?.address && (
                        <p className="AddressForm__error">{errors?.address?.message}</p>
                    )}
                </div>
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'row m-0 w-100' : 'd-flex justify-content-between gap-4'} mb-2`}>
                <div className={`${DisplayUtils.isMobile() ? 'col-4 p-0 pe-2' : ''} mb-2`}>
                    <label>{t('AddressForm.addressNumber')}<span style={{ color: '#f838f9' }}>*</span></label>
                    <input
                        type="number"
                        maxLength={4}
                        onKeyDown={(e) => handleNumberChange(e)}
                        className={`${errors?.number ? 'warning' : ''} noPadding`}
                        {...register('number', { required: t('Globals.required') })}
                        disabled={disabled}
                    />
                    {errors?.number && (
                        <p className="AddressForm__error">{errors?.number?.message}</p>
                    )}
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'col-4 p-0 ps-2 pe-2' : ''} mb-2`}>
                    <label>{t('AddressForm.addressBloq')}</label>
                    <input
                        type="text"
                        maxLength={4}
                        className={`${errors?.bloq ? 'warning' : ''} noPadding`}
                        {...register('bloq', {})}
                        disabled={disabled}
                    />
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'col-4 p-0 ps-2' : ''} mb-2`}>
                    <label>{t('AddressForm.addressStair')}</label>
                    <input
                        type="text"
                        maxLength={4}
                        className={`${errors?.stair ? 'warning' : ''} noPadding`}
                        {...register('stair', {})}
                        disabled={disabled}
                    />
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'col-4 p-0 pe-2' : ''} mb-2`}>
                    <label>{t('AddressForm.addressFloor')}</label>
                    <input
                        type="text"
                        maxLength={4}
                        className={`${errors?.floor ? 'warning' : ''} noPadding`}
                        {...register('floor', {})}
                        disabled={disabled}
                    />
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'col-4 p-0 ps-2' : ''} mb-2`}>
                    <label>{t('AddressForm.addressDoor')}</label>
                    <input
                        type="text"
                        maxLength={4}
                        className={`${errors?.door ? 'warning' : ''} noPadding`}
                        {...register('door', {})}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className='row m-0 w-100'>
                <div className={`${DisplayUtils.isMobile() ? 'col-12 p-0' : 'col-6 p-0 pe-3'} mb-2`}>
                    <label>{t('AddressForm.addressCountry')}<span style={{ color: '#f838f9' }}>*</span></label>
                    <div className="AddressForm__box">
                        <FormControl className={countriesClasses.formControl}>
                            <Controller
                                control={control}
                                name="country"
                                rules={{ required: t('Errors.requiredField') }}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            select
                                            classes={{ root: countriesClasses.root }}
                                            inputRef={field.ref}
                                            SelectProps={{
                                                IconComponent: () => (
                                                    <img src="/misc/icons/arrow-down.png" className={countriesClasses.iconStyle} alt="icon"/>
                                                ),
                                                multiple: false,
                                                displayEmpty: true,
                                                value: field.value,
                                                defaultValue: '',
                                                classes: { select: field.value ? countriesClasses.filled : countriesClasses.empty },
                                                renderValue: () => field.value ? field.value : t('AddressForm.addressCountry'),
                                                onChange: (e) => {
                                                    console.log(e.target.value);
                                                    field.onChange(e);
                                                    const country = countries.find(country => country.countryId === e.target.value)
                                                    setValue('countryId',country.countryId)
                                                    setValue('country', country.name)
                                                    setValue('province',null)
                                                    setValue('provinceId',null)
                                                    setValue('location',null)
                                                    setValue('locationId',null)
                                                    setValue('postalCode',null)
                                                    setValue('postalCodeId',null)
                                                    clearErrors(["province,provinceId,location,locationId,postalCode,postalCodeId"])
                                                    setSelectedCountry(country.name)
                                                }
                                            }}
                                            error={errors?.country?.message}
                                            variant="outlined"
                                            margin="normal"
                                            InputProps={{ classes: { focused: countriesClasses.cssFocused } }}
                                            disabled={disabled}
                                        >
                                            {countries?.map((item, index) => (
                                                <MenuItem value={item.countryId} key={index}>
                                                    <ListItemText primary={item.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                    {errors.country && (
                        <p className="AddressForm__error">{errors.country.message}</p>
                    )}
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'col-12 p-0' : 'col-6 p-0 ps-2'} mb-2`}>
                    {(!selectedCountry || selectedCountry === 'España') && (
                        <>
                            <label>{t('AddressForm.addressProvince')}<span style={{ color: '#f838f9' }}>*</span></label>
                            <div className="AddressForm__box">
                                <FormControl className={provincesClasses.formControl}>
                                    <Controller
                                        control={control}
                                        name="province"
                                        rules={{ required: t('Errors.requiredField') }}
                                        render={({ field }) => {
                                            return (
                                                <TextField
                                                    select
                                                    classes={{ root: provincesClasses.root }}
                                                    inputRef={field.ref}
                                                    SelectProps={{
                                                        IconComponent: () => (
                                                            <img src="/misc/icons/arrow-down.png" className={provincesClasses.iconStyle} alt="icon"/>
                                                        ),
                                                        multiple: false,
                                                        displayEmpty: true,
                                                        value: field.value,
                                                        defaultValue: '',
                                                        classes: { select: field.value ? provincesClasses.filled : provincesClasses.empty },
                                                        renderValue: () => field.value ? field.value : t('AddressForm.addressProvince'),
                                                        onChange: (e) => {
                                                            field.onChange(e);
                                                            getLocalities(e.target.value)
                                                            const province = provinces.find(province => province.provinceId === e.target.value)
                                                            setValue('provinceId',province.provinceId)
                                                            setValue('province', province.name)
                                                            setValue('location',null)
                                                            setValue('locationId',null)
                                                            setValue('postalCode',null)
                                                            setValue('postalCodeId',null)
                                                            clearErrors(["location,locationId,postalCode,postalCodeId"])
                                                        }
                                                    }}
                                                    error={errors?.province?.message}
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputProps={{ classes: { focused: provincesClasses.cssFocused } }}
                                                    disabled={disabled}
                                                >
                                                    {provinces?.map((item, index) => (
                                                        <MenuItem value={item.provinceId} key={index}>
                                                            <ListItemText primary={item.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </div>
                            {errors.province && (
                                <p className="AddressForm__error">{errors.province.message}</p>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className='row m-0 w-100'>
                <div className={`${DisplayUtils.isMobile() ? 'col-12 p-0' : 'col-6 p-0 pe-3'} mb-2`}>
                    <label>{t('AddressForm.addressLocality')}<span style={{ color: '#f838f9' }}>*</span></label>
                    <div className="AddressForm__box">
                        {(!selectedCountry || selectedCountry === 'España') ? (
                            <FormControl className={localitiesClasses.formControl}>
                                <Controller
                                    control={control}
                                    name="location"
                                    rules={{ required: t('Errors.requiredField') }}
                                    render={({ field }) => {
                                        return (
                                            <TextField
                                                select
                                                classes={{ root: localitiesClasses.root }}
                                                inputRef={field.ref}
                                                SelectProps={{
                                                    IconComponent: () => (
                                                        <img src="/misc/icons/arrow-down.png" className={localitiesClasses.iconStyle} alt="icon"/>
                                                    ),
                                                    multiple: false,
                                                    displayEmpty: true,
                                                    value: field.value,
                                                    defaultValue: '',
                                                    classes: { select: field.value ? localitiesClasses.filled : localitiesClasses.empty },
                                                    renderValue: () => field.value ? field.value : t('AddressForm.addressLocality'),
                                                    onChange: (e) => {
                                                        field.onChange(e);
                                                        getPostalCodes(e.target.value)
                                                        const locality = localities.find(locality => locality.localityId === e.target.value)
                                                        setValue('locationId',locality.localityId)
                                                        setValue('location', locality.name)
                                                        setValue('postalCode',null)
                                                        setValue('postalCodeId',null)
                                                        clearErrors(["postalCode,postalCodeId"])
                                                    }
                                                }}
                                                error={errors?.location?.message}
                                                variant="outlined"
                                                margin="normal"
                                                InputProps={{ classes: { focused: localitiesClasses.cssFocused } }}
                                                disabled={disabled}
                                            >
                                                {localities?.map((item, index) => (
                                                    <MenuItem value={item.localityId} key={index}>
                                                        <ListItemText primary={item.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        );
                                    }}
                                />
                            </FormControl>
                        ) : (
                            <input
                                type="text"
                                maxLength={100}
                                className={`${errors?.location ? 'warning' : ''} noPadding`}
                                {...register('location', { required: t('Globals.required') })}
                                disabled={disabled}
                            />
                        )}
                    </div>
                    {errors.location && (
                        <p className="AddressForm__error">{errors.location.message}</p>
                    )}
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'col-12 p-0' : 'col-6 p-0 ps-2'} mb-2`}>
                    <label>{t('AddressForm.addressPostal')}{(!selectedCountry || selectedCountry === 'España') && <span style={{ color: '#f838f9' }}>*</span>}</label>
                    <div className="AddressForm__box">
                        {(!selectedCountry || selectedCountry === 'España') ? (
                            <FormControl className={postalCodesClasses.formControl}>
                                <Controller
                                    control={control}
                                    name="postalCode"
                                    rules={{ required: t('Errors.requiredField') }}
                                    render={({ field }) => {
                                        return (
                                            <TextField
                                                select
                                                classes={{ root: postalCodesClasses.root }}
                                                inputRef={field.ref}
                                                SelectProps={{
                                                    IconComponent: () => (
                                                        <img src="/misc/icons/arrow-down.png" className={postalCodesClasses.iconStyle} alt="icon"/>
                                                    ),
                                                    multiple: false,
                                                    displayEmpty: true,
                                                    value: field.value,
                                                    defaultValue: '',
                                                    classes: { select: field.value ? postalCodesClasses.filled : postalCodesClasses.empty },
                                                    renderValue: () => field.value ? field.value : t('AddressForm.addressPostal'),
                                                    onChange: (e) => {
                                                        field.onChange(e);
                                                        const postal = postalCodes.find(postal => postal.postalCodeId === e.target.value)
                                                        setValue('postalCodeId',postal.postalCodeId)
                                                        setValue('postalCode', postal.code)
                                                    }
                                                }}
                                                error={errors?.postalCode?.message}
                                                variant="outlined"
                                                margin="normal"
                                                InputProps={{ classes: { focused: postalCodesClasses.cssFocused } }}
                                                disabled={disabled}
                                            >
                                                {postalCodes?.map((item, index) => (
                                                    <MenuItem value={item.postalCodeId} key={index}>
                                                        <ListItemText primary={item.code} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        );
                                    }}
                                />
                            </FormControl>
                        ) : (
                            <input
                                type="text"
                                maxLength={50}
                                className={`${errors?.postalCode ? 'warning' : ''} noPadding`}
                                {...register('postalCode', { required: false })}
                                disabled={disabled}
                            />
                        )}
                    </div>
                    {errors.postalCode && (
                        <p className="AddressForm__error">{errors.postalCode.message}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddressForm;
