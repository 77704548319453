import { useEffect, useState, useRef } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Loading, Steps } from '../../../components';
import { DateUtils, DisplayUtils } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import './TransactionDetails.scss';
import { formatToCurrency } from '../../../utils/Currency';
import { getTransaction, uploadTicket } from '../../../services/transactionsService';
import { useErrorsContext } from '../../../contexts/ErrorsContext';
import { useParametrics } from '../../../contexts/ParametricsContext';
import { getTicketFile, removeTicketFile } from '../../../services/fileServices';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// TODO: Fix ticket 329
const TransactionDetails = () => {

    const { t } = useTranslation();
    const {showError} = useErrorsContext();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [uploaded, setUploaded] = useState(0);
    const { petitionId, transactionId } = useParams();
    const [transactionDetail, setTransactionDetail] = useState(null);
    const [show, setShow] = useState(false);
    const { convocationId } = useParametrics();
    const fileInput = useRef(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [isPdf, setIsPdf] = useState(false);
    const [step, setStep] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const minSwipeDistance = 50;

    const loadImage = async (transaction) => {
      if(transaction?.ticketId) {
        const resp = await getTicketFile(transaction.ticketId);
        const isPdf = resp.headers['content-disposition'].includes('.pdf');
        setIsPdf(isPdf);
        const url = URL.createObjectURL(resp.data);
        setImagePreview(url)
      }
    }

    const getDetails = async () => {
      try {
        setLoading(true);
        const result = await getTransaction(convocationId, petitionId, transactionId);
        setTransactionDetail(result);
        loadImage(result);
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    };

    const deleteTicket = async () => {
      try {
        await removeTicketFile(transactionDetail.id);
        setImagePreview(null);
        fileInput.current.value = null;
        setTransactionDetail({...transactionDetail, ticketId: null});
      } catch (error) {
        showError(error);
      }
    }

    useEffect(() => {
      if (!convocationId) return;
      if(!transactionId) {
        history.goBack()
      } else {
        setShow(true);
        getDetails();
      }
    }, [convocationId]) //eslint-disable-line

    const handleClick = (e) => {
      if(uploadingImage) return;
      e.stopPropagation();
      fileInput.current.click();
    }

    const getFileType = (fileType) => {
      if (fileType === 'image/jpeg') return 'TRANSACTION_TICKET_JPG';
      if (fileType === 'image/png') return 'TRANSACTION_TICKET_PNG';
      if (fileType === 'application/pdf') return 'TRANSACTION_TICKET_PDF';
      return null;
    }

    const fileUpload = async (event) => {
        try {
          setUploadingImage(true);
          setUploaded(0);
          if (!transactionDetail.canAddTicket) return showError('transactionCannotUploadTicket');
          if (event.target.files[0]) {
            const fileType = getFileType(event.target.files[0].type);
            if (!fileType) return showError("invalidFileType")
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('fileType', fileType);
            transactionDetail.ticketId = await uploadTicket(convocationId, petitionId, transactionDetail.id, formData, (percent) => {
              setUploaded(percent);
            });
            await loadImage(transactionDetail);
          }
        } catch (error) {
          showError(error);
        } finally {
          setUploadingImage(false);
          setUploaded(0);
        }
    };

    const handleTouchStart = (e) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
      
    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX)
    }

    const handleEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) {
          isLeftSwipe ? setStep(1) : setStep(0)
        }
    }

    const getResultInfo = (result) => {
        switch(result) {
            case 'CONFIRMED':
                return <p className="TransactionCard__info__result green">Confirmada</p>
            case 'BLOCKED':
                return <p className="TransactionCard__info__result orange">Pendiente</p>
            case 'CANCELLED':
                return <p className="TransactionCard__info__result red">Cancelada</p>
            default:
                return <></>
        }
    }

    const getHeaderView = () => {
      return (
        <>
            <img className='TransactionDetails__container__content__icon' src='/misc/icons/transaction-card-icon.png' alt='icon'/>
            <div className={`TransactionDetails__container__content__separator ${(DisplayUtils.isMobile() && imagePreview) ? 'no-margin-bottom' : ''}`}/>
        </>
      );
    }

    const getInfoView = () => {
     return (
        <div className='TransactionDetails__container__content__info-content__info'>
            {
              transactionDetail?.authorizationCode && (
                <>
                  <span className='TransactionDetails__container__content__info-content__info__title'>{t('Transactions.details.authCode')}</span>
                  <span className='TransactionDetails__container__content__info-content__info__data'>ID {transactionDetail?.authorizationCode}</span>
                </>
              )
            }
            <span className='TransactionDetails__container__content__info-content__info__title'>{t('Transactions.details.date')}</span>
            <span className='TransactionDetails__container__content__info-content__info__data'>{DateUtils.getFormatedDate(transactionDetail?.createdAt)}</span>
            <span className='TransactionDetails__container__content__info-content__info__title'>{t('Transactions.details.price')}</span>
            <span className='TransactionDetails__container__content__info-content__info__data'>{formatToCurrency(transactionDetail?.amount)}</span>
            <span className='TransactionDetails__container__content__info-content__info__title'>{t('Transactions.details.result')}</span>
            {getResultInfo(transactionDetail?.status)}
        </div>
      )
    }

    const getPdfView = () => {
      return (
        <Document file={imagePreview} onContextMenu={(e) => e.preventDefault()}>
          <Page pageNumber={1} />
        </Document>
      )
    }

    const getImageView = () => {
      return (
        <>
            <input type="file" onChange={(e) => fileUpload(e)} hidden accept="image/jpeg, image/png, application/pdf" ref={fileInput} autoComplete="off" />
            <div className={`TransactionDetails__container__content__info-content__image ${(imagePreview) ? 'no-padding' : ''}`} onClick={(e) => handleClick(e)}>
                {uploadingImage && (
                  <div className='TransactionDetails__container__content__info-content__image__progress-bar-container' >
                    <div style={{width: uploaded + "%"}}></div>
                  </div>
                )}
                {!imagePreview && !uploadingImage && (
                    <>
                        <img className='TransactionDetails__container__content__info-content__image__icon' src='/misc/icons/upload.png' alt='icon'/>
                        <span className="TransactionDetails__container__content__info-content__image__title">{t('Transactions.details.uploadTitle')}</span>
                        <span className="TransactionDetails__container__content__info-content__image__description">{t('Transactions.details.uploadDescription')}</span>
                    </>
                )}
                {imagePreview && !uploadingImage && (
                    <>
                        {
                          isPdf ? (
                            getPdfView()
                          ) : (
                            <img className='TransactionDetails__container__content__info-content__image__image' src={imagePreview} />
                          )
                        }
                        <div className='TransactionDetails__container__content__info-content__image__remove' onClick={(e) => {
                            e.stopPropagation()
                            deleteTicket()
                        }}>
                            <img src='/misc/icons/trash-red.png' alt='delete'/>
                        </div>
                    </>
                )}
            </div>
        </>
      )
    }

    const getNoTicketView = () => {
      return (
        <div className={`TransactionDetails__container__content__info-content__image no-padding`}  style={{cursor: 'initial'}}>
          <span className="TransactionDetails__container__content__info-content__image__title">{t('Transactions.details.cantAddTicketTitle')}</span>
          <span className="TransactionDetails__container__content__info-content__image__description">{t('Transactions.details.cantAddTicketWarning')}</span>
        </div>
      );
    }

    const onChangeStep = () => {
        if (!step) {
          setStep(1);
        } else {
          setStep(0);
        }
    };

    const getMobileView = () => {
      return (
          <>
              {!step ? (
                  <div className="TransactionDetails__container__content">
                      {getHeaderView()}
                      <div className='TransactionDetails__container__content__info-content'>
                          {getInfoView()}
                      </div>
                  </div>
              ) : (
                  <div className="TransactionDetails__container__content no-padding-bottom">
                      {getHeaderView()}
                      <div className={`TransactionDetails__container__content__info-content ${(imagePreview) ? 'no-padding' : ''}`}>
                          {transactionDetail.canAddTicket ? getImageView() : getNoTicketView()}
                      </div>
                      </div>
              )}
              <div style={{ width: '190px', marginTop: '37px', margin: "0 auto" }} className="d-flex justify-content-center">
                  <Steps list={[0, 1]} color="#f838f9" step={step} onChangeStep={onChangeStep}/>
              </div>
          </>
      )
    }

    const getDesktopView = () => {
      return (
        <div className="TransactionDetails__container__content">
            {getHeaderView()}
            <div className='TransactionDetails__container__content__info-content'>
                {getInfoView()}
                {transactionDetail.canAddTicket ? getImageView() : getNoTicketView()}
            </div>
        </div>
      )
    }
    
    return (
        show && ( loading ? (
          <div className='vh-100 d-flex justify-content-center align-items-center'>
              <Loading />
          </div>
        ) : (
            <div className='TransactionDetails' onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleEnd}>
                <div className="w-100 d-flex justify-content-center">
                    {!DisplayUtils.isMobile() ? (
                        <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="TransactionDetails__logo" />
                    ) : (
                        <div className="TransactionDetails__title">
                            <Button
                                icon="/misc/icons/arrow-left-blue.svg"
                                action={() => history.goBack()}
                                className="Button__transparent"
                            />
                            <h6>{t('Transactions.details.head')}</h6>
                        </div>
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="TransactionDetails__container">
                        {!DisplayUtils.isMobile() && (
                            <div className="TransactionDetails__container__head">
                                <Button
                                    icon="/misc/icons/close-pink.png"
                                    action={() => history.goBack()}
                                    className="Button__transparent"
                                    styles={{width: '50px'}}
                                />
                                <h6>{t('Transactions.details.head')}</h6>
                            </div>
                        )}
                        {
                          transactionDetail !== null ? (
                            DisplayUtils.isMobile() ? getMobileView() : getDesktopView()
                          ) : null
                        }
                    </div>
                </div>
            </div>
        )
    ));
}

export default TransactionDetails;