import { useState, useEffect, useCallback } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { useGeolocationContext } from '../../../../contexts/GeolocationContext';
import { useGoogleMaps } from '../../../../contexts/GoogleMapsProvider';
import { useShopsContext } from '../../contexts/ShopsContext';
import './Map.scss';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const mapStyles = [
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#938b3e"
      }
    ]
  }
];

export const defaultCenter = {
  lat: 40.416775,
  lng: -3.703790
};

export const defaultZoom = 10;

const Map = () => {
  const { mapsState, selectShop, clusters, fetchClusters, fetchSalesPoints, fitBounds, setFitBounds } = useShopsContext();
  const { isLoaded } = useGoogleMaps();
  const { loaded: geolocationLoaded, position: geolocationPosition } = useGeolocationContext();
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [isUserAction, setIsUserAction] = useState(true);

  let markers = [];

  const mapMoved = async () => {
    if (!map) return;
    const zoom = map.getZoom();
    const bounds = map.getBounds();
    if (!bounds) return;
    const swLat = bounds.getSouthWest().lat();
    const swLng = bounds.getSouthWest().lng();
    const neLat = bounds.getNorthEast().lat();
    const neLng = bounds.getNorthEast().lng();
    if (isNaN(swLat) || isNaN(swLng) || isNaN(neLat) || isNaN(neLng) || isNaN(zoom)) return;
    if (!isUserAction) {
      setIsUserAction(true);
      return;
    }
    fetchClusters({
      zoom, 
      swLat, 
      swLng, 
      neLat, 
      neLng,
    });
  }

  const onLoad = useCallback((map) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  const addMarker = (bounds, markers, cluster, lat, lng, isUserLocation) => {
    const svg = `
      <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="#ff00003b" />
        <circle cx="30" cy="30" r="20" fill="red" />
        <text x="50%" y="55%" alignment-baseline="middle" text-anchor="middle" font-size="15" fill="white">${cluster?.count ?? 0}</text>
      </svg>`;
    const svgUrl = 'data:image/svg+xml;base64,' + btoa(svg);
    let marker = (cluster && cluster.count === 1) ? new window.google.maps.Marker({
      map: map, 
      position: {
        lat: lat, lng: lng
      }, 
      icon: '/misc/icons/bono_local.svg',
      clickable: true,
    }) : new window.google.maps.Marker({
      map: map, 
      position: {
        lat: lat, lng: lng
      }, 
      icon: isUserLocation ? '/misc/icons/location.svg' : {
        url: svgUrl
      },
      clickable: cluster != null && cluster.count < 50,
    });
    if (cluster && cluster.count < 50) marker.addListener('click', () => {
      if (cluster.salesPoints)
        selectShop(cluster.salesPoints[0]);
      else {
        fetchSalesPoints(cluster.key);
      }
    });
    markers.push(marker);
    if (cluster) bounds.extend(
      new window.google.maps.LatLng(lat, lng)
    );
    setBounds(bounds);
  }

  const clearMarkers = () => {
    markers.map(item => {
      item.setMap(null);
    })
    markers = [];
  }

  useEffect(() => {
    if (map != null && clusters != null && clusters.length > 0) {
      clearMarkers();
      const bounds = new window.google.maps.LatLngBounds();
      setBounds(bounds);
      if (geolocationPosition) {
        addMarker(
          bounds, markers, null, geolocationPosition.lat,
          geolocationPosition.lng, true
        );
      }
      clusters?.map(s => {
        addMarker(bounds, markers, s, Number(s.lat), Number(s.lng), false);
      });

      return () => {
        for (const marker of [...markers]) {
          marker.setMap(null);
        }
      }
    }
  }, [clusters, map])

  useEffect(() => {
    if (isLoaded && geolocationLoaded && fitBounds && bounds) {
      setIsUserAction(false);
      map.fitBounds(bounds);
      setFitBounds(false)
    }
  }, [isLoaded, geolocationLoaded, fitBounds, bounds])

  
  return isLoaded && geolocationLoaded ? (
    <div className="Map">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={geolocationPosition ?? defaultCenter}
        zoom={mapsState?.zoom ?? defaultZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onIdle={mapMoved}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: mapStyles,
        }}
      >
      </GoogleMap>
    </div>
  ) : <div className='Map'></div>;
}

export default Map;