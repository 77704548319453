import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components';
import './PassResetCodeSuccess.scss';
import { constants } from '../../../utils';

const PassResetCodeSuccess = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!location?.state?.redirect) {
      history.goBack();
    } else {
      setShow(true);
    }
  }, []); //eslint-disable-line

  return (
    show && (
      <div className="PassResetCodeSuccess">
        <div className="w-100 d-flex justify-content-center">
          <img src="/misc/icons/complete_logo_white.svg" alt="shop-icon" className="PassResetCodeSuccess__logo" />
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="PassResetCodeSuccess__container">
            <div className="row m-0 justify-content-center">
              <div className="row m-0 justify-content-center">
                <img
                  src="/misc/icons/pin-create.png"
                  alt="shop-icon"
                  className="PassResetCodeSuccess__container__icon"
                />
              </div>
              <h6>{t('PassResetCode.success.title')}</h6>
              <p>{t('PassResetCode.success.description')}</p>
            </div>
            <div className="PassResetCodeSuccess__container__buttons">
              <Button
                className="buttonPrimaryLarge"
                text={t('Globals.accept')}
                action={() => {
                  localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
                  localStorage.removeItem(constants.CURRENT_REFRESH_KEY);
                  localStorage.removeItem(constants.CURRENT_ID_TOKEN_KEY);
                  localStorage.removeItem(constants.CURRENT_USER_KEY);
                  localStorage.removeItem(constants.CURRENT_PETITON_STATUS_KEY);
                  localStorage.removeItem(constants.KEEP_SESSION);
                  window.location.assign('/login');
                }}
                styles={{ backgroundColor: '#34fdfe', color: '#381940' }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PassResetCodeSuccess;
