import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShopsContext } from '../../contexts/ShopsContext';
import List from '../../components/list/List';
import Map from '../../components/map/Map';
import Search from '../../components/search/Search';
import ShopSquareItem from '../../components/shopSquareItem/ShopSquareItem';
import './MobileLayout.scss';

const MobileLayout = ({ fromPublic }) => {

  const {
    fetchClusters, selectShop, searchTerm, mobileMapView, setMobileMapView, types
  } = useShopsContext();

  const { t } = useTranslation();
  const [emptySearch, setEmptySearch] = useState(searchTerm === '');
  const [listShops, setListShops] = useState([]);

  useEffect(() => {
    if (searchTerm !== '') setEmptySearch(false);
  }, [searchTerm])

  const renderedShops = listShops?.map(s => (
    <ShopSquareItem key={s.id} shop={s} onClick={() => {
      selectShop(s);
    }} />
  )) ?? [];

  const changeFilter = (selected) => {
    fetchClusters({
      wallet: selected
    })
  }

  return (
    <div className={`MobileLayout ${fromPublic ? 'fromPublic' : ''}`}>
      {mobileMapView && (
        <div className="MobileLayout__map">
          <div className="MobileLayout__map__mapContainer">
            <Map setListShops={setListShops}/>
          </div>
          <div className="MobileLayout__map__shopsListContainer">
            {renderedShops}
          </div>
          <div className="MobileLayout__map__searchContainer">
            <Search onClick={() => {
              if (searchTerm === '') setEmptySearch(true);
              setMobileMapView(false);
            }} />
            <div className='MobileLayout__map__searchContainer__chips'>
              <div className={`MobileLayout__map__searchContainer__chips__item ${!types || types?.length < 1 ? 'selected' : ''}`} onClick={() => changeFilter([])}>{t('Shops.filters.all')}</div>
              <div className={`MobileLayout__map__searchContainer__chips__item ${!types || types?.includes('LIVE_ARTS') ? 'selected' : ''}`} onClick={() => changeFilter(['LIVE_ARTS'])}>{t('Shops.filters.cultural')}</div>
              <div className={`MobileLayout__map__searchContainer__chips__item ${!types || types?.includes('DIGITAL_SUPPORT') ? 'selected' : ''}`} onClick={() => changeFilter(['DIGITAL_SUPPORT'])}>{t('Shops.filters.digital')}</div>
              <div className={`MobileLayout__map__searchContainer__chips__item ${!types || types?.includes('PHYSICAL_SUPPORT') ? 'selected' : ''}`} onClick={() => changeFilter(['PHYSICAL_SUPPORT'])}>{t('Shops.filters.physical')}</div>
            </div>
          </div>
        </div>
      )}
      {!mobileMapView && (
        <div className="MobileLayout__no-map">
          <div className="MobileLayout__no-map__searchContainer">
            <Search backgroundColor="white" autofocus={true} />
          </div>
          <div className="MobileLayout__no-map__button-back-container">
            <div
              className="MobileLayout__no-map__button-back-container__button-back"
              onClick={() => { setMobileMapView(true) }}
            >
              <img src="/misc/icons/arrow-left-pink.svg" alt="" />
            </div>
          </div>
          <div className="MobileLayout__no-map__shopsListContainer">
            <List showEmptySearch={emptySearch} listShops={listShops} />
          </div>
        </div>
      )}
    </div>
  );

}

export default MobileLayout;