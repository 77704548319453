import { createContext, useContext, useEffect, useState } from "react"
import { getConvocationById } from "../services/parametricsService";

const ParametricsContext = createContext();

export const useParametrics = () => useContext(ParametricsContext);

export const ParametricsProvider = ({ children }) => {

  const [convocation, setConvocation] = useState(null);
  const [convocationOpen, setconvocationOpen] = useState(false);

  // eslint-disable-next-line no-undef
  const convocationId = process.env.REACT_APP_CONVOCATION_ID;
  // eslint-disable-next-line no-undef
  const convocationYear = process.env.REACT_APP_CONVOCATION_YEAR;

  const isOpen = (dateFromStr, dateToStr) => {
    const dateFrom = new Date(`${dateFromStr}T00:00:00`);
    const dateTo = new Date(`${dateToStr}T23:59:59`);
    return dateFrom <= new Date() && dateTo >= new Date();
  }

  useEffect(() => {
    const f = async () => {
      try {
        const convocation = await getConvocationById(convocationId);
        setConvocation(convocation);
        const { activeDateFrom, activeDateUntil } = convocation;
        const open = isOpen(activeDateFrom, activeDateUntil)
        setconvocationOpen(open);
      } catch (e) { 
        console.log(e);
      }
    }
    f();
  }, [])

  const value = {
    convocationYear: convocationYear, convocationId, convocation, convocationOpen
  };

  return (
    <ParametricsContext.Provider value={value}>
      {children}
    </ParametricsContext.Provider>
  )
}