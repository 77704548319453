import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Loading } from '../../components';
import { getBeneficiary, onLogin } from '../../services/authServices';
import { constants, DisplayUtils } from '../../utils';
import './Login.scss';
import MainLayout from '../../layouts/mainLayout/MainLayout';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorsContext } from '../../contexts/ErrorsContext';

const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { showError } = useErrorsContext();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      email: localStorage.getItem('email') || '',
    },
  });

  const [rememberMe, setRememberMe] = useState(localStorage.getItem('email'));
  const [loading, setLoading] = useState(false);
  const [callError, setCallError] = useState();
  const { login, removeSso } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [captchaOk, setCaptchaOk] = useState(false);

  const onSubmit = async (data) => {
    try {
      setCallError();
      setLoading(true);
      const response = await onLogin({
        email: data.email,
        password: data.password,
      });
      localStorage.setItem(constants.CURRENT_TOKEN_KEY, response.idToken);
      localStorage.setItem(constants.CURRENT_REFRESH_KEY, response.refreshToken);
      const user = await getBeneficiary();
      login({
        ...user,
        fullName: user.name === null ? null : user.fullName,
      });
      removeSso();
      history.replace('/petitions');
    } catch (error) {
      setCaptchaOk(false);
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onRememberMe = () => {
    if (!rememberMe) {     
      localStorage.setItem(constants.KEEP_SESSION, true);
    } else {
      localStorage.removeItem(constants.KEEP_SESSION);
    }
    setRememberMe(!rememberMe);
  };

  const onChangeRecaptcha = () => {
    setCaptchaOk(true);
  }

  return (
    <div className="Login">
      {loading ? (
        <div className='vh-100 d-flex justify-content-center align-items-center'>
            <Loading />
        </div>
      ) : (
        <>
          <MainLayout
            title={t('LoginCode.title')}
            onBack={() => {
              history.replace('/')
            }}
          >
            <div className="Login__container__form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <label>{t('Globals.email')}</label>
                <div className="Login__container__form__box">
                  <input
                    type="text"
                    placeholder={t('Login.email')}
                    className={`${errors.email || callError ? 'warning' : ''}`}
                    {...register('email', {
                      required: t('Globals.required'),
                    })}
                    onChange={() => setCallError()}
                  />
                  <img src="/misc/icons/email.png" alt="icon" style={{ width: '23px' }} />
                </div>
                {errors.email && (
                  <p className="Login__container__form__error">{errors.email.message}</p>
                )}
                <label>{t('Globals.password')}</label>
                <div className="Login__container__form__box">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('Login.password')}
                    className={`${errors.password || callError ? 'warning' : ''}`}
                    {...register('password', {
                      required: t('Globals.required'),
                      validate: (value) =>
                        constants.PASSWORD_PATTERN.test(value) || t('Errors.notValid'),
                    })}
                    onChange={() => setCallError()}
                  />
                  <img src={showPassword ? '/misc/icons/eye-show.png' : '/misc/icons/eye-hide.png'} alt="icon" style={{ width: showPassword ? '26px' : '24px', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                </div>
                {errors.password && (
                  <p className="Login__container__form__error">{errors.password.message}</p>
                )}
                {callError && (
                  <p className="Login__container__form__error">{t(`Errors.${callError}`)}</p>
                )}
                <div className="d-flex align-middle">
                  <input
                    type="checkbox"
                    name="rememberMe"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={onRememberMe}
                  />{' '}
                  <label htmlFor="rememberMe">{t('Login.remember')}</label>
                </div>
                <div className="d-flex justify-content-center" style={{ marginTop: DisplayUtils.isMobile() ? '58px' : '24px' }}>
                  <ReCAPTCHA
                    sitekey="6LclvPAgAAAAAK7ePe7Kb8sSKkMOjAlkUnrgzOXO"
                    onChange={onChangeRecaptcha}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className="buttonPrimaryLarge"
                    text={t('Login.login')}
                    type="submit"
                    action={ () => {
                      const result = document.getElementsByTagName('input')
                      if(result){
                        setValue('email', result[0]?.value)
                        setValue('password', result[1]?.value)
                      }
                    }}
                    styles={{
                      backgroundColor: '#34fdfe',
                      color: '#381940',
                      marginTop: DisplayUtils.isMobile() ? '58px' : '24px',
                    }}
                    disabled={!captchaOk}
                  />
                </div>
              </form>
              <Link to="/reset-password" className="Login__container__reset">
                {t('Login.recover')}
              </Link>
            </div>
          </MainLayout>
        </>
      )}
    </div>
  );
};

export default Login;
