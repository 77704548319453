import http from "./base-http-service";

export const getCard = async (convocationId, petitionId) =>
  http.get(`beneficiary-api/bcj/v1/${convocationId}/correos/petition/${petitionId}/card`)

export const getTransactions = async (convocationId, petitionId, params) =>
  http.get(`beneficiary-api/bcj/v1/${convocationId}/correos/petition/${petitionId}/card/transaction/search`, {
    params
  })

export const getTransaction = async (convocationId, petitionId, transactionId) =>
  http.get(`beneficiary-api/bcj/v1/${convocationId}/correos/petition/${petitionId}/card/transaction/${transactionId}`);

export const uploadTicket = async (convocationId, petitionId, transactionId, data, onProgress) => {
  return http.post(
    `beneficiary-file-api/v1/correos/${convocationId}/${petitionId}/upload-ticket/${transactionId}`, 
    data, {
      onUploadProgress: progressEvent => {
        // Calcular el porcentaje de progreso
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if(onProgress) onProgress(percentCompleted);
      }
    }
  );
}
  